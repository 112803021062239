import {setMapView_actionType, setMobileActiveList_actionType, setMobileApp_actionType} from "../constants"

const initialState = {
  view: null
};
const mapViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case setMapView_actionType:{
      const { payload } = action
      return {
        ...state,
        view: payload
      }
    }
  }
  return state
}

export default mapViewReducer;
