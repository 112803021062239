import React, {useCallback, useContext, useMemo} from "react";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {
  StyledTitle,
  PlatformBody,
  PlatformSection,
  PlatformSectionItem,
  PlatformIconHolder,
  PlatformSectionItemBody
} from "./Platform-styled";
import {createSymbol, getLayerSymbology} from "../../../../utils/symbologies";
import {iconNameToIcon, svgToBase} from "../../../Icons/layerSvg/clusterAssets";
import {useTranslation} from "react-i18next";
import {ALL_STEPS, EditType} from "../EditorSwiper/EditorSwiper";
import {EditorContext, UpdateType} from "../EditorContextProvider";
import {currAsOfDateFieldName} from "../../../../utils/API";
import LayerIcon from "../../../Icons/LayerIcon";

const baseLineColor = '#34495e';

export const EntryPoints = ['aerodromes', 'eep', 'ports'];

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const InformationIcon = () => (
  <svg stroke="currentColor" fill="#8C9BA5" strokeWidth="0" viewBox="0 0 24 24" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 9.5C12.8284 9.5 13.5 8.82843 13.5 8C13.5 7.17157 12.8284 6.5 12 6.5C11.1716 6.5 10.5 7.17157 10.5 8C10.5 8.82843 11.1716 9.5 12 9.5ZM14 15H13V10.5H10V12.5H11V15H10V17H14V15Z"></path></svg>
)

const Platform = ({editableLayers, handleSetEditableLayer, setStep}) => {
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  const {setUpdateType} = useContext(EditorContext);
  
  const getLayerDefaultSymbol = useCallback((layer)=>{
    const symbology = getLayerSymbology(layer, config);
    
    if (!symbology) {
      
      if (layer.renderer && layer.renderer.defaultSymbol) {
        return {
          type: 'picture-marker',
          url: layer.renderer.defaultSymbol.url
        }
      }
      
      return '' ;
    }
    const symbol = createSymbol(symbology, layer, symbology.defaultIcon, symbology.defaultColor, symbology.defaultSize)
    
    if (symbol.type === 'picture-marker') {
      return {...symbol, icon: iconNameToIcon(symbology.defaultIcon)};
    }
    
    if (layer.layerConfig?.titleLabel === 'roads') {
      return {...symbol, icon: iconNameToIcon(layer.layerConfig?.titleLabel)}
    }
    
    if (symbol.type === 'simple-fill'){
      return symbol;
    }
    
    return {
      type: symbol.type,
      url: svgToBase(symbology.defaultIcon, symbology.defaultColor),
      icon: iconNameToIcon(symbology.defaultIcon)
    }
  },[config])
  
  const entryPointsLayers = useMemo(()=>(
    editableLayers.filter(l=> EntryPoints.includes(l?.layerConfig.titleLabel) && l.visible)
  ),[editableLayers])
  
  const handleSelection = useCallback((layer, updateType) =>{
    setUpdateType(updateType);
    if (layer){
      handleSetEditableLayer(layer);
    } else {
      setStep(ALL_STEPS.entryPoints);
    }
  },[]);
  
  const situationalLayers = useMemo(()=>{
    return editableLayers.filter(l=>{
      return l.fields.some(f=>f.name.includes(currAsOfDateFieldName))
        && l.layerConfig.situationalFields.length > 0
        && !entryPointsLayers.some(el=>el === l)
    })
  },[editableLayers, entryPointsLayers])
  
  const baselineLayers = useMemo(()=>{
    return editableLayers.filter(l=>{
      return l.layerConfig.titleLabel !== 'roadsSituational'
        && (l.layerConfig.baselineFields?.length > 0 || l.layerConfig.situationalFields?.length > 0)
        && !entryPointsLayers.some(el=>el === l)
    })
  },[editableLayers, entryPointsLayers])
  
  return (
    <>
      <PlatformBody>
        {
          (entryPointsLayers.length > 0 || situationalLayers.length > 0) && (
            <>
              <StyledTitle>Make situational update</StyledTitle>
              <PlatformSection>
                {entryPointsLayers.length > 0 && (
                  <PlatformSectionItem onClick={()=>handleSelection(undefined, UpdateType.situational)}>
                    <PlatformIconHolder color={config.opsColor}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="#fff">
                        <path d="M107 71H89.142L72.7201 42.2594C72.5011 41.8767 72.1849 41.5587 71.8035 41.3376C71.4221 41.1164 70.9891 41 70.5482 41H60.3138C58.6528 41 57.4544 42.5891 57.9106 44.1859L65.5716 71H49.4997L42.7496 62C42.2778 61.3703 41.5356 61 40.7496 61H34.5012C32.8746 61 31.6808 62.5281 32.0761 64.1063L36.9996 81L32.0761 97.8937C31.6808 99.4719 32.8746 101 34.5012 101H40.7496C41.5371 101 42.2778 100.63 42.7496 100L49.4997 91H65.5716L57.9106 117.812C57.4544 119.409 58.6528 121 60.3138 121H70.5482C71.4451 121 72.2732 120.519 72.7185 119.741L89.142 91H107C112.523 91 122 86.5234 122 81C122 75.4766 112.523 71 107 71Z" fill="#fff"/>
                      </svg>
                    </PlatformIconHolder>
                    <PlatformSectionItemBody>
                      <span className="title">Edit Entry points</span>
                      <span title={entryPointsLayers.map(l=>capitalizeFirstLetter(l.getLayerTitle(t))).join(', ')} className="subtitle text-elipsis">{entryPointsLayers.map(l=>capitalizeFirstLetter(l.getLayerTitle(t))).join(', ')}</span>
                    </PlatformSectionItemBody>
                    <InformationIcon />
                  </PlatformSectionItem>
                )}
                {
                  situationalLayers.map(layer=>{
                    return (
                      <PlatformSectionItem key={layer.id} onClick={()=>handleSelection(layer, UpdateType.situational)}>
                        <PlatformIconHolder color={config.opsColor}>
                          <LayerIcon layer={layer} config={config} t={t} fill="#fff" selected/>
                        </PlatformIconHolder>
                        <PlatformSectionItemBody>
                          <span className="title">{layer.getLayerTitle(t)}</span>
                          <span title={`Update ${layer.getLayerTitle(t)}`} className="subtitle text-elipsis">Update {layer.getLayerTitle(t)}</span>
                        </PlatformSectionItemBody>
                        <InformationIcon />
                      </PlatformSectionItem>
                    )
                  })
                }
              </PlatformSection>
            </>
          )
        }
        
        {
          (baselineLayers.length > 0 || entryPointsLayers.length > 0) && (
            <>
              <StyledTitle>Work on Baseline Dataset</StyledTitle>
              <PlatformSection>
                {entryPointsLayers.length > 0 && (
                  <PlatformSectionItem onClick={()=>handleSelection(undefined, UpdateType.baseline)}>
                    <PlatformIconHolder color={baseLineColor}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="#fff">
                        <path d="M107 71H89.142L72.7201 42.2594C72.5011 41.8767 72.1849 41.5587 71.8035 41.3376C71.4221 41.1164 70.9891 41 70.5482 41H60.3138C58.6528 41 57.4544 42.5891 57.9106 44.1859L65.5716 71H49.4997L42.7496 62C42.2778 61.3703 41.5356 61 40.7496 61H34.5012C32.8746 61 31.6808 62.5281 32.0761 64.1063L36.9996 81L32.0761 97.8937C31.6808 99.4719 32.8746 101 34.5012 101H40.7496C41.5371 101 42.2778 100.63 42.7496 100L49.4997 91H65.5716L57.9106 117.812C57.4544 119.409 58.6528 121 60.3138 121H70.5482C71.4451 121 72.2732 120.519 72.7185 119.741L89.142 91H107C112.523 91 122 86.5234 122 81C122 75.4766 112.523 71 107 71Z" fill="#fff"/>
                      </svg>
                    </PlatformIconHolder>
                    <PlatformSectionItemBody>
                      <span className="title">Edit Entry points</span>
                      <span title={entryPointsLayers.map(l=>capitalizeFirstLetter(l.getLayerTitle(t))).join(', ')} className="subtitle text-elipsis">{entryPointsLayers.map(l=>capitalizeFirstLetter(l.getLayerTitle(t))).join(', ')}</span>
                    </PlatformSectionItemBody>
                    <InformationIcon />
                  </PlatformSectionItem>
                )}
                {
                  baselineLayers.map(layer=>{
                    const symbol = getLayerDefaultSymbol(layer);
        
                    return (
                      <PlatformSectionItem key={layer.id} onClick={()=>handleSelection(layer, UpdateType.baseline)}>
                        <PlatformIconHolder color={baseLineColor}>
                          <LayerIcon layer={layer} config={config} t={t} fill="#fff" selected/>
                        </PlatformIconHolder>
                        <PlatformSectionItemBody>
                          <span className="title">Edit {layer.getLayerTitle(t)}</span>
                          <span className="subtitle">Add missing {layer.getLayerTitle(t).toLowerCase()}</span>
                        </PlatformSectionItemBody>
                        <InformationIcon />
                      </PlatformSectionItem>
                    )
                  })
                }
              </PlatformSection>
            </>
          )
        }
      </PlatformBody>
    </>
  );
};

export default Platform;
