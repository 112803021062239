import {LoaderOverlay, LoaderWrapper} from "./Loader-styled";

const Loader = ({
  position,
  text,
  size,
  ...props
}) => {
  return (
    <LoaderOverlay position={position} {...props}>
      <LoaderWrapper size={size}>
        <svg viewBox="-75 -75 150 150">
            <circle cx="0" cy="0" r="37.5"></circle>
        </svg>
      </LoaderWrapper>
      <span>{text}</span>
    </LoaderOverlay>
  );
};

export default Loader;
