import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import SharedInformationCard from "../../../SharedInformation/SharedInformationCard/SharedInformationCard";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import {
  clickEventOnFeature,
  getFeatureLatestUpdateDate,
  renderCell
} from "../../../../utils/helper";
import Loader from "../Loader/Loader";
import {getField} from "../../../Report/new/helpers";
import {createSymbol, getLayerSymbology} from "../../../../utils/symbologies";
import {graphicsLayer, view} from "../../../../utils/API";
import {loadModules} from "esri-loader";
import CustomSwitch from "../../../CustomSwitch/CustomSwitch";
import useFilters from "../../../../hooks/useFilters";
import Input from "../../../Report/new/Input/Input";
import {arrowDown16, arrowUp16} from "@esri/calcite-ui-icons";
import {SortButton} from "../../../SharedInformation/SharedInformation-styled";
import {StyledSubmitButton} from "../../../Report/new/Footer/Footer-styled";
import {EditorContext, UpdateType} from "../EditorContextProvider";
import {useSelector} from "react-redux";
import {StyledSearchInputWrapper} from "../Editor-styled";
import {LAYER_EFFECT, pointSymbol} from "../EditorSwiper/EditorSwiper";
import {getFeatureIcon} from "../EditorFields/EditorFields";
import {addLayerEffect, createFeatureGraphic, zoomToFeaturesExtent} from "../helpers";

function elementInViewport(element, {
  height = 0,
  width = 0
}) {
  
  const bounding = element.getBoundingClientRect();
  
  if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= width && bounding.bottom <= height) {
    
    console.log('Element is in the viewport!');
  } else {
    
    console.log('Element is NOT in the viewport!');
  }
}


const compareFeaturesByDate = (a, b, sort = 'descending') => {
  const editDateA = getFeatureLatestUpdateDate(a);
  const editDateB = getFeatureLatestUpdateDate(b);
  
  if (!editDateB && !editDateA) {
    return 1
  }
  if (!editDateA && editDateB)
    return 1;
  
  if (!editDateB && editDateA)
    return -1;
  
  if (sort === 'descending') {
    return (editDateA > editDateB) ? -1 : 1
  } else {
    return (editDateA < editDateB) ? -1 : 1
  }
}

const CheckMarkIcon = () =>(
  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" height="16px" width="16px" xmlns="http://www.w3.org/2000/svg"><polygon fill="#43A047" points="40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9"></polygon></svg>
)

const XIcon = ({fill}) => {
  return <svg stroke="currentColor" fill={fill} strokeWidth="0" viewBox="0 0 512 512" height="16px" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path></svg>
}

const EditableFeaturesList = ({editableLayer, handleSelectFeature, handleNext, isEntryPoints, editableLayers, editType}) => {
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterDropdownOptions, setFilterDropdownOptions] = useState([]);
  const [filterFieldName, setFilterFieldName] = useState('');
  const [sortType, setSortType] = useState('descending');
  const [features, setFeatures] = useState([]);
  const [filterByExtent, setFilterByExtent] = useState(false);
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  const {filters, setFilters} = useFilters({isActive:false, config});
  const initialFilters = useRef(filters);
  const {highlightFeature, resetHighlightFeature, batchUpdateFeatures, setBatchUpdateFeatures, updateType, activeColor} = useContext(EditorContext);
  const {activeModule} = useSelector(state=>state);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [highlightedFeatures, setHighlightedFeatures] = useState([]);
  const cardRef = useRef();
  
  useEffect(()=>{
    resetHighlightFeature();
    addLayerEffect([editableLayer]);
    return ()=>{
      setFilters(initialFilters.current);
    }
  },[editableLayer])
  
  useEffect(()=>{
    if (editableLayer.layerConfig.titleTemplate){
      const res = editableLayer.layerConfig.titleTemplate.replace('{feature.', '');
      const fieldName = res.replace('}', '');
      let _filters = JSON.parse(JSON.stringify(filters))
      let filterArray = [];
      filterArray.push(searchText);
      if (searchText.length > 0) {
        editableLayer.effect = undefined;
      } else if (highlightFeature || batchUpdateFeatures.length > 0) {
        editableLayer.effect = LAYER_EFFECT;
      }
  
      _filters[activeModule][fieldName] = filterArray;
      setFilters(_filters)
    }
  },[searchText])
  
  useEffect(()=>{
    queryLayer();
  
    const watcher = editableLayer.watch("definitionExpression", () => {
      queryLayer();
    })
    
    return ()=>{
      watcher?.remove();
      clearTimeout(timer.current);
    }
  },[filterByExtent, editableLayer])
  
  useEffect(()=>{
    let handler;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) =>{
      if (filterByExtent) {
        handler = reactiveUtils.when(() => !!view?.stationary, (stationary)=>{
          if (!filterByExtent) return;
          queryLayer();
        }, {initial:false})
        
      }
    })
    return ()=>{
      handler?.remove()
    }
  },[filterByExtent, showMessage, editableLayer, batchUpdateFeatures])
  
  useEffect(()=>{
    if (batchUpdateFeatures.length > 0) {
      const symbology = getLayerSymbology(editableLayer, config) || {};
      const {colorMap} = symbology;
      if (!symbology) return;
      
      const newFeatures = batchUpdateFeatures.map(item=>{
        const objectId = item.feature.getObjectId();
        const newFeature = features.find(feat=>feat.getObjectId() === objectId);
        
        if (newFeature){
          const iconName = getFeatureIcon(newFeature, symbology);
          let fieldName;
          
          if (colorMap){
            if (Array.isArray(colorMap.fields)){
              fieldName = colorMap.fields[0];
            } else if (colorMap.field){
              fieldName = colorMap.field
            }
          }
          
          const colorOption = newFeature.attributes[fieldName];
          const color = colorOption ?? symbology.defaultColor;
          
          const symbol = createSymbol(symbology, editableLayer, iconName, color, symbology.defaultSize)
          
          item.feature = newFeature;
          item.graphic.symbol = symbol;
        }
        
        return item;
      })
      
      if (graphicsLayer.graphics.length < newFeatures.length){
        graphicsLayer.removeAll();
        newFeatures.forEach(item=>{
          graphicsLayer.add(item.graphic);
        })
      }
      
      setBatchUpdateFeatures(newFeatures);
    } else {
      graphicsLayer.removeAll();
    }
  },[features])
  
  
  const timer = useRef(0);
  const queryLayer = async () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(()=>{
      setLoading(true);
      const query = {
        where: editableLayer.definitionExpression,
        spatialRelationship: "intersects",
        returnGeometry: true,
        outFields: ['*']
      }
  
      if (filterByExtent){
        query.geometry = view.extent;
      }
  
      editableLayer.queryFeatures(query).then(res=>{
        setLoading(false);
        if (res?.features) {
          setFeatures(res.features);
          if (!filterByExtent){
            zoomToFeaturesExtent(batchUpdateFeatures.length > 0 ? batchUpdateFeatures.map(item=>item.feature) : res.features);
          }
      
          const symbology = getLayerSymbology(editableLayer, config);
      
          let fieldName;
          if (symbology.colorMap){
            if (symbology.colorMap.field){
              fieldName = symbology.colorMap.field
            } else if (symbology.colorMap.fields){
              fieldName = symbology.colorMap.fields[0]
            }
          }
      
          const field = editableLayer.fields.find(field=>field.name === fieldName);
      
          if (field){
            const options = getField(editableLayer, fieldName).map(codedValue=>({
              name: renderCell(field, codedValue.code, t, config),
              code: codedValue.code,
              color: symbology.colorMap.default[codedValue.code]
            }))
        
            setFilterDropdownOptions(options);
            setFilterFieldName(fieldName);
          }
        }
      }).catch(err=>{
        setLoading(false);
      })
    },250)
  }
  
  // const zoomToFeaturesExtent = useCallback((features)=>{
  //   loadModules(["esri/geometry/Polyline"]).then(([Polyline])=>{
  //     const paths = [];
  //     features.map(f=>{
  //       paths.push([f.geometry.longitude, f.geometry.latitude])
  //     })
  //
  //     if (paths.length > 0) {
  //       const polyline = new Polyline({
  //         paths: paths
  //       });
  //       view.goTo(polyline.extent.expand(1.5), {
  //         duration: 100,
  //         zoom: features.length === 1 ? 10 : undefined
  //       })
  //     }
  //   })
  // },[])
  
  const handleClick = (feature, e) =>{
    setSelectedFeatureId(feature.getObjectId());
    clickEventOnFeature(feature, {showPopup:false, duration:500}).then(res=>{
      handleSelectFeature(editableLayer, feature);
    });
  }
  
  const handleSwitchChange = useCallback((value)=>{
    setFilterByExtent(value)
  },[])
  
  const handleSearch = useCallback((e)=>{
    const {value} = e.target;
    setSearchText(value);
  },[features])
  
  const sortedFeatures = useMemo(()=>{
    return features.sort((a,b)=>compareFeaturesByDate(a,b,sortType));
  },[features, sortType])
  
  const handleSort = useCallback(()=>{
    const sort = sortType === 'descending' ? 'ascending' : 'descending';
    setSortType(sort);
  },[sortType])
  
  const handleCancel = useCallback((e)=>{
    e.stopPropagation();
    const hasBatchUpdateFeatures = batchUpdateFeatures.length > 0;
    if (!hasBatchUpdateFeatures){
      editableLayer.effect = undefined;
    }
    
    resetHighlightFeature();
    setSelectedFeatureId(null)
    zoomToFeaturesExtent(hasBatchUpdateFeatures ? batchUpdateFeatures.map(item=>item.feature) : features);
  },[features, resetHighlightFeature, batchUpdateFeatures])
  
  const handleSubmit = useCallback((e)=>{
    e.stopPropagation();
    handleNext();
  },[handleNext])
  
  const handleInputChange = useCallback((e, feature) =>{
    const {checked} = e.target;
    view.whenLayerView(editableLayer).then(lv=>{
      if (checked){
        const newFeatures = [...batchUpdateFeatures];
        
        setHighlightedFeatures(newFeatures);
        createFeatureGraphic(feature, editableLayer).then(graphic=>{
          graphicsLayer.add(graphic);
          newFeatures.push({
            feature,
            graphic
          });
  
          setBatchUpdateFeatures(newFeatures);
          zoomToFeaturesExtent(newFeatures.map(item=>item.feature));
        })
      } else {
        const newFeatures = batchUpdateFeatures.filter(item=>{
          if (item.feature.getObjectId() === feature.getObjectId()){
            // item.handle.remove();
            graphicsLayer.remove(item.graphic);
          }
          return item.feature.getObjectId() !== feature.getObjectId()
        })
        setBatchUpdateFeatures(newFeatures);
      }
    })
  },[batchUpdateFeatures])
  
  useEffect(()=>{
    if (batchUpdateFeatures.length > 0) {
      editableLayer.effect = LAYER_EFFECT;
    } else {
     editableLayer.effect = undefined;
    }
  },[batchUpdateFeatures])
  
  return (
      <>
        <div
          style={{
            position:'sticky',
            top: !isEntryPoints ? 0 : 56,
            background:'#fff',
            zIndex:1,
          }}
        >
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: "4px",
            position:'relative',
            gap: 20,
            minHeight: show ? 30 : 16,
            transition:'all 0.3s',
            paddingBottom: 10,
            paddingTop: 7
          }}>
            <StyledSearchInputWrapper show={show}>
              <Input
                borderColor="#8a8a8a"
                noMargin
                onChange={handleSearch}
                label={`Search ${editableLayer.getLayerTitle(t)}`} placeholder={`Enter name`}
                value={searchText}
                className="feature__search-input"
              >
                <div
                  onClick={()=>{
                    setShow(false);
                    setSearchText('');
                  }}
                  style={{
                    position:'absolute',
                    right:5,
                    top:'50%',
                    transform:'translateY(-50%)',
                    cursor:'pointer',
                    padding:2,
                    display:'flex',
                  }}>
                  <svg stroke="currentColor" fill="#939393" strokeWidth="0" viewBox="0 0 512 512" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path></svg>
                </div>
              </Input>
            </StyledSearchInputWrapper>
            {!show && <div style={{display: "flex", alignItems: "center", gap: 6}}>
              <CustomSwitch
                onColor={activeColor}
                checked={filterByExtent}
                onChange={handleSwitchChange}
                height={12}
                width={24}
                handleDiameter={8}
              />
              <span style={{
                fontSize: 10,
                lineHeight: 1,
                color: "#6a6a6a"
              }}>{t("screen.widget.common.filterByMapExtent")}</span>
            </div>}
            {!show && <SortButton onClick={handleSort}>
              <span>{t("screen.widget.common.sortByDate")}</span>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                     fill={sortType === 'descending' ? activeColor : "#cdcbcb"}>
                  <path d={arrowDown16}/>
                </svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                     fill={sortType === "ascending" ? activeColor : "#cdcbcb"}>
                  <path d={arrowUp16}/>
                </svg>
              </div>
            </SortButton>}
            {
              !show && (
                <div
                  onClick={()=>{
                    const input = document.querySelector('.feature__search-input');
                    if (input){
                        input.focus();
                    }
                    
                    setShow(true);
                  }}
                  style={{
                    width:16,
                    height:16,
                    cursor:'pointer'
                  }}>
                  <svg stroke="currentColor" fill="#8a8a8a" strokeWidth="0" viewBox="0 0 512 512" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M337.509 305.372h-17.501l-6.571-5.486c20.791-25.232 33.922-57.054 33.922-93.257C347.358 127.632 283.896 64 205.135 64 127.452 64 64 127.632 64 206.629s63.452 142.628 142.225 142.628c35.011 0 67.831-13.167 92.991-34.008l6.561 5.487v17.551L415.18 448 448 415.086 337.509 305.372zm-131.284 0c-54.702 0-98.463-43.887-98.463-98.743 0-54.858 43.761-98.742 98.463-98.742 54.7 0 98.462 43.884 98.462 98.742 0 54.856-43.762 98.743-98.462 98.743z"></path></svg>
                </div>
              )
            }
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading && !showMessage && <Loader />}
          {selectedFeatureId !== null && (
            <div style={{
              position: "fixed",
              background: "rgba(255,255,255,0.8)",
              width: "100%",
              height: "100%",
              top: 0,
              zIndex: 2,
            }}/>
          )}
          {
            sortedFeatures.map(feature => (
              <SharedInformationCard
                key={feature.getObjectId()}
                feature={feature}
                config={{
                  ...config,
                  opsColor: activeColor
                }}
                t={t}
                template={editableLayer.layerConfig.titleTemplate}
                onClick={handleClick}
                selected={feature.getObjectId() === selectedFeatureId}
                handleCancel={handleCancel}
                style={{paddingLeft:24}}
              >
                <>
                  {feature.getObjectId() === selectedFeatureId ? <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      cursor: "pointer"
                    }}
                    onClick={handleCancel}
                  >
                    <XIcon fill={activeColor}/>
                  </div> : updateType === UpdateType.situational ? <div style={{
                    position:'absolute',
                    top: 10,
                    left:0,
                  }}>
                    <input
                      style={{cursor:'pointer'}}
                      onClick={(e)=>e.stopPropagation()}
                      onChange={(e)=>{
                        handleInputChange(e, feature);
                      }}
                      type="checkbox"
                      checked={batchUpdateFeatures.findIndex(item=>item.feature.getObjectId() === feature.getObjectId()) > -1}
                    />
                  </div> : null}
                  <StyledSubmitButton bg={activeColor} onClick={handleSubmit} style={{height:'auto'}}>
                    Update
                  </StyledSubmitButton>
                </>
              </SharedInformationCard>
            ))
          }
        </div>
      </>
  );
};

export default EditableFeaturesList;