import SearchCoordinates from "../SearchCoodinates/SearchCoordinates";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {loadModules} from "esri-loader";
import {ALL_STEPS, lineSymbol, pointGraphicSymbol} from "../../../EditorSwiper/EditorSwiper";
import {graphicsLayer, view} from "../../../../../../utils/API";
import {StyledTitle} from "../../../Editor-styled";
import {EditorContext} from "../../../EditorContextProvider";
import useRouteCalculation, {API_KEY} from "../../../../../../hooks/useRouteCalculation";
import Loader from "../../../Loader/Loader";
import useCustomSnackbar from "../../../../../../hooks/useCustomSnackbar";
import Message from "../../../Message/Message";
import {ConfigContext} from "../../../../../../utils/ConfigContext";
import RoadsSituationalManual from "../RoadsSituationalManual";
import {addLayerEffect} from "../../../helpers";
import {geojsonToArcGIS} from "@terraformer/arcgis";

export const getPointGraphics = ({
  graphicsLayer,
  point
}) => {
  const coordinateId = point.coordinate.join('|');
  const graphicsArray = graphicsLayer.graphics.toArray().filter(g=>{
    if (g.id){

      if (g.id.includes(coordinateId)){
        return true;
      }

      if (Array.isArray(g.intersectedPoints) && g.intersectedPoints.includes(coordinateId)){
        return true;
      }

      return false;
    }
    return false;
  });
  return graphicsArray;
}

const EnterCoordinates = ({handleNext, goToStep, editType, editableLayer, setEditType}) => {
  const {clickedPoints, setClickedPoints, resetClickedPoints, setAddedPoints, setShowRoadsEditor, activeColor, showRoadsEditor} = useContext(EditorContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showMessage, setShowMessage] = useState({
    show: false,
    pointIndex: -1,
    loading: false
  });
  
  const [manualDrawPoint, setManualDrawPoint] = useState(null);
  const [calculatedPoints, setCalculatedPoints] = useState([]);
  const [openSnackbar] = useCustomSnackbar();
  const {loading, handleCreateRoute, startLookingPossibleRoutes, errorPoints} = useRouteCalculation(clickedPoints, setAddedPoints, {
    onSuccess: (geometry) => {
      // setAddedPoints([geometry])
      // setShowMessage(true);
      setCalculatedPoints(clickedPoints);
    },
    onError: () => {
      openSnackbar("Couldn't create route, please draw it manually");
      // setShowMessage(true);
      setCalculatedPoints(clickedPoints);
    }
  })
  
  const {config} = useContext(ConfigContext);
  const clickHandler = useRef();
  const moveHandler = useRef();
  
  const handleAddCoordinatePoint = useCallback(async (addedPoint, index = undefined)=>{
    const newPoints = [...clickedPoints];
    const coordinateIndex = index ?? newPoints.findIndex(item=>item.coordinate.length === 0);
  
    const [esriRequest] = await loadModules(["esri/request"]);
  
    try {
      const snapPoints = await esriRequest('https://api.openrouteservice.org/v2/snap/driving-hgv/geojson', {
        method: 'POST',
        responseType: 'json',
        body: JSON.stringify({
          "locations": [addedPoint.coordinate],
          "radius": 6000,
        }),
        headers: {
          'Authorization': API_KEY,
          'Content-type': 'application/json'
        }
      })
      let addedIndex;
      if (coordinateIndex > -1){
        if (newPoints[coordinateIndex].graphic){
          graphicsLayer.remove(newPoints[coordinateIndex].graphic);
          const pointGraphics = getPointGraphics({graphicsLayer, point: newPoints[coordinateIndex]});
          if (pointGraphics.length > 0) {
            graphicsLayer.removeMany(pointGraphics);
          }
        }
        addedPoint.graphic.id = `point-${coordinateIndex}`;
        newPoints[coordinateIndex] = addedPoint;
        addedIndex = coordinateIndex;
      } else {
        graphicsLayer.remove(newPoints[newPoints.length-1].graphic)
        addedPoint.graphic.id = `point-${newPoints.length - 1}`;
        newPoints[newPoints.length-1] = addedPoint;
        addedIndex = newPoints.length-1;
      }
  
      if (snapPoints?.data?.features.length === 0){
        openSnackbar("Couldn't find point within 6000 meters you can adjust the location or draw it manually");
        addedPoint.searchable = false;
        setShowMessage({
          show: true,
          pointIndex: addedIndex
        })
      } else {
        addedPoint.searchable = true;
      }
  
      setClickedPoints(newPoints);
      graphicsLayer.add(addedPoint.graphic);
    } catch (err){
      openSnackbar('Erro while snapping point')
    }
  },[clickedPoints]);
  
  useEffect(()=>{
    addLayerEffect([view.map.layers.find(l=>l.layerConfig.titleLabel === 'roads')])
    if (clickedPoints.filter(item=>item.coordinate.length > 0).length > 0){
      resetClickedPoints();
      setAddedPoints([]);
    }
    
    return ()=>{
      setShowRoadsEditor(false);
    }
  },[])
  
  useEffect(()=>{
    loadModules(["esri/Graphic", "esri/geometry/geometryEngine", "esri/geometry/support/webMercatorUtils"]).then(([Graphic, geometryEngine, webMercatorUtils])=>{
      if (clickHandler.current){
        clickHandler.current.remove();
      }
      
      if (moveHandler.current){
        moveHandler.current.remove();
      }
      
      moveHandler.current = view.on('pointer-move', (event)=>{
        
        view.hitTest(event).then((res) => {
          const firstRes = res?.results.filter((res) => res.graphic.layer?.layerConfig?.titleLabel === 'roads' || res.graphic.sourceLayer?.layerConfig?.titleLabel === 'roads').at(0);
          if (!firstRes?.graphic) {
            view.container.style.cursor = 'default';
            return;
          }
          view.container.style.cursor = 'pointer';
        })
      })
      
      
      clickHandler.current = view.on('click', (event)=> {
        view.hitTest(event).then((res) => {
          const firstRes = res?.results.filter((res) => res.graphic.layer?.layerConfig?.titleLabel === 'roads' || res.graphic.sourceLayer?.layerConfig?.titleLabel === 'roads').at(0);
          if (!firstRes?.graphic) {
            openSnackbar('Please click on the nearby road', 1000);
            return;
          }
          view.container.style.cursor = 'progress';
          firstRes.graphic.layer.queryFeatures({
            where: `${firstRes.graphic.layer.definitionExpression} AND ${firstRes.graphic.getObjectId()} = OBJECTID`,
            returnGeometry: true
          }).then(res=>{
            if (res?.features.length === 0) return;
            view.container.style.cursor = 'default';
            const feature = res.features[0];
            const geometry = webMercatorUtils.geographicToWebMercator(feature.geometry);
  
            // const mapPointGeographic = webMercatorUtils.webMercatorToGeographic(event.mapPoint);
            const nearestCoordinate = geometryEngine.nearestCoordinate(geometry, event.mapPoint);
            const nearestMapPoint = [nearestCoordinate.coordinate.longitude, nearestCoordinate.coordinate.latitude];
            
            const graphic = new Graphic({
              geometry: nearestCoordinate.coordinate,
              symbol: pointGraphicSymbol
            });
  
            const newPoint = {
              coordinate: nearestMapPoint,
              graphic: graphic,
              label: nearestMapPoint.join(', ')
            }
  
            handleAddCoordinatePoint(newPoint, activeIndex >= 0 ? activeIndex : undefined)
          }).catch(err=>{
            view.cursor = 'default';
            console.log(err);
          })
          // clickedFeaturesIds.push(firstRes?.graphic.getObjectId());
        })
      })
    })
    
    
    return () => {
      clickHandler.current?.remove();
      moveHandler.current?.remove();
      view.container.style.cursor = 'default';
    }
  },[clickedPoints, activeIndex])
  
  const addPointToMap = useCallback((feature, index)=>{
    loadModules(["esri/Graphic", "esri/geometry/support/webMercatorUtils"]).then(([Graphic, webMercatorUtils])=>{
      if (!feature?.geometry) return;
      let geometry = feature.geometry;
      if (geometry?.spatialReference?.isGeographic){
        geometry = webMercatorUtils.geographicToWebMercator(feature.geometry)
      }
      
      if (geometry.latitude && geometry.longitude){
        const nearestMapPoint = [geometry.longitude, geometry.latitude]
        const point = {
          type: 'point',
          longitude: geometry.longitude,
          latitude: geometry.latitude,
          spatialReference:view.spatialReference
        }
        
        const graphic = new Graphic({
          geometry: point,
          symbol: pointGraphicSymbol,
        });
        
        handleAddCoordinatePoint({
          coordinate: nearestMapPoint,
          graphic: graphic,
          label: feature.name ?? nearestMapPoint.join(', ')
        }, index)
      }
    })
  },[handleAddCoordinatePoint])
  
  const handleRemoveSearchPoint = useCallback((index, removeInput)=>{
    const newClickedPoints = [...clickedPoints];
    const point = newClickedPoints[index];
    
    if (!point) return;
    
    if (point.graphic){
      graphicsLayer.remove(point.graphic);
  
      const routeId = point.coordinate.join('|');
      const pointsToRemove = [];
      
      graphicsLayer.graphics.forEach(g=>{
        if (g.id){
          if (g.id.includes(routeId)){
            pointsToRemove.push(g);
          } else if (g.id.includes('line') && Array.isArray(g.intersectedPoints) && g.intersectedPoints.includes(point.coordinate.join('|'))) {
            pointsToRemove.push(g);
          }
        }
      })
  
      if (pointsToRemove.length > 0) {
        graphicsLayer.removeMany(pointsToRemove);
      }
    }
    
    if (removeInput){
      newClickedPoints.splice(index, 1);
    } else {
      newClickedPoints[index] = {
        coordinate: [],
        label: '',
        graphic: '',
        searchable: true
      }
    }
    
    setClickedPoints(newClickedPoints);
  },[clickedPoints])
  
  const handleSuggestPoint = useCallback(async ()=>{
    try {
      setShowMessage(prev=>({
        ...prev,
        loading: true
      }))
      const newClickedPoints = [...clickedPoints];
      const newPoint = {...newClickedPoints[showMessage.pointIndex]};
  
      const [Graphic, Point, Polyline, Polygon, geometryEngineAsync, webMercatorUtils, esriRequest] = await loadModules([
        "esri/Graphic",
        "esri/geometry/Point",
        "esri/geometry/Polyline",
        "esri/geometry/Polygon",
        "esri/geometry/geometryEngineAsync",
        "esri/geometry/support/webMercatorUtils",
        "esri/request",
      ])
  
      const {data} = await esriRequest('https://api.openrouteservice.org/geocode/reverse?api_key=' + API_KEY, {
        method: 'GET',
        responseType: 'json',
        query:{
          'point.lon': newPoint.coordinate[0],
          'point.lat': newPoint.coordinate[1],
          layers: ['street'],
          'boundary.circle.radius': 1000,
          size:10
        },
      });
  
      if (data.features.length > 0){
        const result = geojsonToArcGIS(data);
        const [nearestFeature] = result;
    
        // data.features.forEach(feat=>{
        //   console.log(feat);
        //   const p = new Point({
        //     latitude: feat.geometry.y,
        //     longitude: feat.geometry.x,
        //     spatialReference: feat.geometry.spatialReference
        //   })
        //
        //   const graphic = new Graphic({
        //     geometry: p,
        //     symbol: pointGraphicSymbol
        //   })
        //
        //   graphicsLayer.add(graphic);
        // })
        
        const p = new Point({
          latitude: nearestFeature.geometry.y,
          longitude: nearestFeature.geometry.x,
          spatialReference: nearestFeature.geometry.spatialReference
        })
  
        const geometry = webMercatorUtils.geographicToWebMercator(p);
    
        const graphic = new Graphic({
          geometry: geometry,
          symbol: pointGraphicSymbol
        })
        
        
        newPoint.graphic.geometry = geometry;
        newPoint.coordinate = [nearestFeature.geometry.x, nearestFeature.geometry.y];
        newPoint.label = newPoint.coordinate.join(', ');
        newPoint.searchable = true;
        view.goTo(newPoint.graphic);
        
        newClickedPoints[showMessage.pointIndex] = newPoint;
        setClickedPoints(newClickedPoints);
    
        // graphicsLayer.add(graphic);
        // const polyline = new Polyline({
        //   paths: [
        //     [nearestFeature.geometry.y - 1, nearestFeature.geometry.x + 1],
        //     [addedPoint.coordinate[0], addedPoint.coordinate[1]]
        //   ]
        // })
        //
        //
        // editableLayer.queryFeatures({
        //   geometry: polyline,
        //   spatialRelationship: "intersects"
        // }).then(res=>{
        //   if (res.features.length > 0){
        //     console.log(res);
        //   } else {
        //     console.log('none');
        //   }
        // })
      }
    } catch (err){
      console.log(err);
    } finally {
      setShowMessage({
        show:false,
        pointIndex: -1,
        loading: false
      })
    }
  },[clickedPoints, showMessage])
  
  return (
    <div style={{paddingTop:8}}>
      {loading && <Loader />}
      {showMessage.show && <Message
        config={config}
        onCancel={()=>{
          setShowMessage({
            show:false,
            pointIndex: -1
          })
        }}
        onSubmit={handleSuggestPoint}
      >
        <>
          <p style={{
            color: "#525252",
            textAlign: "center",
            background: "#fff",
            padding: 2,
            borderRadius: 4,
            fontWeight: 500,
            fontSize: 16,
            marginBottom: 0
          }}>Couldn't find routable point withing a radius of 6000 meters.</p>
          <p
            style={{
              color: "#525252",
              textAlign: "center",
              background: "#fff",
              padding: 2,
              borderRadius: 4,
              fontSize: 12,
              marginTop: 4
            }}>Do you want to select a nearby routable point instead?</p>
          {
            showMessage.loading && (
              <Loader />
            )
          }
        </>
      </Message>}
      <StyledTitle>Indicate the starting and end points</StyledTitle>
      <SearchCoordinates
        setClickedPoints={setClickedPoints}
        addPointToMap={addPointToMap}
        clickedPoints={clickedPoints}
        removeSearchPoint={handleRemoveSearchPoint}
        handleCreateRoute={handleCreateRoute}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        color={activeColor}
        errorPoints={errorPoints}
        calculatedPoints={calculatedPoints}
        setManualDrawPoint={setManualDrawPoint}
      />
      {
        showRoadsEditor && (
          <RoadsSituationalManual
            handleNext={handleNext}
            handlePrev={()=>{
              setShowRoadsEditor(false);
            }}
            manualDrawPoint={manualDrawPoint}
            editType={editType}/>
        )
      }
    </div>
  );
};

export default EnterCoordinates;
