import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {renderCell} from "../../../../utils/helper";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import LayerTable, {formatToDate, generateColumn, generateRows} from "../../../LayerTable/LayerTable";
import {DomainFilter} from "../../../FeatureTable/TableGrid/CustomFilters";
import {currAsOfDateFieldName, graphicsLayer} from "../../../../utils/API";
import {
  createFeatureGraphic,
  getFeatureNameField,
  getSituationalBatchFields,
  zoomToFeaturesExtent
} from "../../Editor/helpers";
import {EditType, LAYER_EFFECT} from "../../Editor/EditorSwiper/EditorSwiper";
import {EditorContext} from "../../Editor/EditorContextProvider";
import Loader from "../../Editor/Loader/Loader";
import {calculateMissingFeatures} from "../MonitoringDashboard/MonitoringDashboard";
import {StyledArrowButton, StyledSubmitButton} from "../../../Report/new/Footer/Footer-styled";
import {StyledCheckListFooter, StyledCheckListTableWrapper} from "../Monitoring-styled";
import {RightArrowIcon} from "../../../Panel/components/Pagination/helpers";

const MissingIcon = () =>(
  <svg stroke="white" fill="orange" strokeWidth="0" viewBox="0 0 512 512" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
  </svg>
)

const Outdated = () => (
  <svg stroke="currentColor" fill="orange" strokeWidth="0" viewBox="0 0 512 512" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path d="M228.9 79.9L51.8 403.1C40.6 423.3 55.5 448 78.9 448h354.3c23.3 0 38.2-24.7 27.1-44.9L283.1 79.9c-11.7-21.2-42.5-21.2-54.2 0zM273.6 214L270 336h-28l-3.6-122h35.2zM256 402.4c-10.7 0-19.1-8.1-19.1-18.4s8.4-18.4 19.1-18.4 19.1 8.1 19.1 18.4-8.4 18.4-19.1 18.4z" />
  </svg>
)

const UpToDate = () => (
  <svg stroke="currentColor" fill="green" strokeWidth="0" viewBox="0 0 512 512" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm106.5 150.5L228.8 332.8h-.1c-1.7 1.7-6.3 5.5-11.6 5.5-3.8 0-8.1-2.1-11.7-5.7l-56-56c-1.6-1.6-1.6-4.1 0-5.7l17.8-17.8c.8-.8 1.8-1.2 2.8-1.2 1 0 2 .4 2.8 1.2l44.4 44.4 122-122.9c.8-.8 1.8-1.2 2.8-1.2 1.1 0 2.1.4 2.8 1.2l17.5 18.1c1.8 1.7 1.8 4.2.2 5.8z" />
  </svg>
)

const UpToDateButUnknown = () =>(
  <svg stroke="currentColor" fill="green" strokeWidth="0" viewBox="0 0 16 16" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
  </svg>
)

const UpdateCellRenderer = ({params, layer}) => {
  
  const renderIcon = useCallback(() => {
    switch (params.value) {
      case 'missing':{
        return <MissingIcon />
      }
      
      case 'outdated':{
        return <Outdated />
      }
      
      case 'up-to-date':{
        return <UpToDate />
      }
      
      case 'up-to-date-but-status-unknown':{
        return <UpToDateButUnknown />
      }
    }
  },[params.value])
  
  
  return <div>
    {renderIcon()}
  </div>
}

const yesterday = new Date() - (24 * 60 * 60 * 1000);
const updateValueFormatter = (value, hasStatus) => {
  if (value === null) {
    return 'missing'
  } else if (value < yesterday){
    return 'outdated'
  } else if (value >= yesterday) {
    if (hasStatus){
      return 'up-to-date'
    } else {
      return 'up-to-date-but-status-unknown'
    }
  }
}

const generateUpdateColumn = ({
                                config, layer, t
                              }) =>{
  const defaultColumn = {
    field: 'update',
    headerName: 'Update',
    minWidth: 90,
    filter: true,
    editable: false,
    cellDataType: undefined,
    cellRenderer: (params)=>{
      return <UpdateCellRenderer layer={layer} params={params} />
    },
  }
  
  const field = {
    name: 'update',
    domain: {
      codedValues:[
        {
          code: 'missing',
          name: 'Missing'
        },
        {
          code: 'outdated',
          name: 'Outdated'
        },
        {
          code: 'up-to-date',
          name: 'Up to date'
        },
        {
          code: 'up-to-date-but-status-unknown',
          name: 'Up-to-date but status is unknown'
        },
      ]
    }
  }
  
  //showing select with values
  defaultColumn.cellEditor = "agSelectCellEditor"
  const values = [];
  let refData = {};
  field.domain.codedValues.forEach(cv=>{
    values.push(cv.code)
    refData = {
      ...refData,
      [cv.code]: renderCell(field, cv.code, t, config)
    }
  })
  
  //Implemented custom dropdown filter for domains
  defaultColumn.filter = (params) => <DomainFilter layer={layer} field={field} {...params} />
  
  defaultColumn.refData = refData;
  defaultColumn.cellEditorParams = {
    values
  }
  return defaultColumn;
}
const ChecklistTable = ({layer, handleNext, layerUpdates, setLayerUpdates, handlePrev, editedFeatures, setEditedFeatures}) => {
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [features, setFeatures] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  const {setBatchUpdateFeatures, batchUpdateFeatures} = useContext(EditorContext);
  
  useEffect(() => {
    if (!layer || loading) return;
    const updatePeriod = new Date() - (24 * 60 * 60 * 1000);
    const newSituationalFields = [...getSituationalBatchFields({editableLayer: layer, config})]; //getFieldsByPattern(layer, layer.layerConfig.situationalFields, config.role !== ROLE_EDITOR);
    
    const name = getFeatureNameField(layer);
    const nameField = layer.fields.find(f=>f.name === name);
    if (nameField){
      newSituationalFields.unshift(nameField);
    }
    const fetchLayer = async (fields) => {
      const outFields = [...fields.map(field => field.name), layer.objectIdField];
      
      const query = {
        outFields,
        where: layer.definitionExpression,
        returnGeometry: true
      }
      
      const result = await layer.queryFeatures(query);
      
      const layerUpdate = layerUpdates[layer.id] ? {...layerUpdates[layer.id]} : {};
      layerUpdate.features = result.features;
      layerUpdate.missing = calculateMissingFeatures(result.features, updatePeriod);
      setLayerUpdates(prev=>({
        ...prev,
        [layer.id]: layerUpdate
      }))
      
      const tableRows = generateRows({
        features: result.features,
        fields: fields,
        layer,
        config,
        t
      })
      
      const batchUpdateFeaturesIds = batchUpdateFeatures.map(item=>item.feature.attributes[layer.objectIdField])
      
      const rows = tableRows.map(item=>{
        const rowColorMapFields = Object.keys(item.rowColorMap);
        const emptyValues = rowColorMapFields.filter(fieldName=> !item.feature.attributes[fieldName]);
        
        return {
          ...item,
          featureSelect: batchUpdateFeaturesIds.includes(item[layer.objectIdField]),
          update: updateValueFormatter(item.feature.attributes[currAsOfDateFieldName], emptyValues.length === 0),
        }
      })
      
      setRows(rows);
      setFeatures(result.features);
    }
    
    const columns = newSituationalFields.map(field=>{
      return generateColumn({field, config, layer, t})
    })
    
    columns.unshift(generateColumn({
      field: {name: 'featureSelect'},
      config,
      layer,
      t,
      isEditable: false
    }))
    
    columns.unshift(generateUpdateColumn({
      config,
      layer,
      t
    }))
    
    setColumns(columns);
    setEditedFeatures({});
    fetchLayer(newSituationalFields);
  },[layer, config, loading])
  
  useEffect(()=>{
    if (!layer) return
    
    if (batchUpdateFeatures.length > 0) {
      layer.effect = LAYER_EFFECT;
      zoomToFeaturesExtent(batchUpdateFeatures.map(item=>item.feature));
    } else if (layer.effect) {
      layer.effect = undefined;
      zoomToFeaturesExtent(features);
    }
  },[batchUpdateFeatures])
  
  const onCellClicked = useCallback((event)=>{
    const {colDef, data, node, value} = event;
    if (colDef.field === 'featureSelect') {
      const objecIdField = layer.objectIdField;
      const feature = data.feature;
      const featureObjectId = feature.attributes[objecIdField];
      const newData = data;
      
      if (!value) {
        newData[colDef.field] = true;
        node.updateData(newData)
        createFeatureGraphic(feature, layer).then(graphic=>{
          graphicsLayer.add(graphic);
          setBatchUpdateFeatures(prev=>prev.concat({
            feature,
            graphic
          }));
        })
      } else {
        
        newData[colDef.field] = false;
        node.updateData(newData)
        setBatchUpdateFeatures(prev=>prev.filter(item=> item.feature.attributes[objecIdField] !== featureObjectId));
        const graphic = graphicsLayer.graphics.find(g=>g.attributes[objecIdField] === featureObjectId);
        
        if (graphic){
          graphicsLayer.remove(graphic);
        }
      }
    }
  },[features, layer])
  
  const onCellValueChanged = useCallback((event) => {
    const {colDef, data, node, value, column, oldValue} = event;
    
    const objectIdField = data.objectIdField;
    const objectId = data[objectIdField];
    const feature = features.find(feat=>feat.attributes[objectIdField] === objectId)
    const featureValue = feature.attributes[colDef.field];
    
    let newValue = event.newValue === 'NULL' ? null : event.newValue;
    if (featureValue === newValue){
      const {[objectId]: attributes, ...restEditedFeatures} = editedFeatures;
      const {[colDef.field]: changedField, ...restAttributes} = attributes || {};
      
      if (Object.values(restAttributes).length > 0){
        restEditedFeatures[objectId] = restAttributes;
      }
      
      colDef.cellClass = (p) => {
        const isDirty = restAttributes[p.colDef.field] !== undefined;
        return isDirty ? "ag-cell-dirty" : '';
      };
      
      setEditedFeatures(restEditedFeatures);
    } else {
      if (colDef.cellDataType === 'date'){
        if (newValue) {
          const formattedValue = newValue.getTime(); // - (newValue.getTimezoneOffset() * 60000);
          feature.attributes[colDef.field] = formattedValue;
          data[colDef.field] = formatToDate(newValue);
          newValue = formattedValue;
        } else {
          data[colDef.field] = oldValue;
        }
        node.updateData(data)
      }
      
      const newEditedFeatures = {
        ...editedFeatures,
        [objectId]: {
          ...editedFeatures[objectId],
          [colDef.field]: newValue
        }
      }
      setEditedFeatures(newEditedFeatures)
      
      colDef.cellClass = (p) => {
        const featureId = p.data[objectIdField];
        let isDirty = false;
        if (editedFeatures[featureId]){
          isDirty = editedFeatures[featureId][p.colDef.field] !== undefined
        }
        
        if (p.rowIndex.toString() === node.id){
          isDirty = true;
        }
        return isDirty ? "ag-cell-dirty" : undefined;
      };
    }
    
    event.api.refreshCells({
      columns: [column.getId()],
      rowNodes: [node],
      force: true // without this line, the cell style is not refreshed at the first time
    });
    
  },[features, editedFeatures])
  
  const handleSave = useCallback(()=>{
    const updateFeatures = [];
    const objectIdField = layer.objectIdField;
    
    const newFeatures = features.map(feat=>{
      const newAttributes = editedFeatures[feat.attributes[objectIdField]] || {};
      
      if (Object.keys(newAttributes).length > 0){
        const newFeature = feat.clone();
        newAttributes[objectIdField] = feat.attributes[objectIdField];
        
        if (!newAttributes[currAsOfDateFieldName]) {
          newAttributes[currAsOfDateFieldName] = new Date().getTime();
        }
        newFeature.attributes = newAttributes;
        Object.keys(newAttributes).forEach(k=>{
          feat.attributes[k] = newAttributes[k]
        });
        
        updateFeatures.push(newFeature);
      }
      return feat.clone();
    })
    setLoading(true);
    layer.applyEdits({
      updateFeatures: updateFeatures
    }).then(res=>{
      layer.refresh();
      gridApi.refreshCells();
      setEditedFeatures({});
      setFeatures(newFeatures);
      setLoading(false);
    })
  },[layer, editedFeatures, features])
  
  const isEditing = useMemo(()=>{
    return Object.values(editedFeatures).length > 0 || batchUpdateFeatures.length > 0
  },[editedFeatures, batchUpdateFeatures])
  
  if (!layer) return null
  
  return (
    <div style={{
      display:'flex',
      flexDirection:'column',
      height:'100%'
    }}>
      {loading && <Loader />}
      <StyledCheckListTableWrapper>
        <LayerTable
          rows={rows}
          columns={columns}
          layer={layer}
          features={features}
          setGridApi={setGridApi}
          onCellClicked={onCellClicked}
          onCellValueChanged={onCellValueChanged}
          loading={loading}
        />
      </StyledCheckListTableWrapper>
      <StyledCheckListFooter>
        <div>
          <StyledArrowButton
            bg={config.opsColor}
            style={{
              borderRadius: 8,
              overflow: "hidden",
              width: 34,
              justifyContent: "center"
            }}
            onClick={handlePrev}
          >
            <RightArrowIcon
              width={18}
              color="#FFFFFF"
            />
          </StyledArrowButton>
        </div>
        <div style={{display:"flex", gap:8}}>
          <StyledSubmitButton disable={Object.keys(editedFeatures).length === 0} onClick={handleSave}>Save</StyledSubmitButton>
          <StyledSubmitButton disable={batchUpdateFeatures.length === 0} onClick={handleNext}>Batch Update{batchUpdateFeatures.length > 0 ? ` (${batchUpdateFeatures.length})` : ''}</StyledSubmitButton>
        </div>
      </StyledCheckListFooter>
    </div>
  );
};

export default ChecklistTable;
