import styled from "styled-components";
import {CalciteLoader, CalciteProgress, CalciteSwitch} from "@esri/calcite-components-react";

export const StyledApp = styled.div`
	display: flex;
	flex-direction: column;
  	height: 100%
`

export const StyledLoader = styled(CalciteLoader)`
	margin: 10rem 0 0;
  color: var(--opsColor);
  --calcite-color-brand: var(--opsColor);
  stroke: var(--opsColor) !important;
`

export const StyledProgress = styled(CalciteProgress)`
  --calcite-color-brand: var(--opsColor);
`

export const CustomLoader = styled(CalciteLoader)`
  padding: 0;
  color: ${({color})=> color ? color  : 'var(--opsColor)'};
  --calcite-color-brand: ${({color})=> color ? color  : 'var(--opsColor)'};
  stroke: ${({color})=> color ? color  : 'var(--opsColor)'} !important;
  --calcite-loader-size: ${({fontSize})=> fontSize ?? '1.5rem'};
`

export const CustomSwitch = styled(CalciteSwitch)`
  --calcite-color-brand: var(--opsColor);
  --calcite-color-brand-hover: var(--opsColor);
  --calcite-ui-focus-color: var(--opsColor);
`

export const StyledMapSurveyContainer = styled.div`
	display: flex;
	justify-content: center;
  flex-grow: 2;
  text-align: center;
  position: relative;
`

export const StyledDateTime = styled.span`
  font-size: 10px;
  color: #999;
  font-weight: 500;
  display: inline-block;
`
