import React, {useMemo} from "react";
import {StyledBody} from "../SidebarMenu/SidebarMenu-styled";
import {StyledSubmitButton} from "../../Report/new/Footer/Footer-styled";
import {getOpsColor} from "../../../utils/helper";
import {useSelector} from "react-redux";

const CustomizationSelection = ({config, setAddCustomizations, handleNext, t}) => {
  const {activeModule} = useSelector(state=>state);
  const opsColor = useMemo(()=>getOpsColor(config, activeModule),[config, activeModule])
  
  return (
    <StyledBody style={{justifyContent:'center'}}>
      <div style={{borderBottom:'none', display:'flex', alignItems:'center', flexDirection:'column', gap:4, paddingBottom:0}}>
        <p style={{marginBottom:0, textAlign:'center'}}>{t('print.publicCustomizations.questions.1.title')}</p>
        <span style={{textAlign:'center'}}>{t('print.publicCustomizations.questions.1.subtitle')}</span>
      </div>
      <div style={{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        gap:20
      }}>
        <StyledSubmitButton onClick={()=>{
          setAddCustomizations(true);
          handleNext(true);
        }} bg={opsColor}>
          <span>{t('print.publicCustomizations.questions.1.yes')}</span>
        </StyledSubmitButton>
        <StyledSubmitButton onClick={()=>{
          setAddCustomizations(false)
          handleNext(false);
        }} bg={opsColor}>
          <span>{t('print.publicCustomizations.questions.1.no')}</span>
        </StyledSubmitButton>
      </div>
    </StyledBody>
  );
};

export default CustomizationSelection;
