import {setPrintWidgetOpen_actionType, setPrintWidgetStep_actionType, setPrintWidgetLoading_actionType} from "../constants";

export const setPrintWidgetOpen = (payload) => ({
  type: setPrintWidgetOpen_actionType,
  payload
})

export const setPrintWidgetStep = (payload) => ({
  type: setPrintWidgetStep_actionType,
  payload
})

export const setPrintWidgetLoading = (payload) => ({
  type: setPrintWidgetLoading_actionType,
  payload
})
