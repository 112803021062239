import React, {useMemo} from "react";
import {FilterIcon, PrintIcon, QuestionIcon, ReportIcon, SearchIcon} from "../../../Icons";
import {StyledWidgetButton} from "../../Header-styled";
import Loader from "../../../Dashboard/Editor/Loader/Loader";

const ICONS = {
  'Filters': (<FilterIcon />),
  'SearchWidget':( <SearchIcon />),
  'Print': (<PrintIcon />),
  'Help': (<QuestionIcon />),
  "Report": <ReportIcon />
}

const WidgetButton = ({type, children, title, isLoading = false, ...props}) => {
  const icon = useMemo(()=>{
    return ICONS[type]
  },[type])
  
  return (
    <StyledWidgetButton title={title} {...props}>
      {
        isLoading && (
          <div style={{
            position:'absolute',
            top:0,
            left:0,
            height:'100%',
            width:'100%',
            background:'#FFFFFF40',
            pointerEvents:'all'
          }}>
            <Loader size="50px" position="absolute" style={{
              background:'transparent',
              top:10
            }}/>
          </div>
        )
      }
      
      {children}
      {type && (
        icon
      )}
    </StyledWidgetButton>
  );
};

export default WidgetButton;
