import React, {useCallback, useEffect, useMemo, useState} from "react";
import {standardizeColor} from "../../../../utils/helper";
import Dropdown, {DropdownBody} from "../../../Dropdown";
import {SelectionBox, SelectionBoxLabel, DescriptionWrapper, SelectionTagItem, FieldLabel} from "./EditorFields-styled";
import CustomSwitch from "../../../CustomSwitch/CustomSwitch";
import {useFormContext, useWatch} from "react-hook-form";

export const SelectionTags = ({id, options, colors, label, value, field, description, onSelect, isRequired, borderColor}) =>{
  const [show, setShow] = useState(false);
  const [selectedCode, setSelectedCode] = useState(value);
  const {register, setValue, getValues, resetField} = useFormContext();
  
  useEffect(()=>{
    const fieldValue = getValues(field.name);
    
    if (!fieldValue){
      setSelectedCode(value);
      setValue(field.name, value, { shouldValidate: true });
    } else {
      //some values are numbers and some are strings
      const convertedValue = field.type.includes('integer') ? Number(fieldValue) : fieldValue;
      const val = !Number.isNaN(convertedValue) ? convertedValue : fieldValue;
      setSelectedCode(val);
    }
  },[value])
  
  const handleSelect = useCallback((option)=>{
    setSelectedCode(option.code);
    setValue(field.name, option.code, { shouldValidate: true });
    if (onSelect){
      onSelect(option);
    }
  },[onSelect])
  
  const filledInColor = useMemo(()=> isRequired ? '#16a34a'  : borderColor,[isRequired])
  
  return <SelectionBox
    borderColor={(!selectedCode && selectedCode !== 0) &&  isRequired ? '#FF0000' : filledInColor}
  >
    {label && <SelectionBoxLabel>{label}</SelectionBoxLabel>}
    <div style={{
      display:'flex',
      flexDirection:'row',
      flexWrap:'wrap',
      gap:6
    }}>
      <select {...register(field.name, {required: isRequired})} readOnly id={id} data-field-name={field.name} style={{display:'none'}} value={selectedCode}>
        {field.nullable && <option key={null} value={""}></option>}
        {options.map((cv) => <option key={cv.code} value={cv.code}>{cv.name}</option>)}
      </select>
      {
        options.map(option=>(
          <SelectionTagItem
            selected={selectedCode === option.code}
            key={option.code}
            color={standardizeColor(colors[option.code] ?? '#808080')}
            onClick={()=>handleSelect(option)}
            style={{
              padding:0
            }}
          >
            <p>{option.name}</p>
          </SelectionTagItem>
        ))
      }
      {description && <DescriptionWrapper>
        <Dropdown
          setShow={setShow}
          show={show}
          leftOffset={0.3}
          hasMinWidth={false}
        >
          <div style={{
            display:'flex'
          }}>
            <svg fill="rgb(76,76,76)" fillRule="evenodd" height="10" role="img" viewBox="0 0 16 16" width="10" aria-label="info" alt="info"><title>info</title><circle cx="8" cy="4" r="1"></circle><path d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 118 0a8 8 0 010 16z"></path><path d="M9 13H7V7h2z"></path></svg>
          </div>
          <DropdownBody type="tooltip" style={{display: "flex"}}>
            {description}
          </DropdownBody>
        </Dropdown>
      </DescriptionWrapper>}
    </div>
  </SelectionBox>
}

export const EditorSwitch = ({id, t, label, value, field, description, onChange, isRequired}) =>{
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(value ?? 2);
  const {register, setValue, getValues} = useFormContext();
  
  useEffect(()=>{
    const fieldValue = getValues(field.name);
    if (!fieldValue){
      setSelected(value ?? 2);
      setValue(field.name, value ?? 2);
    } else {
      const val = field.type.includes('integer') ? Number(fieldValue) : fieldValue;
      setSelected(val);
    }
  },[value]);
  
  const onToggle = useCallback((e)=>{
    const value = e ? 1 : 2;
    setValue(field.name, value);
    if (onChange){
      onChange({name: field.name, value});
    }
    setSelected(e ? 1 : 2)
  },[])
  
  return <div
    style={{
      display:"flex",
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'space-between',
    }}
  >
    <select {...register(field.name, {required:isRequired})} readOnly id={id} style={{display:'none'}} data-field-name={field.name} className="esri-input esri-feature-form__input" value={selected}>
      {field.nullable && <option key={null} value={""}></option>}
      <option key={1} value={1}>{1}</option>
      <option key={2} value={2}>{2}</option>
    </select>
    <div style={{
      display:'flex',
      alignItems:'center',
      gap:6
    }}>
      <FieldLabel style={{fontSize:14}} noMargin>
        {label}
      </FieldLabel>
      {description && <div style={{cursor:'pointer'}}>
        <Dropdown
          setShow={setShow}
          show={show}
          leftOffset={0.3}
          hasMinWidth={false}
        >
          <div style={{
            display:'flex'
          }}>
            <svg fill="rgb(76,76,76)" fillRule="evenodd" height="10" role="img" viewBox="0 0 16 16" width="10" aria-label="info" alt="info"><title>info</title><circle cx="8" cy="4" r="1"></circle><path d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 118 0a8 8 0 010 16z"></path><path d="M9 13H7V7h2z"></path></svg>
          </div>
          <DropdownBody type="tooltip" style={{display: "flex"}}>
            {description}
          </DropdownBody>
        </Dropdown>
      </div>}
    </div>
    <CustomSwitch
      onChange={onToggle}
      checked={selected === 1}
      height={18}
      width={38}
      handleDiameter={14}
    />
  </div>
}