import {useCallback, useContext, useEffect} from "react";
import {loadModules} from "esri-loader";
import {graphicsLayer, view} from "../../../../utils/API";
import {EditType, fillSymbol, LAYER_EFFECT} from "../EditorSwiper/EditorSwiper";
import {EditorContext} from "../EditorContextProvider";
import {selectionLineSymbol} from "./RoadsCoordinates";
import {addLayerEffect} from "../helpers";

const SketchCoordinates = ({editableLayer, clickActive, editType, handleNext}) => {
  const {highlightFeature, setAddedPoints, setSketchGraphicsLayer, sketchGraphicsLayer, sketchViewModel, setSketchViewModel} = useContext(EditorContext);
  
  useEffect(()=>{
    if (sketchViewModel && sketchGraphicsLayer){
      activateCreateTool();
    }
    
    if (editType === EditType.create){
      editableLayer.effect = LAYER_EFFECT;
    } else {
      editableLayer.effect = undefined;
    }
  },[editType])
  
  useEffect(()=>{
    if (editableLayer.geometryType === 'point' || sketchViewModel) return;
    loadModules([
      "esri/widgets/Sketch/SketchViewModel",
      "esri/layers/GraphicsLayer",
      "esri/geometry/geometryEngineAsync",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/Polyline"
    ]).then(([SketchViewModel, GraphicsLayer]) => {
      const graphicsLayer = new GraphicsLayer();
      view.map.add(graphicsLayer);
      setSketchGraphicsLayer(graphicsLayer);
      // create a new sketch view model set its layer
      const svm = new SketchViewModel({
        view: view,
        layer: graphicsLayer,
        defaultUpdateOptions: {
          tool: "reshape",
          enableRotation: false,
          enableScaling: false,
          enableZ: false,
          reshapeOptions: {
            shapeOperation:'none'
          }
        }
      });
      addLayerEffect([editableLayer, graphicsLayer])
      setSketchViewModel(svm);
    })
  },[])
  
  const activateCreateTool = useCallback(()=>{
    if (!clickActive) return;
    if (editType === EditType.create){
      sketchViewModel.create(editableLayer.geometryType);
    }
  },[editType, editableLayer, clickActive, sketchGraphicsLayer, sketchViewModel])
  
  useEffect(()=>{
    const listeners = [];
    let timer;
    if (!sketchViewModel || !sketchGraphicsLayer) return;
    
    loadModules([
      "esri/geometry/geometryEngineAsync",
      "esri/geometry/support/webMercatorUtils"
    ]).then(([geometryEngineAsync])=>{
      sketchViewModel.updateOnGraphicClick = !clickActive;
      if (!clickActive){
        const graphics = sketchGraphicsLayer.graphics.toArray()[0];
        sketchViewModel.update(graphics, "reshape")
      } else {
        graphicsLayer.removeAll();
        sketchGraphicsLayer?.removeAll();
      }
      
      const createHandle = sketchViewModel.on("create", async (event) => {
        if (event.state === "complete") {
          const {graphic} = event;
          
          // editableLayer.effect = LAYER_EFFECT;
          setAddedPoints([graphic] ?? [])
          
          if (clickActive){
            handleNext();
          }
        }
      });
      listeners.push(createHandle)
      
      const updateHandle = sketchViewModel.on("update", async (event) => {
        clearTimeout(timer);
        timer = setTimeout(async ()=>{
          const {graphics} = event;
          
          if (graphics.length > 0){
            setAddedPoints([graphics[0].geometry]);
          }
        },250)
      });
      
      listeners.push(updateHandle);
    })
    
    
    return () => {
      sketchViewModel?.cancel();
      clearTimeout(timer);
      listeners.forEach(handle=>{
        handle.remove();
      })
    }
  },[sketchViewModel])
  
  useEffect(()=>{
    //creating shapes for editing
    if (highlightFeature && editType === EditType.edit && !!sketchGraphicsLayer) {
      const geomType = editableLayer.geometryType
      let graphic;
      if (geomType === "polyline"){
        const polyline = {
          type: "polyline",
          paths: highlightFeature.geometry.paths,
          spatialReference: highlightFeature.geometry.spatialReference
        }
        
        graphic = {
          geometry: polyline,
          symbol: {
            ...selectionLineSymbol,
            width:2
          }
        }
      } else if (geomType === "polygon"){
        const polygon = {
          type: 'polygon',
          rings: highlightFeature.geometry.rings,
          spatialReference: highlightFeature.geometry.spatialReference
        }
        graphic = {
          geometry: polygon,
          symbol: fillSymbol
        }
      }
    
      if (graphic){
        sketchGraphicsLayer.effect = undefined;
        if (sketchGraphicsLayer.graphics.length === 0){
          sketchGraphicsLayer.add(graphic);
        }
      }
    }
  },[highlightFeature, sketchGraphicsLayer])
  
  return (
    <div>
    
    </div>
  );
};

export default SketchCoordinates;
