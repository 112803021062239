import Input from "../../Report/new/Input/Input";
import TextArea from "../../TextArea/TextArea";
import React, {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {getOpsColor} from "../../../utils/helper";

const DisclaimerConfigurations = ({index, t, config, isEditor, mapData, onMapDataChange}) => {
  const {activeModule} = useSelector(state=>state);
  const hidePublic = useMemo(()=>{
    const hideFields = config.printMap?.hidePublic ?? config.opDefaults.printMap?.hidePublic ?? []
    const allFields = ["mapAuthor", "mapEmail", "mapUrl", "mapSources", "mapDisclaimer"];
    
    return isEditor ? allFields : allFields.filter(item=>!hideFields.includes(item));
  },[config, isEditor])
  
  const onInputChange = useCallback((e)=>{
    const {name, value} = e.target;
    onMapDataChange(name, value)
  },[onMapDataChange])
  
  const opsColor = useMemo(()=>getOpsColor(config, activeModule),[config, activeModule])
  
  const renderInputs = (type) => {
    switch (type) {
      case 'mapAuthor':{
        return <Input key={type} onChange={onInputChange} value={mapData.mapAuthor} label={t("print.mapDetails.questions.2.options.mapAuthor")} placeholder="" name="mapAuthor" borderColor={opsColor}  />
      }
      case "mapEmail":{
        return <Input key={type} onChange={onInputChange} value={mapData.mapEmail} label={t("print.mapDetails.questions.2.options.mapEmail")} placeholder="" name="mapEmail" borderColor={opsColor} />
      }
      case "mapUrl":{
        return <Input key={type} onChange={onInputChange} value={mapData.mapUrl} label={t("print.mapDetails.questions.2.options.mapUrl")} placeholder="" name="mapUrl" borderColor={opsColor} />
      }
      case "mapSources":{
        return <TextArea
          key={type}
          label={t("print.mapDetails.questions.2.options.mapSources")}
          value={mapData.dataSources}
          onChange={onInputChange}
          placeholder=''
          wrap='hard'
          maxLength={1000}
          name="dataSources"
          borderColor={opsColor}
        />
      }
      case "mapDisclaimer":{
        return <TextArea
          key={type}
          label={t("print.mapDetails.questions.2.options.mapDisclaimer")}
          value={mapData.disclaimer}
          onChange={onInputChange}
          placeholder=''
          wrap='hard'
          maxLength={1000}
          name="disclaimer"
          borderColor={opsColor}
        />
      }
    }
  }
  
  return (
    <div>
      <p style={{marginBottom:20}}><span>{index}. </span>{t("print.mapDetails.questions.2.title")}</p>
      {
        hidePublic.map(item=>(
          renderInputs(item)
        ))
      }
    </div>
  );
};

export default DisclaimerConfigurations;
