import ProgressBar from "../../Report/new/ProgressBar/ProgressBar";
import {StyledSubmitButton} from "../../Report/new/Footer/Footer-styled";
import React, {useEffect, useState} from "react";
import {currAsOfDateFieldName} from "../../../utils/API";
import useEditableLayers from "../../../hooks/useEditableLayers";

const MonitoringProgress = ({situationalLayers, handleNext}) => {
  const [updatedFeaturesPercent, setUpdatedFeaturesPercent] = useState(0);
  useEffect(()=>{
    const fetchLayerFeatures = async (situationalLayers) => {
      //const iso = new Date(yesterday).toISOString().split('T')[0];
      //`${currAsOfDateFieldName} < DATE '${iso}' OR ${currAsOfDateFieldName} is null`
      try {
        const promises = [];
        const yesterday = new Date() - (24 * 60 * 60 * 1000);
        
        situationalLayers.forEach(l=>{
          const query = {
            outFields: [currAsOfDateFieldName],
            where: l.definitionExpression,
          }
          promises.push(l.queryFeatures(query))
        });
  
        const results = await Promise.all(promises);
        const features = [];
        results.forEach(res=>{
          if (res?.features.length > 0) {
            features.push(...res.features);
          }
        })
        
        const filtered = features.filter(f=>{
          return f.attributes[currAsOfDateFieldName] === null || f.attributes[currAsOfDateFieldName] < yesterday
        })
        
        if (features.length > 0){
          const percent = ((features.length - filtered.length) * 100) / features.length;
          return Math.floor(percent);
        } else {
          return 0;
        }
      } catch (err) {
        console.log(err);
        return 0;
      }
    }
    
    fetchLayerFeatures(situationalLayers).then(res=>{
      setUpdatedFeaturesPercent(res);
    });
    
  },[situationalLayers])
  
  return (
    <div>
      <div style={{
        display:'flex',
        gap:10,
        padding:'0px 4px'
      }}>
        <span style={{fontSize:12}}>Current Update status:</span>
        <div style={{flex:1, display:'flex', gap:4, alignItems:'center'}}>
          <span style={{fontSize:12, fontWeight:500}}>{updatedFeaturesPercent}%</span>
          <div
            style={{
              position:'relative',
              height:8,
              width:'100%',
              borderRadius:100,
              overflow:'hidden'
            }}
          >
            <ProgressBar
              color="#0B8B00"
              currentPosition={updatedFeaturesPercent}
              total={100}
              hasTopRadius={false}
            />
          </div>
        </div>
      </div>
      <div style={{display:'flex', justifyContent:'center'}}>
        <StyledSubmitButton style={{color:'black', margin: '10px 0px'}} bg="#D9D9D9"
          onClick={()=>handleNext('monitoring')}
        >
          Open update monitoring
        </StyledSubmitButton>
      </div>
    </div>
  );
};

export default MonitoringProgress;