import {useContext, useEffect} from "react";
import {EditType, LAYER_EFFECT, pointSymbol} from "../EditorSwiper/EditorSwiper";
import {graphicsLayer, view} from "../../../../utils/API";
import {loadModules} from "esri-loader";
import {createSymbol, getLayerSymbology} from "../../../../utils/symbologies";
import {EditorContext} from "../EditorContextProvider";
import CoordinatesInputs from "./CoordinatesInputs";
import EditableFeaturesList from "./EditableFeaturesList";

const PointCoordinates = ({editableLayer, config, clickActive, editType, handleNext, handleSelectFeature}) => {
  const {
    addedPoints,
    setAddedPoints,
    prefilledFields,
    setHighlightSymbol,
    setHighlightFeature,
    highlightFeature,
    setHighlightHandle,
    highlightSymbol,
    highlightHandle,
    activeColor
  } = useContext(EditorContext);
  
  const addPointToNewFeature = (newPoint) => {
    const newAddedPoints = [...addedPoints];
    newAddedPoints.pop()
    newAddedPoints.push(newPoint)
    
    if (highlightFeature && editType === EditType.edit){
      highlightFeature.geometry.latitude = newPoint.latitude;
      highlightFeature.geometry.longitude = newPoint.longitude;
      
      const nGraphic = highlightFeature?.clone();
      nGraphic.symbol = highlightSymbol ? highlightSymbol : pointSymbol;
      
      graphicsLayer.removeAll();
      graphicsLayer.add(nGraphic);
      setHighlightFeature(nGraphic)
      if (clickActive){
        handleNext();
        // editableLayer.effect = LAYER_EFFECT;
      }
      setAddedPoints(newAddedPoints);
      return;
    }
    view.graphics.removeAll();
    const geomType = editableLayer.geometryType
    loadModules(["esri/Graphic"])
      .then(([Graphic])=>{
        const symbology = getLayerSymbology(editableLayer, config);
        let iconName;
        let attributes = {};
        
        prefilledFields.forEach(item=>{
          if (item.fieldName === 'aerodromeclass' || item.fieldName === 'type_ann') {
            iconName = item.iconName;
          }
          attributes[item.fieldName] = item.code;
        })
        
        let symbol;
        if (symbology) {
          symbol = createSymbol(symbology, editableLayer, iconName ?? symbology.defaultIcon , symbology.defaultColor, symbology.defaultSize);
        }
        
        const nGraphic = new Graphic({
          geometry: newPoint,
          attributes: attributes,
          symbol: symbol ? symbol : pointSymbol
        })
        

        if (geomType === 'point') {
          //for point
          graphicsLayer.removeAll()
          graphicsLayer.add(nGraphic)
          view.whenLayerView(editableLayer).then(lv=>{
            if (clickActive){
              // editableLayer.effect = LAYER_EFFECT;
            }
            highlightHandle?.remove();
            setHighlightHandle(lv.highlight(nGraphic));
            setHighlightFeature(nGraphic)
            setHighlightSymbol(symbol);
          })
        } else {
          graphicsLayer.add({geometry: newPoint, symbol: pointSymbol})
        }
        
        setAddedPoints(newAddedPoints)
      })
  }
  
  //when editor creating new feature add point symbol to the map
  useEffect(()=>{
    let clickListener;
    if (editType !== EditType.create && !clickActive) return;
    const geomType = editableLayer.geometryType
    
    clickListener = view.on('click', (event)=>{
      if (geomType === 'point'){
        addPointToNewFeature(event.mapPoint)
      }
      event.stopPropagation();
    })
    
    return ()=>{
      clickListener?.remove()
    }
  },[highlightHandle, highlightFeature, editType, highlightSymbol])
  
  if (EditType.edit === editType && clickActive) {
    return <EditableFeaturesList editableLayer={editableLayer} handleSelectFeature={handleSelectFeature} handleNext={handleNext} />
  }
  
  return (
    <CoordinatesInputs
      clickActive={clickActive}
      addPointToNewFeature={addPointToNewFeature}
      editType={editType}
      geometry={highlightFeature?.geometry}
      showAll={true}
      color={activeColor}
    />
  );
};

export default PointCoordinates;
