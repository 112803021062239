import styled from 'styled-components';

export const SearchWidgetWrapper = styled.div`
  width: 100%;
  position: relative;

  .search__box {
    overflow: hidden;

    input {
      padding-left: 10px;
      padding-right: 50px;
      border: none;
    }
  }
  
  ${({isMobileApp})=> !isMobileApp ? `
    @media screen and (min-width: 769px){
      width: 240px;

      .search__box {
        box-shadow: 0 1px 2px #0000004d;
        border-radius: 10px;
      }
    }
    
    @media screen and (max-width: 769px){
      .search__box{
        input {
          padding-left: 42px;
        }
      }
    
      input {
        font-size: 16px;
      }
    }
  
    @media screen and (min-width: 769px) and (max-width: 1210px) {
      .search__box {
        input {
          font-size: 12px;
        }
      }
    }
  ` : `
    .search__box{
      input {
        padding-left: 42px;
      }
    }
    
    input {
      font-size: 16px;
    }
  `};
`

export const StyledSuggestions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: ${({isMobileApp})=> !isMobileApp ? '300px' : '500px'};
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s;
  border-radius: 8px;
  margin-top: 4px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px #0000004d;
  position: absolute;
  top: 0px;
  z-index: 1;
  
  
  
  
`

export const StyledSuggestItem = styled.div`
    cursor: pointer;
    user-select: none;
    padding: 4px 8px;
    transition: all 0.3s;
    background: ${({selected, color})=> color ? selected ? color : `` : ''};
    color: ${({selected})=>selected ? '#ffffff' : '#4c4c4c'};
    
    span {
        border-radius: 100px;
        width: 10px;
        height: 10px;
        padding: 0;
    }
    p {
        margin: 0;
        font-size: 12px;
        transition: all 0.1s;
        border-radius:100px;
        font-weight: 500;
        padding: 6px 12px;
    }
    
    :hover {
        color: #000000;
        background: #9393931a
    }
`

export const StyledIconBox = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  position:absolute;
  right:5px;
  top:50%;
  transform:translateY(-50%);
  cursor: pointer;
  padding: 2px;
  pointer-events: none;
`
