import styled from "styled-components";

export const EntryPointsTabs = styled.div`
  display: flex;
  gap: 10px;
  padding: 0px 4px 4px;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #FFFFFF;
  
`
export const EntryPointsTab = styled.div`
  border-bottom: ${({color, selected})=> `2px solid ${selected ? color ? color : 'black' : 'transparent'}`};
  cursor: pointer;
`