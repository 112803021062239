import {useSnackbar} from "../components/SnackBar";

const snackOptions = {
  position: 'top-center',
  style: {
    backgroundColor: '#FFFFFF',
    color: '#393738',
  },
  closeStyle: {
    color: '#393738',
  },
}

const useCustomSnackbar = () => {
  const snackbar = useSnackbar(snackOptions)
  
  return snackbar;
};

export default useCustomSnackbar;