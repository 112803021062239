import styled from "styled-components";

export const FilterLabel = styled.label`
  display:flex;
  align-items:center;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
  user-select: none;
  
  span {
    border-radius:100px;
    width:10px;
    height:10px;
    padding: 0;
  }
  
  p {
    margin: 0;
    font-size: 12px;
    text-align: left;
  }
`

export const FilterDot = styled.div`
  border-radius:100px;
  width:10px;
  height:10px;
  background:${({color})=>color ?? '#999999'};
`