import React, {useCallback, useMemo, useState} from "react";
import {FeatureIcon, FeatureIconContainer} from "../FeatureTable-styled";
import {DownloadIcon} from "../../Icons/Feature-TableIcons";
import Dropdown, {DropdownMenu, DropdownMenuItem} from "../../Dropdown";
import {getOpsColor, renderCell, ROLE_EDITOR} from "../../../utils/helper";
import {getLayerTitle} from "../../../esri/custom-popup-content";
import {arcgisToGeoJSON} from "@terraformer/arcgis";
import shpwrite from "@mapbox/shp-write";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";

function getValue(inputSelector) {
  let text = (document.querySelector(inputSelector)).value;
  switch (text) {
    case "none":
      return;
    case "tab":
      return "\t";
    default:
      return text;
  }
}

function getParams() {
  return {
    columnSeparator: getValue("#columnSeparator"),
  };
}

function convertTimestampToDateString(timestamp) {
  if (typeof timestamp == "number") {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    
    // Format the components into the "yyyy-mm-dd hh:mm" string
    return `${day}-${month}-${year} ${hour}:${minute}`;
  }
  return timestamp;
}

const isDateAttribute = (key, value) => {
  const regex = /.*date.*/i
  return typeof value === "number" && key.match(regex)
}

function getDataFromResults(resultFeatures) {
  return resultFeatures.map((row) => {
    const { attributes, geometry } = row
    for (const [key, value] of Object.entries(attributes)) {
      if (isDateAttribute(key, value))
        attributes[key] = convertTimestampToDateString(value)
    }
    
    if (geometry && geometry.latitude && geometry.longitude){
      attributes.latitude = geometry.latitude;
      attributes.longitude = geometry.longitude;
    }
    
    return attributes
  })
}

const convertToCSV = (objArray, fields, t) => {
  const csvData =
    typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  
  for (let x = 0; x < csvData.length; x++) {
    let line = "";
    const attr = fields.length > 0 ? fields : Object.keys(csvData[x]);
    attr.sort((a, b) => fields.indexOf(a) >= 0 && fields.indexOf(b) >= 0 ? fields.indexOf(a) - fields.indexOf(b) : 0);
    for (let y of attr) {
      if (line !== "") {
        line += ",";
      }
      
      const text =
        csvData[x][y] !== null
          ? t(`layer.domain.${y}.${csvData[x][y]}`, csvData[x][y])
          : "";
      
      csvData[x][y] =
        text.search(/layer.domain/) === -1 ? text : csvData[x][y];
      csvData[x][y] =
        typeof csvData[x][y] == "string"
          ? csvData[x][y]
          : csvData[x][y] == null
            ? ""
            : String(csvData[x][y]);
      line += `"${csvData[x][y].replace(/"/g, '""')}"`;
    }
    
    str += line + "\r\n";
  }
  
  return str;
};

const exportCSVFile = (headers, csvData, fileName, fields, t) => {
  if (headers) {
    csvData.unshift(headers);
  }
  
  let jsonCsvData = JSON.stringify(csvData);
  const csv = convertToCSV(jsonCsvData, fields, t);
  
  const csvBlob = new Blob(["\uFEFF" + csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(csvBlob, fileName);
  }
  else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}


const DownloadButton = ({config, layer, t, fields, gridApi}) => {
  const [show, setShow] = useState(false);
  const [openSnackbar] = useCustomSnackbar();
  
  const exportShapeFile = useCallback((currLayer, resultFeatures, fileName, fields)=>{
    const geoJSON = {
      type: 'FeatureCollection',
      features : resultFeatures.map(item=>{
        const geoJSONFeature = arcgisToGeoJSON(item, currLayer.objectIdField);
        geoJSONFeature.geometry.coordinates = [item.geometry.longitude, item.geometry.latitude];
        const newProperties = {};
        
        Object.keys(geoJSONFeature.properties).forEach(k=>{
          if (fields.includes(k)) {
            
            let field = t(`layer.fieldAlias.${k}`, k)
            if (field.search(/layer.fieldAlias/) === 0) {
              field = k;
            }
            
            const attributeValue = geoJSONFeature.properties[k];
            let propertiesValue = !isDateAttribute(k, attributeValue)
              ? t(`layer.domain.${k}.${attributeValue}`, attributeValue)
              : convertTimestampToDateString(attributeValue)
            
            if (propertiesValue.search(/layer.domain/) === 0) {
              propertiesValue = geoJSONFeature.properties[k];
            }
            
            newProperties[field] = propertiesValue;
          }
        })
        geoJSONFeature.properties = newProperties;
        return geoJSONFeature;
      })
    }
    
    const options = {
      folder: "Downloads",
      filename: fileName,
      outputType: "blob",
      compression: "DEFLATE",
    };
    shpwrite.download(geoJSON, options);
  },[t])
  
  const handleDownload = useCallback(async (downloadType)=>{
    let message;
    const layerName = getLayerTitle(layer, t);
    //fetching current features to get latest data
    const res = await layer.queryFeatures()
    
    //getting current features visible in table after table filters were applied
    const featuresId = [];
    gridApi.forEachNodeAfterFilter(node=>{
      featuresId.push(node.data[layer.objectIdField])
    })
    
    const features = res.features.filter(feat=>featuresId.includes(feat.attributes[layer.objectIdField]))
    features.sort((a,b)=>{
      const indexOfA = featuresId.indexOf(a.attributes[layer.objectIdField])
      const indexOfB = featuresId.indexOf(b.attributes[layer.objectIdField]);
      return indexOfA - indexOfB;
    });
    
    if (features.length) {
      const fileTitle = `${config.alias ? `${config.alias}_` : ''}${layerName}`;
      let date = (new Date()).toISOString().split('T')[0].replace(/-/g,"")
      const exportedFilename = fileTitle !== "" ? `${fileTitle}_${String(date)}` : `logie_data_${String(date)}`;
      if (downloadType === 'shapefile') {
        //export shapefile
        exportShapeFile(layer, features, exportedFilename, fields);
      } else {
        const columnsOrder = gridApi.getAllGridColumns().filter(col=>col.colId !== 'featureSelect').map(col=>col.colId);
        
        // gridApi.exportDataAsCsv();
        // export to csv
        const data = getDataFromResults(features);
        const headers = {};
        const entry = data[0];
        
        Object.keys(data).forEach(attr=>{
          Object.keys(data[attr]).forEach(k=>{
            
            const field = layer.fields.find(f=>f.name === k)
            
            if (field && field.domain){
              data[attr][k] = renderCell(field, data[attr][k], t, config)
            }
          })
        })
        
        for (let key in entry) {
          if (entry.hasOwnProperty(key)) {
            headers[key] = t("layer.fieldAlias." + key, key);
          }
        }
        
        fields.sort((a, b) => columnsOrder.indexOf(a) >= 0 && columnsOrder.indexOf(b) >= 0 ? columnsOrder.indexOf(a) - columnsOrder.indexOf(b) : 0);
        exportCSVFile(headers, data, exportedFilename, fields, t);
      }
      
      message = t("screen.message.downloadSuccess", { title: layerName });
    } else {
      message = t("screen.message.noData", { title: layerName });
    }
    
    openSnackbar(message, 15000);
    
    setShow(false);
  },[fields, t, config, gridApi])
  
  const allowedExports = useMemo(()=>{
    const {layerConfig} = layer || {};
    const allowed = [];
    if (!layerConfig) return allowed;
    
    const shpAllowed = config.role !== ROLE_EDITOR ? (layerConfig.featureTable.allowedExtractionSHPForPublic && layerConfig.featureTable.exportCoordinates) : layerConfig.featureTable.allowedExtractionCSVForEditors
    const csvAllowed = config.role !== ROLE_EDITOR ? layerConfig.featureTable.allowedExtractionCSVForPublic : layerConfig.featureTable.allowedExtractionCSVForEditors
    if (shpAllowed) {
      allowed.push('shapefile');
    }
    
    if (csvAllowed) {
      allowed.push('csv');
    }
    
    return allowed;
  },[layer, config])
  const opsColor = useMemo(()=> getOpsColor(config),[config])
  
  if (allowedExports.length === 0) return null;
  
  return (
    <Dropdown
      setShow={setShow}
      show={show}
    >
      <FeatureIconContainer>
        {t("screen.download.title")}<FeatureIcon><DownloadIcon /></FeatureIcon>
      </FeatureIconContainer>
      <DropdownMenu style={{minWidth: 80}}>
        {allowedExports.includes("csv") && <DropdownMenuItem
          opsColor={opsColor}
          onClick={() => handleDownload("csv")}
        >
          <span
            style={{
              fontSize: 12
            }}
          >{t("screen.download.csv")}</span>
        </DropdownMenuItem>}
        {allowedExports.includes('shapefile') && <DropdownMenuItem
          opsColor={opsColor}
          onClick={() => handleDownload("shapefile")}
        >
          <span
            style={{
              fontSize:12
            }}
          >{t('screen.download.shapefile')}</span>
        </DropdownMenuItem>}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DownloadButton;
