import {setMobileActiveList_actionType, setMobileApp_actionType} from "../constants";

export const setMobileApp = (payload) =>({
  type: setMobileApp_actionType,
  payload
})

export const setActiveList = (payload) => ({
  type: setMobileActiveList_actionType,
  payload
})
