import styled from "styled-components";

export const StyledDropdownBody = styled.div`
  padding: 4px 4px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
  line-height: 1;
`

export const StyledDropdownMenu = styled.div`
  padding: 4px 4px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledDropdownMenuItem = styled.div`
  padding: 6px 14px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 6px;
  background: ${({selected, opsColor})=> selected && `${opsColor ?? '#c03a2b'}0F`};
  color: ${({selected, opsColor})=> selected && (opsColor ?? '#c03a2b')};
  
  img {
    width: 20px;
    height: 15px;
    border-radius: 2px;
    overflow: hidden;
  }

  svg {
    fill: ${({opsColor})=> opsColor ?? '#c03a2b'};
  }
  
  :hover {
    background: ${({opsColor})=> `${opsColor ?? '#c03a2b'}0F`};
    color: ${({opsColor})=> opsColor ?? '#c03a2b'};
  }
`

export const DropdownTooltip = styled.div`
  line-height: normal;
  font-size: 11px;
  color: #393738;
  display: inline-block;
  padding: 4px;

  span {
    span {
      font-weight: 600;
    }
  }
`