import styled from "styled-components";

export const StyledNavButton = styled.button`
  padding:8px;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #ffffff;
  font-size: 12px;
  transition: all 0.5s;
  color: #323232;
  text-align: left;

  @media screen and (max-width: 768px){
    background: #eeeeee;
  }

  &:hover {
    background: #e2f1fb;
  }
`