import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import Platform from "../Platform/Platform";
import {ALL_STEPS, EditType, LAYER_EFFECT, pointSymbol} from "./EditorSwiper";
import {EditorContext, UPDATE_STATUS_TYPE, UpdateType} from "../EditorContextProvider";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {FloatingAddButton, StyledEditorBody, StyledEditorBreadcrumbs} from "../Editor-styled";
import ProgressBar from "../../../Report/new/ProgressBar/ProgressBar";
import EditFeatures from "../EditFeatures/EditFeatures";
import CoordinatesSelection from "../CoordinatesSelection/CoordinatesSelection";
import {useTranslation} from "react-i18next";
import EditorFields from "../EditorFields/EditorFields";
import useEditor from "../../../../hooks/useEditor";
import {FormProvider} from "react-hook-form";
import {StyledArrowButton, StyledSubmitButton} from "../../../Report/new/Footer/Footer-styled";
import {RightArrowIcon} from "../../../Panel/components/Pagination/helpers";
import Button from "../../../Report/new/Button/Button";
import {currAsOfDateFieldName, graphicsLayer, view} from "../../../../utils/API";
import AerodromeType from "../EditableLayerList/Aerodromes/AerodromeType";
import AerodromeClass from "../EditableLayerList/Aerodromes/AerodromeClass";
import {getIconValueDefinitions, getLayerSymbology} from "../../../../utils/symbologies";
import {svgToBase} from "../../../Icons/layerSvg/clusterAssets";
import Annotations from "../EditableLayerList/Annotations/Annotations";
import Message from "../Message/Message";
import EnterCoordinates from "../CoordinatesSelection/SituationalRoads/EnterCoordinates/EnterCoordinates";
import Loader from "../Loader/Loader";
import RoadsSituationalManual from "../CoordinatesSelection/SituationalRoads/RoadsSituationalManual";
import {addLayerEffect} from "../helpers";

const baseLineColor = '#34495e'

const EditorSlides = ({
  editableLayers,
  editableLayer,
  step,
  handleGoBack,
  editType,
  setEditType,
}) => {
  const context = useContext(EditorContext);
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  const [count, setCount] = useState(0);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  
  const {
    setPopupFeature,
    resetHighlightFeature,
    batchUpdateFeatures,
    resetBatchUpdateFeatures,
    setEditableLayer,
    popupFeature,
    setAttachments,
    sketchGraphicsLayer,
    setSketchGraphicsLayer,
    setSketchViewModel,
    updateType,
    setUpdateType,
    activeColor,
    resetEditor,
    addedPoints,
    highlightFeature,
    requiredFields,
    baseLineRequiredFields,
    situationalUpdateType,
    setHighlightFeature,
    setHighlightSymbol
  } = context;
  
  const {airdromeType, setAirdromeType} = context;
  const {prefilledFields, setPrefilledFields} = context;
  const {showWarning, setShowWarning} = context;
  const {clickedPoints, resetClickedPoints} = context;
  const {showRoadsEditor, setShowRoadsEditor} = context;
  
  const swiperRef = useRef();
  
  
  useEffect(()=>{
    const clickEventInPopup =(feature) => {
      setLoading(true);
      const layer = feature.sourceLayer ? feature.sourceLayer : feature.layer
      feature.geometry = feature.originalGeometry? feature.originalGeometry : feature.geometry
      
      view.whenLayerView(layer).then((layerView) => {
        if (layer.layerConfig.titleLabel !== 'roadsSituational'){
          layer?.renderer?.getSymbolAsync(highlightFeature).then((symbol) => {
            const clone = feature.clone();
            if (symbol.style === 'circle'){
              clone.symbol = pointSymbol;
            } else {
              clone.symbol = symbol;
            }
            graphicsLayer.add(clone)
            setHighlightSymbol(symbol);
          })
        }
        
        setPopupFeature(true);
        setHighlightFeature(feature);
        setLoading(false);
        setUpdateType(UpdateType.baseline);
        slideTo(2);
        view.closePopup();
        
        addLayerEffect([]);
        setTimeout(()=>{
        },0)
      })
    }
  
    if (highlightFeature){
      clickEventInPopup(highlightFeature)
    } else {
      slideTo(0);
    }
    
    return ()=>{
      handleReset();
    }
  },[])
  
  const removeLayerEffect = useCallback(()=>{
    view.map.layers.forEach((layer)=>{
      if (graphicsLayer.id !== layer.id && layer.title !== 'wld_bnd_adm0') {
        layer.effect = undefined;
      }
    })
  },[editType, editableLayer])
  
  const handleNext = useCallback(() => {
    swiperRef.current.swiper.slideNext();
    setCount(prev=>prev+1)
  },[])
  
  const handlePrev = useCallback(()=>{
    swiperRef.current.swiper.slidePrev();
    setCount(prev=>prev+1)
  },[])
  
  const slideTo = useCallback((index)=>{
    if (!swiperRef.current?.swiper) return;
    swiperRef.current.swiper.slideTo(index);
    setCount(prev=>prev+1)
  },[])
  
  const handleReset = () =>{
    setEditType(EditType.edit);
    removeLayerEffect();
    resetEditor();
  }
  
  const goBack = () =>{
    handleReset();
    handleGoBack();
  }
  
  const handleSetEditableLayer = useCallback((layer)=>{
    setEditableLayer(layer);
    handleNext();
  },[handleNext]);
  
  const handleSelection = useCallback((item)=>{
    setPrefilledFields(prev=>prev.concat(item))
    handleNext();
  },[setPrefilledFields, handleNext])
  
  const handleAirportTypeSelection = useCallback((item)=>{
    const type = item.code;
    const symbology = getLayerSymbology(editableLayer, config);
    const iconValueDefinition = getIconValueDefinitions(symbology, editableLayer, 1, t);
    
    const filteredIconDefinition = iconValueDefinition.filter(item=>{
      const [aerodromeClass, aerodromeType] = item.value.split(',');
      return aerodromeType == type;
    })
    setAirdromeType(type);
    setPrefilledFields(([item]));
    
    if (filteredIconDefinition.length === 1){
      const airport = filteredIconDefinition[0];
      const [code] = airport.value.split(',').map(value=>Number(value));
      handleSelection({
        code: Number.isFinite(code) ? code : 0,
        icon: svgToBase(airport.icon, symbology.defaultColor),
        label: airport.label,
        iconName: airport.icon,
        fieldName: 'aerodromeclass'
      })
    } else {
      handleNext();
      // setPrefilledFields(prev=>prev.concat(item));
      // goToStep(ALL_STEPS.aerodromeClass);
      // handleNext();
    }
  },[editableLayer, editType, handleNext])
  
  const renderSlides = () => {
    const layerConfig = editableLayer?.layerConfig;
    const titleLabel = layerConfig?.titleLabel;
    
    const allSlides = [
      {
        id: ALL_STEPS.selectLayer,
        component: (
          <Platform
            handleGoBack={handleGoBack}
            editableLayers={editableLayers}
            handleSetEditableLayer={handleSetEditableLayer}
            setStep={()=>handleNext()}
            setEditType={setEditType}
          />
        )
      },
    ]
    
    if (titleLabel !== 'roads' && titleLabel !== 'roadsSituational') {
      allSlides.push({
        id: ALL_STEPS.entryPoints,
        component: (
          <EditFeatures editableLayers={editableLayers} handleNext={handleNext} editType={editType} />
        )
      })
    } else if (titleLabel === 'roads') {
      allSlides.push({
        id: ALL_STEPS.selectLocation,
        component: (
          <CoordinatesSelection
            editableLayer={editableLayer}
            t={t}
            config={config}
            clickActive={true}
            editType={editType}
            editableLayers={editableLayers}
            handleNext={handleNext}
          />
        )
      })
    } else if (titleLabel === 'roadsSituational') {
      allSlides.push({
        id: ALL_STEPS.selectLocation,
        component: (
          <CoordinatesSelection
            editableLayer={editableLayer}
            t={t}
            config={config}
            clickActive={true}
            editType={editType}
            editableLayers={editableLayers}
            handleNext={handleNext}
          />
        )
      })
      
      
      if (editType === EditType.create){
        allSlides.push({
          id: ALL_STEPS.enterRoadsSituationalCoordinates,
          component: (
            <EnterCoordinates
              handleNext={handleNext}
              handlePrev={handlePrev}
              editType={editType}
              editableLayer={editableLayer}
              goToStep={()=>{
                console.log('f');
              }}
            />
          )
        })
      } else {
        allSlides.push({
          id: ALL_STEPS.roadsSituationalManual,
          component: <RoadsSituationalManual
            editType={editType}
            // editableLayer={editableLayer}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        })
      }
  
      
    }
    
    if (editType === EditType.create){
      if (!!titleLabel && titleLabel.includes('aerodromes')) {
        allSlides.push({
          id: ALL_STEPS.aerodromeType,
          component: (
            <AerodromeType layer={editableLayer} config={config} handleSelection={handleAirportTypeSelection} t={t}/>
          )
        })
        
        if (prefilledFields.length >= 1) {
          const type = prefilledFields[0].code
          
          const symbology = getLayerSymbology(editableLayer, config);
          const iconValueDefinition = getIconValueDefinitions(symbology, editableLayer, 1, t);

          const filteredIconDefinition = iconValueDefinition.filter(item=>{
            const [aerodromeClass, aerodromeType] = item.value.split(',');
            return aerodromeType == type;
          })
          
          if (filteredIconDefinition.length > 1) {
            allSlides.push({
              id: ALL_STEPS.aerodromeClass,
              component: (
                <AerodromeClass layer={editableLayer} config={config} t={t} type={airdromeType} handleSelection={handleSelection}/>
              )
            })
          }
        }
      }
      
      if (titleLabel === 'pointAnnotations') {
        allSlides.push({
          id: ALL_STEPS.annotations,
          component: <Annotations layer={editableLayer} config={config} handleSelection={handleSelection} t={t}/>
        })
      }
      
      if (titleLabel !== 'roadsSituational') {
        allSlides.push({
          id: ALL_STEPS.selectLocation,
          component: (
            <CoordinatesSelection
              editableLayer={editableLayer}
              t={t}
              config={config}
              clickActive={true}
              editType={editType}
              editableLayers={editableLayers}
              handleNext={handleNext}
            />
          )
        })
      }
    }
    
    if (editType === EditType.edit && updateType === UpdateType.situational){
      allSlides.push({
        id: ALL_STEPS.fillFields,
        component: (
          <EditorFields
            editableLayer={editableLayer}
            config={config}
            editType={editType}
            isRequired={true}
          />
        )
      })
    } else {
      if (false) { //editableLayer?.layerConfig?.titleLabel === 'roadsSituational'
        allSlides.push({
          id: ALL_STEPS.fillFields,
          component: (
            <EditorFields
              editableLayer={editableLayer}
              config={config}
              editType={editType}
              isRequired={true}
            />
          )
        })
  
      } else {
        if (baseLineRequiredFields.length > 0) {
          allSlides.push({
            id: ALL_STEPS.fillFields,
            component: (
              <EditorFields
                editableLayer={editableLayer}
                config={config}
                editType={editType}
                isRequired={true}
              />
            )
          })
        }
  
        allSlides.push({
          id: ALL_STEPS.fillFields,
          component: (
            <EditorFields
              editableLayer={editableLayer}
              config={config}
              editType={editType}
              isRequired={false}
            />
          )
        })
      }
      
    }
    
    return allSlides;
  }

  const {doEdit, loading, doDelete, setLoading, methods, getIsDeletedField} = useEditor({
    editableLayer,
    editType,
    config,
    onSave: () => {
      if (!popupFeature){
        setShowSaveMessage(true);
      } else {
        goBack();
      }
    },
    goBack,
  })
  //
  const {formState:{isValid}, handleSubmit ,getValues, reset} = methods;
  
  const isNextDisabled = useMemo(()=>{
    const formValues = getValues();
    const currIndex = swiperRef.current?.swiper?.activeIndex;
    const currSlide = swiperRef.current?.swiper?.slides[currIndex];
    
    switch (currSlide?.id){
      case ALL_STEPS.selectLayer:{
        return !editableLayer
      }
      case ALL_STEPS.selectLocation:{
        return editType === EditType.edit ? true : addedPoints.length === 0;
      }
      case ALL_STEPS.enterRoadsSituationalCoordinates:{
        return config.editorRouteApiEnabled ? clickedPoints.filter(item=>item.coordinate.length > 0).length < 2 || addedPoints.length === 0 : clickedPoints.filter(item=>item.coordinate.length > 0).length < 2;
      }
      case ALL_STEPS.roadsSituationalManual:{
        return addedPoints.length === 0;
      }
      case ALL_STEPS.entryPoints:{
        return batchUpdateFeatures.length === 0;
      }
      case ALL_STEPS.fillFields:{
        const disabled = situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT ? !isValid || !formValues[currAsOfDateFieldName] : !isValid
        return disabled;
      }
      default:{
        return true;
      }
    }
  },[count, editableLayer, editType, highlightFeature, addedPoints, clickedPoints, batchUpdateFeatures, isValid, situationalUpdateType]);
  
  const slides = renderSlides();
  
  return (
    <div style={{
      display:'flex',
      flexDirection:'column',
      height:'100%',
    }}>
      <StyledEditorBreadcrumbs
        style={{
          marginBottom:0,
          minHeight:54
        }}
        hasProgress>
        <ProgressBar color={activeColor} currentPosition={swiperRef.current?.swiper?.activeIndex + 1 || 1} total={slides.length} />
        <p>{editType === EditType.edit ? 'Editing' : 'Adding'}</p>
        <button
          onClick={()=>{
            if (editableLayer){
              setShowWarning(true);
            } else {
              goBack();
            }
          }}>
          <svg fillRule="evenodd" role="img" viewBox="0 0 10 10" aria-label="description"><path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path></svg>
        </button>
      </StyledEditorBreadcrumbs>
      {loading && <Loader />}
      {showWarning && (
        <Message
          color={activeColor}
          onCancel={()=>{
            setShowWarning(false)
          }}
          onSubmit={goBack}
        >
          <p style={{color: "#525252", textAlign: "center", background:'#fff', padding:2, borderRadius:4}}>Are you sure you want to stop {editType === EditType.edit ? 'editing' : 'adding'}? Unsaved progress will be lost.</p>
        </Message>
      )}
      {
        showSaveMessage && (
          <Message
            color={activeColor}
            onCancel={()=>{
              if (editType === EditType.create){
                slideTo(1);
              } else {
                goBack();
              }
              setShowSaveMessage(false);
            }}
            onSubmit={()=>{
              resetHighlightFeature();
              setShowSaveMessage(false);
              slideTo(editType === EditType.create ? 2 : 1);
            }}
          >
            <p style={{color: "#525252", textAlign: "center", background:'#fff', padding:2, borderRadius:4}}>Do you want to {editType === EditType.edit ? 'edit' : 'add'}? another element?</p>
          </Message>
        )
      }
      <StyledEditorBody>
        <FormProvider {...methods}>
          <Swiper
            ref={swiperRef}
            preventInteractionOnTransition
            noSwiping
            allowTouchMove={false}
            preventClicks={false}
            simulateTouch={false}
            preventClicksPropagation={false}
            watchSlidesProgress
            style={{
              overflow:'hidden',
              width: '100%',
              height:'100%'
            }}
            onSlideChange={(swiper)=>{
              const currSlide = swiper.slides[swiper.activeIndex];
              if (currSlide.id === ALL_STEPS.selectLayer){
                handleReset();
              }
            }}
            onSlidePrevTransitionStart={(swiper)=>{
              if (swiper.activeIndex === 1){
                setEditType(EditType.edit);
                reset();
              }
            }}
          >
            {
              slides.map((slide, index)=>(
                <SwiperSlide
                  key={slide.id + '-' + index}
                  id={slide.id}
                >
                  {({ isActive, isVisible }) => (
                    isVisible && <div
                      style={{
                        overflow: slide.id === ALL_STEPS.fillFields ? 'hidden' : 'auto',
                        height: '100%',
                        padding: slide.id === ALL_STEPS.entryPoints ||  slide.id === ALL_STEPS.selectLayer || slide.id === ALL_STEPS.fillFields ? '0px 0px 0px' : '0 14px 12px 14px',
                      }}
                    >
                      {slide.component}
                      {
                        (swiperRef.current?.swiper?.activeIndex === 1) && (
                          <FloatingAddButton color={activeColor}>
                            <div style={{pointerEvents:'none'}}>
                              <Button
                                onClick={()=>{
                                  setEditType(EditType.create);
                                  handleNext();
                                }}
                                style={{
                                  border:'none',
                                  pointerEvents:'auto',
                                  marginBottom:'0px',
                                  minWidth:84,
                                  color: activeColor,
                                  fontSize: 12,
                                  minHeight: 28
                                }}>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"></path></svg>
                                Add new
                              </Button>
                            </div>
                          </FloatingAddButton>
                        )
                      }
                    </div>
                  )}
                </SwiperSlide>
              ))
            }
          </Swiper>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop:'auto',
            padding:8
          }}>
            <div style={{borderRadius: "4px", overflow: "hidden", display: "flex", height: "40px"}}>
              {!popupFeature && (
                <StyledArrowButton
                  disable={swiperRef.current?.swiper?.activeIndex === 0}
                  bg={activeColor ?? "#b21b0c"}
                  style={{
                    borderRadius: 8,
                    overflow: "hidden",
                    width: 34,
                    justifyContent: "center"
                  }}
                  onClick={handlePrev}
                >
                  <RightArrowIcon
                    width={18}
                    color="#FFFFFF"
                  />
                </StyledArrowButton>
              )}
            </div>
            {!swiperRef.current?.swiper?.isEnd ? <div>
              <StyledSubmitButton
                disable={isNextDisabled}
                onClick={handleNext}
                bg={activeColor}>
                Next {batchUpdateFeatures.length > 0 ? `(${batchUpdateFeatures.length})` : ''}
              </StyledSubmitButton>
            </div> : <div style={{
              display: "flex",
              gap: 4,
              alignItems:'center'
            }}>
              {(editType === EditType.edit && (updateType === UpdateType.baseline || editableLayer.layerConfig.titleLabel === 'roadsSituational') && getIsDeletedField()) && <StyledSubmitButton
                style={{
                  minWidth: "auto",
                  display: "flex",
                  alignItems: "center"
                }}
                bg={activeColor}
                onClick={doDelete}
              >
                <svg fill="#FFFFFF" fillRule="evenodd" height="16" role="img" viewBox="0 0 12 16" width="12">
                  <path d="M11 4v11c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V4H0V3h12v1h-1zM2 4v11h8V4H2z"></path>
                  <path d="M4 6h1v7H4zm3 0h1v7H7zM3 1V0h6v1z"></path>
                </svg>
              </StyledSubmitButton>}
              <StyledSubmitButton
                disable={isNextDisabled}
                onClick={handleSubmit(doEdit)}
                bg={activeColor}>
                {editType === EditType.create ? 'Save' : `Update ${batchUpdateFeatures.length > 0 ? `(${batchUpdateFeatures.length})` : ''}`}
              </StyledSubmitButton>
            </div>}
          </div>
        </FormProvider>
      </StyledEditorBody>
    </div>
  );
};

export default EditorSlides;