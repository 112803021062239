import styled, {css, keyframes} from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0.5;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0.5;
  }
`

const slideInAnimation = () =>
  css`
    ${slideIn} 0.3s linear;
  `

const slideOutAnimation = () =>
  css`
    ${slideOut} 0.3s linear;
  `

export const StyledFiltersScreen = styled.div`
  position: ${({isMobile})=> !isMobile ? 'absolute' : 'fixed'};
  transform: ${({isMobile})=> !isMobile ? 'translateX(calc(-100% + 40px))' : ''};
  z-index: ${({isMobile})=> !isMobile ? '1' : '100'};
  width: ${({isMobile, show})=> !isMobile ? !show ? '0px' : '400px' : 'calc(100% - 16px)'};
  height: ${({isAppEmbed, isMobile})=> !isMobile ? 'fit-content' : isAppEmbed ? 'calc(100% - 16px)' : 'calc(100% - 56px)'};
  max-height: ${({isMobile, height})=> isMobile ? '' : (height - 100) < 500 ? `${height-100}px` : '500px'};
  min-height: ${({isMobile})=> isMobile ? '' : '200px'};;
  background: #FFFFFF;
  opacity: ${({visible})=> visible ? '1' : '0'};
  pointer-events: ${({visible})=> visible ? 'auto' : 'none'};
  overflow: ${({visible})=> visible ? 'auto' : 'hidden'};;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  
  left: 8px;
  top: ${({isAppEmbed, isMobile})=> isAppEmbed ? '8px' : isMobile ? '48px' : '-7px'};
  border-radius: 8px;
  
  .shadow {
    height: 2px;
    width: 100%;
    background: red;
  }
  
  .filter__arrow-left {
    transform: ${({isMobile})=> !isMobile ? 'rotate(180deg)' : ''};
  }
`

export const StyledFiltersHeader = styled.div`
  min-height: 34px;
  position: relative;
  display: flex;
  align-items: center;
  //justify-content: center;
  padding: 8px 4px;
  gap: 8px;
  box-shadow: rgba(33,35,38,0.1) 0px 2px 6px -2px;
  
  span {
    font-size: 18px;
    font-weight: 400;
    color: #344054;
  }
`

export const StyledFiltersBody = styled.div`
  padding: 8px 0px 80px;
  display:flex;
  flex-direction:column;
  gap:4px;
  overflow:auto;
  
  .react-select__input {
    height: 18px !important;
  }
  .react-select__menu {
    max-height: 200px;
    overflow: auto;
  }
  
  .react-select__multi-value__remove {
    padding-right: 0px;
    cursor:pointer;
    
    :hover {
      background: transparent;
    }
    
    svg {
      width: 14px;
      height: 14px;
      fill: #FFFFFF;
      margin-top: 1px;
    }
  }
  .react-select__indicator-separator, .react-select__clear-indicator {
    display: none;
  }
  
  .react-select__indicator {
    padding:4px !important;
    svg {
      fill: #8c9ba5;
    }
  }
`

export const StyledFiltersBackButton = styled.div`
  background: #FFFFFF;
  border-radius: 100px;
  border:1px solid #eee;
  display: flex;
  min-width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    font-size: 14px;
  }
`

export const DomainFilterWrapper = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  .swiper-wrapper {
    display: flex;
    align-items: flex-end;
  }

  .swiper-slide {
    width: auto;
    height: auto;
  }
  
  .react-select {
    input[type='text'] {
      height: 20px !important;
    }
  }
`

export const DomainFilterTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  padding: 6px 10px;
  transition: all 0.1s;

  span {
    border-radius: 100px;
    width: 10px;
    height: 10px;
    padding: 0;
  }

  p {
    background: ${({selected, color})=> color ? selected ? (color.length > 7 ? color : `${color}d9`) : `#8080801a` : '#8080801a'};
    color: ${({selected})=>selected ? '#ffffff' : '#4c4c4c'};
    margin: 0;
    font-size: 12px;
    transition: all 0.1s;
    border-radius:100px;
    font-weight: 500;
    padding: 6px 8px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 769px) {
    :hover {
      p {
        color: #FFFFFF;
        background: ${({color = '#8080801a'})=>color};
      }
    }
  }
`
export const StyledFilterSection = styled.div`
  border-bottom:1px solid #eee;
  padding: 8px 14px;
`

export const StyledFilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  
  span {
    font-size: 16px;
    color: #344054;
    gap: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  svg {
    path {
      stroke: hsl(0, 0%, 80%);
    }
  }
  
  .filters-length {
    font-size: 10px;
    padding: 0px 4px;
    border-radius: 100%;
    background: #fff;
    color: #8c9ba5;
    line-height: normal;
    margin-left: 4px;
  }
`
