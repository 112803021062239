import {useCallback, useContext, useEffect, useState} from "react";
import ChecklistHeader from "./ChecklistHeader/ChecklistHeader";
import ChecklistTable from "./ChecklistTable";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {addLayerEffect} from "../../Editor/helpers";
import {EditorContext} from "../../Editor/EditorContextProvider";
import {graphicsLayer} from "../../../../utils/API";

const MonitoringChecklist = ({layers, handleNext, layerUpdates, setLayerUpdates, handlePrev, editedFeatures, setEditedFeatures}) => {
  const {editableLayer, setEditableLayer, setBatchUpdateFeatures, batchUpdateFeatures} = useContext(EditorContext);
  const {config} = useContext(ConfigContext);
  
  useEffect(()=>{
    if (layers.length > 0 && editableLayer === null) {
      setEditableLayer(layers.toArray()[0])
    }
  },[layers]);
  
  useEffect(()=>{
    if (editableLayer && batchUpdateFeatures.length === 0){
      addLayerEffect([editableLayer]);
    }
  },[editableLayer])
  
  const handleLayerSelection = useCallback((layer)=>{
    setEditableLayer(layer);
    setBatchUpdateFeatures([]);
    graphicsLayer.removeAll();
  },[]);
  
  return (
    <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
      <ChecklistHeader
        layers={layers}
        selectedLayer={editableLayer}
        onSelect={handleLayerSelection}
        config={config}
        layerUpdates={layerUpdates}
      />
      {editableLayer && (
        <ChecklistTable
          layers={layers}
          layer={editableLayer}
          handleNext={handleNext}
          layerUpdates={layerUpdates}
          setLayerUpdates={setLayerUpdates}
          handlePrev={handlePrev}
          editedFeatures={editedFeatures}
          setEditedFeatures={setEditedFeatures}
        />
      )}
    </div>
  );
};

export default MonitoringChecklist;