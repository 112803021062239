import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  StyledMobileButton,
  StyledMobileDropdown,
  StyledMobileDropdownBody,
  StyledMobileDropdownItem
} from "../Header-styled";

import {MOBILE_SCREENS} from "../../App/App";
import useClickOutside from "../../../hooks/useClickOutside";
import {view} from "../../../utils/API";
import {ROLE_EDITOR} from "../../../utils/helper";
import {isWidgetDisplayed} from "../../../esri/widgets/expandUtils";
import useLayersChange from "../../../hooks/useLayersChange";
import {checkLayerForFeatureTable} from "../../FeatureTable/FeatureTableWrapper";
import {ArrowDownIcon, InsightsIcon, ListIcon, MapIcon} from "../../Icons";
import Shepherd from "shepherd.js";
import {useSelector} from "react-redux";

const checkForInsightsLayer = (layer, config) =>{
  // return !!(layers.includes(layer.layerConfig?.alias) || layers.includes(layer.layerConfig?.extends));
  return config.role === ROLE_EDITOR ? isShownInEditorInsights : layer?.layerConfig?.isShownInPublicInsights;
}

const ScreenDropdown = ({setMobileScreen, mobileScreen, config, t}) => {
  const [menuOptions, setMenuOptions] = useState([]);
  const {visibleLayersIds, layerCount} = useLayersChange(config);
  const [showOptions, setShowOptions] = useState(false);
  const [onboardingActive, setOnboardingActive] = useState(false);
  const activeModule = useSelector(state=>state.activeModule);
  const ref = useRef();
  const prevOptions = useRef([]);
  
  const SCREEN_DATA = useMemo(()=>(
    {
      [MOBILE_SCREENS.MAP]:{
        icon: <MapIcon />,
        title: t('screen.widget.MobileList.map'),
      },
      [MOBILE_SCREENS.TABLE]: {
        icon:<ListIcon />,
        title: t('screen.widget.MobileList.list'),
      },
      [MOBILE_SCREENS.INSIGHTS]: {
        icon: <InsightsIcon />,
        title: t('screen.widget.Insights.title'),
      }
    }
  ),[])
  
  useEffect(()=>{
    Shepherd.on('start', ()=>{
      setOnboardingActive(true);
      setShowOptions(true);
      setMenuOptions(Object.keys(SCREEN_DATA).map(k=>({option:k})))
    })
  
    Shepherd.on('complete', ()=>{
      setOnboardingActive(false);
      setShowOptions(false);
    })
  },[])
  
  useClickOutside(ref, ()=>{
    if(onboardingActive)return;
    setShowOptions(false);
  })
  
  useEffect(()=>{
    prevOptions.current = [];
  },[activeModule])
  
  useEffect(()=>{
    let timer;
    if (!view || !config || onboardingActive) return;
    
    timer = setTimeout(()=>{
      // const insights = config.insights || {};
      // const insightsLayer = config.role === ROLE_EDITOR ? insights.editorLayer : insights.publicLayer;
      const insightsLayers = [];
      
      const hasInsightsWidget = isWidgetDisplayed(config, 'Insights');
      const isFeatureTableActive = isWidgetDisplayed(config, 'FeatureTable');
      
      const moduleConfig = config.modules[activeModule] || {};
      const optionalLayers = moduleConfig.optionalLayers || [];
      const featureTableLayers =  isFeatureTableActive ? view.map.layers.filter(l=> checkLayerForFeatureTable(l, !optionalLayers.includes(l.layerConfig?.alias))) : [];
      
      const visibleLayers = view.map.layers.filter(l=>visibleLayersIds[l.id]);
  
      visibleLayers.forEach((l)=>{
        if (hasInsightsWidget && checkForInsightsLayer(l, config)){
          insightsLayers.push(l);
        }
    
        // if (isFeatureTableActive && checkLayerForFeatureTable(l)) {
        //   featureTableLayers.push(l);
        // }
      })
  
      const options = [{
        option: MOBILE_SCREENS.MAP,
      }];
      
      const moduleOpenWidgets = config.modules[activeModule] ? config.modules[activeModule].openWidgets || [] : [];
      
      const openWidget = moduleOpenWidgets.length > 0 ? moduleOpenWidgets[0] : null;
      let activeScreen = MOBILE_SCREENS.MAP;
  
      if (openWidget && isWidgetDisplayed(config, openWidget)){
        activeScreen = openWidget;
      }
      
      if (featureTableLayers.length > 0){
        options.push({
          option: MOBILE_SCREENS.TABLE,
        })
        
        // if (openWidget === MOBILE_SCREENS.TABLE){
        //   activeScreen = MOBILE_SCREENS.TABLE;
        // }
      }
      
      if (insightsLayers.length > 0){
        options.push({
          option: MOBILE_SCREENS.INSIGHTS,
        })
        // if (openWidget === MOBILE_SCREENS.INSIGHTS){
        //   activeScreen = MOBILE_SCREENS.INSIGHTS;
        // }
      }
      
      if (options.length !== prevOptions.current.length){
        setMobileScreen(activeScreen);
      }
      
      setMenuOptions(options);
      prevOptions.current = options;
      
    },150)
    
    return () => {
      clearTimeout(timer);
    }
  },[config, layerCount, activeModule, onboardingActive])
  
  const handleDropdownSelect = useCallback((screen) => {
    setMobileScreen(screen);
    setShowOptions(false);
  },[]);
  
  if (menuOptions.length <= 1) return null;
  
  return (
    <StyledMobileDropdown
      ref={ref}
    >
      <div
        className="onboarding-navigation"
        style={{
          opacity: onboardingActive ? 1 : 0,
          pointerEvents: onboardingActive ? 'auto' : 'none',
          position:'absolute',
          left:0,
          top:0,
          width:'100%',
          height:'180px',
          zIndex:2
      }}>
      
      </div>
      <StyledMobileButton onClick={()=>setShowOptions(prev=>!prev)}>
        {SCREEN_DATA[mobileScreen].icon}
        <span>{SCREEN_DATA[mobileScreen].title}</span>
        <ArrowDownIcon
          style={{
            marginLeft:'auto',
            transform: showOptions ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
        />
      </StyledMobileButton>
      <StyledMobileDropdownBody show={showOptions}>
        {menuOptions.map(({option, className})=>(
            <StyledMobileDropdownItem
              key={option}
              onClick={()=>handleDropdownSelect(option)}
              selected={option === mobileScreen}
              className={className}
            >
              {SCREEN_DATA[option].icon}
              <span>{SCREEN_DATA[option].title}</span>
            </StyledMobileDropdownItem>
          ))}
      </StyledMobileDropdownBody>
    </StyledMobileDropdown>
  );
};

export default ScreenDropdown;
