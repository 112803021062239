import React, {useContext, useMemo} from "react";
import {ConfigContext} from "../../../utils/ConfigContext";
import {EMBED_VARIANTS, standardizeColor} from "../../../utils/helper";
import {
  DomainFilterTag,
  DomainFilterWrapper,
  StyledFiltersScreen
} from "./MobileFilter-styled";
import Filters from "./Filters";
import useFilters from "../../../hooks/useFilters";
import useWindowSize from "../../../hooks/useWindowSize";

export const ShowPublicFilter = ({
  onChange,
  options,
  value,
  colors = {},
})=>{
  const selectedValues = useMemo(()=>{
    return value.map(item=>item.value);
  },[value])
  
  const onSelect = (option) =>{
    let newValues = value;
    if (Array.isArray(option.value)){
      newValues = option.value
    } else {
      newValues = [option]
    }
    onChange(newValues);
  }
  
  return (
    <DomainFilterWrapper>
      {
        options.map(option=>(
          <DomainFilterTag
            key={option.value}
            className={selectedValues.includes(option.value)}
            selected={selectedValues.length === 0 ? option.value.length === 0 : selectedValues.includes(option.value)}
            color={standardizeColor(colors[option.value] ?? '#808080')}
            onClick={()=>onSelect(option)}
            style={{
              padding:0,
            }}
          >
            <p>
              {option.label}
            </p>
          </DomainFilterTag>
        ))
      }
    </DomainFilterWrapper>
  )
}


export const DomainFilter = ({
  onChange,
  options,
  value,
  colors = {}
}) =>{
  const selectedValues = useMemo(()=>{
    return value.map(item=>item.value);
  },[value])
  
  const onSelect = (option) =>{
    let newValues = value;
    if (selectedValues.includes(option.value)){
      newValues = value.filter(item=> item.value !== option.value);
    } else {
      newValues.push(option)
    }
    onChange(newValues);
  }
  
  return (
    <DomainFilterWrapper>
      {
        options.map(option=>(
          <DomainFilterTag
            key={option.value}
            className={selectedValues.includes(option.value)}
            selected={selectedValues.includes(option.value)}
            color={standardizeColor(colors[option.value] ?? '#808080')}
            onClick={()=>onSelect(option)}
            style={{
              padding:0,
            }}
          >
            <p>
              {option.label}
              {(selectedValues.includes(option.value) &&
                <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill="#fff">
                  <path
                    d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>)}
            </p>
          </DomainFilterTag>
        ))
      }
    </DomainFilterWrapper>
  )
}

const MobileFilter = ({setShowFilters, showFilters, isMobile, menuPosition, onFilterApply}) => {
  const {config} = useContext(ConfigContext);
  const isAppEmbed = useMemo(() => config.embed === EMBED_VARIANTS.APP, [config]);
  const {filters, setAndApplyFilters} = useFilters({isActive:true, config},false);
  
  const initialFilters = useMemo(()=> filters,[]);
  const {height} = useWindowSize();
  
  return (
    <StyledFiltersScreen
      isAppEmbed={isAppEmbed}
      visible={showFilters}
      isMobile={isMobile}
      show={showFilters}
      height={height}
    >
      {showFilters && (
        <Filters
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          initialFilters={initialFilters}
          filters={filters}
          menuPosition={menuPosition}
          onFilterApply={onFilterApply}
          setAndApplyFilters={setAndApplyFilters}
        />
      )}
    </StyledFiltersScreen>
  );
};

export default MobileFilter;
