import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import CustomSwiper, {CustomSwiperSlide} from "../../../CustomSwiper";
import {useTranslation} from "react-i18next";
import {ConfigContext} from "../../../../utils/ConfigContext";
import CoordinatesSelection from "../CoordinatesSelection/CoordinatesSelection";
import {graphicsLayer, isdeletedFieldName, opidFieldName, showpublicFieldName, view} from "../../../../utils/API";
import {StyledArrowButton, StyledSubmitButton} from "../../../Report/new/Footer/Footer-styled";
import {RightArrowIcon} from "../../../Panel/components/Pagination/helpers";
import EditorFields from "../EditorFields/EditorFields";
import {dangerColor} from "../../../../utils/Theme";
import AerodromeType from "../EditableLayerList/Aerodromes/AerodromeType";
import AerodromeClass from "../EditableLayerList/Aerodromes/AerodromeClass";
import Annotations from "../EditableLayerList/Annotations/Annotations";
import ProgressBar from "../../../Report/new/ProgressBar/ProgressBar";
import {FloatingAddButton, StyledEditorBody, StyledEditorBreadcrumbs} from "../Editor-styled";
import aerodromeType from "../EditableLayerList/Aerodromes/AerodromeType";
import {EditorContext} from "../EditorContextProvider";
import {togglePopupEditor} from "../../../../utils/helper";
import useEditor from "../../../../hooks/useEditor";
import {useDispatch} from "react-redux";
import Loader from "../Loader/Loader";
import {getIconValueDefinitions, getLayerSymbology} from "../../../../utils/symbologies";
import {svgToBase} from "../../../Icons/layerSvg/clusterAssets";
import EnterCoordinates from "../CoordinatesSelection/SituationalRoads/EnterCoordinates/EnterCoordinates";
import RoadsSituationalManual from "../CoordinatesSelection/SituationalRoads/RoadsSituationalManual";
import Message from "../Message/Message";
import Button from "../../../Report/new/Button/Button";
import Platform from "../Platform/Platform";
import EditFeatures from "../EditFeatures/EditFeatures";
import {FormProvider} from "react-hook-form";

export const getFieldDefaultValue = (f, config) => {
  if (f.name === 'iso3' || f.name === 'iso3a'){
    return config.iso3 ? Array.isArray(config.iso3) ? config.iso3[0] : config.iso3 : ""
  } else if (f.name === opidFieldName){
    return config.id
  } else if (f.name === isdeletedFieldName || f.name === showpublicFieldName){
    return 2
  } else if (f.defaultValue)
    return f.defaultValue
}

export const LAYER_EFFECT = "grayscale(20%) opacity(13%)";

export const EditType = {
  edit : "edit",
  create : "create",
  delete : "delete"
}

export const lineSymbol = {
  type: "simple-line",
  color: "red",
  width: 1
}

export const pointSymbol = {
  type: "simple-marker",
  size: 8,
  color: dangerColor,
  outline: {
    width: 1,
    color: "#FFF"
  }
}

export const fillSymbol = {
  type: "simple-fill",
  color: dangerColor,
  style: "solid",
  outline: { color: "black", width: 1}
}

export const pointGraphicSymbol = {
  type: "simple-marker",
  size: 14,
  color:'rgb(192, 58, 43)',
  outline: {
    width: 2,
    color: "#FFF"
  }
}

export const ALL_STEPS = {
  selectLayer: 'selectLayer',
  entryPoints: 'entryPoints',
  aerodromeType: 'aerodromeType',
  aerodromeClass: 'aerodromeClass',
  annotations: 'annotations',
  enterRoadsSituationalCoordinates: 'enterRoadsSituationalCoordinates',
  roadsSituationalManual: 'roadsSituationalManual',
  selectLocation: 'selectLocation',
  fillFields: 'fillFields',
}

const ALL_STEPS_ARRAY = [ALL_STEPS.selectLayer, ALL_STEPS.entryPoints, ALL_STEPS.aerodromeType, ALL_STEPS.aerodromeClass, ALL_STEPS.annotations, ALL_STEPS.enterRoadsSituationalCoordinates, ALL_STEPS.roadsSituationalManual, ALL_STEPS.selectLocation, ALL_STEPS.fillFields];

const getLayerSteps = (editableLayer, editType) => {
  const defaultSteps = [ALL_STEPS.selectLayer, ALL_STEPS.selectLocation, ALL_STEPS.fillFields];
  
  if (!editableLayer) {
    return defaultSteps;
  }
  
  if (editType === EditType.edit && editableLayer.layerConfig.titleLabel !== 'roadsSituational' && editableLayer.layerConfig.titleLabel !== 'roads') {
    return [ALL_STEPS.selectLayer, ALL_STEPS.entryPoints, ALL_STEPS.fillFields];
  }
  
  //showing default steps when editing except roads situational as we have custom edit functionality
  if (editType === EditType.edit && editableLayer.layerConfig.titleLabel !== 'roadsSituational'){
    return defaultSteps;
  }
  
  //custom steps per layer
  switch (editableLayer.layerConfig.titleLabel){
    case "aerodromes":{
      const startSteps = defaultSteps.slice(0,1);
      const lastSteps = defaultSteps.slice(-2);
      return [...startSteps, ALL_STEPS.aerodromeType, ALL_STEPS.aerodromeClass, ...lastSteps]
    }
    case 'pointAnnotations':{
      const startSteps = defaultSteps.slice(0,1);
      const lastSteps = defaultSteps.slice(-2);
      return [...startSteps, ALL_STEPS.annotations, ...lastSteps]
    }
    case 'roadsSituational':{
      const startSteps = defaultSteps.slice(0,1);
      
      if (editType === EditType.edit){
        return [...startSteps, ALL_STEPS.selectLocation, ALL_STEPS.roadsSituationalManual, ALL_STEPS.fillFields];
      } else {
        return [...startSteps, ALL_STEPS.enterRoadsSituationalCoordinates, ALL_STEPS.roadsSituationalManual, ALL_STEPS.fillFields];
      }
    }
    default:{
      return defaultSteps
    }
  }
}

const EditorSwiper = ({editableLayers, editableLayer, step, setStep, handleGoBack, editType, setEditType}) => {
  const context = useContext(EditorContext)
  const {airdromeType, setAirdromeType} = context;
  const {addedPoints, setAddedPoints} = context;
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  
  const {highlightHandle, setHighlightHandle} = context;
  const {highlightSymbol, setHighlightSymbol} = context;
  const {highlightFeature,setHighlightFeature} = context;
  const {attachments, setAttachments} = context;
  const {prefilledFields, setPrefilledFields} = context;
  const {sketchGraphicsLayer, setSketchGraphicsLayer} = context;
  const {sketchViewModel, setSketchViewModel} = context;
  const {showWarning, setShowWarning} = context;
  const {clickedPoints, resetClickedPoints} = context;
  const {showRoadsEditor, setShowRoadsEditor} = context;
  
  const {setPopupFeature, resetHighlightFeature, batchUpdateFeatures, resetBatchUpdateFeatures, setEditableLayer, popupFeature} = context;
  const stepHistory = useRef([]);
  
  const {t} = useTranslation('common');
  const {config} = useContext(ConfigContext);
  
  const swiperRef = useRef();
  // const [swiperRef, setSwiperRef] = useState(null)
  
  const dispatch = useDispatch();
  const goBack = () =>{
    resetEditor();
    setEditableLayer(null);
    handleGoBack();
  }
  
  const resetEditor = ()=>{
    setStep(EditType.edit + '-' + ALL_STEPS.selectLayer);
    setEditType(EditType.edit);
    resetBatchUpdateFeatures();
    setAirdromeType(null)
    setAttachments({
      previews: {},
      files: []
    });
    setPrefilledFields([])
    removeAddedGraphics();
    removeLayerEffect();
    sketchGraphicsLayer?.removeAll();
    graphicsLayer.removeAll();
    view.map.remove(sketchGraphicsLayer);
    setSketchGraphicsLayer(null);
    setSketchViewModel(null);
    setPopupFeature(false);
    resetClickedPoints();
    setShowRoadsEditor(false);
  }
  
  const {doEdit, loading, doDelete, setLoading, methods, confirmStatus} = useEditor({
    editableLayer,
    editType,
    config,
    onSave: () => {
      if (!popupFeature){
        setShowSaveMessage(true);
      } else {
        goBack();
      }
    },
    goBack,
  })
  
  useEffect(()=>{
    togglePopupEditor({
      view,
      expand:{
        viewModel:{
          expanded:true
        }
      }
  });
    return () => {
      togglePopupEditor({
        view,
        expand:{
          viewModel:{
            expanded: false
          }
        }
      });
      
      resetEditor();
      setEditableLayer(null);
    }
  },[]);
  
  const addLayerEffect = useCallback(()=>{
    // if (!editableLayer) return;
    // view.map.layers.forEach((layer)=>{
    //   if (editableLayer?.layerConfig?.titleLabel === "roadsSituational" && layer?.layerConfig?.titleLabel === 'roads'){
    //     return;
    //   }
    //   if (editableLayers.some(elayer=>elayer === layer
    //       && (editType === EditType.create && editableLayer.layerConfig.titleLabel === "roadsSituational" || editableLayer?.id !== layer.id)
    //   )){
    //     layer.effect = LAYER_EFFECT;
    //   }
    // })
  },[editType, editableLayer, editableLayers, sketchGraphicsLayer])
  
  const removeAddedGraphics = ()=>{
    graphicsLayer.removeAll();
    highlightHandle?.remove()
    setHighlightSymbol(null);
    setHighlightHandle(null);
    setHighlightFeature(null);
    setAddedPoints([]);
  }
  
  const removeLayerEffect = useCallback(()=>{
    view.map.layers.forEach((layer)=>{
      if (graphicsLayer.id !== layer.id && layer.title !== 'wld_bnd_adm0') {
        layer.effect = undefined;
      }
    })
  },[editType, editableLayer])
  
  const prevStep = useRef();
  useEffect(()=>{
    if (!swiperRef.current) return;
    const currStepIndex = swiperRef.current.swiper.activeIndex;
    const selectLocationIndex = ALL_STEPS_ARRAY.indexOf(ALL_STEPS.selectLocation);
    
    const [stepEditType, stepName] = step.split('-');
    const nextStepIndex = ALL_STEPS_ARRAY.indexOf(stepName);
    
    if (nextStepIndex > currStepIndex) {
      // const prevStep = ALL_STEPS_ARRAY[currStepIndex];
      stepHistory.current.push(prevStep.current);
    }
    
    prevStep.current = step;
    if (nextStepIndex > 0) {
      addLayerEffect();
    } else if (nextStepIndex <  selectLocationIndex) {
      removeLayerEffect();
    }
    
    if (step.includes(ALL_STEPS.selectLayer)) {
      resetEditor();
      setEditableLayer(null);
    }
    
    swiperRef.current.swiper.slideTo(nextStepIndex);
  },[step])
  
  const handleNext = useCallback(() => {
    const [stepEditType, stepName] = step.split('-');
    const steps = getLayerSteps(editableLayer, editType, config);
    const currStepIndex = steps.indexOf(stepName);
    const nextStepName = steps[currStepIndex+1];
    
    goToStep(nextStepName);
  },[step, editableLayer, editType, config])
  
  const handleSetEditableLayer = useCallback((layer)=>{
    setEditableLayer(layer);
    const steps = getLayerSteps(layer, editType, config);
    const nextStep = steps[1];
    goToStep(nextStep);
  },[editType, config, step]);
  
  const handlePrev = useCallback(() => {
    const [stepEditType, backStepName] = stepHistory.current.pop().split('-');
    if (editType !== stepEditType){
      setEditType(stepEditType);
    }
    
    setStep(stepEditType + '-' + backStepName);
  },[step, airdromeType, editableLayer, editType, config, showRoadsEditor])
  
  const isNextDisabled = useMemo(()=>{
    const [stepEditType, stepName] = step.split('-');
    switch (stepName){
      case ALL_STEPS.selectLayer:{
        return !editableLayer
      }
      case ALL_STEPS.selectLocation:{
        return editType === EditType.edit ? true : addedPoints.length === 0;
      }
      case ALL_STEPS.enterRoadsSituationalCoordinates:{
        return config.editorRouteApiEnabled ? clickedPoints.filter(item=>item.coordinate.length > 0).length < 2 || addedPoints.length === 0 : clickedPoints.filter(item=>item.coordinate.length > 0).length < 2;
      }
      case ALL_STEPS.roadsSituationalManual:{
        return addedPoints.length === 0;
      }
      case ALL_STEPS.entryPoints:{
        return batchUpdateFeatures.length === 0;
      }
      default:{
        return true;
      }
    }
  },[step, editableLayer, editType, highlightFeature, addedPoints, clickedPoints, batchUpdateFeatures])
  
  const handleAirportTypeSelection = useCallback((item)=>{
    const type = item.code;
    const symbology = getLayerSymbology(editableLayer, config);
    const iconValueDefinition = getIconValueDefinitions(symbology, editableLayer, 1, t);
    
    const filteredIconDefinition = iconValueDefinition.filter(item=>{
      const [aerodromeClass, aerodromeType] = item.value.split(',');
      return aerodromeType == type;
    })
    setAirdromeType(type);
    setPrefilledFields(prev=>prev.concat(item));
    if (filteredIconDefinition.length === 1){
      const airport = filteredIconDefinition[0];
      const [code] = airport.value.split(',').map(value=>Number(value));
       handleSelection({
         code: Number.isFinite(code) ? code : 0,
         icon: svgToBase(airport.icon, symbology.defaultColor),
         label: airport.label,
         iconName: airport.icon,
         fieldName: 'aerodromeclass'
       })
    } else {
      setPrefilledFields(prev=>prev.concat(item))
      goToStep(ALL_STEPS.aerodromeClass);
    }
  },[editableLayer, editType])
  
  const handleSelection = useCallback((item)=>{
    setPrefilledFields(prev=>prev.concat(item))
    goToStep(ALL_STEPS.selectLocation)
  },[setPrefilledFields])
  
  useLayoutEffect(()=>{
    const clickEventInPopup =(feature) => {
      setLoading(true);
      const layer = feature.sourceLayer ? feature.sourceLayer : feature.layer
      feature.geometry = feature.originalGeometry? feature.originalGeometry : feature.geometry
      
      view.whenLayerView(layer).then((layerView) => {
        setTimeout(()=>{
          if (layer.layerConfig.titleLabel !== 'roadsSituational'){
            layer?.renderer?.getSymbolAsync(highlightFeature).then((symbol) => {
              setHighlightSymbol(symbol)
              const clone = feature.clone();
              clone.symbol = symbol;
              graphicsLayer.add(clone)
            })
            setHighlightHandle(layerView.highlight(feature));
            goToStep(ALL_STEPS.fillFields);
          } else {
            setShowRoadsEditor(true);
            goToStep(ALL_STEPS.roadsSituationalManual);
          }
          
          setEditableLayer(layer);
          setPopupFeature(true);
          setHighlightFeature(feature);
          setLoading(false);
          addLayerEffect();
        },100)
      })
    }
    
    //return
    if (highlightFeature){
      clickEventInPopup(highlightFeature)
    } else {
      goToStep(ALL_STEPS.selectLayer);
    }
  },[])
  
  const goToStep = (stepName, stepEditType = undefined) =>{
    setStep(`${stepEditType || editType}-${stepName}`)
  }
  const renderSlides = () =>{
    const allSlides = [
      {
        id: ALL_STEPS.selectLayer,
        component: (
          <Platform
            handleGoBack={handleGoBack}
            editableLayers={editableLayers}
            handleSetEditableLayer={handleSetEditableLayer}
            setStep={goToStep}
          />
        )
      },
    ]
  
    allSlides.push({
      id: ALL_STEPS.entryPoints,
      component: (
        <EditFeatures editableLayers={editableLayers} handleNext={handleNext} editType={editType} />
      )
    })
  
    allSlides.push({
      id: ALL_STEPS.aerodromeType,
      component: (
        <AerodromeType layer={editableLayer} config={config} handleSelection={handleAirportTypeSelection} t={t}/>
      )
    })
  
    allSlides.push({
      id: ALL_STEPS.aerodromeClass,
      component: (
        <AerodromeClass layer={editableLayer} config={config} t={t} type={airdromeType} handleSelection={handleSelection}/>
      )
    })
    
    allSlides.push({
      id: ALL_STEPS.annotations,
      component: <Annotations layer={editableLayer} config={config} handleSelection={handleSelection} t={t}/>
    })
    
    allSlides.push({
      id: ALL_STEPS.enterRoadsSituationalCoordinates,
      component: <EnterCoordinates handleNext={handleNext} goToStep={goToStep} />
    })
  
    allSlides.push({
      id: ALL_STEPS.roadsSituationalManual,
      component: <RoadsSituationalManual
        editType={editType}
        // editableLayer={editableLayer}
        handleNext={handleNext}
      />
    })
    
    allSlides.push({
      id: ALL_STEPS.selectLocation,
      component: (
        <CoordinatesSelection
          editableLayer={editableLayer}
          t={t}
          config={config}
          clickActive={true}
          editType={editType}
          editableLayers={editableLayers}
          handleNext={handleNext}
        />
      )
    })

    allSlides.push({
      id: ALL_STEPS.fillFields,
      component: (
          <EditorFields
            key="fillFields"
            editableLayer={editableLayer}
            config={config}
            editType={editType}
            highlightFeature={highlightFeature}
            t={t}
            setAttachments={setAttachments}
            attachments={attachments}
            highlightSymbol={highlightSymbol}
            setHighlightFeature={setHighlightFeature}
          />
      )
    })
    return allSlides
  }
  
  // const withNode = useCallback((el)=>{
  //   if (el){
  //     setSwiperRef(el.swiper)
  //   }
  // },[])
  
  const slideRef = useRef();
  
  return (
    <FormProvider {...methods}>
      <StyledEditorBreadcrumbs hasProgress>
        <ProgressBar color={config.opsColor} currentPosition={ALL_STEPS_ARRAY.indexOf(step) + 1} total={ALL_STEPS_ARRAY.length+1} />
        <p>{editType === EditType.edit ? 'Editing' : 'Adding'}</p>
        <button
          onClick={()=>{
            if (step.includes(ALL_STEPS.selectLayer)){
              goBack();
            } else {
              setShowWarning(true);
            }
          }}>
          <svg fillRule="evenodd" role="img" viewBox="0 0 10 10" aria-label="description"><path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path></svg>
        </button>
      </StyledEditorBreadcrumbs>
      <StyledEditorBody>
        {loading && <Loader>
          Text
        </Loader>}
        {showWarning && (
          <Message
            config={config}
            onCancel={()=>{
              setShowWarning(false)
            }}
            onSubmit={goBack}
          >
            <p style={{color: "#525252", textAlign: "center", background:'#fff', padding:2, borderRadius:4}}>Are you sure you want to stop {editType === EditType.edit ? 'editing' : 'adding'}? Unsaved progress will be lost.</p>
          </Message>
        )}
        {
          showSaveMessage && (
            <Message
              config={config}
              onCancel={()=>{
                if (editType === EditType.create){
                  goToStep(ALL_STEPS.entryPoints, EditType.edit);
                } else {
                  goBack();
                }
                setShowSaveMessage(false);
              }}
              onSubmit={()=>{
                resetHighlightFeature();
                setShowSaveMessage(false);
                if (editableLayer.layerConfig.titleLabel === 'roadsSituational'){
                  goToStep(editType === EditType.create ? ALL_STEPS.enterRoadsSituationalCoordinates : ALL_STEPS.selectLocation)
                } else {
                  handlePrev()
                }
              }}
            >
              <p style={{color: "#525252", textAlign: "center", background:'#fff', padding:2, borderRadius:4}}>Do you want to {editType === EditType.edit ? 'edit' : 'add'}? another element?</p>
            </Message>
          )
        }
        <CustomSwiper
          ref={swiperRef}
          prevent-interaction-on-transition
          no-swiping
          allow-touch-move={false}
          prevent-clicks={false}
          simulate-touch={false}
          prevent-clicks-propagation={false}
          virtual="true"
          init="true"
          observer={true}
          style={{
            overflow:'hidden',
            width:'100%',
          }}
        >
          {
            renderSlides().map((slide, index)=>{
              return <CustomSwiperSlide
                id="editorSlide"
                ref={slideRef}
                tabIndex={index}
                key={`${slide.id}-${index}}`}
                style={{overflow: 'hidden'}}
                className={slide.id}
              >
                <div
                  className={step + '-slideId=' + slide.id}
                  style={{
                    position:'absolute',
                    padding: step.includes(ALL_STEPS.entryPoints) ||  step.includes(ALL_STEPS.selectLayer) ? '0px 0px 0px' : '0 14px 12px 14px',
                    overflow:'auto',
                    maxHeight: '100%',
                    boxSizing: 'border-box',
                    width:'100%'
                  }}>
                  {step.includes(slide.id) && slide.component}
                  {
                    (step.includes(ALL_STEPS.entryPoints) || step.includes(ALL_STEPS.selectLocation) && EditType.edit === editType) && (
                      <FloatingAddButton>
                        <div style={{pointerEvents:'none'}}>
                          <Button
                            onClick={()=>{
                              // handleMainSlideChange('layerEdit', EditType.create)
                              const steps = getLayerSteps(editableLayer, EditType.create, config)
                              const stepName = steps[1];
                              
                              setEditType(EditType.create);
                              goToStep(stepName, EditType.create);
                            }}
                            style={{
                              border:'none',
                              pointerEvents:'auto',
                              marginBottom:'0px',
                              minWidth:84,
                              color: config.opsColor,
                              fontSize: 12,
                              minHeight: 28
                            }}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"></path></svg>
                            Add new
                          </Button>
                        </div>
                      </FloatingAddButton>
                    )
                  }
                  {
                    (step.includes(ALL_STEPS.fillFields) && batchUpdateFeatures.length > 0 && !highlightFeature) && (
                      <div style={{
                        position:'fixed',
                        bottom:0,
                        left:'50%',
                        transform: 'translate(-50%)'
                      }}>
                        <div style={{pointerEvents:'none'}}>
                          <Button
                            onClick={confirmStatus}
                            style={{
                              border:'none',
                              pointerEvents:'auto',
                              marginBottom:'0px',
                              minWidth:84,
                              // color: config.opsColor,
                              fontSize: 12,
                              minHeight: 28,
                              display:'flex',
                              alignItems:'center',
                              gap:4
                            }}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z"></path></svg>
                            Confirm status
                          </Button>
                        </div>
                      </div>
                    )
                  }
                </div>
              </CustomSwiperSlide>
            })
          }
        </CustomSwiper>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop:'auto',
          padding:8
        }}>
          <div style={{borderRadius: "4px", overflow: "hidden", display: "flex", height: "40px"}}>
            {!popupFeature && <StyledArrowButton
              disable={step.includes(ALL_STEPS.selectLayer)}
              bg={config.opsColor ?? "#b21b0c"}
              style={{
                borderRadius: 8,
                overflow: "hidden",
                width: 34,
                justifyContent: "center"
              }}
              onClick={handlePrev}
            >
              <RightArrowIcon
                width={18}
                color="#FFFFFF"
              />
            </StyledArrowButton>}
          </div>
          {!step.includes(ALL_STEPS.fillFields) ? <div>
            <StyledSubmitButton
              disable={isNextDisabled}
              onClick={handleNext}
              bg={config.opsColor}>
              Next {batchUpdateFeatures.length > 0 ? `(${batchUpdateFeatures.length})` : ''}
            </StyledSubmitButton>
          </div> : <div style={{
            display: "flex",
            gap: 4,
            alignItems:'center'
          }}>
            <StyledSubmitButton
              style={{
                minWidth:'auto',
                display:'flex',
                alignItems:'center'
              }}
              bg={config.opsColor}
              onClick={doDelete}
            >
              <svg fill="#FFFFFF" fillRule="evenodd" height="16" role="img" viewBox="0 0 12 16" width="12">
                <path d="M11 4v11c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V4H0V3h12v1h-1zM2 4v11h8V4H2z"></path>
                <path d="M4 6h1v7H4zm3 0h1v7H7zM3 1V0h6v1z"></path>
              </svg>
            </StyledSubmitButton>
            <StyledSubmitButton
              onClick={doEdit}
              bg={config.opsColor}>
              {editType === EditType.create ? 'Save' : 'Update'}
            </StyledSubmitButton>
          </div>}
        </div>
      </StyledEditorBody>
    </FormProvider>
  );
};

export default EditorSwiper;
