import React, {useState} from "react";
import {InputLabel, InputContainer, WarningMessage, DescriptionWrapper} from "./Input-styled";
import Dropdown, {DropdownBody} from "../../../Dropdown";

const Input = ({label, register = {}, name ,errorMessage, borderColor, margin, noMargin, description, children, isFocused ,...rest }) => {
  const [show, setShow] = useState(false);
  
  return (
    <InputContainer label={label} noMargin={noMargin} margin={margin} borderColor={borderColor} isFocused={isFocused}>
      <input name={name} {...register} {...rest} />
      {(label && label.length > 0) && <InputLabel>{label}</InputLabel>}
      <WarningMessage isError={!!errorMessage}>{errorMessage}</WarningMessage>
      {description && <DescriptionWrapper>
        <Dropdown
          setShow={setShow}
          show={show}
          leftOffset={0.3}
          hasMinWidth={false}
        >
          <div style={{
            display:'flex'
          }}>
            <svg fill="rgb(76,76,76)" fillRule="evenodd" height="10" role="img" viewBox="0 0 16 16" width="10" aria-label="info" alt="info"><title>info</title><circle cx="8" cy="4" r="1"></circle><path d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 118 0a8 8 0 010 16z"></path><path d="M9 13H7V7h2z"></path></svg>
          </div>
          <DropdownBody type="tooltip" style={{display: "flex"}}>
            {description}
          </DropdownBody>
        </Dropdown>
      </DescriptionWrapper>}
      {children}
    </InputContainer>
  )
}

export default Input