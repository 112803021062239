import React, {useCallback, useEffect, useMemo, useState, useTransition} from "react";
import Details from "../Details/Details";
import Documents from "../Documents";

export const TABS = {
  LCA: 'LCA',
  Documents: 'Documents'
}

const FeatureDetailsTabs = ({config, feature, layer, attachments, tabs, t, headerRef}) => {
  const [selectedTab, setSelectedTab] = useState(TABS.LCA);
  const [lcaTitle, setLcaTitle] = useState(null)
  const [isPending, startTransition] = useTransition();
  const [show, setShow] = useState(false);
  const lcaPageId = useMemo(()=>feature.attributes.lcapageid,[feature])
  
  useEffect(()=>{
    setLcaTitle(null);
  },[feature])
  
  const handleTitleChange = (value) =>{
    startTransition(()=>{
      setLcaTitle(value)
    })
  }
  
  const handleTabChange = useCallback((tab)=>{
    setSelectedTab(tab);
  },[])
  
  const renderTab = ()=> {
    switch (selectedTab) {
      case TABS.LCA: {
        return (
          <Details
            handleTitleChange={handleTitleChange}
            config={config}
            feature={feature}
            layer={layer}
            lcaPageId={lcaPageId}
            setShow={setShow}
          />
        )
      
      }
      case TABS.Documents:{
        return (
          <Documents
            color={config.opsColor}
            files={attachments}
          />
        )
      }
    }
  }
  
  const renderTabContent = useCallback((tab)=>{
    switch (tab){
      case TABS.LCA:{
        return (
          <span title="Logistics Capacity Assesment">
            <svg width="28" height="28" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.25 10.9639C6.25 9.43638 7.43711 8.479 9.20567 8.479C10.9742 8.479 12.125 9.50092 12.125 10.8455V12.6635H10.3443V11.0392C10.3443 10.4906 10.0294 9.90968 9.18144 9.90968C8.33351 9.90968 8.01856 10.3937 8.01856 11.1037V17.3643C8.01856 18.0742 8.39407 18.5583 9.18144 18.5583C9.96881 18.5583 10.3443 17.9774 10.3443 17.4288V15.6432H12.125V17.6225C12.125 18.9778 11.168 19.9998 9.20567 19.9998C7.2433 19.9998 6.25 19.0424 6.25 17.5149V10.9639Z" fill="#C03A2A"/>
              <path d="M0 8.60718H1.79067V18.5547H4.875V19.9999H0V8.60718Z" fill="#C03A2A"/>
              <path d="M15.3333 8.73535H17.6431L20 20.0001H18.2205L17.6902 17.0391H15.3098L14.7795 20.0001H13L15.3569 8.73535H15.3333ZM17.4074 15.6551L16.4882 10.5377L15.5926 15.6551H17.4192H17.4074Z" fill="#C03A2A"/>
              <path d="M0 4.89056H1.00984C1.42518 6.6755 3.04581 8.0042 4.98405 8.0042C6.92229 8.0042 8.54293 6.6755 8.95826 4.89056H10.9779C11.3933 6.6755 13.0139 8.0042 14.9522 8.0042C16.8904 8.0042 18.511 6.6755 18.9264 4.89056H20C20 3.99409 20 3.99409 20 3.99409C20 3.11363 20 3.11363 20 3.11363H18.9264C18.511 1.3287 16.8904 0 14.9522 0C13.0139 0 11.3933 1.3287 10.9779 3.11363H8.95826C8.54293 1.3287 6.92229 0 4.98405 0C3.04581 0 1.42518 1.3287 1.00984 3.11363H0V4.89056ZM12.8755 3.10563C13.2257 2.32122 14.0238 1.76893 14.9522 1.76893C15.8806 1.76893 16.6705 2.32122 17.0288 3.10563C17.151 3.37777 17.2243 3.68193 17.2243 3.99409C17.2243 4.30626 17.151 4.61042 17.0288 4.88256C16.6787 5.66697 15.8806 6.21926 14.9522 6.21926C14.0238 6.21926 13.2338 5.66697 12.8755 4.88256C12.7533 4.61042 12.68 4.30626 12.68 3.99409C12.68 3.68193 12.7533 3.37777 12.8755 3.10563ZM2.90736 3.10563C3.25755 2.32122 4.05565 1.76893 4.98405 1.76893C5.91245 1.76893 6.70241 2.32122 7.06074 3.10563C7.1829 3.37777 7.25619 3.68193 7.25619 3.99409C7.25619 4.30626 7.1829 4.61042 7.06074 4.88256C6.71055 5.66697 5.91245 6.21926 4.98405 6.21926C4.05565 6.21926 3.26569 5.66697 2.90736 4.88256C2.78521 4.61042 2.71191 4.30626 2.71191 3.99409C2.71191 3.68193 2.78521 3.37777 2.90736 3.10563Z" fill="#C03A2A"/>
            </svg>
          </span>
        )
      }
      case TABS.Documents:{
        return (
          <span title="Documents">
            {t('screen.popup.documents')}
          </span>
        )
      }
    }
  },[t])
  
  return (
    lcaPageId && (
      <div style={{padding:'0px 8px'}}>
        <Details
          handleTitleChange={handleTitleChange}
          config={config}
          feature={feature}
          layer={layer}
          lcaPageId={lcaPageId}
          setShow={setShow}
          headerRef={headerRef}
        />
      </div>
    )
  )
  //implement after having more information in popup
  // return (
  //   tabs.length > 0 && (
  //     <div style={{marginTop:4}}>
  //       <StyledPopupTabs>
  //         {
  //           tabs.map(tab=>(
  //             <StyledPopupTab
  //               key={tab}
  //               opsColor={config.opsColor}
  //               selected={selectedTab === tab}
  //               onClick={()=>handleTabChange(tab)}
  //             >
  //               {renderTabContent(tab)}
  //             </StyledPopupTab>
  //           ))
  //         }
  //       </StyledPopupTabs>
  //       <div style={{padding:'0px 8px'}}>
  //         {lcaPageId && (
  //           <Details
  //             handleTitleChange={handleTitleChange}
  //             config={config}
  //             feature={feature}
  //             layer={layer}
  //             lcaPageId={lcaPageId}
  //             setShow={setShow}
  //           />
  //         )}
  //       </div>
  //     </div>
  //   )
  // );
};

export default FeatureDetailsTabs;
