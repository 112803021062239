import {EMBED_VARIANTS, ROLE_EDITOR} from "../../../utils/helper";
import store from "../../../redux/store";
import {isWidgetDisplayed} from "../../../esri/widgets/expandUtils";
import {setPanelPath_actionType} from "../../../redux/constants";

const getPopupActions = ({t, layer, config}) =>{
  const actions = [];
  
  if (config.role === ROLE_EDITOR && layer.layerConfig && layer.layerConfig.editable){
    actions.push({
      title: t("screen.popup.actions.edit-action"),
      id: "edit-action",
      className: "esri-icon-edit"
    })
  }
  /*
  actions.push({
      title: "LCA",
      id: "show-lca-mobile",
      className: "esri-icon-public-mobile",
      display: false
  })
  
  actions.push({
      title: "LCA",
      id: "show-lca",
      className: "esri-icon-public",
      visible: false
  })
  */
  if (layer.layerConfig?.hasReportingUpdate && config.embed !== EMBED_VARIANTS.APP && config.role !== ROLE_EDITOR){
    if (store.getState().panelPath === null && !isWidgetDisplayed(config, 'Report')) {
      store.dispatch({type: setPanelPath_actionType, payload: ''});
    }
    
    actions.push({
      title: t("screen.popup.actions.report-update"),
      id: "report-update",
      visible: false
    })
  }
  
    actions.push({
      title: t("screen.popup.actions.share"),
      id: "share",
      className: "esri-icon-share",
      visible: true
    })
  
  
  // if (config.role !== ROLE_EDITOR) {
  //   actions.push({
  //     title: t("screen.popup.actions.share"),
  //     id: "share",
  //     className: "esri-icon-share",
  //     visible: false
  //   })
  // }
  //
  // if (config.role !== ROLE_EDITOR && layer.layerConfig?.hasReportingUpdate){
  //   actions.push({
  //     title: t("screen.popup.actions.report-update"),
  //     id: "report-update",
  //     visible: false
  //   })
  // }
  //
  // if (config.role === ROLE_EDITOR && layer.layerConfig && layer.layerConfig.editable){
  //   actions.push({
  //     title: t("screen.popup.actions.edit-action"),
  //     id: "edit-action",
  //     className: "esri-icon-edit"
  //   })
  // }
  
  return actions
}

export default getPopupActions;
