import {loadModules} from "esri-loader";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {graphicsLayer} from "../../../../../utils/API";
import {EditorContext} from "../../EditorContextProvider";
import {useDispatch} from "react-redux";
import useCustomSnackbar from "../../../../../hooks/useCustomSnackbar";
import {ConfigContext} from "../../../../../utils/ConfigContext";
import RoadsEditor from "./RoadsEditor/RoadsEditor";

export const point = {
  symbol: {
    type: "simple-marker",
    size: 14,
    color:'rgb(192, 58, 43)',
    outline: {
      width: 2,
      color: "#FFF"
    }
  },
}

export const pointGraphicSymbol = {
  type: "simple-marker",
  size: 14,
  color:'rgb(192, 58, 43)',
  outline: {
    width: 2,
    color: "#FFF"
  }
}

function calculateDistanceFromPixels(tolerance, map, Point, Extent) {
  let screenPoint = map.toScreen(map.extent.center);
  
  let upperLeftScreenPoint = new Point(screenPoint.x - tolerance, screenPoint.y - tolerance);
  let lowerRightScreenPoint = new Point(screenPoint.x + tolerance, screenPoint.y + tolerance);
  
  let upperLeftMapPoint = map.toMap(upperLeftScreenPoint);
  let lowerRightMapPoint = map.toMap(lowerRightScreenPoint);
  
  let ext = new Extent(upperLeftMapPoint.x, upperLeftMapPoint.y, lowerRightMapPoint.x, lowerRightMapPoint.y, map.spatialReference);
  return ext.width;
}
const ZOOM = 13

const RoadsSituationalManual = ({editType, handleNext, handlePrev, manualDrawPoint}) => {
  const [editorAddPointActive, setEditorAddPointActive] = useState(false);
  const [startPointerMoving, setStartPointerMoving] = useState(false);
  const [openSnackbar] = useCustomSnackbar();
  
  const isAllowedZoomLevelRef = useRef(false);
  const [roadsView, setRoadsView] = useState(null);
  
  useEffect(() => {
    let zoomLevelTimer = 0;
    let zoomHandler;
    if (!roadsView)return;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) =>{
      zoomHandler = reactiveUtils.watch(()=> roadsView.zoom >= ZOOM, (value)=>{
        clearTimeout(zoomLevelTimer);
        zoomLevelTimer = setTimeout(()=>{
          isAllowedZoomLevelRef.current = value;
          if (!isAllowedZoomLevelRef.current) {
            // setStartPointerMoving(false);
            openSnackbar('You have zoomed out, to continue please zoom in')
          } else {
            openSnackbar('Click on the point to start editing and move the mouse along the road')
          }

          // setIsAllowedZoomLevel(isAllowedZoomLevelRef.current);
        },250)
      })
    })

    return ()=>{
      clearTimeout(zoomLevelTimer)
      zoomHandler?.remove();
    }
  },[roadsView]);
  
  return (
    <RoadsEditor
      setRoadsView={setRoadsView}
      setEditorAddPointActive={setEditorAddPointActive}
      startPointerMoving={startPointerMoving}
      setStartPointerMoving={setStartPointerMoving}
      editType={editType}
      handleNext={handleNext}
      handlePrev={handlePrev}
      manualDrawPoint={manualDrawPoint}
      roadsView={roadsView}
    />
  );
};

export default RoadsSituationalManual;