import React, {useContext, useEffect, useMemo, useState} from "react";
import {ConfigContext} from "../../../../../../utils/ConfigContext";
import {toDD} from "../../CoordinatesInputs";
import Input from "../../../../../Report/new/Input/Input";
import {baseLineColor} from "../../../EditorContextProvider";

const patterns = {
  lat: {
    isDMS: /^(([1-8]?[0-9])\D+([1-5]?[0-9]|60)\D+([1-5]?[0-9]|60)(\.[0-9]+)?|90\D+0\D+0)\D+[NSns]$/,
    isDDM: /^(([1-8]?[0-9])\D+[1-6]?[0-9](\.\d{1,3})?|90(\D+0)?)\D+([NSns])$/,
    isDD:  /^[\+-]?(([1-8]?[0-9])(\.\d{1,6})?|90)\D*[NSns]?$/
  },
  lng: {
    isDMS: /^((1[0-7][0-9]|[1-9]?[0-9])\D+([1-5]?[0-9]|60)\D+([1-5]?[0-9]|60)(\.[0-9]+)?|180\D+0\D+0)\D+[EWew]$/,
    isDDM: /^((1[0-7][0-9]|[1-9]?[0-9])\D+[1-6]?[0-9](\.\d{1,3})?|180(\D+0)?)\D+([EWew])$/,
    isDD:  /^[\+-]?((1[0-7][0-9]|[1-9]?[0-9])(\.\d{1,6})?|180)\D*[EWew]?$/
  }
};

const detectAndConvertCoordinates = (searchTerm) => {
  try {
    const [x, y] = searchTerm.includes(',') ? searchTerm.split(",") : searchTerm.split(' ');
    const latDDRegex = new RegExp(patterns.lat.isDD);
    const lngDDRegex = new RegExp(patterns.lng.isDD);
  
    const latDDMRegex = new RegExp(patterns.lat.isDDM);
    const lngDDMRegex = new RegExp(patterns.lng.isDDM);
  
    const latDMSRegex = new RegExp(patterns.lat.isDMS);
    const lngDMSRegex = new RegExp(patterns.lng.isDMS);
    
    if (latDDRegex.test(x)){
    } else if (latDDMRegex.test(x)){
    
    
    } else if (latDMSRegex.test(x) && lngDMSRegex.test(y)) {
      const splittedX = x.split(/(?:,| |'|''|°|N|S|W|E)+/).join(' ').trim();
      const splittedY = y.split(/(?:,| |'|''|°|N|S|W|E)+/).join(' ').trim();
      
      const coordinates = `${toDD(splittedX)}, ${toDD(splittedY)}`
      
      return coordinates;
    }
  } catch (err){
    return undefined;
  }
}
const SearchInput = ({point, showCoordinates, setShowOtherCoordinates, handleInputChange, isActive, isEdited ,...props}) => {
  const [value, setValue] = useState('');
  const {config} = useContext(ConfigContext);
  
  useEffect(()=>{
    setValue(point.label);
  },[point])
  
  const borderColor = useMemo(()=>{
    if (point.coordinate.length === 0){
      return baseLineColor;
    } else if (point.searchable){
      return '#16a34a';
    } else {
      if (isEdited){
        return '#fbbf24';
      } else {
        return '#ef4444';
      }
    }
  },[point, isEdited])
  
  return (
    <div style={{flex:'1', position:'relative'}}>
      <Input
        value={value}
        borderColor={borderColor}
        onChange={(e)=>{
          setValue(e.target.value);
          handleInputChange(e);
        }}
        noMargin
        style={{paddingRight:22}}
        isFocused={isActive}
        {...props}
      >
        <div
          onClick={()=>{
            setValue('')
            handleInputChange({
              target:''
            })
          }}
          style={{
            position:'absolute',
            right:5,
            top:'50%',
            transform:'translateY(-50%)',
            cursor:'pointer',
            padding:2,
            display:'flex',
            opacity: value ? 1 : 0,
            pointerEvents: value ? 'auto' : 'none'
        }}>
          <svg stroke="currentColor" fill="#939393" strokeWidth="0" viewBox="0 0 512 512" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path></svg>
        </div>
      </Input>
    </div>
  )
};

export default SearchInput;
