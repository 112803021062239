import React, {lazy, Suspense, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ModuleIcon from "../../Modules/ModuleIcon";
import Modules from "../../Modules";
import {StyledMobileButton, StyledMobileHeader, StyledMobileHeaderTitle} from "../Header-styled";
import ScreenDropdown from "./ScreenDropdown";
import {useTranslation} from "react-i18next";
import {ConfigContext} from "../../../utils/ConfigContext";
import MobileSearch from "./MobileSearch";
import {LogIEIconSmall} from "../../Icons/Header-Icons";
import {EMBED_VARIANTS, getOperationPreferences, getOpsColor, getVisibleFilters} from "../../../utils/helper";
import {isWidgetDisplayed} from "../../../esri/widgets/expandUtils";
import {MOBILE_SCREENS} from "../../App/App";
import {setActiveList} from "../../../redux/action/MobileReducer-action";
import {MOBILE_LISTS} from "../../../redux/reducers/mobileReducer";
import {view} from "../../../utils/API";
import useOnboarding from "../../../hooks/useOnboarding";

const Filters = lazy(() => import('../../Widgets/Filters'));

const MenuIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.39921 2C3.10959 2 2.06055 3.06827 2.06055 4.38135C2.06055 5.69444 3.10916 6.76263 4.39921 6.76263C5.68925 6.76263 6.73786 5.69444 6.73786 4.38135C6.73786 3.06827 5.68875 2 4.39921 2Z" fill="#8C9BA5"/>
    <path d="M11.9695 2C10.68 2 9.63086 3.06827 9.63086 4.38135C9.63086 5.69444 10.68 6.76263 11.9695 6.76263C13.2591 6.76263 14.3084 5.69444 14.3084 4.38135C14.3084 3.06827 13.2596 2 11.9695 2Z" fill="#8C9BA5"/>
    <path d="M19.6609 6.76271C20.9502 6.76271 21.9996 5.69451 21.9996 4.38143C21.9996 3.06834 20.9511 2 19.6609 2C18.3707 2 17.3223 3.06827 17.3223 4.38135C17.3223 5.69444 18.3715 6.76271 19.6609 6.76271Z" fill="#8C9BA5"/>
    <path d="M4.33909 14.3927C5.62863 14.3927 6.67797 13.3248 6.67797 12.0115C6.67797 10.6981 5.62863 9.62988 4.33909 9.62988C3.04955 9.62988 2 10.6982 2 12.0115C2 13.3248 3.04955 14.3927 4.33909 14.3927Z" fill="#8C9BA5"/>
    <path d="M11.907 14.3927C13.1963 14.3927 14.2457 13.3248 14.2457 12.0115C14.2457 10.6981 13.1972 9.62988 11.907 9.62988C10.617 9.62988 9.56836 10.6981 9.56836 12.0115C9.56836 13.3248 10.6175 14.3927 11.907 14.3927Z" fill="#8C9BA5"/>
    <path d="M19.5993 14.3927C20.8891 14.3927 21.938 13.3248 21.938 12.0115C21.938 10.6981 20.8891 9.62988 19.5993 9.62988C18.3095 9.62988 17.2598 10.6981 17.2598 12.0115C17.2598 13.3248 18.3091 14.3927 19.5993 14.3927Z" fill="#8C9BA5"/>
    <path d="M4.39183 17.2373C3.10228 17.2373 2.05273 18.3053 2.05273 19.6187C2.05273 20.9315 3.10228 21.9999 4.39183 21.9999C5.68137 21.9999 6.73041 20.932 6.73041 19.6187C6.73041 18.3053 5.68137 17.2373 4.39183 17.2373Z" fill="#8C9BA5"/>
    <path d="M11.9621 17.2373C10.6725 17.2373 9.62305 18.3053 9.62305 19.6187C9.62305 20.9315 10.6722 21.9999 11.9621 21.9999C13.2515 21.9999 14.3007 20.932 14.3007 19.6187C14.3007 18.3053 13.2515 17.2373 11.9621 17.2373Z" fill="#8C9BA5"/>
    <path d="M19.6531 17.2373C18.3638 17.2373 17.3145 18.3053 17.3145 19.6187C17.3145 20.9315 18.3638 21.9999 19.6531 21.9999C20.9429 21.9999 21.9918 20.932 21.9918 19.6187C21.9918 18.3053 20.9424 17.2373 19.6531 17.2373Z" fill="#8C9BA5"/>
  </svg>
)

const MobileHeader = ({setShowModules, showModules, tour, setMobileScreen, mobileScreen, setShowFilters}) => {
  const {activeModule, showLatestUpdates, mobileReducer, filters, layersLoading} = useSelector(state=>state);
  const {t} = useTranslation('common');
  const {config} = useContext(ConfigContext);
  const [expandSearch, setExpandSearch] = useState(false);
  const {onBoardingTour} = useOnboarding();
  const dispatch = useDispatch();
  
  useEffect(()=>{
    const languageConfig = JSON.parse(localStorage.getItem('languageConfig'));
    if (languageConfig){
      const {showModules} = languageConfig;
      setShowModules(showModules);
      localStorage.removeItem('languageConfig');
    }
  },[])
  
  useEffect(()=>{
    if (!config) return;
    const {allPreferences: preferences, operationPreferences} = getOperationPreferences(config.id);
    
    if (!operationPreferences.activeModule && (Array.isArray(config.activeModules) && config.activeModules.length > 1) && config.embed !== EMBED_VARIANTS.WEBSITE){
      const searchParams = new URLSearchParams(window.location.search);
      const urlModule = searchParams.get('module')
      const dataCollection = searchParams.get('datacollection')
      if (urlModule || dataCollection) return;
      
      setShowModules(true);
    }
  },[config])
  
  useEffect(()=>{
    if (expandSearch){
      setExpandSearch(false);
    }
  },[mobileScreen])
  
  const isHeaderShown = useMemo(() => !(config.embed === EMBED_VARIANTS.APP || config.embed === EMBED_VARIANTS.WEBSITE), [config]);
  
  const title = t("ops." + config.id + ".title", config.title) || '';
  
  const isSearchWidgetDisplayed = useMemo(()=> {
    return isWidgetDisplayed(config, "Search") && mobileScreen !== MOBILE_SCREENS.INSIGHTS;
  },[config, mobileScreen])
  
  const latestUpdatesSelected = useMemo(()=>{
    return mobileReducer.activeList === MOBILE_LISTS.LATEST_UPDATES;
  },[mobileReducer.activeList])
  
  const refInit = useCallback((el)=>{
    if (!el || !window) return;
    
    const {width} = el.parentNode.getBoundingClientRect() || {}
    const {width:elementWidth} = el.getBoundingClientRect() || {};
    if (width !== undefined && elementWidth !== undefined){
      const availableWidth = width - 16 - 32;
      
      if (availableWidth < elementWidth){
        el.style.paddingLeft = '42px';
      } else {
        el.style.paddingLeft = undefined;
      }
    }
  },[])
  
  const opsColor = useMemo(()=> getOpsColor(config),[config, activeModule])
  
  return (
    <>
      <Modules tour={tour} config={config} show={showModules} setShow={setShowModules} setMobileScreen={setMobileScreen} isHeaderShown={isHeaderShown} onBoardingTour={onBoardingTour} />
      {isHeaderShown && (
        <StyledMobileHeaderTitle>
          <a href="/" style={{display: "flex", position:'absolute'}}>
            <LogIEIconSmall fill="#fff"/>
          </a>
          <span ref={refInit}>{title}</span>
        </StyledMobileHeaderTitle>
      )}
      <StyledMobileHeader
        isHeaderShown={isHeaderShown}
        expanded={expandSearch}
        isSearchWidgetDisplayed={isSearchWidgetDisplayed}
      >
        <div className="btns" style={{marginRight:'auto'}}>
          {
            activeModule && (
              <ModuleIcon
                withShadow
                module={activeModule}
                onClick={()=>{
                  setShowModules(true);
                }}
                opsColor={opsColor}
                className="onboarding-modules"
              />
            )
          }
          <ScreenDropdown
            setMobileScreen={setMobileScreen}
            mobileScreen={mobileScreen}
            config={config}
            t={t}
          />
        </div>
        <div className="btns">
          {showLatestUpdates && mobileScreen === MOBILE_SCREENS.TABLE && (
            <StyledMobileButton
              onClick={()=>{
                if (latestUpdatesSelected){
                  dispatch(setActiveList(MOBILE_LISTS.LAYERS))
                } else {
                  dispatch(setActiveList(MOBILE_LISTS.LATEST_UPDATES))
                }
              }}
              className="lu__btn"
              style={{
                border: `3px solid ${latestUpdatesSelected ? opsColor : '#eee'}`,
                borderRadius:100,
            }}
            >
              <span>{t('screen.widget.LatestUpdates.title')}</span>
            </StyledMobileButton>
          )}
          <Suspense>
            <Filters isMobile={true} className="onboarding-mobile-filter"/>
          </Suspense>
          {/*{hasFilters && (*/}
          {/*  <StyledMobileButton*/}
          {/*    onClick={() => setShowFilters(true)}*/}
          {/*    className="onboarding-mobile-filter"*/}
          {/*  >*/}
          {/*    <FilterIcon/>*/}
          {/*    <MobileFilter*/}
          {/*      showFilters={true}*/}
          {/*      setShowFilters={setShowFilters}*/}
          {/*      isMobile={true}*/}
          {/*    />*/}
          {/*  </StyledMobileButton>*/}
          {/*)}*/}
        </div>
        {isSearchWidgetDisplayed && <MobileSearch t={t} mobileScreen={mobileScreen} expandSearch={expandSearch} setExpandSearch={setExpandSearch}/>}
      </StyledMobileHeader>
    </>
  );
};

export default MobileHeader;
