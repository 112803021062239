import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {
  StyledArrowIconWrapper, StyledBookmarkIcon,
  StyledButton,
  StyledCardBody,
  StyledCardHeader,
  StyledModalBody,
  StyledModalContent,
  StyledModalHeader,
  StyledModalWrapper,
  StyledModuleCard,
  StyledModuleWrapper,
} from "./Modules-styled";
import useModuleSelection from "../../hooks/useModuleSelection";
import {useTranslation} from "react-i18next";
import ModuleIcon from "./ModuleIcon";
import UpdateComment from "../LatestUpdates/new/UpdateComment";
import {MOBILE_SCREENS} from "../App/App";
import {ArrowDownIcon, CloseIcon} from "../Icons";
import Disclaimer from "./Disclaimer";
import {getOperationPreferences, getOpsColor, ROLE_EDITOR} from "../../utils/helper";
import {StyledFiltersBackButton} from "../MobileApp/MobileFilter/MobileFilter-styled";
import {isWidgetDisplayed} from "../../esri/widgets/expandUtils";
import LanguageDropdown from "../Header/components/LanguageDropdown/LanguageDropdown";
import {useSelector} from "react-redux";

const Bookmark = ({...props}) => {
  return <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M352 48H160a48 48 0 0 0-48 48v368l144-128 144 128V96a48 48 0 0 0-48-48z"></path></svg>
}

const Modules = ({show, setShow, config, setMobileScreen, isHeaderShown, onBoardingTour}) => {
  const [savePreferences, setSavePreferences] = useState(false);
  const [expandedModule, setExpandedModule] = useState(null);
  const [bookmarkedModule, setBookmarkedModule] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const {t, i18n} = useTranslation('common');
  const {modules, handleModuleSelection, activeModule} = useModuleSelection(config);
  const ref = useRef();
  const scrollableRef = useRef();
  const {isMobileApp} = useSelector(state=>state.mobileReducer)
  
  useEffect(() => {
    if (!isWidgetDisplayed(config, 'Onboarding')) return;
      const urlParams = new URLSearchParams(window.location.search);
      const widgetOpen = urlParams.get('widget-open');
      if (widgetOpen === 'onboarding' && onBoardingTour) {
        setTimeout(()=>{
          handleStartTour();
        },1000)
      }
  }, [onBoardingTour])
  
  const handleModuleExpand = useCallback((e, module)=>{
    if (expandedModule === module){
      setExpandedModule(null);
    } else {
      setExpandedModule(module);
    }
    setExpandedModule(module)
  },[expandedModule]);
  
  const handleHide = useCallback((module = null)=>{
    setShow(false);
    setExpandedModule(module ?? activeModule);
   },[activeModule, savePreferences]);
  
  const handleStartTour = useCallback(()=>{
    if (onBoardingTour){
      onBoardingTour.start();
      handleHide();
      
      onBoardingTour.on('complete', ()=>{
        setShow(true);
      })
    }
  },[onBoardingTour, handleHide])
  
  const onModuleSelect = useCallback((module)=>{
    setExpandedModule(module);
    const moduleOpenWidgets = config.modules[activeModule] ? config.modules[activeModule].openWidgets || [] : [];
    const openWidget = moduleOpenWidgets.length > 0 ? moduleOpenWidgets[0] : null;
    let activeScreen = MOBILE_SCREENS.MAP;
    
    if (openWidget && isWidgetDisplayed(config, openWidget)){
      activeScreen = openWidget
    }
    
    if (setMobileScreen){
      setMobileScreen(activeScreen);
    }
    handleModuleSelection(module)
  },[handleHide, handleModuleSelection, config])
  
  const handleModuleBookmark = useCallback( (e, moduleName) => {
    e.stopPropagation();
    const module = moduleName === bookmarkedModule ? null : moduleName
  
    const {allPreferences: preferences, operationPreferences} = getOperationPreferences(config.id);
    if (module) {
      preferences[config.id] = {
        ...operationPreferences,
        activeModule: module
      };
    } else {
      const {activeModule, ...rest} = operationPreferences;
      preferences[config.id] = rest;
    }
    
    if (module){
      onModuleSelect(module)
    }
    
    localStorage.setItem('savedPreferences', JSON.stringify(preferences));
    setBookmarkedModule(module);
  },[bookmarkedModule, onModuleSelect])
  
  useLayoutEffect(()=>{
    if (!scrollableRef.current || !show)return;
    const el = scrollableRef.current
    
    setTimeout(()=>{
      if (el.offsetHeight < el.scrollHeight) {
        setShowArrow(true);
      }
    },250)
    
  },[show])
  
  const handleScrollDown = useCallback((e)=>{
    e.stopPropagation();
    if (!scrollableRef.current)return;
    scrollableRef.current.scrollTo({top: scrollableRef.current.scrollHeight, behavior: "smooth"})
  },[])
  
  const onScroll = useCallback((e)=>{
    const el = e.target;
    if ((el.offsetHeight + el.scrollTop) < el.scrollHeight) {
      if (showArrow) return;
      setShowArrow(true);
    } else {
      setShowArrow(false);
    }
  },[showArrow])
  
  const selectedModule = useMemo(()=>{
    const {allPreferences: preferences, operationPreferences} = getOperationPreferences(config.id);
    return bookmarkedModule || operationPreferences.activeModule;
  },[bookmarkedModule, config])
  
  const opsColor = useMemo(()=> getOpsColor(config, activeModule),[config, activeModule])
  
  return (
    <StyledModalWrapper show={show}>
      <StyledModalContent show={show} ref={ref} isHeaderShown={isHeaderShown}>
        {show && <StyledModalHeader>
          <h1 style={{marginRight: "auto"}}>{t("screen.widget.ModuleSwitcher.title")}</h1>
          {!isMobileApp && <div style={{display: "flex", gap: "8px"}}>
            {
              config.role !== ROLE_EDITOR && (
                <StyledButton style={{
                  border: "1px solid var(--opsColor)",
                  color: "var(--opsColor)",
                  padding: "4px 12px"
                }} onClick={handleStartTour} bgColor={opsColor}>
                  <span>
                    {t("help.onboarding.title")}
                  </span>
                </StyledButton>
              )
            }
          </div>}
          <LanguageDropdown config={config} isModules/>
          <StyledFiltersBackButton
            onClick={() => handleHide()}
          >
            <CloseIcon/>
          </StyledFiltersBackButton>
        </StyledModalHeader>}
  
        {show && <StyledModalBody
          ref={scrollableRef}
          onScroll={onScroll}
        >
          <StyledModuleWrapper>
            {
              modules.map((module) => (
                <StyledModuleCard
                  key={module.module}
                  onClick={() => {
                    handleHide(module);
                    onModuleSelect(module.module)
                  }}
                >
                  <StyledCardHeader opsColor={module.color}>
                    <StyledBookmarkIcon
                      title={t('screen.widget.ModuleSwitcher.bookmarkTooltip')}
                      onClick={(e)=>handleModuleBookmark(e, module.module)}
                      selected={module.module === selectedModule}
                    >
                      <Bookmark fill={module.module === selectedModule ? 'black' : opsColor} />
                    </StyledBookmarkIcon>
                    <div style={{
                      background:module.color,
                      display:'flex',
                      gap:2,
                      borderRadius: 100,
                      paddingRight: 16,
                      margin:"auto",
                      maxWidth: 'calc(100% - 61px)'
                    }}>
                      <ModuleIcon opsColor={module.color} module={module.module}/>
                      <span style={{marginLeft: "auto", color:'#fff'}}>
                        {module.moduleName}
                      </span>
                    </div>
                  </StyledCardHeader>
                  {
                    module.title && (
                      <StyledCardBody expand={expandedModule === module.module || !window.isSmall}>
                        <UpdateComment
                          text={module.title}
                          textLimit={(expandedModule === module.module || !window.isSmall) ? module.title.length + 1 : 110}
                          onClick={(e) => handleModuleExpand(e, module.module)}
                        />
                      </StyledCardBody>
                    )
                  }
                </StyledModuleCard>
              ))
            }
          </StyledModuleWrapper>
          {/*{*/}
          {/*  modules.length > 0 && (*/}
          {/*    <StyledSavePreferences isSmall={window.isSmall}>*/}
          {/*      <span>*/}
          {/*    Save this as my preferred choice*/}
          {/*  </span>*/}
          {/*      <CustomSwitch onChange={handleSwitchChange} checked={savePreferences} />*/}
          {/*    </StyledSavePreferences>*/}
          {/*  )*/}
          {/*}*/}
  
          {
            (config.role !== ROLE_EDITOR && isMobileApp) && (
              <StyledButton style={{
                border:'1px solid var(--opsColor)',
                color:'var(--opsColor)',
                padding:'4px 12px'
              }} onClick={handleStartTour} bgColor={opsColor}>
                  <span>
                    {t("help.onboarding.title")}
                  </span>
              </StyledButton>
            )
          }
          <Disclaimer
            config={config}
            activeModule={activeModule}
            t={t}
            i18n={i18n}
          />
        </StyledModalBody>}
        <StyledArrowIconWrapper
          onClick={handleScrollDown}
          show={showArrow}
        >
          <ArrowDownIcon />
        </StyledArrowIconWrapper>
      </StyledModalContent>
    </StyledModalWrapper>
  )
};
export default Modules;

