import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"

import {clusterRed, view} from "../../utils/API"
import {
    ColorCube, StyledContainer,
    LayerColorLine, LayerIconPanel,
    LayerColorPanel, LayerGroupPanel,
    LayerPanel,
    LayerTitle, Symbol, SymbolLine, SymbolTitle, ColorLine, ColorPolygon, SymbolCont, LegendHeader
} from "./SymbologyLegend-styled";
import {createSymbol, getLayerSymbology} from "../../utils/symbologies";
import {EMBED_VARIANTS, renderSingleDomain} from "../../utils/helper";
import {ConopsIconAttributeMap} from "../../esri/graphicLayerUtils/Conops";
import {circle, iconNameToIcon, svgToBase} from "../Icons/layerSvg/clusterAssets";
import {useSelector} from "react-redux";
import {ArrowDownIcon} from "../Icons";
import {EpamIconAttributeMap} from "../../esri/graphicLayerUtils/Epam";

/**
 * Legend for custom symbology enabled layers
 * We group the layers based on their color definition, and each group we show the icons visible on the map
 *
 */
const SymbologyLegend = ({t, config, reactiveUtils, showCollapseIcon = true, disableUpdate}) => {
    /**
     * This is the main state object:
     * Each element in this array represents a layer group with the same coloring
     * The structure is like this:
     * [colorGroup1, colorGroup2, ...]
     * colorGroup:
     *  {
     *      colorDefinitions:
     *          [colorDef1, colorDef2],  //all possible color definitions from the symbology
     *      layers: {
     *          layer1Id: {
     *              iconDefinitions: [iconDef1, iconDef2, ....], //icon definitions for all possible icons from the symbology
     *              layer: layerDefinition,
     *              symbols: [sym1, sym2]            //symbols shown actually on the map
     *              colors: [colorDef1, colorDef2]   //color definitions shown actually on the map
     *              isLoading: true|false            //after zoom, we reload visible features. for the reload period, isLoding is set to true
     *          }
     *      }
     *  }
     *
     */
    let [legendInfo, setLegendInfo] = useState([])
    let [layersLoaded, setLayersLoaded] = useState(0)
    let [bodyVisible, setBodyVisible] = useState(true)
    const {printWidget, mobileReducer} = useSelector(state=>state);
    
    const isEmbedMode = useMemo(()=> !!config.embed ,[config]);
    const isWebsiteEmbed = useMemo(()=>config.embed === EMBED_VARIANTS.WEBSITE, [config]);
    const isAppEmbed = useMemo(()=>config.embed === EMBED_VARIANTS.APP, [config]);
    
    useEffect(()=>{
        view?.map.layers.forEach((layer) => addLayer(layer))
        view.map.layers.on("change", (event) => {
            event.added?.forEach((layer) => {
                addLayer(layer)
            })
        })

        if (document.getElementById("mapContainer").clientWidth < 420)
            setBodyVisible(false)
    }, [])
    
    useEffect(()=>{
        if (isEmbedMode) {
            setBodyVisible(false);
        }
    },[isEmbedMode])
    
    useEffect(()=>{
        if (printWidget.open){
            setBodyVisible(true);
        }
    },[printWidget.open])
    const loadingRef = useRef(disableUpdate);
    useEffect(()=>{
        loadingRef.current = disableUpdate;
    },[disableUpdate])
    
    
    const listeners = useRef({});
    const addLayer = (layer) => {
        layer.load().then((l) => {
            if (listeners.current[layer.id] && listeners.current[layer.id].length > 0){
                listeners.current[layer.id].forEach(handler=>{
                    handler.remove();
                })
            }
            
            listeners.current[layer.id] = [];
            if (l.type !== "feature" || !l.layerConfig )
                return

            const watch = l.watch("visible", () => {
                view.whenLayerView(layer).then((layerView) => {
                    if (loadingRef.current) return;
                    updateLayerView(layerView)
                })
            })
            
            listeners.current[layer.id].push(watch);
            createLayerLegend(layer)
            createConopsLayerLegend(layer)
            createEpamLayerLegend(layer);
            view.whenLayerView(layer).then((layerView) => {
                const rWatch = reactiveUtils.watch(() => layerView.updating, () => {
                    if (loadingRef.current) return;
                    if (layerView.updating){
                        legendInfo.forEach((li) => {
                            const layerInfo = li.layers[layer.id]
                            if (layerInfo){
                                layerInfo.isLoading = true
                            }
                        })
                        setLegendInfo(legendInfo)
                    } else
                        updateLayerView(layerView)
                })
    
                listeners.current[layer.id].push(rWatch);
                if (!layerView.updating)
                    updateLayerView(layerView)
            })
        })
    }
    
    const createEpamLayerLegend = (layer)=>{
        if (!layer.layerConfig?.isEpam)
            return
    
        const epamFields = layer.fields.filter((f) => EpamIconAttributeMap[f.name])
          .map((f) => f.name)
    
        const layerSymbols = {
            layer: layer,
            iconDefinitions: [],
            sizeDefinitions: [],
            defaults: [],
            isLoading: false,
            usedLayerFields: epamFields
        }
    
        const layers = {}
        layers[layer.id] = layerSymbols
    
        legendInfo.push({
            colorDefinitions: [],
            layers: layers
        })
    
        setLegendInfo(legendInfo)
        setLayersLoaded(layersLoaded++)
    }

    const createConopsLayerLegend = (layer) => {

        if (!layer.layerConfig?.isConops)
            return

        const conopsFields = layer.fields.filter((f) => ConopsIconAttributeMap[f.name])
            .map((f) => f.name)

        const layerSymbols = {
            layer: layer,
            iconDefinitions: [],
            sizeDefinitions: [],
            defaults: [],
            isLoading: false,
            usedLayerFields: conopsFields
        }

        const layers = {}
        layers[layer.id] = layerSymbols

        legendInfo.push({
            colorDefinitions: [],
            layers: layers
        })

        setLegendInfo(legendInfo)
        setLayersLoaded(layersLoaded++)
    }

    const createLayerLegend = (layer) => {
        if (!layer.renderer || !layer.layerConfig.symbology || !layer.layerConfig.includeInLegend)
            return

        const lid = layer.id
        const colorDefinitions = layer.renderer?.getColorDefinitions()
        const sizeDefinitions = layer.renderer?.getSizeDefinitions()
        const iconDefinitions = layer.renderer?.getIconDefinitions()
        const defaults = layer.renderer?.getDefaults()

        const layerSymbols = {
            layer: layer,
            iconDefinitions: iconDefinitions,
            sizeDefinitions: sizeDefinitions,
            defaults: defaults,
            isLoading: true
        }

        const equalsForColorDef = legendInfo.filter((li) => {
            if (Object.keys(li.layers).filter((l) =>
                li.layers[l].layer.geometryType !== layer.geometryType
            ).length > 0)
                return false

            return isColorDefEqual(li.colorDefinitions, colorDefinitions)
        })

        if (equalsForColorDef.length > 0) {
            equalsForColorDef[0].layers[lid] = layerSymbols
        } else {
            const layers = {}
            layers[lid] = layerSymbols

            legendInfo.push({
                colorDefinitions: colorDefinitions,
                layers: layers
            })
        }

        layerSymbols.symbols = iconDefinitions && iconDefinitions.map((iconDef) => {
            return {
                labels: [iconDef.label],
                symbol: createSymbol(getLayerSymbology(layer, config), layer, iconDef.icon, iconDef.color, iconDef.size)
            }
        })

        let outFields = new Set()
        colorDefinitions && colorDefinitions.forEach((cd) => {
            cd.field.forEach((field) => {
                outFields.add(field.name)
            })
        })

        iconDefinitions && iconDefinitions.forEach((id) => {
            id.field.forEach((field) => {
                outFields.add(field.name)
            })
        })

        layerSymbols.usedLayerFields = Array.from(outFields);

        setLegendInfo(legendInfo)
        setLayersLoaded(layersLoaded++)
    }

    /**
     * Update layer specific legend information:
     * - query for visible features of the layer
     * - calculate the icons and colors used on the screen
     * - trigger rendering
     */
    const updateLayerView = (layerView) => {
        const lid = layerView.layer.id
        const legendGroup = legendInfo.find((li) => li.layers[lid])
        if (!legendGroup)
            return

        const layerInfo = legendGroup.layers[lid]
        const matchingFields = [];
    
        layerInfo.usedLayerFields.forEach(fieldName=>{
            matchingFields.push(layerView.availableFields.includes(fieldName))
        })

        if (matchingFields.filter(value=>!value).length > 0) {
            return;
        }

        if (!layerInfo)
            return

        if (!layerView.layer.visible){
            layerInfo.isLoading = false

            setLegendInfo(legendInfo)
            setLayersLoaded(layersLoaded++)
            return
        }

        const outStats = layerInfo.usedLayerFields.map((field) => {
            return { onStatisticField: field, outStatisticFieldName: "tot_" + field, statisticType: "count"}
        })

        /**
         * Much faster if we group the outcome for the fields that participate in the renderer (both for color and for icon)
         */
        const query = {
            geometry: view.extent,
            where: layerView.layer.definitionExpression,
            outFields: layerInfo.usedLayerFields,
            groupByFieldsForStatistics: layerInfo.usedLayerFields,
            outStatistics: outStats
        }

        //const start = new Date()
        layerView.queryFeatures(query).then((result) => {
            updateLayerSymbolsAndColors(legendGroup, layerInfo, result.features)
            updateConopsSymbols(legendGroup, layerInfo, result.features)
            updateEpamSymbols(legendGroup, layerInfo, result.features)
            
            layerInfo.isLoading = false
            setLegendInfo(legendInfo)
            setLayersLoaded(layersLoaded++)
        }).catch(err=>{})
    }

    const updateLayerSymbolsAndColors = (legendGroup, layerInfo, features) => {
        const layer = layerInfo.layer
        if (!layer.layerConfig.symbology)
            return

        let usedSymbols = {}
        let usedColors = {}
        features && features.forEach((feature) => {
            //Is color used in any of the features on the map?
            const iconDef = layerInfo.iconDefinitions.find((iconDef) => featureConformsDef(feature, iconDef.field, iconDef.value))
            if (iconDef){
                //Same icon used in more definitions? concatenate the labels
                const alreadyUsedSymbol = usedSymbols[iconDef.icon]
                const label = getFeatureLabel(feature, iconDef.field, t)
                if (alreadyUsedSymbol){
                    alreadyUsedSymbol.labels.add(label)
                } else {
                    usedSymbols[iconDef.icon] = {
                        labels: new Set().add(label),
                        symbol: createSymbol(getLayerSymbology(layer, config), layer, iconDef.icon, iconDef.color, iconDef.size)
                    }
                }
            }

            //Is color used in any of the features on the map?
            const colorDef = legendGroup.colorDefinitions.find((colorDef) => featureConformsDef(feature, colorDef.field, colorDef.value))

            if (colorDef && !(typeof colorDef.label === 'string' && colorDef.label.includes('Unspecified') || colorDef.label.includes('Unknown'))){
                //Same color used in more definitions? concatenate the labels
                let alreadyUsedColor = usedColors[colorDef.color]
                const label = getFeatureLabel(feature, colorDef.field, t, colorDef.value)
                if (alreadyUsedColor)
                    alreadyUsedColor.labels.add(label)
                else {
                    //Try to get the proper size
                    const sd = layerInfo.sizeDefinitions
                    let size = layerInfo.defaults.defaultSize
                    if (sd){
                        const value = feature.attributes[sd.field]
                        if (sd.scale && sd.scale[value])
                            size = sd.scale[value]
                    }

                    usedColors[colorDef.color] = {labels: new Set().add(label), color: colorDef.color, size: size, style:colorDef.style}
                }
            } else if (layerInfo.defaults.color && !usedColors[layerInfo.defaults.color] && layerInfo.iconDefinitions.length === 0 && !layerInfo.defaults.icon){
                //Feature is there, but no color found or icon found, we display the default color with no label
                const defCol = layerInfo.defaults.color
                usedColors[defCol] = {labels: new Set().add(''), color: defCol, size: layerInfo.defaults.defaultSize}
            }
        })
        
        const defaults = layerInfo.defaults
        layerInfo.symbols = Object.values(usedSymbols)
        //If features are on the map, but no symbol found, just use the default one
        if (!layerInfo.symbols.length && features.length && defaults?.icon)
            layerInfo.symbols.push({
                labels: [layerInfo.layer.getLayerTitle(t)],
                symbol: createSymbol(getLayerSymbology(layer, config), layer, defaults.icon, defaults.color, defaults.size)
            })

        layerInfo.colors = Object.values(usedColors)
    }
    
    const updateEpamSymbols = (legendGroup, layerInfo, features) => {
        const layer = layerInfo.layer
        if (!layer.layerConfig.isEpam)
            return
        
        
        const attributeNames = new Set();
        features && features.forEach((feature) => {
            Object.keys(EpamIconAttributeMap).filter((attr) => feature.attributes[attr] === 1).forEach((attr) =>
              attributeNames.add(attr)
            )
        })
        
        
        
        const usedSymbols = []
        attributeNames.forEach((attribute) => {
            const iconName = EpamIconAttributeMap[attribute]?.iconName || 'epamOthers';
            const color = EpamIconAttributeMap[attribute]?.color ?? '#219150';
            let icon = iconNameToIcon(iconName)
            
            if (color){
                icon = icon.replaceAll(clusterRed, color)
                icon = icon.replaceAll(clusterRed.toUpperCase(), color)
            }
    
            //Firefox doesn't render SVGs on html5 canvas when the height and width parameters are not set
            //https://stackoverflow.com/questions/28690643/firefox-error-rendering-an-svg-image-to-html5-canvas-with-drawimage
            const result = new DOMParser().parseFromString(icon, 'text/xml')
            let inlineSVG = result.getElementsByTagName("svg")[0]
            inlineSVG.setAttribute('width', '48px')
            inlineSVG.setAttribute('height', '48px')
            const circle = inlineSVG.getElementsByTagName('circle');
    
            if (circle.length === 0) {
                const c = document.createElement('circle');
                c.setAttribute('cx', '36')
                c.setAttribute('cy', '36')
                c.setAttribute('r', '36')
                c.setAttribute('fill', EpamIconAttributeMap[attribute]?.color || clusterRed);
                inlineSVG.prepend(c);
            }
    
            let serializeToString = new XMLSerializer().serializeToString(inlineSVG)
            serializeToString = serializeToString.replaceAll('xmlns="http://www.w3.org/1999/xhtml"', '');
            const url = "data:image/svg+xml;base64," + btoa(serializeToString)
            
            usedSymbols.push({
                labels: [t(`layer.fieldAlias.${attribute}`)],
                symbol: {
                    type: "picture-marker",
                    url: url,
                    height: 18,
                    width: 18
                }})
        })
        
        layerInfo.symbols = usedSymbols
    }

    const updateConopsSymbols = (legendGroup, layerInfo, features) => {
        const layer = layerInfo.layer
        if (!layer.layerConfig.isConops)
            return


        const attributeNames = new Set();
        features && features.forEach((feature) => {
            Object.keys(ConopsIconAttributeMap).filter((attr) => feature.attributes[attr] === 1).forEach((attr) =>
                attributeNames.add(attr)
            )
        })
        
        const usedSymbols = []
        attributeNames.forEach((attribute) => {
            usedSymbols.push({
                labels: [t(`layer.fieldAlias.${attribute}`)],
                symbol: {
                    type: "picture-marker",
                    url: svgToBase(ConopsIconAttributeMap[attribute]),
                    height: 18,
                    width: 18
                }})
        })

        layerInfo.symbols = usedSymbols
    }

    /**
     * Is this feature respects the symbology definition?
     * the for each field we have to match a value in the value array
     *
     * the following value expressions are supported:
     * - exact match: eg. 1
     * - wildcard: *
     * - range: 1-3
     *
     * @param feature
     * @param fields array of fields
     * @param value values of the fields separated by a comma
     * @returns true if the feature is conform to the values
     */
    const featureConformsDef = (feature, fields, value) => {
        const values = value.split(",")
        if (fields.length !== values.length)
            return false

        return values.filter((value, idx) => {
            if (value === "*")
                return true

            const fieldName = fields[idx].name
            const fieldValue = feature.attributes[fieldName]

            const range = value.split("-")
            if (range.length === 2) {
                //Range specified
                return typeof fieldValue === 'number' && fieldValue >= range[0] && fieldValue < range[1]
            } else
                //Single value specified
                return typeof fieldValue === 'number' ? fieldValue === Number(value) : value === 'NULL' ? fieldValue === null : fieldValue === value

        }).length === values.length
    }

    const getFeatureLabel = (feature, fields, t, altValue) => {
        return fields.map(
            (field) => {
                const value = altValue && typeof feature.attributes[field.name] !== "string" ?
                    altValue : feature.attributes[field.name]
                return renderSingleDomain(field, value, t, altValue)
            }
        ).join(" ")
    }

    /**
     * Check two color definitions for equality: they are equal if they define the same colors and labels
     */
    const isColorDefEqual = (def1, def2) => {
        if (def1.length !== def2.length)
            return false

        return def1.filter((cd1) => {
            return def2.filter((cd2) => cd2.label === cd1.label && cd2.color === cd1.color).length > 0
        }).length === def1.length
    }

    if (!view )
        return null

    /**
     * Get all symbols from the layergroup
     */
    const getSymbolMap = (visibleLayerInfos) => {
        const symbolMap = []
        visibleLayerInfos.forEach((layerInfo, lix) => {
            layerInfo.symbols && layerInfo.symbols.forEach((symbol, sidx) => {
                symbolMap.push(
                    <SymbolLine key={"sm" + lix + sidx}>
                        <SymbolCont className="legendBox__layer-symbol"><Symbol key={"img"} alt={"t"} src={symbol.symbol.url}/></SymbolCont>
                        <SymbolTitle className="legendBox__layer-feature" key={"lab"}>
                            {Array.from(symbol.labels).join(', ')}
                        </SymbolTitle>
                    </SymbolLine>
                )
            })
        })
        return symbolMap
    }

    const getColorMap = (visibleLayerInfos, symbolMap) => {
        const colorMap = []
        visibleLayerInfos.forEach((layerInfo, lix) => {
            layerInfo.colors && layerInfo.colors.forEach((cd, cidx) => {
                colorMap[cd.color] = (isPolygon(visibleLayerInfos) ?
                    <SymbolLine key={"syml" + lix + cidx}>
                        <ColorPolygon className="legendBox__color-polygon" color={cd.color}/>
                        <SymbolTitle className="legendBox__symbol-title">{Array.from(cd.labels).join(', ')}</SymbolTitle>
                    </SymbolLine>
                    :
                  Array.from(cd.labels).join(', ') ? <LayerColorLine key={"lc" + lix + cidx}>
                        {symbolMap.length > 0 ?
                            <ColorCube className="legendBox__color-cube" color={cd.color}/> :
                            <ColorLine dash={cd.style === 'dash'} color={cd.color} size={cd.size < 1 ? 1 : cd.size > 6 ? 6 : cd.size}/>
                        }
                        <SymbolTitle className="legendBox__symbol-title">{Array.from(cd.labels).join(', ')}</SymbolTitle>
                    </LayerColorLine> : null)
            })
        })

        return Object.values(colorMap)
    }

    const isPolygon = (visibleLayerInfos) => !visibleLayerInfos.some((li) => li.layer.geometryType !== "polygon")

    const getLayerGroupPanel = (visibleLayerInfos, idx, symbolMap, colorMapValues) => {
        const titleSet = [...new Set(visibleLayerInfos.map((layerInfo) => layerInfo.layer.getLayerTitle(t)))]
        const noSymbolMap = new Map()

        visibleLayerInfos.forEach((layerInfo, lix) => {
            if (!layerInfo.symbols.length && !layerInfo.colors.length && !isPolygon(visibleLayerInfos)){
                const title = visibleLayerInfos[lix].layer.getLayerTitle(t)
                noSymbolMap.set(title,
                    <SymbolLine key={"sm" + lix}>
                        <ColorLine color={"grey"} size={4}/>
                        <SymbolTitle className="legendBox__symbol-title" key={"lab" + lix}>{title}</SymbolTitle>
                    </SymbolLine>
                )
            }
        })

        const showSymbolMap = symbolMap.length > 0 || noSymbolMap.size > 0
        symbolMap.push([...noSymbolMap.values()])

        return [
            <LayerTitle className="legendBox__layer-title" isEmbed={isEmbedMode} key={"lt"} >{titleSet.join(', ')}</LayerTitle>,
            <LayerPanel key={"lp"} >
                {showSymbolMap && <LayerIconPanel key={"ip" + idx}>{symbolMap}</LayerIconPanel>}
                {colorMapValues.length > 0 && <LayerColorPanel key={"cp" + idx}>{colorMapValues}</LayerColorPanel>}
            </LayerPanel>
        ]
    }
    
    const visibleLegendInfos = legendInfo.filter((li) => Object.values(li.layers).some((layerInfo) =>
        (layerInfo.symbols?.length || layerInfo.colors?.length ) && layerInfo.layer.visible))
    const compareElements = (a, b) => (b.props.rowspan + b.props.colspan) - (a.props.rowspan + a.props.colspan)

    const elements = visibleLegendInfos.map((li, idx) => {
        const visibleLayerInfos = Object.values(li.layers).filter((layerInfo) =>
            (layerInfo.symbols?.length || layerInfo.colors?.length ) && layerInfo.layer.visible)
        const symbolMap = getSymbolMap(visibleLayerInfos)
        const colorMapValues = getColorMap(visibleLayerInfos, symbolMap)
        const symbolsAndColors = symbolMap.length && colorMapValues.length
        const rowNumber = Math.max(symbolMap.length, colorMapValues.length)

        return <LayerGroupPanel key={"lgp" + idx} colspan={symbolsAndColors ? 2 : 1} rowspan={Math.ceil(rowNumber / 4)}>
            {getLayerGroupPanel(visibleLayerInfos, idx, symbolMap, colorMapValues)}
        </LayerGroupPanel>
    })

    elements.sort(compareElements)
    
    const showLegendOnTop = useCallback((bodyVisible)=>{
        const reportBtn = document.getElementById('reportButton');
        
        if (reportBtn && window.isSmall){
            return {
                position: bodyVisible ? 'absolute' : undefined,
                left: bodyVisible ? 0 : undefined,
                minWidth: bodyVisible ? 200 : undefined,
                maxHeight: bodyVisible ? 200 : undefined,
                borderRadius: bodyVisible ? 8 : undefined,
                overflow:bodyVisible ? 'auto' : undefined,
                zIndex: bodyVisible ? 2 : undefined,
                bottom: 0
            }
        }
        
        return {}
     },[bodyVisible])
    
    return <div id="legendBox"
                className="onboarding-legend"
                style={{
                    padding: isWebsiteEmbed ? "6px" : "8px",
                    backgroundColor: "white",
                    ...showLegendOnTop(bodyVisible)
                }}>
        <LegendHeader isEmbed={isWebsiteEmbed} style={{margin: "0"}}>
            <div className="legendBox__title" style={{flexGrow: 1, textAlign: "left"}}>
                {t("screen.widget.Legend.name", "Legend")}
            </div>
            {showCollapseIcon && (
              !mobileReducer.isMobileApp ? (
                <div
                  style={{flexGrow: 0}}
                  className={"expandButton esri-icon esri-icon-" + (bodyVisible ? "down" : "up")}
                  onClick={() => setBodyVisible(!bodyVisible)}
                />
              ) : (
                <div
                  style={{display:'flex'}}
                  onClick={() => setBodyVisible(!bodyVisible)}>
                    <ArrowDownIcon
                      style={{
                          transform: bodyVisible ? 'rotate(0deg)' : 'rotate(180deg)',
                          transition:'all 0.3s'
                      }}
                    />
                </div>
              )
            )}
        </LegendHeader>

        <StyledContainer className="legendBox__grid" style={{display: bodyVisible ? "grid" : "none"}}>
            {elements}
        </StyledContainer>
    </div>
}

export default SymbologyLegend
