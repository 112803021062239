import {StyledButton} from "../../../Editor-styled";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {graphicsLayer, view} from "../../../../../../utils/API";
import {loadModules} from "esri-loader";

const SegmentButton = ({
  point,
  nextPoint,
  onClick,
  isRoutable,
  editedItems,
  index,
}) => {
  const [hasSegment, setHasSegment] = useState(false);
  const [hasManualSegment, setHasManualSegment] = useState(false);
  
  useEffect(()=>{
    let handler;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils])=>{
      view.whenLayerView(graphicsLayer).then(lv=>{
        handler = reactiveUtils.watch(
          () => lv.updating,
          (updating) => {
            if (!updating){
              if (!nextPoint) return;
              const hasSegment = graphicsLayer.graphics.some(g=>{
                const isStartingWithPoint = g.id && g.id === `line-${point.coordinate.join('|')}`;
                
                if (isStartingWithPoint){
                  return true;
                }
                const isStartingWithNextPoint = g.id && g.id === `line-${nextPoint.coordinate.join('|')}`;
                if (Array.isArray(g.intersectedPoints) && (g.intersectedPoints.includes(nextPoint.coordinate.join('|')) || isStartingWithNextPoint) && g.intersectedPoints.includes(point.coordinate.join('|'))){
                  return true;
                }
                
                return false;
              });
              setHasManualSegment(hasSegment);
              
              if (nextPoint.searchable && point.searchable){
                const segmentId = point.coordinate.join('|') + '-' + nextPoint.coordinate.join('|');
  
                const hasSegment = graphicsLayer.graphics.some(g=>g.id && g.id === `route-${segmentId}`);
                setHasSegment(hasSegment);
              }
            }
          });
      })
    })
    
    return ()=>{
      handler?.remove();
    }
  },[point, nextPoint])
  
  const handleDeleteAutoSegment = useCallback(()=>{
    const pointId = point.coordinate.join('|');
    const nextPointId = nextPoint.coordinate.join('|');
    const routeId = `route-${pointId}-${nextPointId}`;
    const routeGraphic = graphicsLayer.graphics.find(g=>g.id && g.id === routeId);
    if (routeGraphic){
      graphicsLayer.remove(routeGraphic);
    }
  },[point, nextPoint])
  
  return (
    <div style={{
      display:'flex',
      position: 'absolute',
      bottom: '-33px',
      left: '50%',
      transform: 'translateX(-50%)',
      alignItems:'center'
    }}>
      <StyledButton
        type="ghost"
        hover={!(isRoutable && hasSegment && !hasManualSegment)}
        disabled={(isRoutable && hasSegment && !hasManualSegment)}
        color={hasManualSegment ? '#fbbf24' : !isRoutable ? "#ef4444" : '#16a34a'}
        style={{
          fontSize:10
        }}
        onClick={onClick}
      >
        {!isRoutable ? hasManualSegment ? 'Manually edited' : 'Missing, needs manual editing ' : hasManualSegment ? 'Manually edited' : hasSegment ? 'Auto-calcuated  ' : 'Missing, can be auto-calculated'}
      </StyledButton>
      {isRoutable && hasSegment && !hasManualSegment && (
        <StyledButton
          type="ghost"
          color={hasManualSegment ? "#fbbf24" : !isRoutable ? "#ef4444" : "#16a34a"}
          style={{
            fontSize: 10,
            height:'max-content',
            padding:0
          }}
          onClick={handleDeleteAutoSegment}
        >
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M296 64h-80a7.91 7.91 0 0 0-8 8v24h96V72a7.91 7.91 0 0 0-8-8z"></path><path d="M432 96h-96V72a40 40 0 0 0-40-40h-80a40 40 0 0 0-40 40v24H80a16 16 0 0 0 0 32h17l19 304.92c1.42 26.85 22 47.08 48 47.08h184c26.13 0 46.3-19.78 48-47l19-305h17a16 16 0 0 0 0-32zM192.57 416H192a16 16 0 0 1-16-15.43l-8-224a16 16 0 1 1 32-1.14l8 224A16 16 0 0 1 192.57 416zM272 400a16 16 0 0 1-32 0V176a16 16 0 0 1 32 0zm32-304h-96V72a7.91 7.91 0 0 1 8-8h80a7.91 7.91 0 0 1 8 8zm32 304.57A16 16 0 0 1 320 416h-.58A16 16 0 0 1 304 399.43l8-224a16 16 0 1 1 32 1.14z"></path></svg>
        </StyledButton>
      )}
    </div>
  );
};

export default SegmentButton;