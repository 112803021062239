import React, {useContext, useEffect, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import EditorSituationalFields from "../../Editor/EditorFields/EditorSituationalFields";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {EditorContext, UPDATE_STATUS_TYPE, UpdateType} from "../../Editor/EditorContextProvider";
import BatchFieldsHeader from "../../Editor/EditorFields/BatchFieldsHeader";
import {StyledArrowButton, StyledSubmitButton} from "../../../Report/new/Footer/Footer-styled";
import {RightArrowIcon} from "../../../Panel/components/Pagination/helpers";
import useEditor from "../../../../hooks/useEditor";
import {currAsOfDateFieldName} from "../../../../utils/API";
import {EditType} from "../../Editor/EditorSwiper/EditorSwiper";
import Loader from "../../Editor/Loader/Loader";

const MonitoringBatchUpdate = ({handlePrev}) => {
  const {config} = useContext(ConfigContext);
  const {batchUpdateFeatures, editableLayer, setUpdateType, situationalUpdateType} = useContext(EditorContext);
  const {doEdit, loading, methods} = useEditor({
    editType: EditType.edit ,
    config,
    onSave: ()=>{
      handlePrev();
    },
  })
  const {formState:{isValid}, handleSubmit, getValues} = methods;
  
  useEffect(()=>{
    //for do edit function
    setUpdateType(UpdateType.situational);
  },[])
  
  const isSubmitDisabled = useMemo(()=>{
    const formValues = getValues();
    return situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT ? !isValid || !formValues[currAsOfDateFieldName] : !isValid
  },[isValid, situationalUpdateType])
  
  return (
    <FormProvider {...methods}>
      {
        loading && <Loader />
      }
      <div
      style={{
        position: 'absolute',
        height: '100%',
        width:'100%',
        textAlign:'left',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <BatchFieldsHeader
          editableLayer={editableLayer}
          features={batchUpdateFeatures}
          config={config}
          activeColor={config.opsColor}
        />
        <EditorSituationalFields />
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop:'auto',
          padding:8
        }}>
          <div style={{borderRadius: "4px", overflow: "hidden", display: "flex", height: "40px"}}>
            <StyledArrowButton
              bg={config.opsColor}
              style={{
                borderRadius: 8,
                overflow: "hidden",
                width: 34,
                justifyContent: "center"
              }}
              onClick={handlePrev}
            >
              <RightArrowIcon
                width={18}
                color="#FFFFFF"
              />
            </StyledArrowButton>
          </div>
          <StyledSubmitButton
            disable={isSubmitDisabled}
            onClick={handleSubmit(doEdit)}
            bg={config.opsColor}>
            Update {batchUpdateFeatures.length > 0 ? `(${batchUpdateFeatures.length})` : ''}
          </StyledSubmitButton>
        </div>
      </div>
    </FormProvider>
  );
};

export default MonitoringBatchUpdate;
