import Input from "../../../Report/new/Input/Input";
import React, {useMemo, useState} from "react";
import {CoordinateNotationWrapper, StyledSwitch} from "../Editor-styled";
import Dropdown, {DropdownBody} from "../../../Dropdown";

const CoordinateSwitch = ({dir, selected, onClick}) =>{
  const array = useMemo(()=>{
    if (dir === 'vertical') {
      return ['N', 'S']
    } else {
      return ['W', 'E']
    }
  },[dir])
  
  return <StyledSwitch dir={dir === 'vertical' ? 'column' : 'row'}>
    {
      array.map(item=>(
        <div key={item} onClick={()=>onClick(item, dir)} className={item === selected ? 'selected' : ''}>
          <span>{item}</span>
        </div>
      ))
    }
  </StyledSwitch>
}

const CoordinateNotation = ({
  handleInputChange,
  coordinates,
  onDirectionSelect,
  type,
  directions,
  color,
  t
}) => {
  const [show, setShow] = useState(false);
  
  const arrayInputs = useMemo(()=>{
    const array = [
      {
        label: t('screen.widget.CoordinateConversion.degrees'),
        path: 'degree',
      },
      {
        label: t('screen.widget.CoordinateConversion.minutes'),
        path: 'minutes',
      },
    ]
    
    if (type === 'dms') {
      array.push({
        label: t('screen.widget.CoordinateConversion.seconds'),
        path: 'seconds',
      })
    }
    
    return array;
  },[type])
  
  return (
      <CoordinateNotationWrapper>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems:'center'
        }}>
          <p>{type.toUpperCase()}</p>
          {/*<Dropdown*/}
          {/*  setShow={setShow}*/}
          {/*  show={show}*/}
          {/*  leftOffset={0.3}*/}
          {/*  hasMinWidth={false}*/}
          {/*>*/}
          {/*  <span className="information">*/}
          {/*    <svg fill="rgb(76,76,76)" fillRule="evenodd" height="10" role="img" viewBox="0 0 16 16" width="10" aria-label="info"><title>info</title><circle cx="8" cy="4" r="1"></circle><path d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 118 0a8 8 0 010 16z"></path><path d="M9 13H7V7h2z"></path></svg>*/}
          {/*  </span>*/}
          {/*  <DropdownBody type="tooltip" style={{display: "flex"}}>*/}
          {/*    {'description'}*/}
          {/*  </DropdownBody>*/}
          {/*</Dropdown>*/}
        </div>
        <div style={{display:'flex', flexDirection:"row", gap:4, alignItems:'center'}}>
          <CoordinateSwitch onClick={onDirectionSelect} dir="horizontal" selected={directions[1]}/>
          {
            arrayInputs.map(item=>(
              <Input
                key={item.label}
                onChange={handleInputChange}
                style={{width:'100%'}}
                placeholder={item.label}
                label={item.label}
                value={coordinates.lon[item.path]}
                name={`lon.${item.path}`}
                margin="0px"
                borderColor={color}
              />
            ))
          }
        </div>
        <div style={{display:'flex', flexDirection:"row", gap:4, alignItems:'center'}}>
          <CoordinateSwitch onClick={onDirectionSelect} dir="vertical" selected={directions[0]} />
          {
            arrayInputs.map(item=>(
              <Input
                key={item.label}
                onChange={handleInputChange}
                style={{width:'100%'}}
                placeholder={item.label}
                label={item.label}
                value={coordinates.lat[item.path]}
                name={`lat.${item.path}`}
                margin="0px"
                borderColor={color}
              />
            ))
          }
        </div>
      </CoordinateNotationWrapper>
  );
};

export default CoordinateNotation;
