import {graphicsLayer, view} from "../../utils/API";
import {loadModules} from "esri-loader";
import {useCallback, useEffect, useRef} from "react";
import {createSymbol, getLayerSymbology} from "../../utils/symbologies";
import {pointSymbol} from "../../components/Dashboard/Editor/EditorSwiper/EditorSwiper";

const useEditSupplyRoutes = (layer, config, highlightFeature, highlightHandle, onFinish) => {
  const dragHandle = useRef();
  const drawAction = useRef();
  
  
  /**
   * Creates a Bezier curve between two geometries and adds control points and connection lines to the graphics layer.
   *
   * @param {Point} startGeometry - The starting geometry (Point).
   * @param {Point} endGeometry - The ending geometry (Point).
   * @param {number|Point} center - The center offset for the control point (number) or a specific Point (Object).
   * @param {Object} modules - ESRI modules needed for geometry manipulation and graphics.
   * @returns {Object} An object containing the generated polyline and the coordinates of the start, end, and control points.
   */
  
  const createBezierCurve = useCallback((startGeometry, endGeometry, center, {
    Point,
    webMercatorUtils,
    Polyline,
    PictureMarkerSymbol,
    geometryEngine
  }) => {
    if (!Point || !webMercatorUtils || !Polyline || !PictureMarkerSymbol || !geometryEngine) return;
    let startPoint = startGeometry;
    let endPoint = endGeometry;
    
    if (!startGeometry.spatialReference.isGeographic) {
      startPoint = webMercatorUtils.webMercatorToGeographic(startGeometry)
    }
  
    if (!endGeometry.spatialReference.isGeographic){
      endPoint = webMercatorUtils.webMercatorToGeographic(endGeometry)
    }
    
    let controlPoint;
    if (Number.isFinite(center)){
      const midPoint = new Point({
        longitude: (startPoint.longitude + endPoint.longitude) / 2,
        latitude: (startPoint.latitude + endPoint.latitude) / 2
      });
    
      // Calculate control point
      const deltaX = endPoint.longitude - startPoint.longitude;
      const deltaY = endPoint.latitude - startPoint.latitude;
      const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const normalizedX = deltaX / length;
      const normalizedY = deltaY / length;
    
      controlPoint = new Point({
        longitude: midPoint.longitude - normalizedY * center,
        latitude: midPoint.latitude + normalizedX * center,
        spatialReference:view.spatialReference
      });
    } else {
      let midPoint = center;
      if (!center.spatialReference.isGeographic) {
        midPoint = webMercatorUtils.webMercatorToGeographic(center);
      }
    
      controlPoint = new Point({
        longitude: midPoint.longitude,
        latitude: midPoint.latitude,
        spatialReference: view.spatialReference
      });
    }
  
    const graphics = graphicsLayer.graphics.filter(g=>g.startPoint === `${startPoint.longitude}_${startPoint.latitude}` || g.endPoint === `${endPoint.longitude}_${endPoint.latitude}`)
    
    if (graphics.length > 0){
      graphicsLayer.removeMany(graphics);
    }
    
    // Generate curve points
    const curvePoints = [];
    for (let t = 0; t <= 1; t += 0.05) {
      const x = Math.pow(1 - t, 2) * startPoint.longitude +
        2 * (1 - t) * t * controlPoint.longitude +
        Math.pow(t, 2) * endPoint.longitude;
    
      const y = Math.pow(1 - t, 2) * startPoint.latitude +
        2 * (1 - t) * t * controlPoint.latitude +
        Math.pow(t, 2) * endPoint.latitude;
      curvePoints.push([x, y]);
    }
  
    curvePoints.push([endPoint.longitude, endPoint.latitude])
  
    const result = webMercatorUtils.geographicToWebMercator(new Polyline({
      paths: [curvePoints],
      spatialReference: view.spatialReference,
    }));
    
    if (controlPoint.spatialReference.isGeographic){
      controlPoint = webMercatorUtils.geographicToWebMercator(controlPoint);
    }
    
    //getting the nearest coordinates to connect control point with the curved line
    const nearestCoordinate = geometryEngine.nearestCoordinate(result, controlPoint);
    const connectionLine = new Polyline({
      paths: [[nearestCoordinate.coordinate.x, nearestCoordinate.coordinate.y], [controlPoint.x, controlPoint.y]],
      spatialReference: view.spatialReference
    })
    
    graphicsLayer.add({
      endPoint: `${endPoint.longitude}_${endPoint.latitude}`,
      startPoint: `${startPoint.longitude}_${startPoint.latitude}`,
      geometry: connectionLine,
      symbol: {
        type: "simple-line",
        color: "grey",
        width: 1
      },
      type:'connection-line'
    })
  
    
    const iconSymbol = new PictureMarkerSymbol({
      url: "assets/resizeIcon.svg", // Path to your icon
      width: "24px",  // Width of the icon
      height: "24px"  // Height of the icon
    });
    
    graphicsLayer.add({
      endPoint: `${endPoint.longitude}_${endPoint.latitude}`,
      startPoint: `${startPoint.longitude}_${startPoint.latitude}`,
      geometry: controlPoint,
      symbol: iconSymbol,
      type:'control-point'
    })
    
    return {
      polyline: result,
      coordinates: {
        startPoint: `${startPoint.longitude}_${startPoint.latitude}`,
        endPoint: `${endPoint.longitude}_${endPoint.latitude}`,
        controlPoint: controlPoint
      }
    };
  },[])
  
  /**
   * Updates the curve based on a set of vertices, generating new curved polylines.
   *
   * @param {Array} vertices - An array of vertex coordinates.
   * @param {Object} modules - ESRI modules needed for geometry manipulation.
   * @returns {Object} An object containing the new polyline and curved polylines.
   */
  const updateCurve = useCallback((vertices, {
    Point,
    webMercatorUtils,
    Polyline,
    PictureMarkerSymbol,
    geometryEngine
  }) => {
    if (!Point || !webMercatorUtils || !Polyline || !PictureMarkerSymbol || !geometryEngine) return;
    if (vertices.length < 2) return vertices; // No curve if less than 2 points
    const curvedPaths = [];
    const curvedPolylines = [];
    for (let i = 0; i < vertices.length - 1; i++) {
      const startPoint = new Point({ x: vertices[i][0], y: vertices[i][1], spatialReference:view.spatialReference });
      const endPoint = new Point({ x: vertices[i + 1][0], y: vertices[i + 1][1], spatialReference:view.spatialReference });
      
      const {polyline: bezierCurve, coordinates} = createBezierCurve(startPoint, endPoint, 0, {Point, Polyline, webMercatorUtils, PictureMarkerSymbol, geometryEngine}); // Adjust offset for curve size
      curvedPaths.push(bezierCurve.paths[0]); // Add the Bezier curve path
      curvedPolylines.push({
        polyline: bezierCurve,
        coordinates
      });
    }
  
    return {
      polyline: new Polyline({
        paths: curvedPaths.flat(),
        spatialReference: view.spatialReference
      }),
      curvedPolylines
    }
  },[])
  
  /**
   * Creates graphics (lines) based on the drawn vertices.
   *
   * @param {Object} event - The drawing event containing vertex information.
   * @param {Object} modules - ESRI modules needed for geometry manipulation and graphics.
   */
  const createGraphic = useCallback((event, {
    Point,
    Polyline,
    Graphic,
    webMercatorUtils,
    PictureMarkerSymbol,
    geometryEngine
  }) => {
    if (!Point || !Polyline || !Graphic || !webMercatorUtils || !PictureMarkerSymbol || !geometryEngine) return;
    const vertices = event.vertices;
    view.graphics.removeAll();
  
    const {curvedPolylines} = updateCurve(vertices, {Point, Polyline, webMercatorUtils, PictureMarkerSymbol, geometryEngine});
  
    const graphics = [];
    curvedPolylines.forEach((item=>{
      createCurvedGraphic(item, Graphic)
      const graphic = createCurvedGraphic(item, Graphic);
      graphics.push(graphic);
    }))
    view.graphics.addMany(graphics);
  },[])
  
  //creates curved line
  const createCurvedGraphic = useCallback((item, Graphic, color = 'grey')=>{
    const graphic = new Graphic({
      startPoint: item.coordinates.startPoint,
      endPoint: item.coordinates.endPoint,
      geometry: item.polyline,
      symbol: {
        type: "simple-line", // autocasts as new SimpleFillSymbol
        color: color,
        width: 2,
        cap: "round",
        join: "round",
        style: "dash"
      },
      type: 'curved-line'
    });
  
    return graphic;
  },[])
  
  //Combining all the lines into one and passing the result to save it
  const addLines = useCallback((Polyline)=>{
    const allCurvedLines = view.graphics.filter(g=>g.type === 'curved-line');
    const combinedCurvedPath = [];
    allCurvedLines.forEach(line=> {
      const pathArray = line.geometry.paths[0];
      combinedCurvedPath.push(pathArray);
    })
    
    const combinedPolyline = new Polyline({
      paths: combinedCurvedPath,
      spatialReference: view.spatialReference
    })
    
    onFinish(combinedPolyline);
  },[onFinish])
  
  /**
   * Activates the drawing functionality for creating polylines on the map.
   *
   * This function sets up the drawing tools for the user to draw polylines. It listens for
   * various drawing events, adds points to the graphics layer, and manages the interaction
   * with the drawn polyline, including dragging points and adjusting the line's curvature.
   */
  const activateDraw = useCallback(async () => {
    try {
      const [Polyline, Point, Draw, Graphic, webMercatorUtils, PictureMarkerSymbol, geometryEngine] = await loadModules([
        "esri/geometry/Polyline",
        "esri/geometry/Point",
        "esri/views/draw/Draw",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/symbols/PictureMarkerSymbol",
        "esri/geometry/geometryEngine",
      ])
  
      drawAction.current = new Draw({
        view: view
      });
  
      // creates and returns an instance of PolyLineDrawAction
      const action = drawAction.current.create("polyline");
      action.on(
        [
          "vertex-remove",
          "cursor-update",
          "redo",
          "undo",
          "draw-complete"
        ],
        updateVertices
      );
  
      //When adding a new vertex also adds a point to graphicLayer, we need this point so we could drag it and change the line sizes
      action.on('vertex-add', ({vertices, vertexIndex})=>{
        const coordinates = vertices[vertexIndex];
        const graphic = createLinePoints(coordinates, Point, Graphic);
        graphic.geometry = webMercatorUtils.webMercatorToGeographic(graphic.geometry)
        graphicsLayer.add(graphic);
      })
  
      //When completing draw, activating dragging of points and changing the curve angle
      action.on('draw-complete', ()=>{
        addLines(Polyline);
        startPointDrag(Point, webMercatorUtils, Polyline, PictureMarkerSymbol, geometryEngine, Graphic);
      })
  
      // create a new graphic presenting the polyline that is being drawn on the view
      function updateVertices(event) {
        // create a polyline from returned vertices
        if (event.vertices.length > 1) {
          createGraphic(event, {Point, Polyline, Graphic, webMercatorUtils, PictureMarkerSymbol, geometryEngine});
        }
      }
    } catch (err){
      console.log(err)
    }
  },[addLines])
  
  const deactivateDraw = useCallback(() =>{
    if (drawAction.current){
      drawAction.current?.destroy()
    }
    if (dragHandle.current){
      dragHandle.current?.remove();
      dragHandle.current = null;
    }
    if (graphicsLayer){
      graphicsLayer.removeAll();
    }
  },[])
  
  /**
   * Handles changes to the symbology field and updates the colors of dashed lines accordingly.
   *
   * This function is triggered when the selected symbology field changes in an input select element.
   * If the layer is for supply routes, it retrieves the new color based on the
   * selected field and updates the colors of all relevant curved line graphics on the map.
   *
   * @param {Event} event - The change event from the input select element.
   * This contains details about the target element (the select) and the new value selected by the user.
   */
  const onChange = useCallback((event)=>{
    if (!layer?.layerConfig.isEditorCurved) return;
    
    const {value} = event.target;
    const fieldName = event.target.getAttribute('data-field-name');
    const symbology = getLayerSymbology(layer, config) || {};
    const {colorMap = {}, defaultColor} = symbology;
    
    const fields = colorMap.fields || colorMap.field || [];
    const colors = colorMap.default || {}
    const newColor = colors[value] ?? defaultColor;
    
    if (newColor && fields.includes(fieldName) && symbology){
      const allLines = view.graphics.filter(g=>g.type === 'curved-line');
      
      const symbol = createSymbol(symbology, layer, '', newColor, symbology.defaultSize);
      allLines.forEach(line=>{
        line.symbol = symbol;
      })
    }
  },[layer, config, highlightFeature, highlightHandle])
  
  
  /**
   * For editing a feature, creating lines from feature.geometry and adding points for dragging
   *
   * This effect triggers when the `highlightFeature` or `highlightHandle` changes.
   * It applies a grayscale effect to the highlighted feature, to hide it and creates new curved lines
   * from the feature's geometry, and adds draggable points at the start and end of each line.
   *
   * Dependencies:
   * - `highlightFeature`: The feature selected on the map.
   * - `highlightHandle`: A handle for removing highlight effects.
   */
  useEffect(()=>{
    loadModules([
      "esri/geometry/Polyline",
      "esri/layers/support/FeatureEffect",
      "esri/layers/support/FeatureFilter",
      "esri/Graphic",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/Point",
      "esri/symbols/PictureMarkerSymbol",
      "esri/geometry/geometryEngine",
    ]).then(([Polyline, FeatureEffect, FeatureFilter, Graphic, webMercatorUtils, Point, PictureMarkerSymbol, geometryEngine])=>{
      
      if (highlightFeature && layer?.layerConfig?.isEditorCurved && !dragHandle.current) {
        
        highlightHandle?.remove();
        //removing editable feature from layerView and putting on top new one
        const effect = new FeatureEffect({
          filter: new FeatureFilter({
            where: `${layer.objectIdField} = ${highlightFeature.attributes[layer.objectIdField]}`
          }),
          includedEffect: "grayscale(100%) opacity(0%)"
        });
        layer.featureEffect = effect;
        
        const lineGraphics = [];
        let startCoordinates, endCoordinates;
        const createdGraphics = [];
        
        let geometry = highlightFeature.geometry;
        if (!geometry.spatialReference.isGeographic) {
          geometry = webMercatorUtils.webMercatorToGeographic(geometry)
        }
        
        //creating multiple lines from one big geometry we need it to improve performance
        geometry.paths.forEach((pathArray, index, array)=>{
          if (pathArray.length < 2) return;
          
          const polyline = new Polyline({
            paths: [pathArray],
            spatialReference: view.spatialReference
          });
          
          const [slongitude, slatitude] = pathArray[0];
          const [elongitude, elatitude] = pathArray[pathArray.length - 1];
  
          if (index === 0) {
            startCoordinates = pathArray[0]
          }
          
          if (array.length - 1 === index) {
            endCoordinates = pathArray[pathArray.length - 1];
          }
          
          //getting the color of the line
          const symbology = getLayerSymbology(layer, config) || {};
          const {colorMap} = symbology;
          const colors = colorMap.default || {};
          const color = colors[highlightFeature.attributes.routetype] || symbology.defaultColor;
          
          //creating curved line
          const graphic  = createCurvedGraphic({
            polyline: webMercatorUtils.geographicToWebMercator(polyline),
            coordinates: {
              startPoint: `${slongitude}_${slatitude}`,
              endPoint: `${elongitude}_${elatitude}`
            }
          },
            Graphic,
            color
          )
          
          //putting draggable points
          const startGraphic = createLinePoints([slongitude, slatitude], Point, Graphic);
          const endGraphic = createLinePoints([elongitude, elatitude], Point, Graphic);
          const graphicsArray = [startGraphic, endGraphic].filter(g=> !createdGraphics.some(createG=>geometryEngine.equals(g.geometry, createG.geometry)));
          
          createdGraphics.push(...graphicsArray)
          graphicsLayer.addMany(graphicsArray);
          lineGraphics.push(graphic);
        })
        
        view.graphics.addMany(lineGraphics);
        startPointDrag(Point, webMercatorUtils, Polyline, PictureMarkerSymbol, geometryEngine, Graphic);
      }
    })
  },[highlightFeature, highlightHandle])
  
  /**
   * Handles the drag-and-drop functionality for point and control point graphics on a map.
   *
   * This function listens for drag events on the map view, allowing users to
   * drag point graphics or control points. It updates the corresponding graphics
   * and the curves they are associated with in real-time.
   *
   * @param {Point} Point - The Point class used to create point geometries.
   * @param {webMercatorUtils} webMercatorUtils - Utility functions for Web Mercator projections.
   * @param {Polyline} Polyline - The Polyline class used to create line geometries.
   * @param {PictureMarkerSymbol} PictureMarkerSymbol - The symbol used for point graphics.
   * @param {geometryEngine} geometryEngine - The geometry engine for spatial operations.
   *
   * @returns {void}
   */
  
  //Dragging points and control point of curved line
  const startPointDrag = useCallback((Point, webMercatorUtils, Polyline, PictureMarkerSymbol, geometryEngine)=>{
    let draggedGraphic, curvedLineGraphic;
    dragHandle.current = view.on('drag', (event)=>{
      if (event.action === 'start') {
        view.hitTest(event, {include: [graphicsLayer]}).then((response) => {
          if (response.results.length > 0) {
            const firstRes = response.results.at(0)?.graphic;
            if (!firstRes.type)return;
            
            event.stopPropagation();
            draggedGraphic = firstRes;
          
            //point means it is a starting or ending point of the line
            if (firstRes.type === 'point') {
              const pointString = `${draggedGraphic.geometry.longitude}_${draggedGraphic.geometry.latitude}`
              
              //getting all curved lines related to this point
              curvedLineGraphic = view.graphics.filter(g=>g.type === 'curved-line' && (g.startPoint === pointString || g.endPoint === pointString));
            }
          }
        })
      } else if (event.action === 'update') {
        if (!draggedGraphic) return;
        
        event.stopPropagation();
        
        if (draggedGraphic.type === 'point'){
          const draggedPoint = view.toMap({x:event.x, y: event.y});
          const draggedPointGeographic = webMercatorUtils.webMercatorToGeographic(draggedPoint)
          //changing geometry of curved lines related to the point
          curvedLineGraphic = curvedLineGraphic.map(lineGraphic=>{
            const [startLongitude, startLatitude] = lineGraphic.startPoint.split('_')
            const [endLongitude, endLatitude] = lineGraphic.endPoint.split('_')
  
            let startPoint =  new Point({
              x: startLongitude,
              y: startLatitude,
              
            })
            
            let endPoint =  new Point({
              x: endLongitude,
              y: endLatitude,
              
            })
            
            //comparing to get which point to change when creating a new curve
            if (geometryEngine.equals(draggedGraphic.geometry, startPoint)) {
              startPoint = draggedPoint
            } else {
              endPoint = draggedPoint
            }
  
            const item = createBezierCurve(startPoint, endPoint, 0, {Point, Polyline, webMercatorUtils, PictureMarkerSymbol, geometryEngine});
            lineGraphic.geometry = item.polyline;
            lineGraphic.startPoint = item.coordinates.startPoint;
            lineGraphic.endPoint = item.coordinates.endPoint;
            return lineGraphic;
          })
  
  
          draggedGraphic.geometry = draggedPointGeographic;
        } else if (draggedGraphic.type === 'control-point') {
          //changing the curve of the line using control point
          const start = draggedGraphic.startPoint.split('_');
          const end = draggedGraphic.endPoint.split('_');
  
          const startPoint = new Point({
            x: start[0],
            y: start[1],
          });
  
  
          const endPoint = new Point({
            x: end[0],
            y: end[1]
          });
  
          const draggedPoint = view.toMap({x:event.x, y: event.y});
          const lineGraphic = view.graphics.find(g=>g.startPoint === draggedGraphic.startPoint && g.endPoint === draggedGraphic.endPoint);
          const {polyline} = createBezierCurve(startPoint, endPoint, draggedPoint, {Point, Polyline, webMercatorUtils, PictureMarkerSymbol, geometryEngine});
          lineGraphic.geometry = polyline;
        }
      } else if (event.action === 'end') {
        if (!draggedGraphic) return;
        event.stopPropagation();
        addLines(Polyline);
        draggedGraphic = null;
      }
    })
  },[addLines])
  
  
  /**
   * Creates a graphic representation of a point based on geographic coordinates.
   *
   * @param {Array} coordinatesArray - An array containing [longitude, latitude].
   * @param {Function} Point - The Point class used to create point geometries.
   * @param {Function} Graphic - The Graphic class used to create graphic elements.
   *
   * @returns {Graphic} pointGraphic - The graphic representation of the point.
   */
  const createLinePoints = useCallback((coordinatesArray, Point, Graphic)=>{
    const [longitude, latitude] = coordinatesArray;
    let point = new Point({
      x: longitude,
      y: latitude,
    })
    
    
    const pointGraphic = new Graphic({
      type: 'point',
      geometry: point,
      symbol: pointSymbol
    })
    
    return pointGraphic;
    
  },[])
  
  return {
    activateDraw,
    deactivateDraw,
    onChange
  }
};

export default useEditSupplyRoutes;
