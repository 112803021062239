import Input from "../../../../../Report/new/Input/Input";
import React, {useEffect, useMemo} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import Comment from "../../../../../Report/new/Comment/Comment";

const InputField = ({field, label, isRequired, borderColor, defaultValue, ...props}) => {
  const {register, getValues, setValue} = useFormContext();
  const value = useWatch({name: field.name}) || '';
  
  useEffect(()=>{
    const fieldValue = getValues(field.name);
    if (!fieldValue){
      setValue(field.name, defaultValue || '');
    } else {
      setValue(field.name, fieldValue);
    }
  },[defaultValue])
  
  const filledInColor = useMemo(()=> isRequired ? '#16a34a'  : borderColor,[isRequired])
  
  return (
    field.length > 60 ? (
        <Comment
          id="editorFields"
          data-field-name={field.name}
          key={"i" + field.name}
          register={{...register(field.name, {required: isRequired})}}
          borderColor={(value.length === 0 && isRequired) ? '#FF0000' : filledInColor}
          defautlValue={defaultValue}
          noMargin
          title={label}
          {...props}
        />
      ) : (
      <Input
        id="editorFields"
        data-field-name={field.name}
        key={"i" + field.name}
        register={{...register(field.name, {required: isRequired})}}
        noMargin
        borderColor={(value.length === 0 && isRequired) ? '#FF0000' : filledInColor}
        defaultValue={defaultValue}
        label={label}
        {...props}
      />
    )
  );
};

export default InputField;