import {StyledBody, StyledLabel} from "../SidebarMenu/SidebarMenu-styled";
import {view} from "../../../utils/API";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {loadModules} from "esri-loader";
import {createMap} from "../../../esri/map-view";
import {ZOOM_DIMENSIONS} from "../helpers";
import activeModule from "../../../redux/reducers/activeModule";
import {getOpsColor} from "../../../utils/helper";
import {useSelector} from "react-redux";

const ConfigureMap = ({
  setZoomMap,
  zoomViewRef,
  zoomMap,
  setZoomOut,
  zoomOut,
  zoomOutViewRef,
  config,
  t
}) => {
  const ref = useRef();
  const sliderMoving = useRef(false);
  const {activeModule} = useSelector(state=>state);
  
  useEffect(()=>{
    let handler;
    loadModules(['esri/core/reactiveUtils']).then(([reactiveUtils])=>{
      handler = reactiveUtils.watch(()=>view.zoom, ()=>{
        if (!ref.current || sliderMoving.current) return;
        ref.current.value = view.zoom
      })
    })
    
    return ()=>{
      handler?.remove();
    }
  },[])
  
  const listeners = useRef([]);
  
  const removeListeners = useCallback(()=>{
    if (Array.isArray(listeners.current)) {
      listeners.current.forEach(listener=>{
        listener?.remove();
      })
      
      listeners.current = [];
    }
  },[zoomViewRef.current])
  
  useEffect(()=>{
    return () => {
      removeListeners()
    }
  },[])
  
  let timer;
  const onProgressChange = (e)=>{
    sliderMoving.current = true;
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(()=>{
      sliderMoving.current = true;
      view.goTo({
        zoom: Number(e.target.value)
      },{
        duration:500,
      }).then(()=>{
        timer = null;
        sliderMoving.current = false;
      })
    },250)
  }
  
  //if ZoomIn is active changing the extent of ZoomIn map
  useEffect(()=>{
    const onZoomSelect = () => {
      const mapDiv = document.querySelector('#pdfZoomMap');
      if (!mapDiv) return;
      const mapPos = mapDiv.getBoundingClientRect();
      
      const topLeft = view.toMap({x: mapPos.x, y: mapPos.y - 57})
      
      const bottomRight = view.toMap({x: mapPos.x + mapPos.width, y: mapPos.y - 57 + mapPos.height })
  
      zoomViewRef.current.extent.xmin = topLeft.x
      zoomViewRef.current.extent.xmax = bottomRight.x
      zoomViewRef.current.extent.ymin = topLeft.y
      zoomViewRef.current.extent.ymax = bottomRight.y
      zoomViewRef.current.goTo({
        target:  zoomViewRef.current.extent,
        zoom: view.zoom,
      }).then(()=>{
        mapDiv.style.opacity = 1;
        zoomViewRef.current.isShown = true;
      })
    }
    
    if (zoomMap.selected && !zoomViewRef.current.isShown) {
      onZoomSelect();
    }
  },[zoomMap.selected])
  
  //Activating and deactivating ZoomInMap
  const onZoomInputChange = useCallback((e)=>{
    if (e.target.checked) {
      loadModules(["esri/views/MapView"])
        .then(([MapView]) => {
          zoomViewRef.current = new MapView({
            map: view.map,
            container: "pdfZoomMap",
            center: view.center,
            extent: view.extent,
          });
  
          zoomViewRef.current.ui.components = ['zoom'];
          zoomViewRef.current.ui.add('zoom', 'bottom-left');
          zoomViewRef.current.popupEnabled = false;
          zoomViewRef.current.popupEnabledCustom = false;
          zoomViewRef.current.isShown = false;
          zoomViewRef.current.listeners = [];
  
          //sync zoom levels
          function syncZoom() {
            if (zoomViewRef.current.zoom <= view.zoom) {
              zoomViewRef.current.zoom = view.zoom; // Ensure view2's zoom is always less
            }
          }
  
          // Listen for changes in the main view's zoom level
          const mainViewZoomListener = view.watch("zoom", function(newZoom) {
            syncZoom();  // Sync the zoom of view2
          });
  
          //listen for changes in zoomInView and prevent it from zooming too high
          const zoomInListener = zoomViewRef.current.watch("zoom", function(newZoom) {
            if (newZoom <= view.zoom) {
              zoomViewRef.current.zoom = view.zoom;
            }
          });
          zoomViewRef.current.listeners.push(mainViewZoomListener, zoomInListener);
          
        })
      const preview = document.querySelector('#pdfMap');
      if (!preview) return;
      setZoomMap(prev=>({
        ...prev,
        active: true,
        x: 2,
        y: preview.offsetHeight - prev.height - 4
      }))
      
    } else {
      const mapDiv = document.querySelector('#pdfZoomMap');
      if (!mapDiv) return;
      mapDiv.style.opacity = 0;
      mapDiv.innerHTML = '';
      mapDiv.className = '';
      if (zoomViewRef.current && Array.isArray(zoomViewRef.current.listeners)){
        zoomViewRef.current.listeners.forEach(l=>{
          l.remove();
        });
      }
      zoomViewRef.current = null;
      setZoomMap(prev=>({
        ...prev,
        selected:false,
        active: false,
        x:0,
        y:0
      }))
    }
  },[setZoomMap, zoomViewRef.current])
  
  const onZoomOutInputChange = useCallback((e)=>{
    if (e.target.checked) {
      loadModules(["esri/views/MapView"])
        .then(([MapView]) => {
          createMap(config, ()=> console.warn('error, creating Zoomout Map')).then(map=>{
            zoomOutViewRef.current = new MapView({
              map: map,
              container: "pdfZoomOut",
              center: view.center,
              extent: view.extent,
            });
  
            zoomOutViewRef.current.ui.components = ["zoom"];
            zoomOutViewRef.current.popupEnabled = false;
            zoomOutViewRef.current.popupEnabledCustom = false;
            zoomOutViewRef.current.zoom = view.zoom - 3;
            
            const preview = document.querySelector('#pdfMap');
            const zoomOutDiv = document.querySelector('#pdfZoomOut');
            const zoomOutArea = document.querySelector('#zoomOutArea');
            const zoomAreaDefaultWidth = preview.offsetWidth / 8.5;
            const zoomAreaDefaultHeight = preview.offsetHeight / 8.5;
            
            if (zoomOutViewRef.current.zoom >= 0) {
              zoomOutArea.style.width = (zoomAreaDefaultWidth) + 'px';
              zoomOutArea.style.height = (zoomAreaDefaultHeight) + 'px';
            }
            
            setZoomOut(prev=>({
              ...prev,
              loading: true,
              active: true,
              x: 2,
              y: preview.offsetHeight - prev.height - 4
            }))
  
            zoomOutViewRef.current.when(()=>{
              const zoomInBtn = zoomOutDiv.querySelector('.esri-widget--button');
              if (!zoomInBtn) return;
              if (view.zoom - zoomOutViewRef.current.zoom <= 3) {
                zoomInBtn.style.pointerEvents = 'none'
              }
              
              const zoomListener = zoomOutViewRef.current.watch('zoom', function (val){
                zoomInBtn.style.pointerEvents = 'none';
                
                const floorVal = Math.floor(val);
                const diff = view.zoom - floorVal - 2;
                
                // if (val % 1 !== 0) return;
                
                if (diff > 1) {
                  zoomInBtn.style.pointerEvents = 'all';
                } else {
                  zoomInBtn.style.pointerEvents = 'none';
                }
                
                
                if (diff >= 1) {
                  const preview = document.querySelector('#pdfMap');
                  if (!preview) return;
                  const zoomWidth = preview.offsetWidth / 8.5
                  const zoomHeight = preview.offsetHeight / 8.5
                  
                  zoomOutArea.style.width = (zoomWidth / diff) + 'px';
                  zoomOutArea.style.height = (zoomHeight / diff) + 'px';
                }
              })
              
              listeners.current.push(zoomListener);
              
              const extentHandler = view.watch("extent", function(newValue) {
                const prevZoom = zoomOutViewRef.current.zoom;
                const preview = document.querySelector('#pdfMap');
                if (!preview) return;
                
                const zoomWidth = preview.offsetWidth / 8.5
                const zoomHeight = preview.offsetHeight / 8.5
  
                zoomOutViewRef.current.extent = newValue;
                zoomOutViewRef.current.zoom = prevZoom;
                
                const roundedZoom = Math.floor(view.zoom);
                
                if (roundedZoom - prevZoom >= 3) {
                  zoomOutArea.style.width = zoomWidth / (roundedZoom - prevZoom - 2) + 'px';
                  zoomOutArea.style.height = zoomHeight / (roundedZoom - prevZoom - 2) + 'px';
                } else {
                  zoomOutArea.style.width = 200 + 'px';
                  zoomOutArea.style.height = 150 + 'px';
                }
                
                if (roundedZoom - prevZoom > 3) {
                  zoomInBtn.style.pointerEvents = 'all';
                } else {
                  zoomInBtn.style.pointerEvents = 'none';
                  zoomOutViewRef.current.zoom = roundedZoom - 3;
                }
              });
              
              listeners.current.push(extentHandler)
              
              setZoomOut(prev=>({
                ...prev,
                loading: false,
              }))
            })
            
            //Removing all interaction with zoomOut map
            zoomOutViewRef.current.on("mouse-wheel", (event) => {
              event.stopPropagation();
            });
  
            zoomOutViewRef.current.on("key-down", (event) => {
              const prohibitedKeys = ["+", "-", "Shift", "_", "="];
              const keyPressed = event.key;
              if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                event.stopPropagation();
              }
            });
  
            zoomOutViewRef.current.on("double-click", (event) => {
              event.stopPropagation();
            });
  
            zoomOutViewRef.current.on("double-click", ["Control"], (event) => {
              event.stopPropagation();
            });
            zoomOutViewRef.current.on("drag", (event) => {
              event.stopPropagation();
            });
  
            zoomOutViewRef.current.on("drag", ["Shift", "Control"], (event) => {
              event.stopPropagation();
            });
  
            zoomOutViewRef.current.on("drag", ["Shift"], (event) => {
              event.stopPropagation();
            });
          })
          
        })
    } else {
      zoomOutViewRef.current?.destroy();
      setZoomOut(prev=>({
        ...prev,
        x:0,
        y:0,
        active: false,
        loading: false
      }))
      
      removeListeners();
      
      const zoomOutArea = document.querySelector('#zoomOutArea');
      if (!zoomOutArea) return;
      zoomOutArea.style.width = 200 + 'px';
      zoomOutArea.style.height = 150 + 'px';
    }
  },[setZoomOut, zoomViewRef.current, zoomOutViewRef.current])
  
  const opsColor = useMemo(()=>getOpsColor(config, activeModule),[config, activeModule])
  
  return (
      <StyledBody opsColor={opsColor}>
        <div>
          <p><span>1. </span>{t("print.setUpMapView.questions.1.title")}</p>
          <input
            ref={ref}
            onChange={onProgressChange}
            type="range"
            step="0.5"
            min="1"
            max="8"
            defaultValue={view.zoom}
          />
        </div>
        <div>
          <p style={{marginBottom:0}}><span>2. </span>{t("print.setUpMapView.questions.2.title")}</p>
        </div>
        <div>
          <p><span>3. </span>{t("print.setUpMapView.questions.3.title")}</p>
          <StyledLabel>
            <input onChange={onZoomInputChange} style={{transform:'scale(1.3)'}} type="checkbox" checked={zoomMap.active} />
            <span>{t("print.setUpMapView.questions.3.options.zoomIn")}</span>
          </StyledLabel>
          <StyledLabel>
            <input onChange={onZoomOutInputChange} style={{transform:'scale(1.3)'}} type="checkbox" checked={zoomOut.active} />
            <span>{t("print.setUpMapView.questions.3.options.zoomOut")}</span>
          </StyledLabel>
        </div>
      </StyledBody>
  );
};

export default ConfigureMap;
