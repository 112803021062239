import styled from "styled-components";

export const StyledOverlay = styled.div`
  position: ${({position})=> position ?? 'fixed'};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({bg})=> bg ?? 'rgba(255, 255, 255, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 2000ms cubic-bezier(0.5, 0, 0.1, 1);
  z-index: 8000;
  flex-direction: column;
  
  .title {
    color: #525252;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 0;
  }
  
  .subtitle {
    color: #525252;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 4px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    text-align: center;
  }
`