import styled from "styled-components";

export const RoadsMapBarStyled = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  padding: 8px;
  box-sizing: border-box;
  align-items: center;
  z-index: 10;
`

export const ButtonWrapperStyled = styled.div`
  margin-left:auto;
  display:flex;
  gap:10px;
  align-items:center;
`

export const BarButtonStyled = styled.button`
  display: flex;
  background: ${({selected})=>selected ? ' #525252' : '#fff'};
  border-radius:10px;
  height: auto;
  padding: 8px;
  cursor: pointer;
  //border: 1px solid #525252;
  border: none;
  transition: all 0.3s;
  
  svg {
    fill: ${({selected})=> selected ? '#fff' : '#525252'};
  }
  
  :hover {
    background: ${({selected})=> selected ? '' : '#5252521a'};
  }
`

export const SelectionIndicator = styled.div`
  position: absolute;
  bottom: -31px;
  padding: 8px 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display:flex;
  gap:4px;
  margin:auto;
  background: #FFFFFF;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`

export const TitleWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  span {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #525252;
    //width: 100%;
  }
`