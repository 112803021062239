import Dropdown, {DropdownBody} from "../../Dropdown";
import {getDisclaimerText} from "../../../utils/helper";
import {StyledDisclaimer} from "../Modules-styled";
import React, {useCallback, useRef, useState} from "react";
import useClickOutside from "../../../hooks/useClickOutside";

const Disclaimer = ({config, activeModule, t, i18n}) => {
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const ref = useRef();
  
  // useClickOutside(ref, ()=>{
  //   setShowDisclaimer(false)
  // })
  
  const handleShowDisclaimer = useCallback(()=>{
    setShowDisclaimer(true);
  },[])
  
  const handleCloseDisclaimer = useCallback(()=>{
    setShowDisclaimer(false);
  },[])
  
  return (
    <StyledDisclaimer>
      <Dropdown
        setShow={setShowDisclaimer}
        show={showDisclaimer}
        hasMinWidth={false}
        centerTooltip
        styles={{
          pointerEvents:'none'
        }}
      >
        <div
          ref={ref}
          style={{
            display:'flex'
          }}>
          <span>{t('screen.information.disclaimer')}</span>
        </div>
        <DropdownBody type="tooltip" style={{display: "flex"}}>
          {getDisclaimerText(config, activeModule, t)}
        </DropdownBody>
      </Dropdown>
      <a
        target="_blank"
        href={`https://logcluster.org/${i18n.language}/privacy-policy`}
      >
        {t('screen.information.privacy')}
      </a>
      <a
        href="mailto:global.logie@wpf.org"
      >{t('screen.information.contact')}</a>
    </StyledDisclaimer>
  );
};

export default Disclaimer;
