import {getFeatureTitle} from "../../../../../esri/custom-popup-content";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useFormContext} from "react-hook-form";
import {getFeatureNameField} from "../../helpers";

const EditableTitle = ({
  showPublicActive,
  highlightFeature,
  t,
  editableLayer,
  handleChange,
  nameField
}) => {
  const [el, setEl] = useState(undefined);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const {register, setValue, getValues} = useFormContext();
  
  useEffect(()=>{
    if (!el) return;
    const name = getFeatureNameField(editableLayer)
    
    const fieldValue = getValues(name);
    
    const title = getFeatureTitle(highlightFeature, t);
    const defaultValue = fieldValue ?? title ?? '';
    
    setValue(name, defaultValue, { shouldValidate: true });
    if (defaultValue.length){
      el.innerText = defaultValue;
      setShowPlaceholder(false)
    } else {
      setShowPlaceholder(true)
    }
  },[el])
  
  const handleTitleChange = useCallback((text) =>{
    setValue(nameField, text, { shouldValidate: true });
  },[handleChange, editableLayer, nameField, setValue])
  
  const {  onBlur, ref } = register(nameField ?? '', {required: 'string'});
  
  const measuredRef = useCallback(node => {
    try {
      if (node != null) {
        if (nameField){
          ref(node)
        }
        setEl(node)
      }
    } catch (err){
      console.log(err)
    }
  }, [ref, nameField]);
  
  return (
    <span
      ref={measuredRef}
      contentEditable={true}
      suppressContentEditableWarning={true}
      onKeyUp={e=>{
        handleTitleChange(e.target.innerText);
      }}
      onFocus={()=>{
        setTimeout(()=>{
          setShowPlaceholder(false);
        },50)
      }}
      onBlur={(e)=>{
        if (e.target.innerText.length === 0){
          setShowPlaceholder(true);
        }
        onBlur(e);
      }}
      style={{
        fontSize:24,
        color: showPublicActive ? 'rgb(76,76,76, 1)' : 'rgb(76,76,76, 0.5)',
        outline:"none",
        padding:'0px 2px',
        maxWidth: 'calc(100% - 24px)',
        position:'relative',
        overflow:'auto',
        height: 65
      }}>
        {showPlaceholder ? "Please enter name" : ""}
      </span>
  );
};

export default EditableTitle;