import {forwardRef} from "react";

const CustomSwiper = forwardRef(( {children, init= "true" ,...props}, ref)=>{
   return (
     <swiper-container
       init={init}
       ref={ref}
       active-index-change={()=>{
       
       }}
       {...props}
     >
       {children}
     </swiper-container>
   );
})

export default CustomSwiper;
