import  {setPrintWidgetOpen_actionType, setPrintWidgetStep_actionType, setPrintWidgetLoading_actionType} from "../constants"

const initialState = {
  open: false,
  step: 0,
  isLoading: false
}
const printWidget = (state = initialState, action) => {
  switch (action.type) {
    case setPrintWidgetOpen_actionType: {
      const {payload} = action
      return {
        ...state,
        open: payload
      }
    }
    case setPrintWidgetStep_actionType: {
      const {payload} = action
      return {
        ...state,
        step: payload
      }
    }
    case setPrintWidgetLoading_actionType: {
      const {payload} = action
      return {
        ...state,
        isLoading: payload
      }
    }
    default: {
      return state
    }
  }
}

export default printWidget
