import React, {useContext} from "react";
import {EditorContext} from "../../EditorContextProvider";
import {useFormContext} from "react-hook-form";
import {createSymbol} from "../../../../../utils/symbologies";
import {EditorSwitch, SelectionTags} from "../Fields";
import {graphicsLayer, showpublicFieldName} from "../../../../../utils/API";
import {getFieldDefaultValue} from "../../EditorSwiper/EditorSwiper";
import {FieldWrapper} from "../EditorFields-styled";
import InputField from "./Fields/InputField";
const borderColor = '#4c4c4c';

const AttributeFields = ({
                           fields,
                           config,
                           t,
                           symbology,
                           onChangeHandler,
                           editableLayer,
                           requiredFields
                         }) =>{
  const {highlightFeature, setHighlightSymbol, setHighlightFeature, batchUpdateFeatures, setBatchUpdateFeatures} = useContext(EditorContext);
  const {getValues} = useFormContext();
  const renderField = (f, value) => {
    const label = t('layer.fieldAlias.' + f.name, f.alias)
    const {colorMap = {}} = symbology || {};
    const isRequired = requiredFields.includes(f.name);
    
    if (f.domain){
      const domain = f.domain;
      const options = domain.codedValues;
      
      if (options.length === 3) {
        const check = {
          'yes': false,
          'no': false,
          'unknown': false
        }
        
        options.forEach(cv=>{
          const lowerName = cv.name.toLowerCase();
          if (lowerName in check){
            check[lowerName] = true;
          }
        })
        if (Object.values(check).filter(isTrue => !isTrue).length === 0){
          return <div style={{
            margin:'4px 0px'
          }}>
            <EditorSwitch
              key={"i" + f.name}
              id="editorFields"
              t={t}
              value={value}
              label={label}
              field={f}
              description="description"
              onChange={showpublicFieldName === f.name ? onChangeHandler : undefined}
              isRequired={isRequired}
            />
          </div>
        }
      }
      
      let colors = {};
      
      if (Array.isArray(colorMap.fields) && colorMap.fields.includes(f.name)) {
        colors = colorMap.default;
      } else if (colorMap.field === f.name) {
        colors = colorMap.default;
      }
      
      const getFeatureIcon = (feature, symbology) =>{
        let iconName = symbology.defaultIcon;
        
        if (symbology?.iconMap){
          const values = [];
          symbology.iconMap.fields.map(fieldName=>{
            const value = feature.attributes[fieldName];
            values.push(value || '*');
          })
          
          iconName = symbology?.iconMap.default[values.join(',')] ?? symbology.defaultIcon;
        }
        
        return iconName;
      }
      
      const onSelect = (option) => {
        if (editableLayer.geometryType === 'point' && colors[option.code]) {
          if (highlightFeature){
            const editGraphic = highlightFeature.clone();
            const formValues = getValues();
            
            Object.keys(formValues).forEach(k=>{
              editGraphic.attributes[k] = formValues[k];
            })
            
            const iconName = getFeatureIcon(editGraphic, symbology);
            
            graphicsLayer.remove(highlightFeature);
            editGraphic.symbol = createSymbol(symbology, editableLayer, iconName, colors[option.code], symbology.defaultSize);
            graphicsLayer.add(editGraphic);
            setHighlightSymbol(editGraphic.symbol);
            setHighlightFeature(editGraphic);
          } else if (batchUpdateFeatures.length > 0) {
            const newFeatures = [...batchUpdateFeatures];
            
            newFeatures.forEach(item=>{
              const iconName = getFeatureIcon(item.feature, symbology);
              item.graphic.symbol = createSymbol(symbology, editableLayer, iconName, colors[option.code], symbology.defaultSize);
            })
            setBatchUpdateFeatures(newFeatures);
          }
        }
        if (onChangeHandler){
          onChangeHandler({name: f.name, option: option.code});
        }
      }
      
      return (
        <SelectionTags
          key={"i" + f.name}
          id="editorFields"
          label={label}
          colors={colors}
          options={options}
          value={value}
          t={t}
          field={f}
          config={config}
          description="description"
          onSelect={onSelect}
          isRequired={isRequired}
          borderColor={borderColor}
        />
      )
    }
    
    switch (f.type) {
      case "double":
        return (
          <InputField
            label={label}
            type="number"
            step="any"
            defaultValue={value}
            placeholder="0.00"
            borderColor={borderColor}
            isRequired={isRequired}
            field={f}
          />
        )
      case "integer":
        return (
          <InputField
            label={label}
            type="number"
            step="1"
            defaultValue={value}
            placeholder="0"
            borderColor={borderColor}
            isRequired={isRequired}
            field={f}
          />
          )
      
      case "date":
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]
        
        return (
          <InputField
            label={label}
            type="date"
            defaultValue={value || todayString}
            description="description"
            field={f}
            isRequired={isRequired}
            borderColor={borderColor}
          />
        )
      default:
        if (f.length > 60) {
          return (
            <InputField
              label={label}
              placeholder={label}
              rows="4"
              maxLength={f.length}
              height={100}
              defaultValue={value}
              borderColor={borderColor}
              description="Some long text for description of this field"
              field={f}
              isRequired={isRequired}
            />
          )
        } else {
          return (
            <InputField
              description="description"
              noMargin
              label={label}
              placeholder={label}
              type="text"
              maxLength={f.length}
              defaultValue={value}
              borderColor={borderColor}
              field={f}
              isRequired={isRequired}
            />
          )
        }
    }
  }
  const getField = (f) => {
    //@todo: this should be null
    let value = ""
    if (highlightFeature) {
      if (highlightFeature.attributes[f.name] === undefined){
        value = getFieldDefaultValue(f, config)
      } else if (highlightFeature.attributes[f.name] != null) {
        if (f.type === "date" )
          value = new Date(highlightFeature.attributes[f.name]).toISOString().substring(0, 10)
        else
          value = highlightFeature.attributes[f.name]
      }
    }
    
    return <FieldWrapper key={f.name}>
      {renderField(f, value)}
    </FieldWrapper>
  }
  
  return fields.map(field=>(
    getField(field)
  ))
}

export default AttributeFields;