import React, {useContext, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setPrintWidgetOpen} from "../../../redux/action/PrintWidget-action";
import WidgetButton from "../../Header/components/WidgetButton";
import {ConfigContext} from "../../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import {ProgressLoader, StyledProgressBar} from "../../Report/new/ProgressBar/ProgressBar-styled";
import {PrintLoadingOverlay} from "./PrintButton-styled";
import {stepsValues, transition} from "../SidebarMenu/SidebarMenu";
import {getOpsColor} from "../../../utils/helper";

const PrintButton = () => {
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  const {layersLoading, printWidget, activeModule} = useSelector(state=>state);
  const dispatch = useDispatch();
  useEffect(()=>{
    // if (layersLoading) return;
    // document.querySelector('#Print').style.opacity = 1;
    // document.querySelector('#Print').style.pointerEvents = 'auto';
  },[layersLoading])
  const opsColor = useMemo(()=>getOpsColor(config, activeModule),[config, activeModule])
  return <WidgetButton
    title={t('screen.widget.Print.name')}
    type="Print"
    className="onboarding-print"
    isLoading={printWidget.isLoading}
    disabled={printWidget.isLoading}
    onClick={()=>{
      if (printWidget.isLoading) return;
      // const mapDiv = document.querySelector('#mapContainer');
      // if (mapDiv) {
      //   mapDiv.classList.add('print-enabled')
      // }
      dispatch(setPrintWidgetOpen(true))
  }}>
    {/*{*/}
    {/*  printWidget.isLoading && (*/}
    {/*    <div style={{*/}
    {/*      position:'absolute',*/}
    {/*      top:0,*/}
    {/*      left:0,*/}
    {/*      height:'100%',*/}
    {/*      width:'100%',*/}
    {/*      background:'#FFFFFF40',*/}
    {/*      pointerEvents:'all'*/}
    {/*    }}>*/}
    {/*      <Loader size="50px" position="absolute" style={{*/}
    {/*        background:'transparent',*/}
    {/*        top:10*/}
    {/*      }}/>*/}
    {/*    </div>*/}
    {/*  )*/}
    {/*}*/}
    <PrintLoadingOverlay isLoading={printWidget.isLoading}>
      <div style={{
        width:'100%',
        height:10,
        minHeight:10,
        borderRadius:10,
        overflow:'hidden',
        background: '#a8b1b780',
        position:'relative'
      }}>
        <ProgressLoader color={opsColor} />
        <StyledProgressBar
          style={{transition: `all ${transition[printWidget.step]}s`}}
          bgcolor={opsColor}
          progress={100 / 4 * (stepsValues[printWidget.step] ?? 0)}
        />
      </div>
      <span>{t("print.final.loadingSteps." + printWidget.step)}</span>
    </PrintLoadingOverlay>
  </WidgetButton>
}

export default PrintButton;
