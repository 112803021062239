import styled from "styled-components";
import {ResponsiveContainer} from "recharts";

export const StyledAnalytics = styled.div`
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  //height: calc(100% - 54px);

  position: absolute;
  overflow: auto;
  padding: 0 6px 6px;
  display: flex;
  flex-direction: column;

  --calcite-color-brand:var(--opsColor);
  --calcite-color-brand-hover: transparent;
  
  .title {
    color: #393738;
    line-height: 1;
    font-weight: 400;
    font-size: 18px;
  }
  
  .custom__xaxis {
    .recharts-xAxis {
      transform: translate(0, -74%);
    }
  }
  
  path {
    outline: none;
  }
  
  g {
    outline: none;
  }
`

export const StyledLoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #a09e9e1a;
  z-index: 1;
  display: flex;
  align-items: center;
`

export const StyledCardTitle = styled.p`
  color: #393738;
  line-height: 1;
  font-weight: 500;
  font-size: 16px;
  margin:0;
`

export const StyledCard = styled.div`
  width: 156px;
  flex: auto;
  box-shadow:rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
`

export const StyledCardHeader = styled.div`
  min-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  div {
    display: flex;
    cursor: pointer;
  }

  svg {
    width: 12px;
    height: 12px;
    fill: #6F7072;
  }

  p {
    line-height: 1;
    color: #393738;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }

  @media screen and (min-width: 769px) {
    font-size: 15px;
  }
`

export const StyledCardBody = styled.div`
  display:flex;
  justify-content:center;
  flex-direction: column;
  flex:1;

  span {
    font-size:18px;
    font-weight:600;
    color:#393738;
  }
`

export const StyledCardDescription = styled.span`
  line-height: normal;
  font-size: 11px;
  color: #393738;//rgb(111, 112, 114);
  width: 240px;
  display: inline-block;
  padding: 4px;
  
  span {
    span {
      font-weight: 600;
    }
  }
`

export const StyledFilterWrapper = styled.div`
  display:flex;
  margin:4px 0 10px 0;
  align-items:center;
  justify-content:center;
  gap:10px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const StyledFilterButton = styled.button`
  border: 1px solid #a6a6a6;
  background: none;
  color: #535353;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap:4px;
  
  svg {
    width: 12px;
    height: 12px;
    padding: 0;
    display: flex;
  }
`

export const StyledContentWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  padding: 0 4px 3px 1px;
  gap:10px;
  box-sizing:border-box;
  overflow:hidden;
`

export const StyledChartTooltip = styled.div`
  background-color: hsla(0, 0%, 100%, .9);
  border: 1px solid #f5f5f5;
  line-height: 24px;
  margin: 0;
  padding: 10px;
  border-radius: 8px;
  display:flex;
  flex-direction:column;
  gap: 4px;
  max-width: 230px;

  span {
    line-height: 1;
  }
`

export const StyledCardLegendText = styled.span`
  font-size:12px;
  line-height:1;
  display: flex;
  align-items:center;
  color: #6F7072;
  
  svg {
    user-select: none;
    cursor: pointer;
    margin-left: 2px;
    margin-top: 2px;
  }
  
  :before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background: ${({bg})=> bg ?? 'black'};
    border-radius: 2px;
    
  }
`

export const StyledMainBarContainer = styled(ResponsiveContainer)`
`

export const StyledVerticalBarXAxisResponsiveContainer = styled(ResponsiveContainer)`
  position: absolute;
  bottom: -14px;
  left: 0;
  pointer-events: none;

  .recharts-bar {
    display: none;
  }
  .recharts-yAxis {
    display: none;
  }

  .recharts-cartesian-grid {
    display: none;
  }
`;
