import React from "react";
import {StyledImageSlider} from "../../MobilePopup-styled";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation} from "swiper/modules";
import 'swiper/css/navigation';

const ImagesSlider = ({images}) => {
  if (images.length === 0) return null;
  
  return (
    <StyledImageSlider
      style={{
        width: "100%",
        zIndex: 0,
        marginBottom: images.length > 0 ? 10 : 0,
        height: images.length > 0 ? "200px" : "0px",
        transition: "all 0.5s",
        boxSizing: 'border-box',
      }}>
      {
        images?.length > 0 && (
          <Swiper
            style={{zIndex: 0}}
            slidesPerView='auto'
            spaceBetween={5}
            freeMode={true}
            modules={[FreeMode]}
            pagination={false}
            preventInteractionOnTransition={false}
            updateOnWindowResize
          >
            {
              images.map((image,index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    // width: "100%",
                    // height: "200px",
                  }}
                >
                  <img
                    draggable={false}
                    alt="feature"
                    src={image.url}
                  />
                </SwiperSlide>
              ))
            }
          </Swiper>)
      }
    </StyledImageSlider>
  );
};

export default ImagesSlider;
