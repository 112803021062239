import React, {useCallback, useEffect, useMemo, useRef, useState, useTransition} from "react";
import {StyledArrowButton, StyledSubmitButton} from "../../Report/new/Footer/Footer-styled";
import {LeftArrowIcon, RightArrowIcon} from "../../Panel/components/Pagination/helpers";
import {CalciteLoader} from "@esri/calcite-components-react"
import {loadModules} from "esri-loader";
import {view} from "../../../utils/API";
import {addElementsToPDF} from "../helpers";
import {applyZoomLevelFilter, getOpsColor, removeZoomLevelFilter, ROLE_EDITOR} from "../../../utils/helper";
import {useDispatch, useSelector} from "react-redux";
import {setPrintWidgetLoading, setPrintWidgetOpen, setPrintWidgetStep} from "../../../redux/action/PrintWidget-action";
import {removeMask} from "../../../esri/widgets/print";
import {
  StyledBody,
  StyledHeader,
  StyledLoaderWrapper,
  StyledSidebarWrapper,
} from "./SidebarMenu-styled";
import CustomSwiper, {CustomSwiperSlide} from "../../CustomSwiper";
import DocumentSettings from "../ExportPages/DocumentSettings";
import ConfigureMap from "../ExportPages/ConfigureMap";
import Drawings from "../ExportPages/Drawings";
import ProgressBar from "../../Report/new/ProgressBar/ProgressBar";
import CustomizeMapDetails from "../ExportPages/CustomizeMapDetails";
import FinaliseExport from "../ExportPages/FinaliseExport";
import {useTranslation} from "react-i18next";
import {getLayerSymbology} from "../../../utils/symbologies";
import {ProgressLoader, StyledProgressBar} from "../../Report/new/ProgressBar/ProgressBar-styled";
import {useSnackbar} from "../../SnackBar";
import CustomizationSelection from "../ExportPages/CustomizationSelection";
import DisclaimerConfigurations from "../ExportPages/DisclaimerConfigurations";

export const stepsValues = {
  1: 1,
  2: 2.7,
  3: 4
}

export const transition = {
  1: 1,
  2: 5,
  3: 1.5
}


const SidebarMenu = ({config, mapData, onMapDataChange, page, setPage, setDpi, dpi, setZoomMap, getPrintableData, zoomMap, zoomOut ,setZoomOut, setDrawings, drawings, setAddPublicCustomizations, addPublicCustomizations}) => {
  const {t} = useTranslation("common");
  const abortControllers = useRef([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPending, setTransition] = useTransition();
  const {step, loading} = useSelector(state=>state.printWidget)
  const {activeModule} = useSelector(state=>state);
  
  useEffect(() => {
    return () =>{
      abortControllers.current.forEach(c=>{
        c.abort();
      })
    }
  },[])
  
  
  const [openSnackbar] = useSnackbar({
    position: 'top-center',
  })
  const swiperRef = useRef();
  const zoomInView = useRef();
  const zoomOutView = useRef();
  
  const dispatch = useDispatch();
  
  const handleStepChange = useCallback((step)=>{
    dispatch(setPrintWidgetStep(step))
  },[dispatch])
  
  const setLoading = useCallback((v)=>{
    dispatch(setPrintWidgetLoading(v))
  },[])
  
  const removeZoomListeners = useCallback(()=>{
    if (zoomInView.current && Array.isArray(zoomInView.current.listeners)){
      zoomInView.current.listeners.forEach(l=>{
        l.remove();
      })
    }
  },[])
  
  let timer;
  const onDownload = async () =>{
    removeZoomListeners();
    handleStepChange(1);
    try {
      const preview = document.querySelector('#pdfMap');
      if (!preview) return;
      const previewWidth = preview.getBoundingClientRect().width;
      const previewHeight = preview.getBoundingClientRect().height;

      const dims = {width: previewWidth, height: previewHeight}
      
      const printableData = await getPrintableData();
      const [print, PrintTemplate, PrintParameters, MapView] = await loadModules(["esri/rest/print", "esri/rest/support/PrintTemplate", "esri/rest/support/PrintParameters", "esri/views/MapView"]);
      const promises = [];
      const {zoom} = printableData;
      
      if (zoom.selected) {
        promises.push({
          key: 'zoom',
          promise: fetchZoomMap({
            PrintTemplate,
            PrintParameters,
            print,
            view: zoomInView.current,
            size: zoom.size,
            orientation: zoom.orientation
          })
        })
        
        const pos = preview.getBoundingClientRect();
        const zoomDiff = view.zoom - zoomInView.current.zoom;

        if (Math.floor(zoomDiff) <= 0) {
          const zoomPoint = {
            x: zoomInView.current.extent.xmin,
            y: zoomInView.current.extent.ymax,
            spatialReference:{
              wkid: zoomInView.current.extent.spatialReference.wkid
            }
          }
          
          const zoomPoint2 =  {
            x: zoomInView.current.extent.xmax,
            y: zoomInView.current.extent.ymin,
            spatialReference:{
              wkid: zoomInView.current.extent.spatialReference.wkid
            }
          }
  
          const zoomCoordinates = {
            x: (view.toScreen(zoomPoint).x) - pos.x,
            y: (view.toScreen(zoomPoint).y - 10 - (printableData.page.orientation === 'Portrait' ? 55 : 0))
          }
          
          const helper = document.querySelector('#zoom-helper');
          if (helper){
            helper.style.left = zoomCoordinates.x + 'px';
            helper.style.top = zoomCoordinates.y + 'px';
            helper.style.width = (view.toScreen(zoomPoint2).x - view.toScreen(zoomPoint).x) + 'px';
            helper.style.height = (view.toScreen(zoomPoint2).y - view.toScreen(zoomPoint).y) + 'px';
            helper.style.transform = 'scale(1.4)';
            printableData.zoom.coordinates = {
              x: helper.getBoundingClientRect().x - pos.x,
              y: helper.getBoundingClientRect().y - 10 - (printableData.page.orientation === 'Portrait' ? 55 : 0),
              width: helper.getBoundingClientRect().width,
              height: helper.getBoundingClientRect().height
            };
          }
        }
      }
      
      if (printableData.zoomOut.active) {
        promises.push({
          key: 'zoomOut',
          promise: fetchZoomMap({PrintTemplate, PrintParameters, print, view: zoomOutView.current})
        })
      }
  
      view.map.layers.forEach(l=>{
        if (l.layerConfig && l.layerConfig.labelField) {
          l.labelingInfo.forEach(info => {
            info.symbol.font.size = 7;
            info.symbol.haloSize = 1;
          })
        }
      })
      
      timer = setTimeout(()=>{
        handleStepChange(2);
      },2000);
      //moved setting loading to request interceptor as we have to calculate view dimensions before sidebar and other widgets appear on screen
      const [main, ...rest] = await Promise.all([
        fetchMainMap({PrintTemplate, PrintParameters, print, MapView}),
        ...promises.map(item=>item.promise)
      ]);
      
      clearTimeout(timer);
      view.map.layers.forEach(l=>{
        if (l.layerConfig && l.layerConfig.labelField) {
          l.labelingInfo.forEach(info=>{
            info.symbol.font.size = 9;
            info.symbol.haloSize = 2;
          })
        }
        
        applyZoomLevelFilter(l, config);
      })

      rest.map((item, index)=>{
        printableData[promises[index].key].url = item.url
      })
  
      handleStepChange(3);
      
      await addElementsToPDF(main.url, dims, printableData);
      openSnackbar(t('print.final.downloadComplete'), 15000)
    } catch (err) {
      console.log(err);
      if (err.name !== "AbortError"){
        if (err.message && err.name){
          openSnackbar(err.message, 15000)
        } else if (err.messages && Array.isArray(err.messages)) {
          const errorMessages = err.messages.filter(err=>err.type === 'error');
          const descriptionErrors = Array.from(new Set(errorMessages.map(message=>message.description)));
          openSnackbar(descriptionErrors.join(' '), 15000)
        } else {
          openSnackbar(t('screen.message.error'), 15000)
        }
      }
      
      view.map.layers.forEach(l=>{
        if (l.layerConfig && l.layerConfig.labelField) {
          l.labelingInfo.forEach(info=>{
            info.symbol.font.size = 9
          })
        }
        applyZoomLevelFilter(l, config);
      })
      
    }
    handleStepChange(0);
    handleClose();
    setLoading(false);
  }
  
  const fetchMainMap = async ({PrintTemplate, PrintParameters, print}) => {
    const template = new PrintTemplate({
      format: "pdf",
      attributionVisible: false,
      exportOptions: {
        dpi: 300
      },
      layout: "",
      layoutOptions: {
        titleText: "",
        authorText: mapData.mapAuthor,
        copyright: config?.mapCpr,
        title: mapData.title,
      }
    });
  
    const promises = [];
    
    const params = new PrintParameters({
      view: view,
      template: template
    });
    
    params.view.map.layers.forEach(layer=>{
      promises.push(params.view.whenLayerView(layer));
    })
  
    const layerViews = await Promise.all(promises);
  
    layerViews.forEach(lv=>{
      const sizeMap = getLayerSymbology(lv.layer, config)?.sizeMap;
      if (!!sizeMap && sizeMap.zoom && lv.layer.visible) {
        removeZoomLevelFilter(lv);
      }
    })
    
    const controller = new AbortController();
    abortControllers.current.push(controller);
    return await print.execute(config.printServiceUrl, params, {
      signal: controller.signal
    });
  }
  
  const fetchZoomMap = async ({PrintTemplate, PrintParameters, print, view, size, orientation}) =>{
    let layout = '';
    if (size && orientation) {
      const layoutSize = size.substring(0,1).toUpperCase();
      const layoutOrientation = orientation.substring(0,1).toUpperCase() + orientation.substring(1);
      layout = `LogIE_LogisticsCluster_${layoutSize}_${layoutOrientation}_Zoom_Map`;
    } else {
      layout = "LogIE_LogisticsCluster_Zoom_Map";
    }
    
    const template = new PrintTemplate({
      format: "pdf",
      attributionVisible: false,
      exportOptions: {
        dpi: 300
      },
      layout: layout,
      layoutOptions: {
        titleText: "",
        authorText: "",
      }
    });

    const params = new PrintParameters({
      view: view,
      template: template
    });
    const controller = new AbortController();
    abortControllers.current.push(controller);
    return await print.execute(config.printServiceUrl, params, {
      signal: controller.signal
    })
  }
  
  const handleClose = useCallback(()=>{
    removeMask();
    dispatch(setPrintWidgetLoading(false));
    dispatch(setPrintWidgetOpen(false));
    removeZoomListeners();
  },[dispatch, removeZoomListeners])
  
  const isEditor = useMemo(()=>config.role === ROLE_EDITOR || config.printMap.allowPublicPrintAsEditor, [config]);
  
  const handleDrawings = useCallback((params)=>{
    setTransition(()=>{
      setDrawings(prev=>({
        ...prev,
        ...params
      }))
    })
  },[setDrawings])
  
  const handleNext = useCallback((customization)=>{
    let activeIndex = swiperRef.current.swiper.activeIndex;
    const isCustom = customization || addPublicCustomizations;
    if (!isCustom && activeIndex === 2 && !isEditor) {
      swiperRef.current.swiper.slideTo(6)
    } else {
      swiperRef.current.swiper.slideNext()
    }
    
    activeIndex = swiperRef.current.swiper.activeIndex;
    setCurrentSlide(activeIndex);
    
    if (isEditor || isCustom) {
      const index = isEditor ? 2 : 4
      if (activeIndex === index){
        handleDrawings({active: true})
      } else if (activeIndex > index && drawings.active) {
        handleDrawings({viewModeEnabled: true})
      }
    }
  },[isEditor, handleDrawings, drawings, addPublicCustomizations])
  
  const handlePrev = useCallback(()=>{
    let activeIndex = swiperRef.current.swiper.activeIndex;
    if (!addPublicCustomizations && activeIndex === 6 && !isEditor) {
      swiperRef.current.swiper.slideTo(2)
    } else {
      swiperRef.current.swiper.slidePrev();
    }
    
    if (addPublicCustomizations && activeIndex === 3 && !isEditor) {
      setAddPublicCustomizations(false);
    }
    
    activeIndex = swiperRef.current.swiper.activeIndex;
    setCurrentSlide(activeIndex);
    
    if (isEditor || addPublicCustomizations) {
      const index = isEditor ? 1 : 3;
      
      if (activeIndex === index && drawings.active) {
        handleDrawings({
          active: false,
          elements: drawings.api.getSceneElements()
        })
      } else if (activeIndex === index + 1 && drawings.active) {
        handleDrawings({viewModeEnabled: false})
      }
    }
  },[drawings, isEditor, handleDrawings, addPublicCustomizations])
  
  const opsColor = useMemo(()=>getOpsColor(config, activeModule),[config, activeModule])

  const slides = useMemo(() => {
    
    const allSlides = [
      {
        title: t("print.documentSettings.title"),
        component: <DocumentSettings
          config={config}
          dpi={dpi}
          setDpi={setDpi}
          page={page}
          setPage={setPage}
          t={t}
        />
      },
      {
        title: t("print.setUpMapView.title"),
        component:(
          <ConfigureMap
            zoomViewRef={zoomInView}
            zoomMap={zoomMap}
            setZoomMap={setZoomMap}
            setZoomOut={setZoomOut}
            zoomOutViewRef={zoomOutView}
            config={config}
            zoomOut={zoomOut}
            t={t}
          />
        )
      },
    ];
    
    if (isEditor) {
      allSlides.push(...[
        {
          title: t("print.drawings.title"),
          component: (<Drawings t={t} drawings={drawings} config={config}/>)
        },
        {
          title: t("print.mapDetails.title"),
          component: (
            <CustomizeMapDetails
              mapData={mapData}
              config={config}
              isEditor={true}
              onMapDataChange={onMapDataChange}
              t={t}
              addCustomizations={addPublicCustomizations}
            />
          )
        },
      ])
    } else if (config.printMap.allowPublicPrintCustomisations) {
      allSlides.push(...[
        {
          title: t("print.mapDetails.title"),
          component: (
            <CustomizeMapDetails
              mapData={mapData}
              config={config}
              isEditor={isEditor}
              onMapDataChange={onMapDataChange}
              t={t}
              addCustomizations={addPublicCustomizations}
            />
          )
        },
        {
          title: t("print.drawings.title"),
          component: (<Drawings t={t} drawings={drawings} config={config}/>)
        },
        {
          title: t('print.mapDetails.title'),
          component: (<StyledBody opsColor={opsColor}>
            <DisclaimerConfigurations index={1} t={t} config={config} isEditor={isEditor} onMapDataChange={onMapDataChange} mapData={mapData}/>
          </StyledBody>)
        },
      ])
    }
    
    allSlides.push({
      title: t("print.final.title"),
      component: (
        <FinaliseExport
          config={config}
          onDownload={onDownload}
          loading={loading}
          t={t}
        />
      )
    })
    
    if (!isEditor && config.printMap.allowPublicPrintCustomisations) {
      return [
        ...allSlides.slice(0,2),
        {
          title: t('print.publicCustomizations.title'),
          component: (<CustomizationSelection t={t} setAddCustomizations={setAddPublicCustomizations} handleNext={handleNext} config={config}/>)
        },
        ...allSlides.slice(2)
      ]
    }
    
    return allSlides
  },[config, mapData ,onMapDataChange, page, setPage, setDpi, dpi, setZoomMap, getPrintableData, zoomMap, setZoomOut, setDrawings, drawings, loading, isEditor, t, addPublicCustomizations, setAddPublicCustomizations, handleNext, opsColor])

  return (
    <StyledSidebarWrapper>
      <StyledHeader>
        <ProgressBar total={slides.length - 1} currentPosition={currentSlide} color={opsColor} />
        <p style={{ textWrap:'wrap', textAlign:'left'}}>{slides[currentSlide].title}</p>
      </StyledHeader>
      <StyledArrowButton
        radius={4}
        onClick={handleClose}
        style={{
          cursor: 'pointer',
          minHeight: 'auto',
          position: 'absolute',
          top: '15px',
          right: '0px',
          zIndex: 2
        }}
      >
        <LeftArrowIcon color="#ffffff" width={12} height={12} />
      </StyledArrowButton>
      <StyledLoaderWrapper loading={loading || step > 0} opsColor={opsColor}>
            {(loading || step > 0) ? <CalciteLoader /> : <div style={{height:192}} />}
            <div style={{
              width:'90%',
              height:10,
              borderRadius:10,
              overflow:'hidden',
              background: '#a8b1b780',
              marginTop:-30,
              position:'relative'
            }}>
              <ProgressLoader color={opsColor} />
              <StyledProgressBar
                style={{transition: `all ${transition[step]}s`}}
                bgcolor={opsColor}
                progress={100 / 4 * (stepsValues[step] ?? 0)}
              />
            </div>
        <p>{t("print.final.loadingSteps." + step)}</p>
      </StyledLoaderWrapper>
        <CustomSwiper
          ref={swiperRef}
          prevent-interaction-on-transition
          no-swiping
          allow-touch-move={false}
          prevent-clicks={false}
          simulate-touch={false}
          prevent-clicks-propagation={false}
          virtual="true"
        >
          {
            slides.map((slide,index)=>(
              <CustomSwiperSlide key={`${slide.title}-${index}`} tabIndex={index}>
                {!loading && step === 0 && currentSlide === index && slide.component}
              </CustomSwiperSlide>
            ))
          }
        </CustomSwiper>
      <div style={{
        display:'flex',
        justifyContent:'space-between',
        padding:'5px 10px',
        alignItems:'center'
      }}>
        <div style={{borderRadius:'4px', overflow:'hidden', display:'flex'}}>
          <StyledArrowButton
            disable={currentSlide === 0}
            bg={opsColor ?? '#b21b0c'}
            style={{
              borderBottomLeftRadius:6,
              borderTopLeftRadius:6,
              overflow:'hidden'
            }}
            onClick={handlePrev}
          >
            <RightArrowIcon
              width={18}
              color='#FFFFFF'
            />
          </StyledArrowButton>
          <div style={{width:'1px', height:'100%', background: '#F3F3F3'}} />
          <StyledArrowButton
            disable={currentSlide === slides.length - 1 || (!isEditor && currentSlide === 2)}
            bg={opsColor ?? '#b21b0c'}
            style={{
              borderBottomRightRadius:6,
              borderTopRightRadius:6,
              overflow:'hidden'
            }}
            onClick={()=>handleNext()}
          >
            <LeftArrowIcon width={18} color='#FFFFFF'/>
          </StyledArrowButton>
        </div>
        <StyledSubmitButton
          disable={currentSlide === slides.length - 1 || (!isEditor && currentSlide === 2)}
          onClick={()=>handleNext()}
          bg={opsColor}>
          {t('screen.popup.actions.next')}
        </StyledSubmitButton>
      </div>
    </StyledSidebarWrapper>
  );
};

export default SidebarMenu;
