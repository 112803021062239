import React from "react";
import Switch from "react-switch";
import {StyledSwitchWrapper} from "./CustomSwitch-styled";

const CustomSwitch = ({labelA, labelB, small = false, checked, ...props}) => {
  return (
    <StyledSwitchWrapper checked={checked} small={small}>
      {labelA && <span>{labelA}</span>}
      <Switch
        height={18}
        width={38}
        handleDiameter={14}
        offColor="#f3f3f3"
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow={!checked ? "0 0 0 2px #949494" : "0 0 0 0px #fff" }
        checked={checked}
        {...props}
      />
      {labelB && <span>{labelB}</span>}
    </StyledSwitchWrapper>
  );
};

export default CustomSwitch;
