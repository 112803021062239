import {StyledEditorWrapper} from "./Editor-styled";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {view} from "../../../utils/API";
import EditorSwiper from "./EditorSwiper/EditorSwiper";
import EditorContextProvider from "./EditorContextProvider";
import {isEditable} from "./helpers/helpers";
import {useDispatch, useSelector} from "react-redux";
import {setEditableLayer} from "../../../redux/action/Dashboard-action";
import Platform from "./Platform/Platform";
import {CustomSwiperSlide} from "../../CustomSwiper";

export const EditorIcon = ({color, ...props}) => {
  return <svg strokeWidth="2" viewBox="0 0 24 24" width="16px" height="16px" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg" {...props}><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path><path d="m15 5 4 4"></path></svg>
}

const Editor = ({
  handleGoBack,
  editType,
  handleMainSlideChange,
  setEditType
}) => {
  const [visibleEditableLayers, setVisibleEditableLayers] = useState();
  const [step, setStep] = useState('');
  const [editableLayers, setEditableLayers] = useState([]);
  const {editableLayer} = useSelector(state=>state.dashboard);
  const dispatch = useDispatch();
  
  useEffect(()=>{
    view.popupEnabledCustom = false;
    view.popupEnabled = false;
    //close popup
    // handleClosePopup();
    // store.dispatch(closePopupSidebar());
    
    let layerViewFilters = {};
    view.layerViews.forEach(lv=>{
      if (lv.filter?.where) {
        layerViewFilters[lv.layer.id] = lv.filter.where;
        lv.filter.where = '';
      }
    })
    
    updateLayers(view.map.layers)
    view.map.layers.on("change", (event) => {
      if (event.added)
        updateLayers(event.added)
    })
    
    return () => {
      view.layerViews.forEach(lv=>{
        if (layerViewFilters[lv.layer.id]) {
          lv.filter.where = layerViewFilters[lv.layer.id];
        }
      })
      layerViewFilters = {};
      view.popupEnabled = true;
    }
  },[])
  
  const groupLayers = (layers) => {
    const layersByLabel = {};
    
    layers.forEach(l=>{
      const label = l.layerConfig?.titleLabel;
      if (label && l.visible) {
        if (layersByLabel[label]) {
          layersByLabel[label].groupedIds.push(l.id)
        } else {
          layersByLabel[label] = l;
          layersByLabel[label].groupedIds = [l.id];
        }
      }
    })
    return layersByLabel;
  }
  
  const groupedLayers = useMemo(()=>{
    return groupLayers(editableLayers)
  },[editableLayers])
  
  const updateLayers = (newLayers) => {
    const newEditableLayers = newLayers.filter((l) => isEditable(l))
    newEditableLayers.forEach((el) => {
      el.watch("visible", () => {
        setVisibleEditableLayers(editableLayers.filter((l) => l.visible))
      })
    })
    
    if (newEditableLayers.length) {
      setEditableLayers(view.map.layers.filter((l) => isEditable(l)))
    }
  }
  
  const handleEditableLayer = useCallback((layer) =>{
    dispatch(setEditableLayer(layer));
  },[])
  
  return (
    <StyledEditorWrapper>
        <EditorContextProvider>
            <EditorSwiper
              editableLayer={editableLayer}
              editableLayers={editableLayers}
              setEditableLayer={handleEditableLayer}
              setStep={setStep}
              step={step}
              handleGoBack={handleGoBack}
              editType={editType}
              handleMainSlideChange={handleMainSlideChange}
              setEditType={setEditType}
            />
        </EditorContextProvider>
    </StyledEditorWrapper>
  );
};

export default Editor;