import {useContext, useEffect, useMemo, useRef} from "react";
import {graphicsLayer, view} from "../../../../utils/API";
import {EditType, pointSymbol} from "../EditorSwiper/EditorSwiper";
import {EditorContext} from "../EditorContextProvider";
import EntryPointsList from "../EntryPointsList/EntryPointsList";
import EditableFeaturesList from "../CoordinatesSelection/EditableFeaturesList";
import {StyledDescription, StyledTitle} from "../Editor-styled";
import {EntryPoints} from "../Platform/Platform";

const EditFeatures = ({editableLayers, handleNext, editType}) => {
  const {highlightHandle, setHighlightHandle, setHighlightSymbol, setHighlightFeature, editableLayer, highlightSymbol} = useContext(EditorContext);
  const selectedLayer = useRef(editableLayer);
  
  useEffect(()=>{
    let clickHandler;
    clickHandler = view.on('click', (event)=>{
      view.hitTest(event, {include:view.map.layers}).then((res) => {
        const firstRes = res?.results.filter((res) => res.graphic.layer === editableLayer || res.graphic.sourceLayer === editableLayer).at(0);
        if (!firstRes)
          return
        
        const clickedLayer = firstRes.layer;
        const graphic = firstRes.graphic;
        
        if (clickedLayer?.layerConfig){
          handleClick(clickedLayer, graphic, handleNext);
        } else {
          handleClick(editableLayer, graphic, handleNext);
        }
      })
      event.stopPropagation();
    })
    return ()=>{
      clickHandler.remove();
    }
  },[editableLayer, highlightSymbol, handleNext])
  
  const addGraphic = (editingGraphic, editingSymbol, callback)=>{
    const nGraphic = editingGraphic?.clone();
    nGraphic.symbol = editingGraphic ? editingSymbol : pointSymbol;
    setHighlightFeature(nGraphic);
    graphicsLayer.add(nGraphic);
  
    if (callback){
      callback();
    }
  }
  
  const handleClick = async (clickedLayer, feature, callback) =>{
    highlightHandle?.remove();
    
    const query = clickedLayer.createQuery();
    const objectIdField = clickedLayer.objectIdField;
    query.objectIds = [feature.attributes[objectIdField]];
    query.outFields = ['*'];
    let editingGraphic = feature
    
    let editingSymbol;
    clickedLayer.effect = "grayscale(40%) opacity(13%)";
    if (clickedLayer.layerConfig.titleLabel === 'conops') {
      editingSymbol = pointSymbol;
      setHighlightSymbol(pointSymbol)
      
      const result = await clickedLayer.queryFeatures(query);
      const graphic = result.features.find(feat=>feat[objectIdField] === editingGraphic[objectIdField])
      if (!!graphic) {
        editingGraphic = graphic;
      }
    } else {
      const symbol = await editingGraphic.layer?.renderer?.getSymbolAsync(editingGraphic);
      editingSymbol = symbol;
      setHighlightSymbol(symbol);
    }
  
    addGraphic(editingGraphic, editingSymbol, callback);
  }
  
  const isEntryPointsLayer = useMemo(()=>{
    return EntryPoints.includes(editableLayer?.layerConfig?.titleLabel)
  },[editableLayer])

  return (
    !selectedLayer.current || isEntryPointsLayer ? (
      <EntryPointsList handleNext={handleNext} editableLayers={editableLayers} handleClick={handleClick} editType={editType} />
    ) : (
      <>
        <StyledTitle style={{padding:'8px 14px 0px 14px'}}>Which do you want to edit?</StyledTitle>
        <StyledDescription style={{padding:'0px 14px'}}>Select an element on the map or choose from the options below</StyledDescription>
        <EditableFeaturesList
          editableLayer={editableLayer}
          handleNext={handleNext}
          handleSelectFeature={handleClick}
          isEntryPoints={false}
          editableLayers={editableLayers}
          editType={editType}
        />
      </>
    )
  );
};

export default EditFeatures;
