import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useSelector} from "react-redux";

import {view} from "../../utils/API"
import FilterFieldCombo from "./FilterFieldCombo"
import {applyCurrentFilters} from "./ApplyFilters";
import { StyledContainer, StyledLi, StyledListContainer, StyledTitleDiv, StyledUl } from "./Filter-styled"
import useFilters from "../../hooks/useFilters";

/**
 * Filter widget. Uses the "filterFields" op level configuration option
 *
 * - only works if initiated when layers are already loaded
 * - at the moment enabled only for editors
 */
const Filter = ({config, t, expand, featureTableStyling = false, filterFields = null}) => {
	// const dispatch = useDispatch();
	// let filters = useSelector((store) => store.filters)
	let activeModule = useSelector((store) => store.activeModule)
	let [counter, setCounter] = useState([])
	const {filters, setFilters} = useFilters({isActive:true, config})

	/**
	 * Actually applied filters: field name is the key, the filtering value is the value
	 */

	useEffect(()=>{
		addLayers(view?.map?.layers)

		view?.map?.layers?.on("change", (event) => {
			addLayers(event.added)
		})
	}, [])

	const addLayers = (layers) => {
		layers?.forEach((layer) => {
			layer.load().then((layer) => {
				setCounter(counter++)
				layer.watch("visible", () => setCounter(counter++))
			})
		})
	}

	useEffect(() => {
		applyCurrentFilters(filters[activeModule], config)
		//initLayers()
	}, [activeModule])

	useEffect(() => {
		applyFilters()
	}, [filters])

	let timer
	const applyFilters = useCallback(() => {
		//Prevent creating multiple queries while user is still typing
		if (timer)
			return

		timer = setInterval(() => {
			clearInterval(timer)
			timer = null
			applyCurrentFilters(filters[activeModule], config)
		}, 1000)
	}, [filters])

	 /**
	 * Filter changed event, apply filters on layers
	 */
	const onFilterChange = (fieldName, value) => {
		let _filters = JSON.parse(JSON.stringify(filters))
		_filters[activeModule][fieldName] = value
		setFilters(_filters)
		applyFilters()
	}

	const getVisibleFilters = () => {
		let fields = {}
		const filterArray = config.filterFields.map(item=>item.name);
		view.map.layers.forEach((layer) => {
			layer.fields && layer.visible && filters[activeModule] && Object.keys(filters[activeModule]).forEach((filterField) => {
				layer.fields && layer.fields.filter((field) => field.name === filterField).forEach((field) => {
					if (filterArray.includes(field.name)) {
						fields[field.name] = field
					}
				})
			})
		})

		return fields
	}

	const visibleFields = getVisibleFilters();
	
	if (expand)
		expand.visible = Object.keys(Object.values(visibleFields)).length > 0

	if (!view || !visibleFields || Object.keys(Object.values(visibleFields)).length === 0)
		return null
	
	return <StyledContainer className="esri-layer-list esri-widget esri-widget--panel" featureTableStyling={featureTableStyling} >
		<StyledUl className="esri-item-list__list" featureTableStyling={featureTableStyling}>{
			Object.values(visibleFields).filter((field) => !filterFields || filterFields.includes(field.name)).map((field) =>
				<StyledLi key={field.name} className="esri-item-list__list-item" tabIndex="0" featureTableStyling={featureTableStyling}>
					<StyledListContainer className="esri-item-list__list-item-container">
						<FilterFieldCombo key={`ff_${field.name}`} field={field} t={t}
							  referenceKey={field.name} onValuesChange={onFilterChange.bind(this)}
							  defaultValue={filters[activeModule][field.name]} multi={true}/>
					</StyledListContainer>
				</StyledLi>
			)
		}</StyledUl>
	</StyledContainer>
}

export default Filter
