import {clusterRed} from "../../utils/API";
import {DragAndDropGraphics} from "./DragAndDropGraphics";
import {circle, iconNameToIcon} from "../../components/Icons/layerSvg/clusterAssets";
import {getFeatureTitle} from "../custom-popup-content";
import {getOpsColor} from "../../utils/helper";

export const getScreenSizeRatio = () => {
    if (!window) return;
    const windowWidth = window.innerWidth;
    
    if (windowWidth > 1210) {
        return 4;
    }
    
    return 5
}

export function displayTextWidth(text, font) {
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
}

export const ConopsIconAttributeMap = {
    hascoordination: "coordinationCircle",
    hasinfomgnt: "informationManagementCircle",
    hasroadtransport: "roadTransportCircle",
    hasairfixed: "airTransportCircle",
    hasairheli: "airTransportHelyCircle",
    hasrivertransport: "riverTransportCircle",
    hasseatransport: "seaTransportCircle",
    hasfuelprovision: "fuelProvisionCircle",
    hasroadrehab: "roadRehabilitationCircle",
    hasgiscapacity: "gisCapacityCircle",
    hasport: "portCircle",
    hasairport: "airportCircle",
    hasstorage: "storageCircle",
    hasstoragecontrol: "storageClimateControl",
    //TODO: these attributes don't have corresponding icons
    hastranshipmenthub: "circle",
    hasaircargoconsolidation: "airTransportHelyCircle",
    "haspreparedness": "preparednessCircle",
    hasothers: "circle"
}
/**
 * Base class for the Conops and commenting layers
 * This class creates a Graphics layer. On the graphics layer we draw SVG images for features.
 * The SVG images can be dragged and dropped
 */
export class Conops extends DragAndDropGraphics {


    createSvgSymbol = (feature) => {
        const title = getFeatureTitle(feature, this.t)
        const storageCap = feature.attributes["storagecap"]
        const storageControlCap = feature.attributes["storagecontrolcap"]
        return this.svgsToBase( title, this.getConopIconNames(feature.attributes), clusterRed, storageCap, storageControlCap)
    }


    getConopIconNames = (attributes) => {
        return Object.keys(ConopsIconAttributeMap).filter((attribute) => attributes[attribute] === 1).map((attribute) => {
            return ConopsIconAttributeMap[attribute]
        })
    }

    svgsToBase = (title, iconNames, color, storageCap, storageControlCap) => {
        const font = "34px 'Open Sans', sans-serif";
        const titleLength = displayTextWidth(title, font);
        const padding = 5, textBarHeight = 45, minWidth = titleLength > 304 ? titleLength + 8 : 308

        let inlineSVGContainer =  this.createNsElement('svg')
        inlineSVGContainer.style.backgroundColor = "white"
        inlineSVGContainer.style.color = "black"
        inlineSVGContainer.style.borderColor = getOpsColor(this.config)
        inlineSVGContainer.style.borderWidth = "3px"
        inlineSVGContainer.style.borderStyle = "solid"

        let height = padding
        let maxWidth = 0
        let cursor = [padding, padding]
        if (title){
            const textEl = this.createNsElement("text")
            textEl.setAttribute("x", "5")
            textEl.setAttribute("y", "34")
            textEl.setAttribute("style", "font: " + font + ';')
            textEl.innerHTML = title
            inlineSVGContainer.append(textEl)
            cursor[1] += textBarHeight
        }

        let lineHeight = 0, iconsOnLine = 0

        const newLine = () => {
            iconsOnLine = 0
            cursor[0] = padding
            cursor[1] += lineHeight + 3
            lineHeight = 0
        }

        const cloneIcon = (icon) => {
            let result = new DOMParser().parseFromString(icon, 'text/xml')
            let inlineSVG = result.getElementsByTagName("svg")[0]
            const viewBox = inlineSVG.getAttribute('viewBox').split(" ")

            //inlineSVGContainer.appendChild(inlineSVG.children)
            Array.from(inlineSVG.children).forEach((child) => {
                const clone = child.cloneNode(true)
                if (child.nodeType === 1)
                    clone.setAttribute("transform", `translate(${cursor[0]} ${cursor[1]})`)

                inlineSVGContainer.append(clone)
            })

            return viewBox
        }

        iconNames.forEach((iconName) => {
            if (!iconName)
                iconName = circle

            const isStorageText = iconName === ConopsIconAttributeMap.hasstorage && storageCap
            const isStorageControlText = iconName === ConopsIconAttributeMap.hasstoragecontrol && storageControlCap
            //Break line after 4 icons
            if (iconsOnLine > 3 || isStorageText || isStorageControlText)
                newLine()

            let icon = iconNameToIcon(iconName)
            if (color)
                icon = icon.replace(clusterRed, color)

            const viewBox = cloneIcon(icon)
            cursor[0] += Number(viewBox[2]) + 3
            maxWidth = Math.max(cursor[0], maxWidth)
            lineHeight = Math.max(lineHeight, Number(viewBox[3]))
            iconsOnLine++

            if (isStorageText){
                this.addStorageCapText(inlineSVGContainer, cursor, padding, lineHeight, storageCap)
                newLine()
            }

            if (isStorageControlText) {
                this.addStorageCapText(inlineSVGContainer, cursor, padding, lineHeight, storageControlCap)
                newLine()
            }
        })

        maxWidth += padding
        maxWidth = Math.max(minWidth, maxWidth)
        height += cursor[1] + lineHeight + padding

        this.setElementWidthHeight(inlineSVGContainer, maxWidth, height)
        
        const ratio = getScreenSizeRatio();

        return { type: "picture-marker",
            height: height / ratio,
            width: maxWidth / ratio,
            url: "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(inlineSVGContainer))))
        }
    }

    addStorageCapText = (inlineSVGContainer, cursor, padding, lineHeight, storageCap) => {
        const textEl = this.createNsElement("text")
        textEl.setAttribute("x", cursor[0] + padding)
        textEl.setAttribute("y", cursor[1] + lineHeight / 2 + padding  * 2)
        textEl.setAttribute("style", "font: 28px 'Open Sans', sans-serif;")
        textEl.innerHTML = ` = ${storageCap} m<tspan dy='-8'>2</tspan>`
        inlineSVGContainer.append(textEl)

    }
}
