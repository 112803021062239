import {StyledCheckListTab} from "../../Monitoring-styled";
import React, {useCallback, useEffect, useState} from "react";
import * as ProgressBar from 'progressbar.js';

const ChecklistTab = ({selected, layerUpdate, children,...props}) => {
  const [bar, setBar] = useState();
  
  useEffect(()=>{
    if (bar && layerUpdate) {
      const {features, missing} = layerUpdate;
      
      const percent = ((features.length - missing.length)) / features.length;
      bar.animate(percent);
    }
  },[layerUpdate, bar])
  
  const withNode = useCallback((e)=>{
    if (!e || bar) return;
    const progressBar = new ProgressBar.Circle(e, {
      strokeWidth: 10,
      easing: 'easeInOut',
      duration: 1400,
      color: 'green',
      trailColor: '#eee',
      svgStyle: null
    });
    
    setBar(progressBar);
  },[layerUpdate, bar])
  
  return (
    <StyledCheckListTab
      ref={withNode}
      selected={selected}
      {...props}
    >
      <div className="icon">
        {children}
      </div>
    </StyledCheckListTab>
  );
};

export default ChecklistTab;