import React, {useCallback, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {UpdateDate} from "../../../../LatestUpdates/new/LatestUpdates-styled";
import {currAsOfDateFieldName, graphicsLayer, showpublicFieldName} from "../../../../../utils/API";
import {timeAgo} from "../../../../../utils/helper";
import {FeatureHeaderWrapper} from "../EditorFields-styled";
import {EyeOffIcon, EyeOnIcon} from "../../../../Icons";
import {createSymbol, getLayerSymbology} from "../../../../../utils/symbologies";
import AttributeFields from "../AttributeFields/AttributeFields";
import useClickOutside from "../../../../../hooks/useClickOutside";
import {useFormContext} from "react-hook-form";
import EditableTitle from "./EditableTitle";
import {getFeatureIcon} from "../EditorFields";
import {UpdateType} from "../../EditorContextProvider";
import {getFeatureNameField} from "../../helpers";

const FeatureHeader = ({
  editableLayer,
  highlightFeature,
  handleChange,
  symbol,
  config,
  requiredFields,
  setHighlightSymbol,
  updateType
}) => {
  
  const {getValues} = useFormContext();
  const [openIconUpdate, setOpenIconUpdate] = useState(false);
  const {t, i18n} = useTranslation('common');
  const ref = useRef();
  
  useClickOutside(ref, ()=>{
    setOpenIconUpdate(false);
  })
  
  const publicField = useMemo(()=>{
    if (!editableLayer) return;
    return editableLayer.fields.find(f=>f.name === showpublicFieldName)
  },[editableLayer])
  
  const onIconClick = useCallback(() =>{
    let value;
    if (highlightFeature.attributes[publicField.name] === 1) {
      value = 2
    } else {
      value = 1
    }
    
    handleChange({
      name: publicField.name,
      value
    })
  },[handleChange, publicField, highlightFeature])
  
  const showPublicActive = useMemo(()=>{
    if (!highlightFeature || !publicField) return false;
    return highlightFeature.attributes[publicField.name] === 1;
  },[highlightFeature])
  
  const format = (value) => {
    return timeAgo(new Date(value), i18n.language, false)
  }
  
  const editFieldName = useMemo(()=>{
    if (editableLayer.fields.some(f=>f.name === currAsOfDateFieldName)){
      return currAsOfDateFieldName;
    }
    
    return editableLayer?.editFieldsInfo?.editDateField;
  },[editableLayer])
  
  const symbology = useMemo(()=> getLayerSymbology(editableLayer, config) || {},[editableLayer]);
  
  const iconFields = useMemo(()=>{
    if (symbology.iconMap){
      return editableLayer.fields.filter(field=> symbology.iconMap.fields.includes(field.name))
    }
    
    return []
  },[editableLayer, symbology]);
  
  const handleIconChange = useCallback(()=>{
    const iconMapFields = symbology.iconMap.fields;
    const colorMapFields = symbology.colorMap.fields;
    const iconMapValues = getValues(iconMapFields);
    const colorMapValues = getValues(colorMapFields);
    
    const cloned = highlightFeature.clone();
    let color;
    cloned.layer = editableLayer;
    
    iconMapFields.forEach((fname,index)=>{
      if (iconMapValues[index] !== undefined) {
        cloned.attributes[fname] = iconMapValues[index];
      }
    })
    
    colorMapFields.forEach((fname, index)=>{
      if (colorMapValues[index] !== undefined){
        cloned.attributes[fname] = colorMapValues[index];
        
      }
      color = symbology.colorMap.default[cloned.attributes[fname]];
    })
    
    const iconName = getFeatureIcon(cloned, symbology);
    const newSymbol = createSymbol(symbology, editableLayer, iconName, color ?? symbology.defaultColor, symbology.defaultSize);
    setHighlightSymbol(newSymbol);
    graphicsLayer.graphics.forEach(g=>{
      g.symbol = newSymbol;
    });
  },[handleChange, iconFields, getValues, symbology, highlightFeature, symbol])
  
  const showIconChangeOptions = useMemo(()=> iconFields.length > 0 && updateType !== UpdateType.situational,[iconFields, updateType])
  const nameField = useMemo(()=>getFeatureNameField(editableLayer),[editableLayer]);
  return (
    <div ref={ref}>
      <FeatureHeaderWrapper active={showPublicActive}>
        <div className="content__box">
          <div style={{display:'flex', gap:4}}>
            {nameField && <EditableTitle
              showPublicActive={showPublicActive}
              highlightFeature={highlightFeature}
              t={t}
              editableLayer={editableLayer}
              handleChange={handleChange}
              nameField={nameField}
            />}
            {
              publicField && (
                <div style={{
                  width: 16,
                  height:16,
                  display:'flex'
                }}>
              
                  {highlightFeature.attributes[showpublicFieldName] === 1 ? <EyeOnIcon onClick={onIconClick} style={{cursor:'pointer'}} title={t('layer.fieldAlias.' + publicField.name, publicField.alias)} /> : <EyeOffIcon fill="#4c4c4c4d" onClick={onIconClick} style={{cursor:'pointer'}} title={t('layer.fieldAlias.' + publicField.name, publicField.alias)} />}
                </div>
              )
            }
          </div>
      
          {(editFieldName && highlightFeature.attributes[editFieldName]) && <UpdateDate>
            {format(highlightFeature.attributes[editFieldName])}
          </UpdateDate>}
        </div>
        <div className="icon__box" style={{cursor: showIconChangeOptions ? "pointer" : "default"}} onClick={()=>setOpenIconUpdate(prev=>!prev)}>
          {symbol?.url && <img
            alt="feature"
            src={symbol?.url}
          />}
        </div>
      </FeatureHeaderWrapper>
      {(showIconChangeOptions) && (
        <div style={{
          display: "grid",
          gridTemplateRows: openIconUpdate ? "1fr" : "0fr",
          transition: "all 0.5s",
          marginLeft: 4
        }}>
          <div style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            padding: openIconUpdate ? "10px 10px" : "0px 10px",
            transition: "all 0.5s",
            gap: 14
          }}>
            <AttributeFields
              fields={iconFields}
              highlightFeature={highlightFeature}
              config={config}
              t={t}
              symbology={symbology}
              onChangeHandler={handleIconChange}
              editableLayer={editableLayer}
              requiredFields={requiredFields}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureHeader;