import {setMobileActiveList_actionType, setMobileApp_actionType} from "../constants"

export const MOBILE_LISTS = Object.freeze({
  'LATEST_UPDATES': 'LATEST_UPDATES',
  'LAYERS': 'LAYERS'
})

const initialState = {
  isMobileApp: false,
  activeList: MOBILE_LISTS.LAYERS
};
const mobileReducer = (state = initialState, action) => {
  switch (action.type) {
    case setMobileApp_actionType:{
      const { payload } = action
      return {
        ...state,
        isMobileApp: payload
      }
    }
    case setMobileActiveList_actionType:{
      const {payload} = action;
      return {
        ...state,
        activeList: payload
      }
    }
  }
  return state
}

export default mobileReducer;
