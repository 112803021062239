import React, {useCallback, useContext, useEffect, useRef} from "react";
import {EditType,  pointSymbol} from "../EditorSwiper/EditorSwiper";
import {graphicsLayer, view} from "../../../../utils/API";
import {EditorContext, UpdateType} from "../EditorContextProvider";
import FeatureHeader from "./FeatureHeader/FeatureHeader";
import BatchFieldsHeader from "./BatchFieldsHeader";
import EditorSituationalFields from "./EditorSituationalFields";
import EditorDefaultFields from "./EditorDefaultFields";

export const filledInColor = '#16a34a';

const featureConformsDef = (feature, fields, value) => {
  const values = value.split(",")
  if (fields.length !== values.length)
    return false
  
  return values.filter((value, idx) => {
    if (value === "*")
      return true
    
    const fieldName = fields[idx].name
    const fieldValue = feature.attributes[fieldName]
    
    const range = value.split("-")
    if (range.length === 2) {
      //Range specified
      return typeof fieldValue === 'number' && fieldValue >= range[0] && fieldValue < range[1]
    } else
      //Single value specified
      return typeof fieldValue === 'number' ? fieldValue === Number(value) : fieldValue === value
    
  }).length === values.length
}

export const getFeatureIcon = (feature, symbology) =>{
  let iconName = symbology.defaultIcon;
  
  if (symbology?.iconMap){
    const values = [];
    symbology.iconMap.fields.map((fieldName)=>{
      const value = feature.attributes[fieldName];
      values.push(value || '*');
    })
    
    iconName = symbology?.iconMap.default[values.join(',')];
    if (!iconName){
      const iconDef = feature.layer.renderer.getIconDefinitions().find((iconDef) => featureConformsDef(feature, iconDef.field, iconDef.value));
      
      return iconDef?.icon || symbology.defaultIcon
    }
  }
  
  return iconName;
}

//remove
export const getFeatureNameField = (editableLayer) => {
  const titleTemplate = editableLayer?.layerConfig?.titleTemplate;
  const name = titleTemplate?.substring(1, titleTemplate.length-1)?.replace('feature.', '');
  if (name){
    return name;
  }
  
  return null;
}

const EditorFields = ({
  editableLayer,
  config,
  editType,
  isRequired
}) => {
  const dragHandle = useRef();
  const ref = useRef();
  
  const {
    highlightSymbol,
    highlightFeature,
    setHighlightFeature,
    setAddedPoints,
    popupFeature,
    updateType,
    batchUpdateFeatures,
    activeColor,
    setHighlightSymbol,
    situationalUpdateType,
    setSituationalUpdateType,
    requiredFields,
  } = useContext(EditorContext);
  
  useEffect(()=>{
    let timer;
    if (!highlightFeature || editableLayer?.geometryType !== 'point') return;
    dragHandle.current?.remove();
    dragHandle.current = view.on("drag", async (event) => {
      if (event.action === "start"){
        //Drag starting, search for graphics around the mouse
      } else if (event.action === "update" || event.action === "end") {
        //Dragging: adjust position
        highlightFeature.geometry = view.toMap({x: event.x, y: event.y})
        const nGraphic = highlightFeature?.clone()
        nGraphic.symbol = highlightSymbol ? highlightSymbol : pointSymbol
  
        graphicsLayer.removeAll()
        graphicsLayer.add(nGraphic)
        clearTimeout(timer);
        timer = setTimeout(()=>{
          setAddedPoints([nGraphic.geometry]);
          setHighlightFeature(nGraphic);
        },250)
        
        event.stopPropagation()
      }
    })
    return ()=>{
      clearTimeout(timer);
      dragHandle.current?.remove();
    }
  },[editableLayer, highlightSymbol, highlightFeature])
  
  const handleChange = useCallback(({name, value}) =>{
    if (highlightFeature){
      const clonedFeature = highlightFeature.clone();
      clonedFeature.attributes[name] = value;
      setHighlightFeature(clonedFeature);
    }
  },[highlightFeature]);
  
  return (
    <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
      {
        (editableLayer.geometryType === 'point' && highlightFeature) && (
          <FeatureHeader
            editableLayer={editableLayer}
            highlightFeature={highlightFeature}
            symbol={highlightSymbol}
            handleChange={handleChange}
            config={config}
            requiredFields={requiredFields}
            setHighlightSymbol={setHighlightSymbol}
            updateType={updateType}
          />
        )
      }
  
      {
        !highlightFeature && batchUpdateFeatures.length > 0 && (
          <BatchFieldsHeader
            editableLayer={editableLayer}
            features={batchUpdateFeatures}
            config={config}
            activeColor={activeColor}
          />
        )
      }
      
      <form style={{overflow:'hidden', flex:1, display:'flex', flexDirection:'column'}} ref={ref}>
        {
          updateType === UpdateType.situational && editType !== EditType.create ? ( //(editType === EditType.create && editableLayer.layerConfig.titleLabel === 'roadsSituational' && updateType === UpdateType.situational)
            <EditorSituationalFields
              editableLayer={editableLayer}
              requiredFields={requiredFields}
              highlightFeature={highlightFeature}
              activeColor={activeColor}
              batchUpdateFeatures={batchUpdateFeatures}
              situationalUpdateType={setSituationalUpdateType}
              setSituationalUpdateType={setSituationalUpdateType}
            />
          ) : (
            <EditorDefaultFields
              requiredFields={requiredFields}
              editableLayer={editableLayer}
              isRequired={isRequired}
              editType={editType}
              handleChange={handleChange}
            />
          )
        }
      </form>
    </div>
  );
};

export default EditorFields;