import {EntryPointsTabs, EntryPointsTab} from "./EntryPointsList-styled";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {getLayerSymbology} from "../../../../utils/symbologies";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {EntryPoints} from "../Platform/Platform";
import {LayerIconWrapper} from "../../../ManualWidgets/ManualWidget-styled";
import {iconNameToIcon} from "../../../Icons/layerSvg/clusterAssets";
import EditableFeaturesList from "../CoordinatesSelection/EditableFeaturesList";
import {EditorContext} from "../EditorContextProvider";
import {addLayerEffect} from "../helpers";
import useInView from "../../../../hooks/useInView";

const EntryPointsList = ({editableLayers, handleNext, handleClick, editType}) => {
  const [selectedLayerIndex, setSelectedLayerIndex] = useState(0);
  const {config} = useContext(ConfigContext);
  const {setEditableLayer, editableLayer, resetBatchUpdateFeatures, activeColor} = useContext(EditorContext);
  
  const entryPointsLayers = useMemo(()=>(
    editableLayers.filter(l=> EntryPoints.includes(l?.layerConfig.titleLabel)).toArray()
  ),[editableLayers])
  
  const selectedLayer = useMemo(()=> entryPointsLayers[selectedLayerIndex],[entryPointsLayers, selectedLayerIndex])
  
  const handleTabSelection = useCallback((index)=>{
    setSelectedLayerIndex(index);
    resetBatchUpdateFeatures();
  },[resetBatchUpdateFeatures])
  
  useEffect(()=>{
    setEditableLayer(selectedLayer);
    addLayerEffect([selectedLayer])
  },[selectedLayer])
  
  return (
    <div>
      <EntryPointsTabs>
        {
          entryPointsLayers.map((layer, index)=>{
            const symbol = getLayerSymbology(layer, config)
            const icon = iconNameToIcon(symbol.defaultIcon);
            
            return <EntryPointsTab
              key={layer.id}
              selected={selectedLayerIndex === index}
              color={activeColor}
              onClick={()=>handleTabSelection(index)}
            >
              <LayerIconWrapper
                width={50}
                height={50}
                fill={activeColor}
                dangerouslySetInnerHTML={{__html:icon}}
              />
            </EntryPointsTab>
          })
        }
      </EntryPointsTabs>
      <div>
        {editableLayer && (
          <EditableFeaturesList
            editableLayer={editableLayer}
            handleSelectFeature={handleClick}
            handleNext={handleNext}
            editableLayers={editableLayers}
            editType={editType}
            isEntryPoints
          />
        )}
      </div>
    </div>
  );
};

export default EntryPointsList;