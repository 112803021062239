import React, {lazy, Suspense, useCallback, useEffect, useMemo, useState} from "react";
import {EMBED_VARIANTS, getOperationPreferences, getOpsColor} from "../../../utils/helper";
import {isWidgetDisplayed} from "../../../esri/widgets/expandUtils";
import {
  HeaderIcon,
  StyledDesktopHeader,
  StyledTitleWrapper,
  StyledWidgetsWrapper,
} from "../Header-styled";
import {LanguageDropdown} from "../components";
import HelpDropdown from "../components/HelpDropdown/HelpDropdown";
import {
  LogIEIconSmallColored,
  LogisticsClusterIcon,
  LogisticsClusterSmallIcon,
  WFPIcon
} from "../../Icons/Header-Icons";
import Modules from "../../Modules";
import cdema_logo from "../../Icons/img/cdema_logo.png";
import bngrc from "../../Icons/img/bngrc.jpg";
import useOnboarding from "../../../hooks/useOnboarding";
import ModuleIcon from "../../Modules/ModuleIcon";
import WidgetButton from "../components/WidgetButton";
import {view} from "../../../utils/API";
import SearchWidget from "../../SearchWidget/SearchWidget";
import PrintButton from "../../PrintMask/PrintButton/PrintButton";
import {useSelector} from "react-redux";
import ReportButton from "../../Report/new/ReportButton.js/ReportButton";
import {createPortal} from "react-dom";

const Filters = lazy(() => import('../../Widgets/Filters'));

const getHeaderIcon = (config) => {
  switch (config.header.icon) {
    case "wfp":
      return <WFPIcon />
    case "cdema":
      return <div style={{
        display:'flex',
        alignItems:'center',
        gap:2
      }}>
        <WFPIcon />
        <img src={cdema_logo} alt={"cdema_logo"} width={72} height={72} />
      </div>
    case "bngrc":
      return <img src={bngrc} alt={"Bngrc"} height={50} width={50} />
    default:
      return <a href="https://logcluster.org/en" target="_blank" style={{display:'flex'}}>
        <LogisticsClusterIcon key="lci"/>
        <LogisticsClusterSmallIcon key="lcsi"/>
      </a>
  }
}

const DesktopHeader = ({config, t, setActiveLanguage}) => {
  const {printWidget, activeModule} = useSelector(state=>state);
  const [showMenu, setMenuVisible] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  const [show, setShow] = useState(false);
  const {onBoardingTour} = useOnboarding();
  
  useEffect(()=>{
    const languageConfig = JSON.parse(localStorage.getItem('languageConfig'));
    if (languageConfig){
      const {showModules} = languageConfig;
      setShow(showModules);
      localStorage.removeItem('languageConfig');
    }
  },[])
  
  useEffect(()=>{
    if (!config) return;
    const {allPreferences: preferences, operationPreferences} = getOperationPreferences(config.id);
    
    if (!operationPreferences.activeModule && (Array.isArray(config.activeModules) && config.activeModules.length > 1) && config.embed !== EMBED_VARIANTS.WEBSITE){
      const searchParams = new URLSearchParams(window.location.search);
      const urlModule = searchParams.get('module')
      const dataCollection = searchParams.get('datacollection')
      if (urlModule || dataCollection) return;
      
      setShow(true);
    }
  },[config])
  
  const title = t("ops." + config.id + ".title", config.title) || '';
  
  const handleShowModules = useCallback(()=>{
    setShow(true);
  },[])
  
  const hasSearch = useMemo(()=>{
    return isWidgetDisplayed(config, 'Search')
  },[config])
  
  const hasPrint = useMemo(()=>{
    return isWidgetDisplayed(config, 'Print')
  },[config])
  
  const hasReport = useMemo(()=>{
    return isWidgetDisplayed(config, 'Report')
  },[config])
  
  const activeModuleIcons = useMemo(()=>{
    if (config?.modules && activeModule){
      return config.modules[activeModule]?.icons || []
    }
    return []
  },[config, activeModule])
  
  const handleStartTour = ()=>{
    if (!onBoardingTour) return;
    onBoardingTour.start()
  }
  
  const handleSearchExpand = useCallback(()=>{
    setExpandSearch(true);
    setTimeout(()=>{
      const searchBox = document.querySelector('.search__box');
      if (searchBox){
        const input = searchBox.querySelector('input');
    
        if (input){
          setTimeout(()=>{
            input.focus();
          },100)
        }
      }
    },100)
  },[])
  
  const onFiltersOpen = useCallback((open)=>{
    setShowFilters(open);
  },[])
  
  const opsColor = useMemo(()=> getOpsColor(config, activeModule),[config, activeModule])
  
  return (
    <>
      {
        createPortal(
          <Modules show={show} setShow={setShow} config={config} isHeaderShown={false} onBoardingTour={onBoardingTour} />,
          document.querySelector('#portal'),
        )
      }
      <StyledDesktopHeader
        style={{
          display: printWidget.open && !printWidget.isLoading ? 'none' : ''
        }}
      >
        <StyledTitleWrapper>
          <a href="/">
            <LogIEIconSmallColored />
          </a>
          <span>{title}</span>
          {!!activeModule && (
            <ModuleIcon
              className="onboarding-modules"
              onClick={handleShowModules}
              style={{padding: 8}}
              opsColor={opsColor}
              module={activeModule}
              showTitle
            />
          )}
        </StyledTitleWrapper>
        <StyledWidgetsWrapper className="top-widgets-wrapper">
          {
            !expandSearch && (
              <>
                {
                 !showFilters && (
                    <>
                      {hasReport && <ReportButton config={config} t={t}/>}
                      {hasPrint && <PrintButton/>}
                    </>
                  )
                }
                <Suspense>
                  <Filters onFiltersOpen={onFiltersOpen} isMobile={false} className="onboarding-filter" />
                </Suspense>
              </>
            )
          }
          {(hasSearch && view) && <div style={{position:'relative'}} className="onboarding-search">
            <WidgetButton title={t('screen.widget.Search.name')} onClick={handleSearchExpand} type="SearchWidget"/>
            <div style={{
              width: expandSearch ? 270 : 0,
              transition: "all 0.3s",
              position:'absolute',
              top: -7,
              right: 4,
              height: 54,
              background: '#fff',
              borderRadius: 8,
              display:'flex',
              alignItems:'center',
              opacity: expandSearch ? 1  :0,
              pointerEvents: expandSearch ? 'auto' : 'none',
            }}>
              {(view && expandSearch) && (
                <SearchWidget
                  config={config} t={t}
                  view={view}
                  isMobileApp={true}
                  onBackClick={() => setExpandSearch(false)}
                  rotate="rotate(180deg)"
                  isExpanded={expandSearch}
                />
              )}
            </div>
          </div>}
          <HelpDropdown onTourStart={handleStartTour} config={config} setMenuVisible={setMenuVisible}/>
          <LanguageDropdown className="onboarding-language" setActiveLanguage={setActiveLanguage} config={config} />
          {
            activeModuleIcons.map(icon=>(
              <HeaderIcon key={icon.icon}>
                <a href={icon.url} target="_blank" style={{display: "flex"}}>
                  <img style={{width: "100%"}} src={process.env.PUBLIC_URL + `/assets/moduleIcons/${icon.icon}`} alt="module-icon"/>
                </a>
              </HeaderIcon>
            ))
          }
          <HeaderIcon>
            {getHeaderIcon(config)}
          </HeaderIcon>
        </StyledWidgetsWrapper>
      </StyledDesktopHeader>
    </>
  );
};

export default DesktopHeader;
