import {
  setAnimateFeatureTable_actionType,
  setEditableFeature_actionType,
  setEditableLayer_actionType, setEditorAddPointActive_actionType,
  setEditorDrawActive_actionType
} from "../constants";

export const setAnimateFeatureTable = (payload) => ({
  type: setAnimateFeatureTable_actionType,
  payload
})

export const setEditableFeature = (payload) => ({
  type: setEditableFeature_actionType,
  payload
})

export const setEditableLayer = (payload) => ({
  type: setEditableLayer_actionType,
  payload
})

export const setEditorDrawActive = (payload)=>({
  type: setEditorDrawActive_actionType,
  payload
})

export const setEditorAddPointActive = (payload)=>({
  type: setEditorAddPointActive_actionType,
  payload
})