import {Swiper, SwiperSlide} from "swiper/react";
import MonitoringDashboard from "../MonitoringDashboard/MonitoringDashboard";
import MonitoringChecklist from "../MonitoringChecklist/MonitoringChecklist";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import ProgressBar from "../../../Report/new/ProgressBar/ProgressBar";
import {StyledEditorBreadcrumbs} from "../../Editor/Editor-styled";
import {ConfigContext} from "../../../../utils/ConfigContext";
import MonitoringBatchUpdate from "../MonitoringBatchUpdate/MonitoringBatchUpdate";
import {removeLayerEffects} from "../../Editor/helpers";
import {EditorContext} from "../../Editor/EditorContextProvider";
import Message from "../../Editor/Message/Message";

const MonitoringSlides = ({layers, goBack, expandToWidth}) => {
  const [layerUpdates, setLayerUpdates] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [editedFeatures, setEditedFeatures] = useState({});
  const [showCloseMessage, setShowCloseMessage] = useState(false);
  const {resetEditor, batchUpdateFeatures} = useContext(EditorContext);
  const {config} = useContext(ConfigContext);
  const swiperRef = useRef();
  
  useEffect(()=>{
    return () => {
      resetEditor();
      removeLayerEffects();
      expandToWidth(320);
    }
  },[])
  
  const handleNext = useCallback(()=>{
    swiperRef.current.swiper.slideNext();
  },[]);
  
  const handlePrev = useCallback(()=>{
    swiperRef.current.swiper.slidePrev();
  },[]);
  
  const handleClose = useCallback(() => {
    if (Object.values(editedFeatures).length > 0 || batchUpdateFeatures.length > 0){
      setShowCloseMessage(true);
    } else {
      goBack();
    }
  },[editedFeatures, batchUpdateFeatures, goBack])
  
  return (
    <div style={{
      display:'flex',
      flexDirection:'column',
      height:'100%'
    }}>
      {
        showCloseMessage && (
          <Message
            title="Are you sure you want to stop editing? Unsaved progress will be lost."
            onSubmit={()=>{
              goBack();
            }}
            onCancel={()=>{
              setShowCloseMessage(false);
            }}
          />
        )
      }
      <StyledEditorBreadcrumbs
        style={{
          marginBottom:0,
          minHeight:54
        }}
        hasProgress>
        <ProgressBar color={config.opsColor} currentPosition={1} total={2} />
        <p>{activeIndex === 0 ? 'Monitoring Dashboard' : 'Update Checklist'}</p>
        <button
          onClick={handleClose}>
          <svg fillRule="evenodd" role="img" viewBox="0 0 10 10" aria-label="description"><path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path></svg>
        </button>
      </StyledEditorBreadcrumbs>
      <div style={{flex:1}}>
        <Swiper
          ref={swiperRef}
          preventInteractionOnTransition
          noSwiping
          allowTouchMove={false}
          preventClicks={false}
          simulateTouch={false}
          preventClicksPropagation={false}
          watchSlidesProgress
          onSlideChange={(swiper)=>{
            setActiveIndex(swiper.activeIndex);
            if (swiper.activeIndex === 0) {
              removeLayerEffects();
              resetEditor();
            }
          
          
            if (swiper.activeIndex === 1) {
              expandToWidth(window.innerWidth / 2);
            } else {
              expandToWidth(320);
            }
          }}
          style={{height:'100%'}}
        >
          <SwiperSlide>
            {({ isVisible }) => (
              isVisible && (
                <MonitoringDashboard
                  handleNext={handleNext}
                  layers={layers}
                  layerUpdates={layerUpdates}
                  setLayerUpdates={setLayerUpdates}
                />
              )
            )}
          </SwiperSlide>
          <SwiperSlide>
            {({ isVisible }) => (
              isVisible && (
                <MonitoringChecklist
                  layers={layers}
                  handleNext={handleNext}
                  layerUpdates={layerUpdates}
                  setLayerUpdates={setLayerUpdates}
                  handlePrev={handlePrev}
                  editedFeatures={editedFeatures}
                  setEditedFeatures={setEditedFeatures}
                />
              )
            )}
          </SwiperSlide>
          <SwiperSlide>
            {({ isVisible }) => (
              isVisible && (
                <MonitoringBatchUpdate
                  handlePrev={handlePrev}
                />
              )
            )}
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default MonitoringSlides;
