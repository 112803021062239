import SketchCoordinates from "./SketchCoordinates";
import {EditType} from "../EditorSwiper/EditorSwiper";
import {useEffect} from "react";

export const selectionLineSymbol = {
  type: "simple-line",
  color: [255, 240, 0, 1],
  width: 5
}

const RoadsCoordinates = ({editableLayer, clickActive, editType, handleNext}) => {
  if (editType === EditType.edit && editableLayer.layerConfig.titleLabel === 'roadsSituational') return null;
  
  return (<SketchCoordinates editableLayer={editableLayer} clickActive={clickActive} editType={editType} handleNext={handleNext} />);
};

export default RoadsCoordinates;