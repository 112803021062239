import styled from "styled-components";

export const TableGridWrapper = styled.div`
  width: 100%;
  height: 100%;
  --ag-font-family: 'Open Sans', sans-serif;

  .ag-body-horizontal-scroll-container, .ag-body-horizontal-scroll, .ag-body-horizontal-scroll.ag-apple-scrollbar {
    height: 8px !important;
    max-height: 8px !important;
    min-height: 8px !important;
  }

  .ag-body-horizontal-scroll-viewport {
    height: 8px !important;
    max-height: 8px !important;
    min-height: 8px !important;
    
    ::-webkit-scrollbar-thumb{
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 2px #FFFFFF, 0 0 2px #FFFFFF;
      border-radius: 2px;
      width: 4px;
    }

    ::-webkit-scrollbar {
      height: 8px;
    }
  }
`
