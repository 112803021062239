import React, {useCallback} from "react";
import {getIconValueDefinitions, getLayerSymbology} from "../../../../../utils/symbologies";
import {svgToBase} from "../../../../Icons/layerSvg/clusterAssets";
import {StyledList, StyledListItem} from "../../Editor-styled";
import {LayerIconWrapper} from "../../../../ManualWidgets/ManualWidget-styled";

const Annotations = ({layer, config, t, handleSelection}) => {
  const renderFields = useCallback(()=>{
    const symbology = getLayerSymbology(layer, config);
    
    const iconValueDefinition = getIconValueDefinitions(symbology, layer, 1, t);
    
    return iconValueDefinition.map(item=>({
      icon: svgToBase(item.icon, symbology.defaultColor),
      iconName: item.icon,
      label: item.label,
      fieldName: "type_ann"
    }));
  },[layer, config, t])
  
  return (
    <StyledList>
      {renderFields().map(item=>(
        <StyledListItem
          key={item.label}
          onClick={()=>{
            handleSelection(item)
          }}
        >
          <div style={{
            width:'50px',
            height:'50px',
          }}>
            <img
              alt={item.label}
              style={{
                width:'100%',
                height:'100%',
                objectFit:'contain'
              }}
              src={item.icon} />
          </div>
          <span>{item.label}</span>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export default Annotations;