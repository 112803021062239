import styled from "styled-components";

export const PlatformBody = styled.div`
  box-sizing: border-box;
  text-align: left;
  padding: 4px 4px;
`

export const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
`

export const PlatformSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px 4px;
`

export const PlatformIconHolder = styled.div`
  width: 40px;
  height: 40px;
  background: ${({color})=> color ?? '#C03A2B'};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
`

export const PlatformSectionItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  border: 1px solid #EDE9E9;
  border-radius: 8px;
  padding: 12px 10px;
  box-sizing: border-box;
  cursor: pointer;
`

export const PlatformSectionItemBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  overflow: hidden;
  
  .title {
    font-size: 14px;
    font-weight: 500;
  }
  
  .subtitle {
    font-size: 12px;
    color: #3f3f3f;
  }
`