import React, {useCallback, useState} from "react";
import {StyledFilterHeader, StyledFilterSection} from "../MobileFilter-styled";
import {ArrowDownIcon} from "../../../Icons";
const FilterSection = ({title, children, appliedFilters, layerTitle}) => {
  const [expand, setExpand] = useState(true);
  
  const toggle = useCallback(()=>{
    setExpand(prev=>!prev);
  },[])
  
  return (
    <StyledFilterSection>
      <StyledFilterHeader>
          <span>
            {title}
            {layerTitle && <span className="filters-length">{layerTitle}</span>}
          </span>
        {/*<ArrowDownIcon*/}
        {/*  width="16px"*/}
        {/*  height="16px"*/}
        {/*  style={{*/}
        {/*    transform: expand ? 'rotate(360deg)' : 'rotate(180deg)',*/}
        {/*    transition: 'all 0.3s'*/}
        {/*  }}*/}
        {/*/>*/}
      </StyledFilterHeader>
      <div style={{
        display: "grid",
        gridTemplateRows: expand ? '1fr' : '0fr',
        transition: "all 0.5s",
        gap: 4
      }}>
        <div>
          {children}
        </div>
      </div>
    </StyledFilterSection>
  );
};

export default FilterSection;
