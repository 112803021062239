import {StyledStatusBar} from "../../Monitoring-styled";
import {useCallback, useEffect, useState} from "react";
import * as ProgressBar from 'progressbar.js';

const StatusBar = ({value, color, title}) => {
  const [bar, setBars] = useState(null);
  
  useEffect(()=>{
    if (bar && value){
      bar.animate(value)
    }
  },[value, bar])
  
  const withNode = useCallback(el=>{
    if (!el || bar) return;
    
    const progressBar = new ProgressBar.Line(el, {
      strokeWidth: 2,
      easing: 'easeInOut',
      duration: 1400,
      color: color,
      trailColor: '#eee',
      svgStyle: {width: '100%', height: '100%'},
    });
    
    progressBar.set(value);
    setBars(progressBar);
  },[bar, value, color])
  
  return (
    <StyledStatusBar ref={withNode}>
      <span>{title}</span>
    </StyledStatusBar>
  );
};

export default StatusBar;