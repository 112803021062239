import styled from "styled-components";

export const StyledProgressBarContainer = styled.div`
    align-items: center;
    border-top-left-radius: ${({hasTopRadius = true})=> hasTopRadius ? '10px' : undefined};
    border-top-right-radius: ${({hasTopRadius = true})=> hasTopRadius ? '10px' : undefined};
    background: #a8b1b7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const StyledProgressBar = styled.div`
  width: ${({progress}) => `${progress}%`};
  background-color: ${({bgcolor})=>bgcolor};
  transition: all 0.6s;
  height: inherit;
  position: relative;
  z-index: 1;
`

export const ProgressLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #FFF;
  background-image: ${({color})=> `linear-gradient(120deg, ${color}40 25%, transparent 25%, transparent 50%, ${color}40 50%, ${color}40 75%, transparent 75%, transparent)`};
  font-size: 40px;
  background-size: 1em 1em;
  box-sizing: border-box;
  animation: barStripe 1s linear infinite;
  transform: rotate(180deg);
  border: 1px solid ${({color})=>color ?? '#ffffff40'};
  border-radius: 100px;

  @keyframes barStripe {
    0% {
      background-position: 1em 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`
