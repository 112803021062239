import {
  setAnimateFeatureTable_actionType,
  setEditableFeature_actionType,
  setEditableLayer_actionType, setEditorAddPointActive_actionType,
  setEditorDrawActive_actionType
} from "../constants";

const initialState = {
  animateFeatureTable: false,
  editableFeature: null,
  editableLayer: null,
  editorDrawActive: false,
  editorAddPointActive: false
}
const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case setAnimateFeatureTable_actionType:{
      const { payload } = action
      return {
        ...state,
        animateFeatureTable: payload
      }
    }
    case setEditableFeature_actionType:{
      const { payload } = action
      return {
        ...state,
        editableFeature: payload
      }
    }
    case setEditableLayer_actionType:{
      const { payload } = action
      return {
        ...state,
        editableLayer: payload
      }
    }
    case setEditorDrawActive_actionType:{
      const { payload } = action
      return {
        ...state,
        editorDrawActive: payload
      }
    }
    case setEditorAddPointActive_actionType:{
      const { payload } = action
      return {
        ...state,
        editorAddPointActive: payload
      }
    }
    default:
      return state
  }
}

export default dashboard
