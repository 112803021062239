import styled from "styled-components";
import {mutedColor} from "../../../../utils/Theme";
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  margin: ${({margin, noMargin})=> noMargin ? '10px 0px 0px 0px' : margin ?? '0 0 20px 0'};
  
  & input {
    box-sizing:border-box;
    display: block;
    padding: 6px 8px;
    cursor: auto;
    color: #000000;
    border: ${({borderColor, isFocused = false}) => `1px solid ${borderColor ?? '#C0392B'}${isFocused ? '' : '4D'}`};
    outline: 0;
    font-size: 16px !important;
    line-height: 2em;
    text-overflow: ellipsis;
    transition: all 0.5s;
    border-radius: 6px;
  }
  
  @media screen and (min-width: 769px){
    font-size: 14px;
  }

  & input::placeholder {
    color: #cbcbcb;
    opacity: ${({label})=>label ? 0 : 1};
    transition: all 0.7s;
  }

  & input:focus {
    border: ${({borderColor}) => `1px solid ${borderColor ?? '#C0392B'}`};;

    ::placeholder {
      opacity: 1;
    }
  }

  input:not(:placeholder-shown) + label {
    top: -9px;
    font-size: 10px;
  }

  & input:focus + label {
    top: -9px;
    font-size: 10px;
    //overflow: visible;
    //white-space: normal;
    max-width: calc(100% - 8px);
  }

`
export const InputLabel = styled.label`
  font-family: 'Open Sans', sans-serif !important;
  padding: 0px 4px;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.5em;
  color: ${mutedColor};
	text-align: left;
  border-radius: 4px;
  pointer-events: none;
  transition: all 0.5s;
  position: absolute;
  top: 10px;
  left: 8px;
  background: #FFFFFF;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: calc(100% - 18px);
`

export const WarningMessage = styled.span`
  position: absolute;
  bottom: -22px;
  font-size: 10px !important;
  color: #b21b0c;
  opacity: ${({isError})=> isError ? 1 : 0};
  transform: ${({isError})=> isError ? 'translateY(0px)' : 'translateY(5px)'};
  transition: all 0.5s;
  width: 100%;
  text-align: end;
`

export const DescriptionWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 0;
  transform: translateY(-50%);
  background: white;
  display: flex;
  cursor: pointer;
`
