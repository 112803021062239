import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {StyledButton, StyledDescription} from "../Editor-styled";
import Input from "../../../Report/new/Input/Input";
import CoordinateNotation from "./CorrdinateNotation";
import {EditorContext} from "../EditorContextProvider";
import {EditType} from "../EditorSwiper/EditorSwiper";

export function toDegreesMinutesAndSeconds(dd, type) {
  const d = Math.floor(dd);
  const m = Math.floor((dd - d) * 60);
  
  if (type === 'ddm') {
    return d + " " + m;
  }
  
  if (type === 'dms') {
    const s = (dd - d - (m/ 60)) * 3600;
    
    return d + " " + m + " " + s;
  }
  return '';
}

export function toDD(coordinateString) {
  const array = coordinateString.split(' ');
  console.log(array)
  let res = 0;
  if (array.length === 2) {
    const d = array[0];
    const m = array[1]
    
    res = Number(d) + (Number(m) / 60)
  }
  
  if (array.length === 3) {
    const d = array[0];
    const m = array[1]
    const s = array[2]
    
    res = Number(d) + (Number(m) / 60) + (Number(s) / 3600)
  }
  return res;
}

const CoordinatesInputs = ({showTitle, addPointToNewFeature, editType, showAll = false, geometry, color, t}) => {
  const [showOther, setShowOther] = useState(showAll);
  const [coordinates, setCoordinates] = useState({
    lat: '',
    lon: ''
  })
  
  const [ddm, setDdm] = useState({
    lat: {
      degree:'',
      minutes:'',
    },
    lon: {
      degree:'',
      minutes:'',
    }
  });
  
  const [dms, setDms] = useState({
    lat: {
      degree:'',
      minutes:'',
      seconds:''
    },
    lon: {
      degree:'',
      minutes:'',
      seconds:''
    }
  });
  const [updateType, setUpdateType] = useState('');
  const [directions, setDirections] = useState(['N', 'E']);
  
  useEffect(()=>{
    const isEmpty = () => {
      return Object.values(coordinates).map(item=>item.length === 0).length > 0;
    }
    
    if (geometry){
      updateCoordinates(geometry, updateType)
    } else {
      setCoordinates({
        lat: '',
        lon: ''
      })
      setDdm({
        lat: {
          degree:'',
          minutes:'',
        },
        lon: {
          degree:'',
          minutes:'',
        }
      })
      setDms({
        lat: {
          degree:'',
          minutes:'',
          seconds:''
        },
        lon: {
          degree:'',
          minutes:'',
          seconds:''
        }
      })
      setUpdateType('');
    }
  },[geometry, editType])
  
  const updateCoordinates = (coordinates, type) =>{
    if (type !== 'coordinates') {
      setCoordinates({
        lat: coordinates.latitude,
        lon: coordinates.longitude
      })
    }
    
    if (type !== 'directions') {
      const vertical = coordinates.latitude >= 0 ? 'N' : 'S';
      const horizontal = coordinates.longitude >= 0 ? 'E' : 'W'
      setDirections([vertical, horizontal])
    }
    
    if (type !== 'dms') {
      const latDms = toDegreesMinutesAndSeconds(coordinates.latitude, 'dms').split(' ');
      const lonDms = toDegreesMinutesAndSeconds(coordinates.longitude, 'dms').split(' ');
      
      const latDmsArray = latDms;
      const longDmsArray = lonDms;
      
      setDms({
        lat: {
          degree: latDmsArray[0],
          minutes: latDmsArray[1],
          seconds: latDmsArray[2]
        },
        lon: {
          degree: longDmsArray[0],
          minutes: longDmsArray[1],
          seconds: longDmsArray[1]
        }
      })
    }
    
    if (type !== 'ddm') {
      
      const latDdm = toDegreesMinutesAndSeconds(coordinates.latitude, 'ddm').split(' ');
      const lonDdm = toDegreesMinutesAndSeconds(coordinates.longitude, 'ddm').split(' ');
      
      setDdm({
        lat: {
          degree: latDdm[0],
          minutes: latDdm[1],
        },
        lon: {
          degree: lonDdm[0],
          minutes: lonDdm[1]
        }
      })
    }
  }
  
  const timer = useRef(-1);
  const handleInputChange = (e)=>{
    const {name, value} = e.target;
    const cn = {...coordinates};
    cn[name] = value;
    clearTimeout(timer.current)
    timer.current = setTimeout(()=>{
      if (cn.lat && cn.lon) {
        const point = {
          type: "point",
          longitude: parseFloat(cn.lon),
          latitude: parseFloat(cn.lat)
        };
        setUpdateType('coordinates')
        addPointToNewFeature(point)
      }
    }, 500)
    
    setCoordinates(cn)
  }
  
  const convertToDDAndAddPoint = (coodinates, type)=>{
    const latHasEmptyValue = Object.values(coodinates.lat).filter(value=>!value).length > 0;
    const lonHasEmptyValue = Object.values(coodinates.lon).filter(value=>!value).length > 0;
    if (!latHasEmptyValue && !lonHasEmptyValue) {
      const latValues = Object.values(coodinates.lat).join(' ')
      const lonValues = Object.values(coodinates.lon).join(' ')
      
      const geometry = {
        latitude: toDD(latValues),
        longitude: toDD(lonValues)
      }
      const point = {
        type: "point",
        longitude: geometry.longitude,
        latitude: geometry.latitude,
      };
      
      addPointToNewFeature(point, type)
    }
  }
  
  const covertTimer = useRef(-1);
  const handleOtherInputChange = (e, type)=>{
    const {name, value} = e.target;
    const [key, innerKey] = name.split('.');
    let local;
    
    if (type === 'dms') {
      local = {
        ...dms,
        [key]:{
          ...dms[key],
          [innerKey]: value
        }
      };
    } else if (type === 'ddm') {
      local = {
        ...ddm,
        [key]:{
          ...ddm[key],
          [innerKey]: value
        }
      }
    }
    clearTimeout(covertTimer.current)
    covertTimer.current = setTimeout(()=>{
      setUpdateType(type);
      convertToDDAndAddPoint(local, type)
    }, 250)
    
    if (type === 'dms') {
      setDms(local);
    } else if (type === 'ddm') {
      setDdm(local)
    }
  }
  
  const handleDirectionsChange = useCallback((val, dir)=>{
    const newDirections = [...directions];
    newDirections[dir === 'vertical' ? 0 : 1] = val;
    if (coordinates.lat && coordinates.lon){
      let lat = Math.abs(coordinates.lat)
      let lon = Math.abs(coordinates.lon)
      lat = newDirections[0] === 'N' ? lat : -lat;
      lon = newDirections[1] === 'E' ? lon : -lon;
      
      const point = {
        type: 'point',
        latitude: lat,
        longitude: lon
      }
      addPointToNewFeature(point, 'directions');
    }
    
    setDirections(newDirections);
  },[directions, coordinates])
  
  return (
    <div>
      {(editType === EditType.create || !showTitle) && <>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
          {showTitle &&
            <StyledDescription>
              Click on the map the position, or write down the coordinates (DD).
            </StyledDescription>}
          <div style={{
            display: "grid",
            gridTemplateRows: "2fr",
            transition: "all 0.5s"
          }}>
            <div style={{
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              gap: 10,
              padding: '10px 0px',
              transition: "all 0.5s"
            }}>
              <div style={{flex: 1}}>
                <Input noMargin onChange={handleInputChange} name="lon" style={{width: "100%"}} placeholder={t("layer.fieldAlias.longitude")}
                       label={t("layer.fieldAlias.longitude")} value={coordinates.lon} borderColor={color}/>
              </div>
              <div style={{flex: 1}}>
                <Input noMargin onChange={handleInputChange} name="lat" style={{width: "100%"}} placeholder={t("layer.fieldAlias.latitude")}
                       label={t("layer.fieldAlias.latitude")} value={coordinates.lat} borderColor={color}/>
              </div>
            </div>
          </div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
          <StyledButton
            hover={!showAll}
            expand={showOther}
            small
            onClick={() => {
            if (!showAll){
              setShowOther(prev => !prev);
            }
          }}>
            <span>{t('screen.widget.CoordinateConversion.other')}</span>
            {!showAll &&
              <svg fill="#525252" fillRule="evenodd" height="7" role="img" viewBox="0 0 12 7" width="10" aria-label="">
                <title></title>
                <path d="M6.002 5.55L11.27 0l.726.685L6.003 7 0 .685.726 0z"></path>
              </svg>}
          </StyledButton>
          <div style={{
            display: "grid",
            gridTemplateRows: showOther ? "2fr" : "0fr",
            transition: "all 0.3s"
          }}>
            <div style={{
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              paddingTop: showOther ? 10 : 0,
              transition: "all 0.5s"
            }}>
              <CoordinateNotation
                type="ddm"
                coordinates={ddm}
                handleInputChange={(e) => handleOtherInputChange(e, "ddm")}
                onDirectionSelect={handleDirectionsChange}
                directions={directions}
                color={color}
                t={t}
              />
              <CoordinateNotation
                type="dms"
                handleInputChange={(e) => handleOtherInputChange(e, "dms")}
                coordinates={dms}
                onDirectionSelect={handleDirectionsChange}
                directions={directions}
                color={color}
                t={t}
              />
            </div>
          </div>
        </div>
      </>}
    </div>
  );
};

export default CoordinatesInputs;
