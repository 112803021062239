import {forwardRef} from "react";

const CustomSwiperSlide = forwardRef(( {children, ...props}, ref)=>{
  return (
    <swiper-slide ref={ref} {...props}>
      {children}
    </swiper-slide>
  );
})

export default CustomSwiperSlide;