import {useCallback, useEffect, useRef, useState} from "react";
import {view} from "../utils/API";

const useLayersChange = (config, deps = []) => {
  const [count, setCount] = useState(0);
  const [countAdded, setCountAdded] = useState(0);
  const visibleLayers = useRef({});
  const countRef = useRef(0);
  const addedRef = useRef(0);
  
  const processLayer = useCallback((layer, visible)=>{
    countRef.current++;
    setCount(countRef.current);
    visibleLayers.current[layer.id] = visible;
  },[])
  
  useEffect(()=>{
    if (!view) return;
    const listeners = [];
    
    const layersChangeHandler = view.map.layers.on("change", (event) => {
      if (event.added){
        event.added.forEach((layer) => {
          addedRef.current++;
          setCountAdded(addedRef.current);
          
          processLayer(layer, layer.visible);
          const visibleHandler = layer.watch("visible", (visible) => {
            processLayer(layer, visible);
          })
          listeners.push(visibleHandler)
        })
      }
    })
    listeners.push(layersChangeHandler)
  
    view.map.layers.forEach((layer) => {
      processLayer(layer, layer.visible);
      const visibleHandler = layer.watch("visible", (visible) => {
        processLayer(layer, visible);
      })
      listeners.push(visibleHandler)
    })
    
    return ()=>{
      listeners.forEach(h=>h.remove());
    }
  },[config, view, ...deps])
  
  return {
    layerCount: count,
    visibleLayersIds: visibleLayers.current,
    countAdded
  }
};

export default useLayersChange;
