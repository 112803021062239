import React, {useCallback, useEffect, useState} from "react";
import {TextAreaStyled, TextAreaWrapper, MaxLength, DescriptionWrapper} from "./TextArea-styled";
import Dropdown, {DropdownBody} from "../Dropdown";

const TextArea = ({borderColor, label, height, noMargin, onChange, description, register, error,...props}) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  useEffect(()=>{
    const v = props.value ?? props.defaultValue ?? ''
    if (v){
      setValue(v);
    }
  },[props])
  
  const handleChange = useCallback((e)=>{
    const {value} = e.target;
    setValue(value);
    if (onChange){
      onChange(e);
    }
  },[onChange])
  
  return (
    <TextAreaWrapper error={error} borderColor={borderColor} noMargin={noMargin}>
      <TextAreaStyled
        height={height}
        borderColor={borderColor}
        onChange={handleChange}
        error={error}
        {...register}
        {...props}
      />
      {label && <span>{label}</span>}
      {props.maxLength && <MaxLength className="">{value.length} / {props.maxLength}</MaxLength>}
      {description && <DescriptionWrapper>
        <Dropdown
          setShow={setShow}
          show={show}
          leftOffset={0.3}
          hasMinWidth={false}
        >
          <div style={{
            display:'flex'
          }}>
            <svg fill="rgb(76,76,76)" fillRule="evenodd" height="10" role="img" viewBox="0 0 16 16" width="10" aria-label="info" alt="info"><title>info</title><circle cx="8" cy="4" r="1"></circle><path d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 118 0a8 8 0 010 16z"></path><path d="M9 13H7V7h2z"></path></svg>
          </div>
          <DropdownBody type="tooltip" style={{display: "flex"}}>
            {description}
          </DropdownBody>
        </Dropdown>
      </DescriptionWrapper>}
    </TextAreaWrapper>
  );
};

export default TextArea;