import {useContext, useEffect, useMemo, useRef} from "react";
import {graphicsLayer, view} from "../../../../utils/API";
import {EditType, pointSymbol} from "../EditorSwiper/EditorSwiper";
import {EditorContext} from "../EditorContextProvider";
import PointCoordinates from "./PointCoordinates";
import {StyledDescription, StyledTitle} from "../Editor-styled";
import {useDispatch} from "react-redux";
import {setAnimateFeatureTable} from "../../../../redux/action/Dashboard-action";
import RoadsCoordinates from "./RoadsCoordinates";
import {addLayerEffect} from "../helpers";

const CoordinatesSelection = ({editableLayer, config, clickActive, editType, handleNext}) => {
  const {
    addedPoints,
    setHighlightSymbol,
    setHighlightFeature,
    highlightHandle,
    sketchGraphicsLayer,
    highlightSymbol
  } = useContext(EditorContext);
  
  const clickListener = useRef();
  const dispatch = useDispatch();
  
  useEffect(()=>{
    if (clickActive){
      graphicsLayer.removeAll();
      setHighlightFeature(null);
      setHighlightSymbol(null);
      highlightHandle?.remove();
      
      if (editableLayer.layerConfig.titleLabel === 'roadsSituational'){
        addLayerEffect([editableLayer])
      }
    }
  },[editType])
  
  useEffect(()=>{
    if (clickActive) {
      // editableLayer.effect = undefined;
    }
    
    if (editType === EditType.edit && clickActive){
      dispatch(setAnimateFeatureTable(true));
    }
    
    return ()=>{
      dispatch(setAnimateFeatureTable(false));
    }
  },[editType])
  
  //when user is editing feature to detect and assign the clicked feature
  useEffect(()=>{
    //temp
    if (!clickActive || editType !== EditType.edit || (editableLayer.layerConfig.titleLabel === 'roadsSituational' && EditType.create === editType)) return;
    clickListener.current?.remove()
    clickListener.current = view.on('click', (event)=>{
      view.hitTest(event).then((res) => {
        const sketchGraphic = res?.results.filter((res) => res.graphic.layer === sketchGraphicsLayer || res.graphic.sourceLayer === sketchGraphicsLayer).at(0);
        if (sketchGraphic) return;
        
        const firstRes = res?.results.filter((res) => res.graphic.layer === editableLayer || res.graphic.sourceLayer === editableLayer).at(0);
        if (!firstRes)
          return
    
        const clickedLayer = firstRes.layer;
        const graphic = firstRes.graphic;
        
        if (clickedLayer?.layerConfig){
          handleClick(clickedLayer, graphic, handleNext);
        } else {
          handleClick(editableLayer, graphic, handleNext);
        }
      })
      event.stopPropagation();
    })
    
    return ()=>{
      clickListener.current?.remove()
    }
  },[addedPoints, clickActive, editableLayer, highlightHandle, highlightSymbol, sketchGraphicsLayer, editType])
  
  const handleClick = (clickedLayer, feature, callback) =>{
    highlightHandle?.remove();
    view.whenLayerView(clickedLayer).then((layerView) => {
      const query = clickedLayer.createQuery();
      const objectIdField = clickedLayer.objectIdField;
      query.objectIds = [feature.attributes[objectIdField]];
      query.outFields = ['*'];
      let editingGraphic = feature
      let editingSymbol;
      // highlightHandle?.remove();
      if (editableLayer.layerConfig.titleLabel !== 'roadsSituational' && editableLayer.layerConfig.titleLabel !== 'roads'){
        // setHighlightHandle(layerView.highlight(editingGraphic))
        
        if (editableLayer.layerConfig.titleLabel === 'conops') {
          editingSymbol = pointSymbol;
          setHighlightSymbol(pointSymbol)
        } else {
          editingGraphic.layer?.renderer?.getSymbolAsync(editingGraphic).then((symbol) => {
            editingSymbol = symbol;
            setHighlightSymbol(symbol)
          })
        }
      }
    
      clickedLayer.queryFeatures(query).then((result) => {
        const graphic = result.features.find(feat=>feat[objectIdField] === editingGraphic[objectIdField])
      
        if (!!graphic) {
          editingGraphic = graphic;
        }
        
        const nGraphic = editingGraphic?.clone()
        nGraphic.symbol = editingGraphic ? editingSymbol : pointSymbol
        setHighlightFeature(nGraphic);
        // editableLayer.effect = LAYER_EFFECT;
      
        graphicsLayer.removeAll();
        graphicsLayer.add(nGraphic);
      
        if (callback){
          callback();
        }
      }).catch(err=>{
        console.log(err)
      })
    })
  }
  
  const isPoint = useMemo(()=>{
    const geomType = editableLayer.geometryType
    return geomType === 'point';
  },[editableLayer])
  
  return (
    <div style={{paddingTop:8}}>
      {clickActive &&
        <>
          <StyledTitle>{editType === EditType.create ? "Where is it?" : `Which do you want to edit?`}</StyledTitle>
          <StyledDescription>Select an element on the map or choose from the options below</StyledDescription>
        </>
      }
      
      {isPoint ? (
        <PointCoordinates config={config} clickActive={clickActive} editableLayer={editableLayer} editType={editType} handleNext={handleNext} handleSelectFeature={handleClick}/>
      ) : <RoadsCoordinates editableLayer={editableLayer} clickActive={clickActive} editType={editType} handleNext={handleNext} />}
    </div>
  );
};

export default CoordinatesSelection;