import React from "react";
import {Provider} from "react-redux";
import {getDomainValueListExpression} from "./arcadeUtils";
import {view} from "./API";
import store from "../redux/store";
import {createSymbol, getLayerSymbology} from "./symbologies";
import Cluster from "../components/CustomPopupWrapper/components/ClusterDetails/Cluster";
import {createRoot} from "react-dom/client";
import {EMBED_VARIANTS} from "./helper";

export const setLayerClustering = (layer, config, t) => {
    const cluster = layer.layerConfig.cluster
    if (!cluster || !cluster.isClustered)
        return
    
    const popupContent = [{
        type: "expression",
        expressionInfo: {
            title: '',
            expression: getDomainValueListExpression(cluster.clusterAttribute1)
        }
    }]
    
    view.when(() => {
        // When clicking a cluster, show list of clustered features
        view.on('click', (event) => {
            // if all popups are disabled we ignore this case, for example when editor widgets are open
            if (view.popupEnabled || view.popupEnabledCustom) {
                view.hitTest(event).then((hitTestResult) => {
                    const layerRes = hitTestResult.results?.filter((res) => {
                        return res.graphic.layer?.portalItem?.id === layer.layerConfig?.id
                    })
                    if (layerRes.length === 0){
                        // view.popupEnabled = true;
                        // view.popupEnabledCustom = false;
                        return;
                    }
                    
                    const graphic = layerRes[0].graphic
                    const clusterField = layer.fields.find(item=> item.name === cluster.clusterAttribute1)
                    view.popupEnabled = false;
                   
                    setTimeout(()=>{
                        view.popupEnabled = true;
                        openClusterPopup({
                            graphic,
                            title: clusterField ? t('layer.fieldAlias.' + clusterField?.name, clusterField?.alias) : '',
                            layer,
                            config,
                            t
                        })
                    },50)
                })
            }
        })
    })
    
    layer.featureReduction = {
        type: "cluster",
        popupTemplate: {
            actions: [],
            includeDefaultActions: false,
            content: popupContent
        },
        labelingInfo: [{
            labelExpressionInfo: {expression: "$feature.cluster_count"},
            deconflictionStrategy: "none",
            labelPlacement: "center-center",
            symbol: {
                type: "text",
                color: "white",
                font: {	size: "12px"},
                haloSize: 1,
                haloColor: "black"
            }
        }]
    }
    
    const symbology = getLayerSymbology(layer, config)
    
    if (symbology) {
        layer.featureReduction.symbol = createSymbol(symbology, layer, symbology.defaultIcon, symbology.defaultColor, 40)
    }
    
    layer.featureReduction.labelsVisible = cluster.isCounterVisible
    if (cluster.clusterMaxSize)
        layer.featureReduction.clusterMaxSize = cluster.clusterMaxSize + "px"
    
    if (cluster.clusterMinSize)
        layer.featureReduction.clusterMinSize = cluster.clusterMinSize + "px"
}

export const openClusterPopup = ({graphic, title, layer, config, t, tabIndex = 0, clusterFeatures = []}) =>{
    view.closePopup()
    view.popup.visibleElements.closeButton = true;
    const element = document.createElement("div");
    element.id = `popupdiv_${graphic.id}_${graphic.getObjectId()}`;
    element.classList.add('cluster-popup')
    const root = createRoot(element)
    root.render(<Provider store={store}>
        <Cluster layer={layer} graphic={graphic} config={config} t={t} initial={tabIndex} clusterFeatures={clusterFeatures}/>
    </Provider>)

    view.popup.content = element
    view.openPopup({
        location: graphic.geometry,
        title: title,
        content: element,
        actions:[],
        features: (window.isSmall || config.embed === EMBED_VARIANTS.APP) ? [graphic] : [],
    })
}
