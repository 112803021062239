import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {loadModules} from "esri-loader";
import {graphicsLayer, view} from "../../../../../../utils/API";
import {
  BarButtonStyled,
  ButtonWrapperStyled,
  RoadsMapBarStyled,
  SelectionIndicator,
  TitleWrapper
} from "./RoadsMapBar-styled";
import {StyledSubmitButton} from "../../../../../Report/new/Footer/Footer-styled";
import {EditType, LAYER_EFFECT, lineSymbol, pointSymbol} from "../../../EditorSwiper/EditorSwiper";
import {selectionLineSymbol} from "../../RoadsCoordinates";
import {addLayerEffect, cutFeaturesAsync} from "../../../helpers";
import {pointGraphicSymbol} from "../RoadsSituationalManual";
import {EditorContext} from "../../../EditorContextProvider";
import {RightArrowIcon} from "../../../../../Panel/components/Pagination/helpers";
import Message from "../../../Message/Message";
import {createMap} from "../../../../../../esri/map-view";
import {ConfigContext} from "../../../../../../utils/ConfigContext";
import useCustomSnackbar from "../../../../../../hooks/useCustomSnackbar";

const ZOOM = 12;

const MODES = {
  selection: 'selection',
  draw: 'draw',
  addPoint: 'addPoint'
}

const RoadsEditor = ({roadsView, startPointerMoving, setStartPointerMoving, setRoadsView, setEditorAddPointActive, editType, handlePrev, manualDrawPoint, handleNext}) => {
  const {highlightFeature, setHighlightFeature, setAddedPoints, popupFeature, editableLayer} = useContext(EditorContext);
  const [startDrawing, setStartDrawing] = useState(true);
  const [initDraw, setInitDraw] = useState(true);
  const [smallView, setSmallView] = useState(null)
  const [mode, setMode] = useState(MODES.draw);
  const [showSmallMap, setShowSmallMap] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const mapRef = useRef();
  const smallMapRef = useRef();
  const editorLayerRef = useRef();
  const firstMount = useRef(true);
  const draw = useRef(null);
  const isSaving = useRef(false);
  const {config} = useContext(ConfigContext);
  const [openSnackbar] = useCustomSnackbar();
  
  useEffect(()=>{
    if (EditType.edit === editType){
      addLayerEffect([]);
    }
    return () =>{
      view.map.remove(editorLayerRef.current);
      smallView?.destroy();
      roadsView?.destroy();
    }
  },[]);
  
  useEffect(() => {
    let zoomLevelTimer = 0;
    let zoomHandler;
    if (!roadsView) return;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) =>{
      zoomHandler = reactiveUtils.watch(()=> roadsView.zoom >= ZOOM, (value)=>{
        clearTimeout(zoomLevelTimer);
        zoomLevelTimer = setTimeout(()=>{
          // isAllowedZoomLevelRef.current = value;
          setShowSmallMap(value);
          if (!value) {
            
            // setStartPointerMoving(false);
            // openSnackbar('You have zoomed out, to continue please zoom in')
          } else {
            // openSnackbar('Click on the point to start editing and move the mouse along the road')
          }
          
          // setIsAllowedZoomLevel(isAllowedZoomLevelRef.current);
        },250)
      })
    })
    
    return ()=>{
      clearTimeout(zoomLevelTimer)
      zoomHandler?.remove();
    }
  },[roadsView]);
  
  useEffect(()=>{
    let watch;
    loadModules(["esri/views/MapView"]).then(([MapView])=>{
      const newView = new MapView({
        container: mapRef.current,
        map: view.map,
        popupEnabled:false,
        zoom: 12,
      })
      
      const smallMapView = new MapView({
        container: smallMapRef.current,
        map: view.map,
        popupEnabled:false,
        zoom: 6,
      })
      
      smallMapView.ui.components = [];
      newView.ui.components = [];
      newView.constraints.snapToZoom = false;
      
      setRoadsView(newView);
      setSmallView(smallMapView);
      loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils])=>{
        watch = reactiveUtils.watch(()=>newView.extent, ()=>{
          smallMapView.extent = newView.extent
          smallMapView.zoom = 6;
        })
      })
    })
    return ()=>{
      watch?.remove()
    }
  },[])
  
  
  const combineEditorLineGraphics = ({polyline, geometryEngine, Graphic}) =>{
    const lineGraphics = editorLayerRef.current.graphics.filter(g=>g.id && g.id.includes('line'));
    let geometry = polyline;

    if (lineGraphics.length > 0){
      const union = geometryEngine.union(lineGraphics.toArray().map(g=>g.geometry));
      if (geometry){
        geometry = geometryEngine.union(geometry, union);
      } else {
        geometry = union;
      }
    }
    
    const newLineGraphic = new Graphic({
      id:'line',
      geometry,
      symbol: selectionLineSymbol
    });
    
    return newLineGraphic;
  }
  
  const dragHandle = useRef();
  const pointsUnionGeometry = useRef();
  let vertices = [];
  useEffect(()=>{
    const view = roadsView;
    if (!view || MODES.draw !== mode || !initDraw) return;
    
    let actionDrawHandler, actionCompleteHandler, actionVertexAddHandler;
    let highlightedRoadGraphic;
    let highlightPointGraphics = [];
    let drawGraphic;
    let deleteVertices = [];
    let bufferGraphic;
    
    loadModules(["esri/Graphic", "esri/geometry/geometryEngine", "esri/geometry/Point", "esri/geometry/Polyline", "esri/views/draw/Draw", "esri/geometry/Polygon", "esri/geometry/support/webMercatorUtils", "esri/geometry/Extent", "esri/widgets/Sketch/SketchViewModel", "esri/layers/GraphicsLayer", "esri/views/draw/MultipointDrawAction", "esri/geometry/Multipoint", "esri/views/draw/DrawAction"])
      .then(([Graphic, geometryEngine, Point, Polyline, Draw, Polygon, webMercatorUtils, Extent, SketchViewModel, GraphicsLayer, MultipointDrawAction, Multipoint, DrawAction])=>{
        const editorLayer = editorLayerRef.current ?? new GraphicsLayer();
        if (!editorLayerRef.current){
          view.map.add(editorLayer);
          editorLayerRef.current = editorLayer
        }
        const pointArray = [];
        
        graphicsLayer.graphics.forEach(g=>{
          if (g.id && g.geometry.spatialReference.isGeographic){
            g.geometry = webMercatorUtils.geographicToWebMercator(g.geometry);
          }
        })
        
        graphicsLayer.graphics.forEach((g)=>{
          if (g.id && g.id.includes('point')){
            pointArray.push(g);
          }
        })
  
        pointsUnionGeometry.current = geometryEngine.union(pointArray.map(p=>p.geometry));
        const lines = graphicsLayer.graphics.filter(g=>g.id && g.id.includes('line'));
        if (lines.length > 0){
          //divide lines to smaller segments to improve performance
          lines.forEach((line)=>{
            const lineGeometry = line.geometry;
  
            lineGeometry.paths.forEach((path, i)=>{
              const poly = new Polyline({
                paths: path,
                spatialReference: view.spatialReference
              })
    
              const graphic = new Graphic({
                geometry: poly,
                symbol: selectionLineSymbol,
                id: `${line.id}-` + i
              })
    
              editorLayer.add(graphic);
            })
          })
        }
        
        // const line = graphicsLayer.graphics.find(g=>g.id && g.id.includes('line'));
        //
        // //divide line to smaller segments to improve performance
        // if (line){
        //   const lineGeometry = line.geometry;
        //
        //   lineGeometry.paths.forEach((path, i)=>{
        //     const poly = new Polyline({
        //       paths: path,
        //       spatialReference: view.spatialReference
        //     })
        //
        //     const graphic = new Graphic({
        //       geometry: poly,
        //       symbol: selectionLineSymbol,
        //       id: 'line-' + i
        //     })
        //
        //     editorLayer.add(graphic);
        //   })
        // }
        const activateDraw = () => {
          draw.current = new Draw({
            view,
          });
          
          const action = draw.current.create("polyline", {mode:'click'});
          const hitTestLayers = view.map.layers.filter(l=>l.layerConfig?.titleLabel === 'roads');
          // hitTestLayers.push(graphicsLayer);
          hitTestLayers.push(editorLayer);
          // hitTestLayers.push(graphicsLayer);
  
          // const hasDrawPoint = graphicsLayer.graphics.some(g=>g.id && g.id.includes(`route-${manualDrawPoint.coordinate.join('|')}`))
          // console.log(manualDrawPoint, hasDrawPoint)
          
          //for adding roads situational
          if (manualDrawPoint){
            if (firstMount.current) {
              firstMount.current = false;
              const manualLine = graphicsLayer.graphics.find(g=>g.id && g.id === `line-${manualDrawPoint.coordinate.join('|')}`);
              // const lineGraphics = graphicsLayer.graphics.filter(g=>g.id && g.id.includes('line'));
              // if (lineGraphics){
              //   editorLayer.addMany(lineGraphics);
              // }
              
              if (manualLine){
                const geometry = manualLine.geometry;
                const lastPoint = geometry.paths[0][manualLine.geometry.paths[0].length - 1];
      
                const point = geometry.getPoint(0, geometry.paths[0].length - 1);
                view.center = point;
                vertices.push(lastPoint);
              } else {
                let geometry = manualDrawPoint.graphic.geometry;
                if (geometry.spatialReference.isGeographic){
                  geometry = webMercatorUtils.geographicToWebMercator(manualDrawPoint.graphic.geometry);
                }
      
                view.center = geometry;
                vertices.push([geometry.x, geometry.y]);
              }
              graphicsLayer.removeMany(lines);
            }
          } else if (lines.length > 0){
            const target = lines.toArray()[0]
            
            setTimeout(()=>{
              view.goTo({
                target,
              },{
                animate: false,
              }).then(res=>{
                graphicsLayer.removeMany(lines);
              })
            },250)
          }
          
          let timer = 0;
          let clickedPointGraphic;
          actionVertexAddHandler = action.on(['vertex-add'], (event)=> {
            if (event.vertices.length > 0) {
              const [x,y] = event.vertices[event.vertices.length - 1];
              
              if (vertices.length === 0) {
                clickedPointGraphic = null;
                const screenPoint = view.toScreen({x,y});
                view.hitTest(screenPoint, {include: [graphicsLayer]}).then(res => {
                  if (res?.results) {
                    
                    if (manualDrawPoint){
                      const resGraphic = res.results.find(res=>res.graphic.id === manualDrawPoint.graphic.id);
                      clickedPointGraphic = resGraphic?.graphic;
                    } else {
                      const resGraphic = res.results.find(res=>res.graphic.id && res.graphic.id.includes('point'));
                      clickedPointGraphic = resGraphic?.graphic;
                    }
                    
                    if (clickedPointGraphic) {
                      view.goTo({
                        target: clickedPointGraphic,
                        zoom: 12
                      },{
                        animate: false,
                      })
                      vertices.push([clickedPointGraphic.geometry.x, clickedPointGraphic.geometry.y]);
                      setStartDrawing(true);
                    }
                  }
                })
              } else if (highlightedRoadGraphic) {
                const point = new Point({
                  x,
                  y
                });
        
                const nearestPoint = geometryEngine.nearestCoordinate(pointsUnionGeometry.current, point);
                const nearestVertex = geometryEngine.nearestVertex(highlightedRoadGraphic.geometry, point);
                let nearestCoordinate;
                if (nearestPoint.distance <= nearestVertex.distance) {
                  nearestCoordinate = nearestPoint;
                } else {
                  nearestCoordinate = nearestVertex;
                }
        
                const polyline = new Polyline({
                  paths: vertices.slice(0, vertices.length-1)
                })
                const isIntersecting = geometryEngine.intersects(polyline, nearestCoordinate.coordinate)
                
                if (typeof highlightedRoadGraphic?.parentId === 'string' && highlightedRoadGraphic?.parentId.includes('line')) {
                  const lastVertex = vertices[vertices.length - 1];
                  const polyline = new Polyline({
                    paths: [lastVertex, [nearestCoordinate.coordinate.x, nearestCoordinate.coordinate.y]],
                    spatialReference: view.spatialReference
                  })
                  vertices = [[nearestCoordinate.coordinate.x,nearestCoordinate.coordinate.y]];
                  
                  const buffer = geometryEngine.geodesicBuffer(polyline, 200, "meters");
                  // graphicsLayer.remove(bufferGraphic);
                  //
                  // bufferGraphic = new Graphic({
                  //   symbol: lineSymbol,
                  //   geometry: buffer
                  // });
                  //
                  // graphicsLayer.add(bufferGraphic);
                  const union = cutFeaturesAsync([highlightedRoadGraphic], buffer, {geometryEngine, Polyline, Point});
                  
                  if (union){
                    const difference = geometryEngine.difference(highlightedRoadGraphic.geometry, union);
                    const roadGraphic = editorLayer.graphics.find(g=>g.id === highlightedRoadGraphic.parentId);
                    editorLayer.remove(roadGraphic);
            
                    if (difference){
                      // difference.paths[0].push([nearestCoordinate.coordinate.x, nearestCoordinate.coordinate.y])
              
                      const newRoadGraphic = new Graphic({
                        geometry: geometryEngine.union(difference, nearestCoordinate),
                        symbol: roadGraphic.symbol,
                        id: roadGraphic.id,
                        parentId: highlightedRoadGraphic.parentId
                      })
                      
                      // graphicsLayer.removeMany(highlightPointGraphics)
                      // editorLayer.remove();
                      editorLayer.add(newRoadGraphic);
                      // graphicsLayer.remove(highlightedRoadGraphic);
                      highlightedRoadGraphic.geometry = newRoadGraphic.geometry;
                      // graphicsLayer.add(highlightedRoadGraphic);
              
                      // deleteVertices = [nearestCoordinate.coordinate.x,nearestCoordinate.coordinate.y];
                      deleteVertices.push([nearestCoordinate.coordinate.x,nearestCoordinate.coordinate.y]);
                    }
                  }
                } else {
                  deleteVertices = [deleteVertices[deleteVertices.length - 1]];
                  if (isIntersecting){
                    const linetopoints = geometryEngine.intersectLinesToPoints(polyline, nearestCoordinate.coordinate)
                    const coordinatesIntersectPoints = linetopoints.map(v=> {
                      const [x] = `${v.x}`.split('.');
                      const [y] = `${v.y}`.split('.');
              
                      return x + '-' + y;
                    });
            
                    // vertices.pop();
                    const index = vertices.findIndex(arr=>{
                      const [coordinateX,coordinateY] = arr;
                      const [x] = `${coordinateX}`.split('.');
                      const [y] = `${coordinateY}`.split('.');
              
                      return coordinatesIntersectPoints.includes(x+'-'+y);
                    })
            
                    vertices = vertices.slice(0, index === 1 ? 1 : index + 1);
                  } else {
                    if (vertices.length === 1){
                      //after delete
                    } else {
                    }
                    vertices.push([nearestCoordinate.coordinate.x,nearestCoordinate.coordinate.y]);
                  }
                }
                
                if (!nearestCoordinate.coordinate.spatialReference.isGeographic) {
                  view.goTo({
                    target: nearestCoordinate.coordinate,
                    zoom: view.zoom < ZOOM ? 12 : view.zoom
                  },{
                    animate: true,
                  }).then(res=>{
                    // graphicsLayer.graphics.forEach(g=>{
                    //   if (g.id && g.id.includes('point') && g.id !== manualDrawPoint.graphic.id) {
                    //     g.symbol.color.a = 1;
                    //   }
                    // })
                  })
                }
              }
            }
          })
  
          actionDrawHandler = action.on(
            ["cursor-update"],
            (event)=>{
              clearTimeout(timer);
              if (vertices.length === 0){
                return;
              }
              createGraphic(event);
              if (!event.native) return;
              timer = setTimeout(()=>{
                view.hitTest({
                  x: event.native.layerX,
                  y: event.native.layerY
                }, {
                  include: hitTestLayers
                }).then(res=>{
                  if (res?.results) {
                    const eventGeometry = view.toMap({
                      x: event.native.layerX,
                      y: event.native.layerY
                    })
            
                    const roadResult = res.results.filter((res) => res.graphic.layer?.layerConfig?.titleLabel === 'roads' || res.graphic.sourceLayer?.layerConfig?.titleLabel === 'roads').sort((a,b)=>a.graphic?.attributes?.fclass - b.graphic?.attributes?.fclass).at(0);
                    const graphicsLayerResults = res.results.filter(res=> res.graphic.layer === editorLayer || res.graphic.sourceLayer === editorLayer);
                    // const pointResults = res.results.filter(res=>res.graphic.layer === graphicsLayer || res.graphic.sourceLayer === graphicsLayer);
                    const highlightedRoadResult = res.results.find(res=>res.graphic?.id === 'highlightedRoad');
                    
                    const line = graphicsLayerResults.find(g=>g.graphic.id && g.graphic.id.includes('line'));
                    const road = line?.graphic ?? highlightedRoadResult?.graphic ?? roadResult?.graphic;
                    
                    if (!road) {
                      graphicsLayer.remove(highlightedRoadGraphic)
                      highlightPointGraphics.forEach(g=>{
                        editorLayer.remove(g);
                      })
                      highlightedRoadGraphic = null;
                      highlightPointGraphics = [];
                    } else {
                      if (highlightedRoadGraphic?.parentId && highlightedRoadGraphic.parentId === road.id || highlightedRoadGraphic?.parentId === road.getObjectId()){
                      } else {
                        
                        graphicsLayer.remove(highlightedRoadGraphic)
                        highlightedRoadGraphic = new Graphic({
                          id: 'highlightedRoad',
                          parentId: road.id ?? road.getObjectId(),
                          symbol: {
                            ...lineSymbol,
                            width: 20,
                            color: [222,222,222, 0.3]
                          },
                          geometry: road.geometry
                        })
  
                        //highlighting hovered road
                        graphicsLayer.add(highlightedRoadGraphic);
                      }
              
                      highlightPointGraphics.forEach(g=>{
                        editorLayer.remove(g);
                      })
                      
                      if (road.id && road.id.includes('line')){
                        const nearestVertices = geometryEngine.nearestVertices(road.geometry, eventGeometry, 20000, 20);
                        nearestVertices.forEach(vertices=>{
                          const {coordinate} = vertices;
                          const graphic = new Graphic({symbol: pointSymbol, geometry:coordinate});
                          highlightPointGraphics.push(graphic);
                          editorLayer.add(graphic);
                        })
                      } else {
                        road.geometry.paths.forEach((path, index)=>{
                          path.forEach((arr, i)=>{
                            const point = road.geometry.getPoint(index, i);
                            const graphic = new Graphic({symbol: pointSymbol, geometry:point})
                            highlightPointGraphics.push(graphic);
                            editorLayer.add(graphic);
                          })
                        })
                      }
                    }
                  } else {
                    graphicsLayer.remove(highlightedRoadGraphic)
                    highlightPointGraphics.forEach(g=>{
                      editorLayer.remove(g);
                    })
                    highlightedRoadGraphic = null;
                    highlightPointGraphics = [];
                  }
                }).catch(err=>{
                  console.log(err);
                })
              },50)
            }
          );
  
          actionCompleteHandler = action.on(["draw-complete"], ()=>{
            // if (EditType.create === editType && !isSaving.current){
            //   setTimeout(()=>{
            //     activateDraw();
            //   },100);
            //   return;
            // }
            
            const points = graphicsLayer.graphics.filter(g=>g?.id && g?.id.includes('point'));
            const polyline = new Polyline({
              paths: vertices.slice(0, vertices.length),
              spatialReference:view.spatialReference
            })
    
            const newLineGraphic = combineEditorLineGraphics({polyline, geometryEngine, Graphic});
    
            dragHandle.current?.remove();
            actionDrawHandler?.remove();
            actionVertexAddHandler?.remove();
    
            clearTimeout(timer);
            
            //not activating drawing when clicking on save button
            if (!isSaving.current){
              setInitDraw(false);
            }
            
            setTimeout(()=>{
              editorLayer.removeAll();
              graphicsLayer.graphics.forEach(g=>{
                if (!g.id) {
                  graphicsLayer.remove(g);
                }
              })
              
              if (newLineGraphic.geometry.paths[0].length > 1){
                if (manualDrawPoint){
                  newLineGraphic.id = 'line-' + manualDrawPoint.coordinate.join('|');
                  //checking for intersected points
                  const buffer = geometryEngine.geodesicBuffer(newLineGraphic.geometry, 100);
                  const intersectedPoints = points.filter(g=>g.id !== manualDrawPoint.graphic.id && (geometryEngine.intersects(buffer, g.geometry) || geometryEngine.contains(buffer, g.geometry)));
                  newLineGraphic.intersectedPoints = intersectedPoints.toArray().map(p=>p.geometry.longitude+'|'+p.geometry.latitude);
    
                  const existingManualLine = graphicsLayer.graphics.find(g=>g.id === newLineGraphic.id);
                  if (existingManualLine){
                    newLineGraphic.geometry.paths[0].unshift(...existingManualLine.geometry.paths[0]);
                    existingManualLine.geometry = newLineGraphic.geometry;
                  } else {
                    graphicsLayer.add(newLineGraphic);
                  }
                  
                } else {
                  newLineGraphic.id = 'line';
    
                  if (vertices.length > 0 && clickedPointGraphic){
                    const [x,y] = vertices[vertices.length - 1];
                    const point = new Point({
                      x,
                      y
                    })
                    const nearestCoordinate = geometryEngine.nearestVertex(newLineGraphic.geometry, point);
                    clickedPointGraphic.geometry = nearestCoordinate.coordinate;
                    clickedPointGraphic = null;
                  }
    
                  setAddedPoints([newLineGraphic.geometry]);
                  graphicsLayer.add(newLineGraphic);
                }
              } else {
                openSnackbar('Nothing to save');
              }
              
              const lastPoint = vertices[vertices.length - 1];
  
              if (EditType.create === editType && !isSaving.current) {
                handlePrev();
                return;
              }
              
              if (lastPoint && !isSaving.current){
                setInitDraw(true);
                setStartDrawing(false);
              }
      
              if (isSaving.current){
                isSaving.current = false;
                if (editType === EditType.create){
                  handlePrev();
                } else {
                  handleNext();
                }
              }
            },1000)
          })
        }
  
        activateDraw();
        function createGraphic(event) {
          graphicsLayer.remove(drawGraphic);
          const isLineGraphic = typeof highlightedRoadGraphic?.parentId === 'string' && highlightedRoadGraphic?.parentId.includes('line');
          const lineVertices = isLineGraphic ? [...deleteVertices] : [...vertices];
          
          const [x,y] = event.vertices[event.vertices.length - 1];
          if (highlightedRoadGraphic){
            const point = new Point({
              x,
              y
            })
            const pointArray = [];
            graphicsLayer.graphics.forEach((g)=>{
              if (g.id && g.id.includes('point')){
                pointArray.push(g);
              }
            })
            
            const union = geometryEngine.union(pointArray.map(p=>p.geometry));
            const nearestPoint = geometryEngine.nearestCoordinate(union, point);
            const nearestCoordinate = geometryEngine.nearestVertex(highlightedRoadGraphic.geometry, point);
            
            if (nearestPoint.distance <= nearestCoordinate.distance) {
              lineVertices.push([nearestPoint.coordinate.x, nearestPoint.coordinate.y]);
            } else {
              lineVertices.push([nearestCoordinate.coordinate.x, nearestCoordinate.coordinate.y]);
            }
            
          } else {
            lineVertices.push([x,y])
          }
          
          const sr = view.spatialReference;
          
          let graphic;
          if (isLineGraphic) {
            // a graphic representing the polyline that is being drawn
            graphic = new Graphic({
              geometry: {
                type: "polyline",
                paths: lineVertices,
                spatialReference: sr
              },
              symbol: {
                type: "simple-line", // autocasts as new SimpleFillSymbol
                color: [4, 90, 141, 0],
                width: 2,
                cap: "round",
                join: "round"
              }
            });
          } else {
            graphic = new Graphic({
              geometry: {
                type: "polyline",
                paths: lineVertices,
                spatialReference: sr
              },
              symbol: {
                type: "simple-line", // autocasts as new SimpleFillSymbol
                color: [4, 90, 141], //!highlightedRoadGraphic ? '#e4e4e4' : ,
                width: 2,
                cap: "round",
                join: "round"
              }
            });
          }
          
          graphicsLayer.add(graphic);
          drawGraphic = graphic;
        }
      })
    return ()=>{
      dragHandle.current?.remove();
      actionDrawHandler?.remove();
      actionCompleteHandler?.remove();
    }
   },[roadsView, startPointerMoving, mode, initDraw])
  
  useEffect(()=>{
    if (highlightFeature && EditType.edit === editType) {
      const lineGraphic = graphicsLayer.graphics.find(g=>g.id && g.id === 'line');
      if (lineGraphic){
        setMode(MODES.draw);
        return;
      }
      
      
      loadModules(["esri/Graphic", "esri/geometry/geometryEngine", "esri/geometry/Point", "esri/geometry/Polyline", "esri/views/draw/Draw", "esri/geometry/Polygon", "esri/geometry/support/webMercatorUtils"]).then(([Graphic, geometryEngine, Point, Polyline, Draw, Polygon, webMercatorUtils])=>{
        let polyline = new Polyline({
          paths: highlightFeature.geometry.paths,
          spatialReference: highlightFeature.geometry.spatialReference
        })
        
        
        const line = graphicsLayer.graphics.find(g=>g?.id === 'line')
        if (!popupFeature && !line){
          polyline = webMercatorUtils.geographicToWebMercator(polyline);
        }
        
        let graphic = new Graphic({
          geometry: polyline,
          symbol: selectionLineSymbol
        })
        graphic.id = 'line';
        
        const points = [];
        polyline.paths.forEach((pathArray, i)=>{
          const start = polyline.getPoint(i, 0);
          const end = polyline.getPoint(i, pathArray.length - 1);
          points.push([start, end]);
        })
        
        graphicsLayer.removeAll();
        
        points.forEach((arr,i)=>{
          const [start, end] = arr;
          const startGraphic = new Graphic({
            geometry: start,
            symbol: pointGraphicSymbol
          })
          startGraphic.id = 'point-'+i;
          const endGraphic = new Graphic({
            geometry: end,
            symbol: pointGraphicSymbol
          })
          endGraphic.id = 'point-'+i;
          graphicsLayer.add(startGraphic);
          graphicsLayer.add(endGraphic);
        })
        
        setMode(MODES.draw);
        graphicsLayer.add(graphic);
        view.map.layers.forEach((l)=>{
          if (l.layerConfig?.titleLabel === 'roads'){
            l.effect = undefined;
          }
        })
      })
    }
    
  },[highlightFeature, popupFeature])
  
  const handleSave = () => {
    if (draw.current){
      isSaving.current = true;
      if (draw.current.activeAction === null || draw.current.activeAction?.vertices?.length === 1) {
        if (editType === EditType.create){
          handleGoBack();
        } else {
          loadModules(["esri/geometry/geometryEngine", "esri/Graphic"]).then(([geometryEngine, Graphic])=>{
            const newLineGraphic = combineEditorLineGraphics({geometryEngine, Graphic});
            if (newLineGraphic.geometry){
              graphicsLayer.add(newLineGraphic);
              handleNext();
            } else {
              openSnackbar('Nothing to save');
            }
          })
        }
      } else {
        isSaving.current = true;
        setTimeout(()=>{
          draw.current.complete();
        },100);
      }
    }
  }
  
  const handleGoBack = ()=>{
    view.map.remove(editorLayerRef.current);
    if (manualDrawPoint){
      const graphicsToRemove = graphicsLayer.graphics.filter(g=>!g.id || g.id === `line-${manualDrawPoint.coordinate.join('|')}`);
      graphicsLayer.removeMany(graphicsToRemove);
    }
    
    handlePrev();
  }
  
  return (
    createPortal(
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          background:'rgba(0,0,0,0.9)',
          zIndex:30,
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          animation:'fadeIn 0.2s linear'
        }}
      >
        <div
          style={{
            width:'90%',
            height: '90%',
            background:'#efefef',
            display:'flex',
            flexDirection:'column',
            borderRadius:10,
            overflow:'hidden',
            animation:'fadeIn 0.2s linear',
            position:'relative'
          }}>
          {
            showPopup && (
              <Message
                bg="#0000004a"
                position="absolute"
                title="Are you sure you want to abort editing?"
                subtitle="All changes will be lost"
                onSubmit={handleGoBack}
                onCancel={()=>{
                  setShowPopup(false);
                }}
              />
            )
          }
          <RoadsMapBarStyled>
            <StyledSubmitButton
              style={{
                borderRadius: 8,
                overflow: "hidden",
                width:34,
                justifyContent:'center',
                padding:0,
                minWidth:34,
                display:'flex',
                alignItems:'center',
              }}
              onClick={()=>{
                setShowPopup(true);
              }}
            >
              <RightArrowIcon
                width={18}
                color="#FFFFFF"
              />
            </StyledSubmitButton>
            <TitleWrapper>
              <span>Roads editor</span>
            </TitleWrapper>
            {
              startDrawing && <SelectionIndicator>
                <div style={{position:'relative', top:4, left:4, width:15, height:15}}>
                  <div className="pulsating-circle"></div>
                </div>
                <span style={{fontSize:12}}>Selecting</span>
              </SelectionIndicator>
            }
            <ButtonWrapperStyled>
              {/*<BarButtonStyled title="Delete route" onClick={handleDeleteLine}>*/}
              {/*  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg">*/}
              {/*    <path fill="none" d="M0 0h24v24H0V0z"></path>*/}
              {/*    <path d="M14.12 10.47 12 12.59l-2.13-2.12-1.41 1.41L10.59 14l-2.12 2.12 1.41 1.41L12 15.41l2.12 2.12 1.41-1.41L13.41 14l2.12-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>*/}
              {/*  </svg>*/}
              {/*</BarButtonStyled>*/}
              {/*<BarButtonStyled title="Add new point" selected={editorAddPointActive} onClick={handleAddPoint}>*/}
              {/*  <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg">*/}
              {/*    <path fillRule="evenodd" clipRule="evenodd" d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12ZM12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13Z" fill={editorAddPointActive ? '#fff' : '#525252'}></path>*/}
              {/*    <path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19Z" fill={editorAddPointActive ? '#fff' : '#525252'}></path>*/}
              {/*  </svg>*/}
              {/*</BarButtonStyled>*/}
              {/*<BarButtonStyled title="Draw manually" selected={MODES.draw === mode} onClick={handleDraw}>*/}
              {/*  <svg stroke="currentColor" fill="#fff" strokeWidth="0" viewBox="0 0 16 16" height="18" width="18" xmlns="http://www.w3.org/2000/svg">*/}
              {/*    <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.1 6.1 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.1 8.1 0 0 1-3.078.132 4 4 0 0 1-.562-.135 1.4 1.4 0 0 1-.466-.247.7.7 0 0 1-.204-.288.62.62 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896q.19.012.348.048c.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04M4.705 11.912a1.2 1.2 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.4 3.4 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3 3 0 0 0 .126-.75zm1.44.026c.12-.04.277-.1.458-.183a5.1 5.1 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005zm3.582-3.043.002.001h-.002z"></path>*/}
              {/*  </svg>*/}
              {/*</BarButtonStyled>*/}
              {/*<BarButtonStyled onClick={handleSelection} selected={mode===MODES.selection}>*/}
              {/*  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 32 32" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M 9 2.59375 L 9 28.15625 L 10.65625 26.78125 L 14.6875 23.40625 L 16.71875 27.4375 L 17.15625 28.34375 L 18.0625 27.875 L 21.15625 26.28125 L 22.03125 25.84375 L 21.59375 24.9375 L 19.75 21.3125 L 24.8125 20.6875 L 26.84375 20.4375 L 25.40625 19 L 10.71875 4.28125 Z M 11 7.4375 L 22.5625 18.96875 L 18.0625 19.5 L 16.65625 19.6875 L 17.3125 20.96875 L 19.375 24.96875 L 18.0625 25.65625 L 15.90625 21.34375 L 15.3125 20.21875 L 14.34375 21.03125 L 11 23.84375 Z"></path></svg>*/}
              {/*</BarButtonStyled>*/}
              <StyledSubmitButton
                style={{
                  height:'36px'
                }}
                onClick={handleSave}
              >
                Save
              </StyledSubmitButton>
            </ButtonWrapperStyled>
          </RoadsMapBarStyled>
          <div style={{flex:1}} ref={mapRef}></div>
          <div style={{
            width: 300,
            height: 200,
            position: "absolute",
            right: 8,
            bottom: 8,
            borderRadius: 8,
            background: "#efefef",
            border: "2px solid #fff",
            overflow: "hidden",
            opacity: showSmallMap ? 1 : 0,
            pointerEvents: showSmallMap ? 'auto' : 'none',
            transition:'all 0.3s'
          }} ref={smallMapRef}/>
        </div>
      </div>,
    document.querySelector("#portal"),
)
  );
};

export default RoadsEditor;
