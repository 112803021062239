import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {getFieldsByPattern} from "../../../../utils/helper";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {EditorContext, UPDATE_STATUS_TYPE} from "../EditorContextProvider";
import {getLayerSymbology} from "../../../../utils/symbologies";
import AttributeFields from "./AttributeFields/AttributeFields";
import {AccordionBody, AccordionButton, AccordionWrapper} from "./Accordion/Accordion";
import {StyledSwitch} from "../Editor-styled";
import {currAsOfDateFieldName} from "../../../../utils/API";
import {useFormContext} from "react-hook-form";

export const batchUpdateFields = ["currstatusremarken", "currasofdate", "currsourcename", "currinforely"];

const EditorSituationalFields = () => {
  const {highlightFeature, activeColor, batchUpdateFeatures, situationalUpdateType, setSituationalUpdateType, editableLayer, requiredFields} = useContext(EditorContext);
  const {reset} = useFormContext();
  
  const {t} = useTranslation('common');
  const {config} = useContext(ConfigContext);
  
  useEffect(()=>{
    return () => {
      setSituationalUpdateType(UPDATE_STATUS_TYPE.UPDATE);
    }
  },[]);
  
  const symbology = useMemo(()=>{
    return getLayerSymbology(editableLayer, config) || {}
  },[config, editableLayer]);
  
  const situationalFields = useMemo(()=>{
    if (!editableLayer && editableLayer?.layerConfig) return [];
    const lc = editableLayer.layerConfig;
    let fields = getFieldsByPattern(editableLayer, lc.situationalFields);
    
    //filtering fields for batch editor
    if (!highlightFeature && batchUpdateFeatures.length > 0) {
      const {colorMap} = symbology;
      const symbologyFields = [];
      
      if (colorMap?.fields){
        symbologyFields.push(...colorMap.fields);
      } else if (colorMap?.field) {
        symbologyFields.push(colorMap.field);
      }
      
      fields = fields.filter(field=>
        situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT
          ? field.name === currAsOfDateFieldName
          : batchUpdateFields.includes(field.name) || symbologyFields.includes(field.name)
      );
      
      if (fields.length === 0){
        fields = getFieldsByPattern(editableLayer, lc.situationalFields);
      }
    }
    
    return fields;
  },[editableLayer, situationalUpdateType, highlightFeature, batchUpdateFeatures, symbology]);
  
  const situationalAccordion = useMemo(()=>{
    if (!editableLayer) return [];
    const symbology = getLayerSymbology(editableLayer, config);
    const acc = [];
    
    if (situationalFields.length > 0){
      acc.push({
        id:'situational',
        label:t('screen.widget.Editor.situationalFields.label'),
        component: <AttributeFields
          highlightFeature={highlightFeature}
          fields={situationalFields}
          config={config}
          t={t}
          symbology={symbology}
          editableLayer={editableLayer}
          requiredFields={requiredFields}
        />
      })
    }
    return  acc;
  },[situationalFields])
  
  const handleStatusChange = useCallback((status)=>{
    setSituationalUpdateType(status);
    reset({}, {
      keepValues: true
    });
  },[]);
  
  const showStatusChange = useMemo(()=>{
    return (
      situationalFields.some(field=>field.name === currAsOfDateFieldName)
      && (!highlightFeature && batchUpdateFeatures.length > 0)
    );
  },[situationalFields, highlightFeature, batchUpdateFeatures])
  
  return (
    <div
      style={{
        flex:1,
        overflow:'hidden',
        display:'flex',
        flexDirection:'column'
      }}
    >
      {showStatusChange && <div
        style={{
          width: "fit-content",
          padding: "0px 14px 4px",
          margin: "0px auto"
        }}>
        <StyledSwitch color={activeColor} dir="hor">
          <div
            onClick={() => {
              handleStatusChange(UPDATE_STATUS_TYPE.UPDATE)
            }}
            className={situationalUpdateType === UPDATE_STATUS_TYPE.UPDATE ? "selected" : ""}>
            <span>Update new status</span>
          </div>
          <div
            onClick={() => {
              handleStatusChange(UPDATE_STATUS_TYPE.CURRENT)
            }}
            className={situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT ? "selected" : ""}>
            <span>Confirm current status</span>
          </div>
        </StyledSwitch>
      </div>}
      {
        situationalAccordion.map(item=>(
          <AccordionWrapper selected>
            <AccordionButton
              selected
              label={item.label}
              config={config}
              description="Section description"
              color={activeColor}
            />
            <AccordionBody
              selected
            >
              {item.component}
            </AccordionBody>
          </AccordionWrapper>
        ))
      }
    </div>
  );
};

export default EditorSituationalFields;