import React, {useCallback, useState} from "react";
import Uploader from "../../Uploader/Uploader";

const UploadMediaFiles = ({t, config, attachments, setAttachments, previews, setPreviews, color}) => {
  const onDrop = useCallback((acceptedFiles)=>{
    const previewUrls = {};
    acceptedFiles.forEach((file)=>{
      if (file.type !== 'application/pdf'){
        previewUrls[file.name] = URL.createObjectURL(file);
      } else {
        previewUrls[file.name] = 'pdf';
      }
    })
    
    setAttachments(prev=>({
      ...prev,
      files: prev.files.concat(...acceptedFiles),
      previews:{
        ...prev.previews,
        ...previewUrls
      }
    }))
  },[setAttachments])
  
  const handleRemove = useCallback((file)=>{
    const {[file.name]:filePreview, ...restPreviews} = attachments.previews;
    
    setAttachments(prev=>({
      ...prev,
      files: prev.files.filter((f)=>f.name !== file.name),
      previews: restPreviews
    }))
  },[attachments])
  
  return <Uploader
    onDrop={onDrop}
    handleDelete={handleRemove}
    config={config}
    fileDescription="JPG, PNG or PDF, file size no more than 150MB"
    buttonTitle="Browse"
    files={attachments.files}
    previews={attachments.previews}
    color={color}
  />
};

export default UploadMediaFiles;