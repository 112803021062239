import {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import useEditor from "../../../hooks/useEditor";
import {useDispatch, useSelector} from "react-redux";
import {setEditableFeature, setEditableLayer, setEditorDrawActive} from "../../../redux/action/Dashboard-action";
import {currAsOfDateFieldName, graphicsLayer, view} from "../../../utils/API";
import {ConfigContext} from "../../../utils/ConfigContext";
import {ALL_STEPS, EditType} from "./EditorSwiper/EditorSwiper";
import {getLayerSymbology} from "../../../utils/symbologies";
import {getFieldsByPattern} from "../../../utils/helper";
import {getFeatureNameField} from "./helpers";
import {batchUpdateFields} from "./EditorFields/EditorSituationalFields";

export const baseLineColor = '#34495e'

export const INITIAL_CLICKED_POINTS = [
  {
    id:1,
    graphic: undefined,
    coordinate:[],
    label: '',
    searchable: true
  },
  {
    id:2,
    graphic: undefined,
    coordinate:[],
    label: '',
    searchable: true
  }
]

export const UpdateType = Object.freeze({
  situational: 'situational',
  baseline: 'baseline'
})

export const UPDATE_STATUS_TYPE = Object.freeze({
  UPDATE: 'update',
  CURRENT: 'current',
});

export const EditorContext = createContext(null);

const EditorContextProvider = ({children}) => {
  const {config} = useContext(ConfigContext);
  
  const [showWarning, setShowWarning] = useState(false);
  // const [editableLayer, setEditableLayer] = useState();
  
  const [visibleEditableLayers, setVisibleEditableLayers] = useState();
  const [step, setStep] = useState('selectLayer');
  const [editableLayers, setEditableLayers] = useState([]);
  const [airdromeType, setAirdromeType] = useState(null);
  const [addedPoints, setAddedPoints] = useState([]);
  const [highlightHandle, setHighlightHandle] = useState(null);
  const [highlightSymbol, setHighlightSymbol] = useState(null);
  const {editableFeature, editableLayer, editorDrawActive} = useSelector(state => state.dashboard);
  const [attachments, setAttachments] = useState({
    previews: {},
    files: []
  });
  const [prefilledFields, setPrefilledFields] = useState([]);
  const [sketchGraphicsLayer, setSketchGraphicsLayer] = useState(null);
  const [sketchViewModel, setSketchViewModel] = useState(null);
  const [popupFeature, setPopupFeature] = useState(false);
  // const [showRoadsEditor, setShowRoadsEditor] = useState(false);
  const [updateType, setUpdateType] = useState(undefined);
  
  //for adding point in roadsSituational layer
  const [clickedPoints, setClickedPoints] = useState(INITIAL_CLICKED_POINTS);
  
  //for batch update
  const [situationalUpdateType, setSituationalUpdateType] = useState(UPDATE_STATUS_TYPE.UPDATE);
  const [batchUpdateFeatures, setBatchUpdateFeatures] = useState([]);
  
  const dispatch = useDispatch();
  const setHighlightFeature = useCallback((feature) =>{
    dispatch(setEditableFeature(feature))
  },[])
  
  const handleSetEditableLayer = useCallback((layer)=>{
    dispatch(setEditableLayer(layer));
  },[])
  
  useEffect(()=>{
    return ()=>{
      highlightHandle?.remove();
    }
  },[highlightHandle])
  
  const resetClickedPoints = useCallback(()=>{
    setClickedPoints(INITIAL_CLICKED_POINTS);
  },[])
  
  const setShowRoadsEditor = useCallback((value)=>{
    dispatch(setEditorDrawActive(value))
  },[])
  
  const resetHighlightFeature = useCallback(()=>{
    if (highlightSymbol){
      const graphicToRemove = graphicsLayer.graphics.find(g=>g.symbol.id === highlightSymbol.id)
      graphicsLayer.remove(graphicToRemove);
    }
    setHighlightSymbol(null)
    setHighlightFeature(null);
    highlightHandle?.remove();
    setHighlightHandle(null);
  },[highlightHandle, highlightSymbol])
  
  const resetBatchUpdateFeatures = useCallback(()=>{
    batchUpdateFeatures.forEach(item=>{
      graphicsLayer.remove(item.graphic);
    })
    
    setBatchUpdateFeatures([]);
  },[batchUpdateFeatures])
  
  const resetEditor = ()=>{
    setStep(EditType.edit + '-' + ALL_STEPS.selectLayer);
    resetBatchUpdateFeatures();
    setAirdromeType(null)
    setAttachments({
      previews: {},
      files: []
    });
    setPrefilledFields([])
    // removeAddedGraphics();
    sketchGraphicsLayer?.removeAll();
    graphicsLayer.removeAll();
    view.graphics.removeAll();
    view.map.remove(sketchGraphicsLayer);
    setSketchGraphicsLayer(null);
    setSketchViewModel(null);
    setPopupFeature(false);
    resetClickedPoints();
    setShowRoadsEditor(false);
    setUpdateType(undefined);
    handleSetEditableLayer(null);
    setHighlightFeature(null);
  }
  
  const requiredFields = useMemo(() => {
    if (!editableLayer) return [];
    
    const overviewTable1Fields = editableLayer.layerConfig.customPopupOps.overviewTable1Fields || [];
    const overviewTable2Fields = editableLayer.layerConfig.customPopupOps.overviewTable2Fields || [];
    
    const symbology = getLayerSymbology(editableLayer, config) || {};
    const layerMandatoryFields = editableLayer?.layerConfig?.mandatoryFields || [];
    
    const rFields = [...layerMandatoryFields];
    
    const {colorMap} = symbology;
    if (colorMap){
      if (Array.isArray(colorMap.fields)){
        rFields.push(...colorMap.fields)
      } else if (colorMap.field) {
        rFields.push(colorMap.field)
      }
    }
    
    return [...rFields, ...overviewTable1Fields, ...overviewTable2Fields];
  },[editableLayer, config])
  
  const getFields = useCallback((fields, requiredFields, filteredFields) => {
    return fields.filter((f) => f.editable
      && requiredFields.includes(f.name)
      && !filteredFields.includes(f.name)
    )
  },[])
  
  const baseLineRequiredFields = useMemo(() =>{
    if (!editableLayer && !editableLayer?.layerConfig) return [];
    
    const nameField = getFeatureNameField(editableLayer);
    const filteredFields = [nameField, ...prefilledFields.map(item=>item.fieldName)];
    const symbology = getLayerSymbology(editableLayer, config) || {};
    if (symbology.iconMap?.fields){
      const iconFields = editableLayer.fields.filter(field=> symbology.iconMap.fields.includes(field.name)).map(f=>f.name);
      filteredFields.push(...iconFields);
    }
  
    const baselineFields = getFields(getFieldsByPattern(editableLayer, editableLayer.layerConfig.baselineFields), requiredFields, filteredFields);
    
    return baselineFields;
  },[editableLayer, requiredFields, config])
  
  const activeColor = useMemo(()=>updateType === UpdateType.baseline ? baseLineColor : config.opsColor,[updateType])
  
  return (
    <EditorContext.Provider
      value={{
        editableLayer,
        visibleEditableLayers,
        setVisibleEditableLayers,
        step,
        editableLayers,
        setStep,
        setEditableLayers,
        airdromeType,
        setAirdromeType,
        addedPoints,
        setAddedPoints,
        highlightHandle,
        setHighlightHandle,
        highlightSymbol,
        setHighlightSymbol,
        highlightFeature:editableFeature,
        setHighlightFeature,
        attachments,
        setAttachments,
        prefilledFields,
        setPrefilledFields,
        sketchGraphicsLayer,
        setSketchGraphicsLayer,
        sketchViewModel,
        setSketchViewModel,
        setPopupFeature,
        popupFeature,
        showWarning,
        setShowWarning,
        clickedPoints,
        setClickedPoints,
        resetClickedPoints,
        resetHighlightFeature,
        showRoadsEditor: editorDrawActive,
        setShowRoadsEditor,
        setEditableLayer: handleSetEditableLayer,
        updateType,
        setUpdateType,
        batchUpdateFeatures,
        setBatchUpdateFeatures,
        resetBatchUpdateFeatures,
        activeColor,
        resetEditor,
        requiredFields,
        baseLineRequiredFields,
        situationalUpdateType,
        setSituationalUpdateType
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export default EditorContextProvider;
