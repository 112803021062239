import styled from "styled-components";
import {StyledPopupWrapper} from "../../MobileApp/MobilePopup/MobilePopup-styled";

export const InformationCard = styled.div`
  padding: ${({selected})=> selected ? '18px 14px' : '10px'};
  //box-shadow: rgba(0,0,0,0.15) 0px 2px 8px;//rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-shadow: ${({selected})=> selected ? '0px 0px 10px 4px rgba(153,153,153,0.3)' : undefined};
  
  border-top: 1px solid #99999957;
  //border-radius: 8px;
  margin: ${({selected})=> selected ? '8px 4px' : '0 6px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: fit-content;
  //cursor: pointer;
  transition: all 0.3s;
  text-align: left;
  //min-height: 45px;
  z-index: ${({selected})=> selected ? 10 : undefined};
  border-radius: ${({selected})=> selected ? '8px' : undefined};
  background: #FFFFFF;
  
  .image-holder {
    display: flex;
    align-items: center;
    gap: 4px;
    
    .image {
      display: flex;
    }
    
    img {
      width: 24px;
    }
  }
  
  :hover {
    //box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const CardHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  
  img {
    width: 22px;
    height: 22px;
  }
`
export const tagColors = {
  10:'#999999',
  6:'#999999',
  5:'#34495e',
  2:'#2fa69a',
  1:'#c03a2a',
}
export const Tag = styled.span`
  // background:${({bg, status})=> bg ?? (tagColors[status] ? tagColors[status] : tagColors[10])};
  color: ${({bg, status})=> bg ?? (tagColors[status] ? tagColors[status] : tagColors[10])};
  font-size: 11px;
  font-weight: 600;
  //padding: 4px 8px;
  border-radius: 14px;
  line-height: normal;
  letter-spacing: normal;
  text-wrap: nowrap;
  
`

export const CardBody = styled.div`
  //margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //gap: 2px;
  width: 100%;
  
  .comment {
    span {
      color:#6a6a6a;
    }
  }

  .tag {
    margin: 0.125rem;
    border-radius: 0.9375rem;
    white-space: nowrap;
    font-size: 10px;
    padding: 0 6px;
    height: 20px;
    width: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    background-color: #d9ecf5;
    color: #005e8d;
  }
`

export const StyledField = styled.div`

  ${({expand = false})=> !expand ? `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  ` : '' }
  
  transition: all 0.3s;
  p {
    font-size: 12px;
    color:#393738;
    line-height: normal;
    margin: 0;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap:40px;
  margin: 10px auto auto;
  height: ${({show})=> !show ? '0px' : 'auto'};
  overflow: hidden;
  transition: all 0.3s;
  
  button {
    // background: ${({color})=> color ?? '#FFFFFF'};
    // display: flex;
    // align-items: center;
    // border: none;
    // border-radius: 4px;
    // cursor: pointer;
    // transition:all 0.3s;
    // color: #FFFFFF;
    // font-weight: 500;
    // padding: 10px;
    // font-size:14px;
    //
    :hover {
      //background: #f9f6f682;
    }
  }
`

export const ReportCardWrapper = styled(StyledPopupWrapper)`
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  text-align: left;
  padding-bottom: 4px;
  cursor: pointer;
`

export const ReportCardHeader = styled.div`
  display: flex;
`
export const ReportCardFooter = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 4px;
  justify-content: space-between;
`

export const ReportCardFooterLeftSide = styled.div`
  display: flex;
  gap: 4px;
`

export const ReportCardStatus = styled.div`
  padding: 4px 8px;
  background: #c03a2a;
  border-radius: 100px;
  color: #fff !important;
  box-sizing: border-box;
  line-height: 1rem;
  display: flex;
  align-items: center;
`

export const ReportType = styled.div`
  padding: 4px 8px;
  background: #eeeeee;
  border-radius: 100px;
  box-sizing: border-box;
  line-height: 1rem;
  display: flex;
  align-items: center;
  
  span {
    font-size:12px;
    text-transform: capitalize;
  }
`

export const StyledButtonPill = styled.div`
  border-radius:100px;
  border: 1px solid #eee;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor: pointer;
  gap: 4px;
  background: #eeeeee;
  padding: 2px 10px;
  
  span {
    font-size: 12px;
  }
`
