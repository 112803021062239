import SearchInput from "./SearchInput";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";

import {
  SearchInputWrapper,
  StyledSuggestions,
  StyledSuggestItem, StyledTitle
} from "../../../Editor-styled";
import CoordinatesInputs from "../../CoordinatesInputs";
import Loader from "../../../Loader/Loader";
import {graphicsLayer, view} from "../../../../../../utils/API";
import {loadModules} from "esri-loader";
import {composeLayerQuery, searchWidget} from "../../../../../../esri/widgets/search";
import {getFeatureTitle, getLayerTitle} from "../../../../../../esri/custom-popup-content";
import {countryCodes} from "../../../../../../data/countryCodes";
import {useTranslation} from "react-i18next";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import {StyledSubmitButton} from "../../../../../Report/new/Footer/Footer-styled";
import {ConfigContext} from "../../../../../../utils/ConfigContext";
import {dangerColor} from "../../../../../../utils/Theme";
import {geojsonToArcGIS} from "@terraformer/arcgis";
import {pointGraphicSymbol} from "../RoadsSituationalManual";
import {API_KEY} from "../../../../../../hooks/useRouteCalculation";
import {EditorContext} from "../../../EditorContextProvider";
import Message from "../../../Message/Message";
import SegmentButton from "../EnterCoordinates/SegmentButton";
import CalculateRouteButton from "../EnterCoordinates/CalculateRouteButton";
import {getPointGraphics} from "../EnterCoordinates/EnterCoordinates";

const PointIcon = ({isFilled}) => (
  <svg style={{
    width: '40px',
    height: '40px',
    position: 'absolute',
    left: '-9px',
    top: '12px'
  }} stroke="#fff" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
    <path stroke="#939393" strokeWidth={3} d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
    <path fill={isFilled ? dangerColor : '#939393'} d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
  </svg>
)

const AddIcon = ()=>{
  return <svg stroke="currentColor" fill='#8a8a8ab5' strokeWidth="0" viewBox="0 0 512 512" height="22px" width="22px" xmlns="http://www.w3.org/2000/svg"><path d="M346.5 240H272v-74.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-74.5c-8.8 0-16 6-16 16s7.5 16 16 16H240v74.5c0 9.5 7 16 16 16s16-7.2 16-16V272h74.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z"></path><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path></svg>
}

const DotsIcon = () =>{
  return (
    <svg stroke="" fill="#939393" strokeWidth="0" viewBox="0 0 256 256" height="16px" width="16px" xmlns="http://www.w3.org/2000/svg">
      <path d="M112,60a16,16,0,1,1,16,16A16,16,0,0,1,112,60Zm16,52a16,16,0,1,0,16,16A16,16,0,0,0,128,112Zm0,68a16,16,0,1,0,16,16A16,16,0,0,0,128,180Z" />
    </svg>
  )
}

const SearchCoordinates = ({addPointToMap, removeSearchPoint, handleCreateRoute, setActiveIndex, activeIndex, color, calculatedPoints, setManualDrawPoint}) => {
  const {setClickedPoints, clickedPoints, setShowRoadsEditor} = useContext(EditorContext);
  const [showOtherCoordinates, setShowOtherCoordinates] = useState(true);
  const [showNoResults, setShowNoResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [search, setSearch] = useState(null);
  const [editedItems, setEditedItems] = useState([]);
  const [message, setMessage] = useState({
      show: false,
      index: -1,
      removeInput: false
  });
  const {t} = useTranslation('common');
  const {config} = useContext(ConfigContext);
  const ref = useRef();
  
  useClickOutside(ref, ()=>{
    setShowOtherCoordinates(false);
    setSuggestions([]);
  })
  
  useEffect(()=>{
    graphicsLayer.removeAll();
    const createWidget = async () =>{
      const [Search, LayerSearchSource, Query, Graphic] = await loadModules(["esri/widgets/Search", "esri/widgets/Search/LayerSearchSource", "esri/rest/support/Query", "esri/Graphic"]);
      const sw = new Search({
        view: view,
        locationEnabled: false,
        includeDefaultSources: false,
        minSuggestCharacters: 2,
        sources: searchWidget?.sources || [],
        popupEnabled:false,
        resultGraphicEnabled:false
      });
      
      const promises = []
      
      view.map.layers.forEach(layer=>{
        promises.push(layer.load());
      })
      
      let initialExtent = view.extent;
      if (!initialExtent)
        initialExtent = view.extent
      
      //adding sources if we don't have search widget
      if (!searchWidget) {
        Promise.all(promises).then(layerRes=>{
          layerRes.forEach(layer=>{
            if (layer.type !== "feature")
              return null
            const searchConfig = layer.layerConfig?.search
            if (searchConfig && searchConfig.enabled === false)
              return
      
            //exclude not feature layers and the global country layer
            if (!layer.fields || layer.title === "World Countries (Generalized)")
              return
      
            let searchFields = layer.fields.filter(field => field.type === "string").map((field) => field.name)
            if (searchFields.length === 0)
              return
      
            //Filter fields
            if (searchConfig && searchConfig.searchFields){
              const fields = searchConfig.searchFields
              if (!Array.isArray(fields) || fields.length === 0) {
                console.warn("Layer configuration error found. Layer: " + layer.title +
                  " Error: searchField attribute must be an array of strings with at least one value")
              } else {
                searchFields = searchFields.filter((layerField) => fields.includes(layerField))
              }
            }
      
            //Is layer already loaded?
            if (sw.sources.filter((locator) => locator.layer && locator.layer === layer).length > 0)
              return
      
            let layerSearchSource = new LayerSearchSource({
              layer: layer,
              name: getLayerTitle(layer, t),
              popupEnabled: false,
              enableSuggestions: true,
              getSuggestions: (params) => {
                if (!layer.visible)
                  return
          
                const query = composeLayerQuery(params.suggestTerm, Query, config, layer, searchFields)
                return layer.queryFeatures(query).then((results) => {
                  return results.features.map((feature) => {
                    let suggestion = getFeatureTitle(feature, t, config)
                    if (!suggestion)
                      suggestion = t("screen.widget.Search.noTitle", "No title")
              
                    return {
                      key: feature.attributes[layer.objectIdField] ? feature.attributes[layer.objectIdField] : "key",
                      text: suggestion,
                      sourceIndex: params.sourceIndex,
                      feature: feature
                    }
                  })
                })
              },
              getResults: (params) => {
                if (!params.suggestResult || !params.suggestResult.feature)
                  return null
          
                return new Promise( (resolve) => {
                  const feature = params.suggestResult.feature
                  const graphic = new Graphic({
                    geometry: feature.geometry,
                    attributes: feature.attributes
                  })
            
                  //Mark the result, that is coming from the layer search source
                  return resolve ( [{
                    feature: feature,
                    target: graphic,
                    isLayerSearchSourceResult: true
                  }])
                })
              },
              filter: layer.definitionExpression ? {where: layer.definitionExpression} : {},
              exactMatch: false,
              outFields: ["*"]
            })
      
            if (initialExtent)
              layerSearchSource.zoomScale=  initialExtent.width / 3
      
            sw.sources.push(layerSearchSource)
            sw.sources.sort((a, b) => {
              if (a.layer && !b.layer)
                return -1
              else if (!a.layer && b.layer)
                return 1
        
              return a.name.localeCompare(b.name)
            })
          })
        })
  
        const arcgisSource = {
          name: t("screen.widget.Search.geocoding", "ArcGIS World Geocoding Service"),
          url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
          singleLineFieldName: "SingleLine", //to search by coordinates on copy paste
          zoomScale: initialExtent?.extent?.width / 3
        }
  
        const country = countryCodes.find((country) => Array.isArray(config.iso3) ? config.iso3.includes(country.ISO3) : country.ISO3 === config.iso3)
  
        sw.sources.push(arcgisSource)
  
        if (country) {
          arcgisSource.countryCode = country.ISO2;
        }
      }
  
      sw.when(()=>{
        setSearch(sw);
      })
    }
    
    createWidget();
  },[])
  
  const handleAdd = useCallback((index = undefined)=>{
    const newElement = {
      id: clickedPoints.length + 1,
      graphic: undefined,
      coordinate: [],
      searchable: true
    }
    
    if (index !== undefined){
      setActiveIndex(index + 1);
      const newPoints = [...clickedPoints.slice(0, index + 1), newElement, ...clickedPoints.slice(index + 1, clickedPoints.length)];
      
      
      const currPoint = clickedPoints[index];
      const nextPoint = clickedPoints[index+1];
      const routeId = `route-${currPoint.coordinate.join('|')}-${nextPoint.coordinate.join('|')}`;
      
      const graphicsToRemove = []
      graphicsLayer.graphics.forEach(g=>{
        if (g.id){
          if (g.id === routeId){
            graphicsToRemove.push(g);
          }
          if (g.id.includes(`line-${currPoint.coordinate.join('|')}`)){
            graphicsToRemove.push(g);
          }
          
          if (Array.isArray(g.intersectedPoints) && g.intersectedPoints.includes(currPoint.coordinate.join('|'))){
            graphicsToRemove.push(g);
          }
        }
      })
      
      graphicsLayer.removeMany(graphicsToRemove);
      
      setClickedPoints(newPoints)
    } else {
      // setActiveIndex(clickedPoints.length);
      const newPoints = [...clickedPoints, {
        graphic: undefined,
        coordinate: []
      }]
      setClickedPoints(newPoints)
    }
  },[clickedPoints])
  
  // const showAddMore = useMemo(()=>{
  //   return clickedPoints.filter(item=>item.coordinate.length === 0).length === 0
  // },[clickedPoints])
  
  const handleSelectResult = useCallback((result)=>{
    const {feature, ...rest} = result;
    
    search.goToOverride = (view, params)=>{
      const targetFeature = params.target.target;
      targetFeature.name = rest.text;
      
      addPointToMap(targetFeature, activeIndex);
      view.goTo({ target: params.target.target, zoom: 8});
      const {signal} = params;
      signal.abort();
    }
    
    search.search(result);
    setShowOtherCoordinates(false);
  },[activeIndex, addPointToMap, search])
  
  const handleSubmitMessage = useCallback((index, removeInput)=>{
    removeSearchPoint(index, removeInput);
    setSuggestions([]);
    setLoading(false);
    setMessage({
      show: false,
      index: -1,
      removeInput: false
    })
  },[removeSearchPoint]);
  
  const resetInput = useCallback((index, removeInput = false)=>{
    // const point = clickedPoints[index];
    // const pointGraphics = getPointGraphics({graphicsLayer, point});
    //
    // if (pointGraphics.length > 0 && (index > 0 && index < clickedPoints.length - 1)) {
    //   setMessage(({
    //     show: true,
    //     index,
    //     removeInput
    //   }));
    // } else {
    //   handleSubmitMessage(index, removeInput);
    // }
  
    handleSubmitMessage(index, removeInput);
  },[removeSearchPoint, handleSubmitMessage, clickedPoints])
  
  const handleInputChange = useCallback(async (e, index)=>{
    setShowNoResults(false);
    if (!e.target.value){
      resetInput(index);
      return;
    }
    
    setLoading(true);
    try {
      const suggestResult = await search.suggest(e.target.value);
      if (!suggestResult) {
        return;
      }
      
      const {results} = suggestResult;
      const availableResults = [];
      results.forEach(result=>{
        if (result.results.length === 0) {
          return;
        }
        availableResults.push({
          label: result.source.name,
          results :result.results
        });
      })
  
      setLoading(false);
      if (availableResults.length === 0){
        setShowNoResults(true);
        setSuggestions(availableResults);
      } else {
        setSuggestions(availableResults);
        setShowOtherCoordinates(true);
        
      }
    } catch (err){
      setLoading(false);
    }
  },[search, resetInput])
  
  const handleCoordinates = useCallback((point)=>{
    addPointToMap({geometry: point}, activeIndex);
  },[activeIndex, addPointToMap]);
  
  const activeCoordinates = useMemo(()=>{
    if (activeIndex > -1 && clickedPoints.length > activeIndex && clickedPoints[activeIndex].coordinate.length > 0){
      const [longitude, latitude] = clickedPoints[activeIndex].coordinate;
      return {
        longitude,
        latitude
      }
    }
    
    return null;
  },[clickedPoints, activeIndex])
  
  const handleFocus = useCallback((e, index)=>{
    const point = clickedPoints[index];
    
    if (point.graphic){
      view.goTo(point.graphic)
    }
    
    setShowOtherCoordinates(true);
  },[activeIndex, clickedPoints])
  
  const handleRemoveInput = useCallback((index)=>{
    setActiveIndex(-1);
    resetInput(index, true);
  },[resetInput, activeIndex])
  const searchWrapper = useRef();
  
  useEffect(()=>{
    if (searchWrapper.current){
      const nodeList = searchWrapper.current.querySelectorAll('input.search-input');
      if (nodeList.length > 0){
        setTimeout(()=>{
          nodeList[0].focus()
        },600)
      }
    }
  },[])
  
  return (
    <div style={{display:'flex', flexDirection:'column', gap:8}}>
      {message.show && (
        <Message
          title="Are your sure?"
          subtitle="Routes to/from this point will be removed"
          onCancel={()=>{
            setMessage(({
              show: false,
              index: -1,
              removeInput: false
            }))
          }}
          onSubmit={()=>handleSubmitMessage(message.index, message.removeInput)}
        />
      )}
      <div style={{paddingBottom:10, display:'flex', flexDirection:'column', borderBottom:'1px solid #9393934D'}}>
        <div style={{display:'flex', flexDirection:'column', gap:28}} ref={searchWrapper}>
          {
            clickedPoints.map((item, index)=> {
              let nextPoint, isRoutable;
              if (index < clickedPoints.length) {
                nextPoint = clickedPoints[index + 1];
                isRoutable = nextPoint?.searchable && item?.searchable;
              }
              
              return (
                <SearchInputWrapper key={item.id}>
                  <div style={{position:'absolute', top:45, left:0, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    {clickedPoints.length -1 !== index && (
                      <>
                        <DotsIcon />
                        <div onClick={() => {
                          handleAdd(index);
                        }} style={{cursor: "pointer", display: "flex"}}>
                          <AddIcon/>
                        </div>
                      </>
                    )}
                    {
                      index < clickedPoints.length - 1 && (
                        <DotsIcon />
                      )
                    }
                  </div>
                  <div style={{width:22, height:22}}>
                    <PointIcon isFilled={item.coordinate.length > 0} />
                  </div>
                  <SearchInput
                    showCoordinates={showOtherCoordinates}
                    setShowOtherCoordinates={setShowOtherCoordinates}
                    point={item}
                    handleInputChange={(e)=>handleInputChange(e, index)}
                    isActive={activeIndex === index}
                    className="search-input"
                    placeholder={index === 0 ? 'Choose a starting point or click on the map' :
                      index < clickedPoints.length - 1 ? 'Choose intermediate point' : 'Choose destination point'}
                    onFocus={(e)=>{
                      setActiveIndex(index);
                      handleFocus(e, index);
                    }}
                  />
                  {
                    (item.coordinate.length > 0 && nextPoint?.coordinate?.length > 0) && (
                      <SegmentButton
                        point={item}
                        nextPoint={nextPoint}
                        editedItems={editedItems}
                        isRoutable={isRoutable}
                        index={index}
                        calculatedPoints={calculatedPoints}
                        onClick={()=>{
                          const newClickedPoints = [...clickedPoints];
                          const newItem = {...item}
                          newItem.edited = true;
                          newClickedPoints[index] = newItem;
                          setEditedItems(prev=>prev.concat(index));
                          
                          setManualDrawPoint(item);
                          setShowRoadsEditor(true);
                        }}
                      />
                    )
                  }
                  {index !== 0 && index !== clickedPoints.length -1 && (
                    <button
                      onClick={()=>handleRemoveInput(index)}
                      style={{
                        border:'none',
                        background:'none',
                        display:'flex',
                        alignItems:'center',
                        cursor:'pointer',
                        marginTop:10
                      }}>
                      <svg stroke="#939393" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeMiterlimit="10" strokeWidth="32" d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"></path><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320 192 192m0 128 128-128"></path></svg>
                    </button>
                  )}
                </SearchInputWrapper>
              )
            })
          }
        </div>
        <CalculateRouteButton
          config={config}
          handleCreateRoute={handleCreateRoute}
          clickedPoints={clickedPoints}
        />
        {/*<div style={{*/}
        {/*  display:'flex',*/}
        {/*  justifyContent:'space-between',*/}
        {/*  margin: '28px auto auto auto',*/}
        {/*  flexDirection:'column',*/}
        {/*  alignItems:'center',*/}
        {/*  justifyItems:'center'*/}
        {/*}}>*/}
        {/*  {config.editorRouteApiEnabled && <StyledSubmitButton*/}
        {/*    style={{*/}
        {/*      height: "auto",*/}
        {/*    }}*/}
        {/*    disable={disableCalculate}*/}
        {/*    onClick={handleCreateRoute}*/}
        {/*  >*/}
        {/*    Calculate route*/}
        {/*  </StyledSubmitButton>}*/}
        {/*</div>*/}
      </div>
      <StyledSuggestions
        ref={ref}
        style={{
          opacity: showOtherCoordinates ? 1 : 0,
          pointerEvents: showOtherCoordinates ? 'auto' : 'none',
        }}>
    
        {
          loading && <Loader position="absolute" />
        }
        {
          showNoResults ? <div>
            <StyledTitle>No results found</StyledTitle>
          </div> : showOtherCoordinates && (
            !!suggestions.length ? (
              suggestions.map((suggestion)=>(
                <div key={suggestion.label}>
                  <div style={{padding:'8px 8px', fontSize:12, background:'#9393934D', borderRadius:2}}>
                    <span>{suggestion.label}</span>
                  </div>
                  <div style={{padding:'4px 0px'}}>
                    {
                      suggestion.results.map(result=>(
                        <StyledSuggestItem
                          key={result.key}
                          onClick={()=>handleSelectResult(result)}
                          color="#ffffff"
                        >
                          <p>{result.text}</p>
                        </StyledSuggestItem>
                      ))
                    }
                  </div>
                </div>
              ))
            ) : (
              <div>
                <CoordinatesInputs
                  showAll={true}
                  showTitle={false}
                  addPointToNewFeature={handleCoordinates}
                  geometry={activeCoordinates}
                  color={color}
                />
              </div>
            )
          )
        }
      </StyledSuggestions>
    </div>
  );
};

export default SearchCoordinates;
