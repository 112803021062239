import styled from 'styled-components';

export const StyledStatusBarWrapper = styled.div`
  flex:1;
  padding: 4px 8px;
`

export const StyledStatusBar = styled.div`
  position: relative;

  span {
    position: absolute;
    font-size: 12px;
    left: 0;
    top: -4px;
  }
`

export const StyledChecklistHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const CheckListTabWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  overflow: auto;
  flex:2;
  padding: 0 4px;
`

export const StyledCheckListTab = styled.div`
  position: relative;
  cursor: pointer;
  min-width: 48px;
  min-height: 48px;
  
  ::after {
    content: '';
    height: 4px;
    background: var(--opsColor);
    width: 100%;
    position: absolute;
    bottom: -4px;
    left: 0;
    opacity: ${({selected})=>selected ? 1 : 0};
    transition: all 0.15s;
  }
  
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    svg {
      //width: 40px !important;
      //height: 40px !important;
    }
  }
`

export const StyledCheckListTableWrapper = styled.div`
  flex:1;
  
  .ag-root-wrapper {
    border: 1px solid #9e9e9e30;
  }
  
  .ag-cell-dirty {
    border: 1px solid #1E90FFFF;
  }
`

export const StyledCheckListFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px;
  height: 40px;
  align-items: center;
`

export const BreakdownCard = styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  align-items:center;
  
  span {
    color: #525252;
  }
`