import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {getLayerSymbology} from "../../../../../utils/symbologies";
import {iconNameToIcon} from "../../../../Icons/layerSvg/clusterAssets";
import {LayerIconWrapper} from "../../../../ManualWidgets/ManualWidget-styled";
import {ConfigContext} from "../../../../../utils/ConfigContext";
import {CheckListTabWrapper, StyledChecklistHeader, StyledStatusBarWrapper} from "../../Monitoring-styled";
import ChecklistTab from "./ChecklistTab";
import StatusBar from "./StatusBar";
import LayerIcon from "../../../../Icons/LayerIcon";

const ChecklistHeader = ({
  layers,
  onSelect,
  selectedLayer,
  layerUpdates
}) => {
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  
  const getTabTitle = (layer) => {
    const symbology = getLayerSymbology(layer, config);
    if (symbology){
      const icon = iconNameToIcon(symbology.defaultIcon);
      return icon ? <LayerIconWrapper dangerouslySetInnerHTML={{__html:icon}} /> : null
    }
    return <span>{layer.getLayerTitle(t)[0]}</span>
  }
  
  return (
    <StyledChecklistHeader>
      <StyledStatusBarWrapper>
        <StatusBar value={0.3} color="green" title="Overall"/>
        <StatusBar value={0.4} color="#FD6F02" title="Infrastructure"/>
        <StatusBar value={0.5} color="blue" title="Reports"/>
      </StyledStatusBarWrapper>
      <CheckListTabWrapper>
        {
          layers.map(l=>(
            <ChecklistTab
              selected={selectedLayer === l}
              onClick={()=>onSelect(l)}
              layerUpdate={layerUpdates[l.id]}
            >
              <LayerIcon
                layer={l}
                config={config}
                t={t}
                opsColor={config.opsColor}
                width={34}
                height={34}
                textColor="#000"
              />
            </ChecklistTab>
          ))
        }
      </CheckListTabWrapper>
    </StyledChecklistHeader>
  );
};

export default ChecklistHeader;
