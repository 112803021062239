import React, {useEffect, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {chevronLeft16} from "@esri/calcite-ui-icons";
import {renderCell} from "../../../../utils/helper";
import {openClusterPopup} from "../../../../utils/clustering";
import {loadModules} from "esri-loader";
import {view} from "../../../../utils/API";
import {setClusterFeature} from "../../../../redux/action/ClusterFeature-action";
import {StyledNavButton} from "../../style/ClusterDetails/ClusterDetails-styled";
import getClusterAttributeFields from "../../helpers/getClusterAttributeFields";

const ClusterDetails = ({fields, feature, t, config, layer, children}) => {
  const cluster = layer.layerConfig.cluster
  const {graphic, title, tabIndex, features} = useSelector(state=>state.clusterFeature);
  const dispatch = useDispatch();
  const listener = useRef();
  
  useEffect(()=>{
    if (!view) return;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) =>{
      listener.current = reactiveUtils.when(
        () => !view.popup.visible,
        () => {
          dispatch(setClusterFeature(null))
        }
      )
    })
    
    return ()=>{
      dispatch(setClusterFeature(null));
      listener.current?.remove();
    }
  },[])
  
  const clusterFields = useMemo(()=> getClusterAttributeFields(cluster, fields, t),[fields, t])
  
  return (
    <div style={{width:'100%', marginTop:10}}>
      <div style={{display:'flex', alignItems:'center', marginBottom:4}}>
        <StyledNavButton
          onClick={()=>{
            listener.current?.remove();
            openClusterPopup({
              graphic,
              title,
              config,
              t,
              layer,
              tabIndex,
              clusterFeatures: features
            })
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path d={chevronLeft16} />
          </svg>
          <span style={{fontSize:10}}>
            {clusterFields.fieldTitle1}
            {clusterFields.fieldTitle2 && ` / ${clusterFields.fieldTitle2}`}
          </span>
        </StyledNavButton>
      </div>
      <span style={{fontSize:10}}>
				{renderCell(clusterFields.field2, feature.attributes[clusterFields.field2?.name], t, config)}
			</span>
      {children}
    </div>
  );
};

export default ClusterDetails;
