import {StyledSelectWrapper} from "./StyledSelect-styled";
import Select from "react-select";
import {borderColor, dangerColor} from "../../utils/Theme";

const StyledSelect = ({label, opsColor, ...props}) => {
  return (
    <StyledSelectWrapper>
      {label && <span>{label}</span>}
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            color:'#000',
            borderColor: state.isFocused && opsColor ? opsColor : baseStyles.borderColor,
            boxShadow: state.isFocused && opsColor ? `0 0 0 1px ${opsColor}` : baseStyles.boxShadow,
            '&:hover': {
              borderColor: state.isFocused && opsColor ? opsColor : baseStyles.borderColor,
            },
          }),
          option:(baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected && opsColor ? opsColor : state.isFocused && opsColor ? `${opsColor}1a` : baseStyles.backgroundColor,
            color: state.isSelected && opsColor ? '#fff' : baseStyles.color,
            '&:hover': {
              backgroundColor: !state.isSelected ? `${opsColor}1a` : opsColor,
            },
          }),
        }}
        {...props}
      />
    </StyledSelectWrapper>
  );
};

export default StyledSelect;
