import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {loadModules} from "esri-loader";
import {composeLayerQuery, isCoordSearch} from "../../esri/widgets/search";
import {getFeatureTitle, getLayerTitle} from "../../esri/custom-popup-content";
import {countryCodes} from "../../data/countryCodes";
import Input from "../Report/new/Input/Input";
import {SearchWidgetWrapper, StyledIconBox, StyledSuggestions, StyledSuggestItem} from "./SearchWidget-styled";
import {StyledSearchBox} from "../Dashboard/Editor/Editor-styled";
import Loader from "../Dashboard/Editor/Loader/Loader";
import CoordinatesInputs from "../Dashboard/Editor/CoordinatesSelection/CoordinatesInputs";
import useLayersChange from "../../hooks/useLayersChange";
import useClickOutside from "../../hooks/useClickOutside";
import {clickEventOnFeature} from "../../utils/helper";
import {ArrowLeftIcon, MOBILE_ICON_COLOR, SearchIcon} from "../Icons";
import {StyledFiltersBackButton} from "../MobileApp/MobileFilter/MobileFilter-styled";

const patterns = {
  dd: /^(\s*[NSns+-]?\s*)(\d+([.,]\d+)?)(\s*[NSns+-]?\s*)(\s*[-,\s;\/]\s*)(\s*[EWOewo+-]?\s*)(\d+([.,]\d+)?)(\s*[EWOewo+-]?\s*)\s*$/,
  ddm: /^(\s*[NSns+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*(\s*[NSns+-]?\s*)([,-\s;/]\s*)([EWOewo+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*([EWOewo+-]?\s*)$/,
  dms: /^(\s*[NSns+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*(\d+(\.\d+)?)[`"′]\s*(\s*[NSns+-]?\s*)([,-\s;/]\s*)([EWOewo+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*(\d+(\.\d+)?)[`"′]\s*([EWOewo+-]?\s*)$/
};

const martinPatterns = {
  dd: /^(\s*[NSns+-]?\s*)(\d+(\.\d+)?)(\s*[,\s;/]\s*)([EWOewo+-]?\s*)(\d+(\.\d+)?)\s*$/,
  ddm: /^(\s*[NSns+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*([,\s;/]\s*)([EWOewo+-]?\s*)(\d+)[°\s]+(\d+(\.\d+)?)[`'′]\s*$/,
  dms: /^(\s*[NSns+-]?\s*)(\d+)[°\s]+(\d+)[`'′]\s+(\d+(\.\d+)?)[\"”″]\s*([,\s;/]\s*)([EWOewo+-]?\s*)(\d+)[°\s]+(\d+)[`'′]\s+(\d+(\.\d+)?)[\"”″]\s*$/
}


const checkForCoordinates = (text) => {
  console.log(text.match(martinPatterns.dd))
  console.log(text.match(martinPatterns.ddm))
  console.log(text.match(martinPatterns.dms))
}

export const SearchBox = ({value, onChange, onBackClick, children, isMobileApp, rotate = 'rotate(0deg)', ...props}) => (
  <SearchWidgetWrapper isMobileApp={isMobileApp}>
    <div className="search__box">
      {!!onBackClick && (
        <StyledFiltersBackButton
          style={{
            display:"flex",
            position:'absolute',
            top:'50%',
            transform:`translateY(-50%) ${rotate}`,
            zIndex:1,
            alignItems: 'center',
            height: 34,
            left: 3,
          }}
          onClick={onBackClick}
        >
          <ArrowLeftIcon />
        </StyledFiltersBackButton>
      )}
      <Input
        borderColor="#939393"
        value={value}
        onChange={onChange}
        margin="0px"
        {...props}
      >
        <StyledIconBox>
          <div
            onClick={() => {
              onChange({
                target: {
                  value: ""
                }
              })
            }}
            style={{
              opacity: value ? 1 : 0,
              pointerEvents: value ? "auto" : "none",
              display: "flex"
            }}>
            <svg
              stroke="currentColor"
              fill="#939393"//{isMobileApp ? MOBILE_ICON_COLOR : '#6F7072'}
              strokeWidth="0"
              viewBox="0 0 512 512"
              width='18px'
              height='18px'
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"/>
            </svg>
          </div>
          <SearchIcon
            width='18px'
            height='18px'
            fill={isMobileApp ? MOBILE_ICON_COLOR : '#6F7072'}
          />
        </StyledIconBox>
      </Input>
    </div>
    {children}
  </SearchWidgetWrapper>
)

const SearchWidget = ({config, t, view, onBackClick, isMobileApp, rotate, isExpanded}) => {
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showCoordinates, setShowCoordinates] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const {layerCount, visibleLayersIds} = useLayersChange(config);
  const [activeCoordinates, setActiveCoordinates] = useState(null);
  
  const ref = useRef();
  const expandedRef = useRef();
  
  const removeSearchGraphic = ()=> {
    if (!view)return;
    const searchGraphic = view.graphics.find(g=>g?.symbol?.url && g?.symbol?.url.includes('search'))
  
    if (searchGraphic){
      view.graphics.remove(searchGraphic)
    }
  }
  
  const resetSearch = useCallback(()=>{
    setValue('');
    setShowCoordinates(false);
    setShowNoResults(false);
    setLoading(false);
    setSuggestions([]);
    setActiveCoordinates(null);
    
    if (search){
      removeSearchGraphic();
      search.clear();
    }
    clearTimeout(timer.current);
  },[search])
  
  useClickOutside(ref, ()=>{
    setShowCoordinates(false);
    setShowNoResults(false);
  })
  
  useEffect(()=>{
    if (!isExpanded){
      resetSearch();
    }
    expandedRef.current = isExpanded;
  },[isExpanded])
  
  const initialExtent = useMemo(()=>{
    return view.extent
  },[])
  
  const addLayerToSearch = async (layer, sw) =>{
    const [LayerSearchSource, Query, Graphic] = await loadModules(["esri/widgets/Search/LayerSearchSource", "esri/rest/support/Query", "esri/Graphic"]);
    
    if (layer.type !== "feature")
      return null
    const searchConfig = layer.layerConfig?.search
    if (searchConfig && searchConfig.enabled === false)
      return
  
    //exclude not feature layers and the global country layer
    if (!layer.fields || layer.title === "World Countries (Generalized)")
      return
  
    let searchFields = layer.fields.filter(field => field.type === "string").map((field) => field.name)
    if (searchFields.length === 0)
      return
  
    //Filter fields
    if (searchConfig && searchConfig.searchFields){
      const fields = searchConfig.searchFields
      if (!Array.isArray(fields) || fields.length === 0) {
        console.warn("Layer configuration error found. Layer: " + layer.title +
          " Error: searchField attribute must be an array of strings with at least one value")
      } else {
        searchFields = searchFields.filter((layerField) => fields.includes(layerField))
      }
    }
  
    //Is layer already loaded?
    if (sw.sources.filter((locator) => locator.layer && locator.layer === layer).length > 0)
      return
  
    let layerSearchSource = new LayerSearchSource({
      layer: layer,
      name: getLayerTitle(layer, t),
      popupEnabled: false,
      enableSuggestions: true,
      getSuggestions: (params) => {
        if (!layer.visible)
          return
      
        const query = composeLayerQuery(params.suggestTerm, Query, config, layer, searchFields)
        return layer.queryFeatures(query).then((results) => {
          return results.features.map((feature) => {
            let suggestion = getFeatureTitle(feature, t, config)
            if (!suggestion)
              suggestion = t("screen.widget.Search.noTitle", "No title")
          
            return {
              key: feature.attributes[layer.objectIdField] ? feature.attributes[layer.objectIdField] : "key",
              text: suggestion,
              sourceIndex: params.sourceIndex,
              feature: feature
            }
          })
        })
      },
      getResults: (params) => {
        if (!params.suggestResult || !params.suggestResult.feature)
          return null
      
        return new Promise( (resolve) => {
          const feature = params.suggestResult.feature
          const graphic = new Graphic({
            geometry: feature.geometry,
            attributes: feature.attributes
          })
        
          //Mark the result, that is coming from the layer search source
          return resolve ( [{
            feature: feature,
            target: graphic,
            isLayerSearchSourceResult: true
          }])
        })
      },
      filter: layer.definitionExpression ? {where: layer.definitionExpression} : {},
      exactMatch: false,
      outFields: ["*"]
    })
  
    if (initialExtent)
      layerSearchSource.zoomScale=  initialExtent.width / 3
  
    sw.sources.push(layerSearchSource)
    sw.sources.sort((a, b) => {
      if (a.layer && !b.layer)
        return -1
      else if (!a.layer && b.layer)
        return 1
    
      return a.name.localeCompare(b.name)
    })
  }
  
  useEffect(() => {
    const createWidget = async () =>{
      const [Search] = await loadModules(["esri/widgets/Search"]);
      const sw = new Search({
        view: view,
        locationEnabled: false,
        includeDefaultSources: false,
        minSuggestCharacters: 2,
        sources: [],
        popupEnabled:false,
        resultGraphicEnabled: true,
      });
      
      const arcgisSource = {
        name: t("screen.widget.Search.geocoding", "ArcGIS World Geocoding Service"),
        url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
        singleLineFieldName: "SingleLine", //to search by coordinates on copy paste
        zoomScale: initialExtent?.extent?.width / 3
      }
  
      const country = countryCodes.find((country) => Array.isArray(config.iso3) ? config.iso3.includes(country.ISO3) : country.ISO3 === config.iso3)
  
      if (country) {
        arcgisSource.countryCode = country.ISO2;
      }
  
      sw.sources.push(arcgisSource)
      sw.when(()=>{
        setSearch(sw);
  
        sw.goToOverride = (view, params)=>{
          if (!isCoordSearch(sw.searchTerm) || window.isSmall){
            setShowCoordinates(false);
          }
          
          return view.goTo({ target: params.target.target, scale: initialExtent.width / 3})
        }
      })
    }
    
    createWidget();
    
    return ()=>{
      removeSearchGraphic();
    }
  },[])
  
  useEffect(()=>{
    if (!search || !view) return;
    const visibleLayers = view.map.layers.filter(l=>visibleLayersIds[l.id]);
    visibleLayers.forEach(l=>{
      addLayerToSearch(l, search);
    })
    
  },[layerCount, search])
  
  const timer = useRef(0);
  const handleInputChange = useCallback(async (e)=>{
    setShowNoResults(false);
    setValue(e.target.value);
    if (search){
      removeSearchGraphic();
      search.clear();
    }
    
    clearTimeout(timer.current);
    timer.current = setTimeout(async ()=>{
      // checkForCoordinates(e.target.value);
      if (!e.target.value){
        setShowNoResults(false);
        setSuggestions([]);
        setActiveCoordinates(null);
        return;
      }
  
      if (isCoordSearch(e.target.value)){
        
        const [latitude, longitude] = e.target.value.split(',');
        const point = {
          latitude: latitude.trim(),
          longitude: longitude.trim()
        }
        
        handleCoordinates(point);
      } else {
        setLoading(true);
        try {
          const suggestResult = await search.suggest(e.target.value);
          
          if (!suggestResult || !expandedRef.current) {
            return;
          }
      
          const {results} = suggestResult;
          const availableResults = [];
          results.forEach(result=>{
            if (result.results.length === 0) {
              return;
            }
            availableResults.push({
              label: result.source.name,
              results: result.results,
              key: `${Math.random()}-${result.source.name}`
            });
          })
          
          setLoading(false);
          if (availableResults.length === 0){
            setShowNoResults(true);
            setSuggestions(availableResults);
          } else {
            setSuggestions(availableResults);
            setShowCoordinates(true);
          }
        } catch (err){
          setLoading(false);
        }
      }
    },250)
  },[search])
  
  const handleSelectResult = useCallback((result)=>{
    const {feature, text, ...rest} = result;
    
    search.search(result).then(res=>{
      if (res?.results?.length > 0){
        const {results = [], source} = res.results[0] || {};
        if (results.length > 0){
          const {feature, isLayerSearchSourceResult} = results[0];
  
          setActiveCoordinates({
            latitude: feature.geometry.latitude,
            longitude: feature.geometry.longitude
          })
  
          if (feature){
            clickEventOnFeature(feature, {
              zoom: false,
              duration: 100,
              showPopup: (!!source.layer?.layerConfig?.clickable && isLayerSearchSourceResult)},
            );
          }
        }
      }
    });
    
    setValue(text);
    setShowCoordinates(false);
  },[search])
  
  const handleFocus = useCallback(()=>{
    setShowCoordinates(true);
  },[])
  
  const handleBlur = useCallback(()=>{
    // setShowCoordinates(false);
  },[])
  
  const handleCoordinates = useCallback((point)=>{
    setActiveCoordinates({
      latitude: point.latitude,
      longitude: point.longitude
    })
    
    const searchValue = `${point.longitude}, ${point.latitude}`;
    
    setValue(searchValue);
    search.search(searchValue);
  },[search]);
  
  return (
    <SearchBox
      value={value}
      onChange={handleInputChange}
      placeholder={t("screen.widget.Search.placeholder", "Search on LogIE")}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onBackClick={onBackClick}
      isMobileApp={isMobileApp}
      rotate={rotate}
      disabled={!search}
    >
      <StyledSearchBox ref={ref}>
        <StyledSuggestions
          isMobileApp={isMobileApp}
          style={{
            opacity: showCoordinates ? 1 : 0,
            pointerEvents: showCoordinates ? "auto" : "none",
          }}>
          {
            (loading && !showNoResults) && <Loader position="absolute"/>
          }
         
          <div style={{flex:1, overflow:'auto'}}>
            {
              showNoResults ? <div>
                <StyledSuggestItem>No results found</StyledSuggestItem>
              </div> : showCoordinates && (
                !!suggestions.length ? (
                  suggestions.map((suggestion) => (
                    <div key={suggestion.key}>
                      <div style={{padding: "8px 8px", fontSize: 12, background: "#9393934D", borderRadius: 2}}>
                        <span>{suggestion.label}</span>
                      </div>
                      <div>
                        {
                          suggestion.results.map(result => (
                            <StyledSuggestItem
                              key={result.key}
                              onClick={() => handleSelectResult(result)}
                              color="#ffffff"
                            >
                              <p>{result.text}</p>
                            </StyledSuggestItem>
                          ))
                        }
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{padding: "0px 4px"}}>
                    <CoordinatesInputs
                      showAll={true}
                      showTitle={false}
                      addPointToNewFeature={handleCoordinates}
                      geometry={activeCoordinates}
                      color="#8c9ba5"
                      t={t}
                    />
                  </div>
                )
              )
            }
          </div>
        </StyledSuggestions>
      </StyledSearchBox>
    </SearchBox>
  );
};

export default SearchWidget;
