import {DropdownTooltip, StyledDropdownBody} from "./Dropdown-styled";

//type can be default and description
const DropdownBody = ({children, type = 'default' ,...props}) => {
  if (type === 'default') {
    return <StyledDropdownBody {...props}>
      {children}
    </StyledDropdownBody>
  }
  
  if (type === 'tooltip') {
    return <StyledDropdownBody {...props}>
      <DropdownTooltip>
        {children}
      </DropdownTooltip>
    </StyledDropdownBody>
  }
};

export default DropdownBody;