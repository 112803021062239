import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {view} from "../../../utils/API";
import {isEditable} from "./helpers/helpers";
import {setEditableLayer} from "../../../redux/action/Dashboard-action";
import EditorContextProvider from "./EditorContextProvider";
import EditorSlides from "./EditorSwiper/EditorSlides";
import useLayersChange from "../../../hooks/useLayersChange";

const EditorPlatform = ({
  handleGoBack,
  editType,
  handleMainSlideChange,
  setEditType,
  editableLayers
}) => {
  // const [visibleEditableLayers, setVisibleEditableLayers] = useState();
  const [step, setStep] = useState('');
  // const [editableLayers, setEditableLayers] = useState([]);
  const {editableLayer} = useSelector(state=>state.dashboard);
  // const visibleLayers = useRef({});
  const dispatch = useDispatch();
  // const {visibleLayersIds, layerCount} = useLayersChange();
  
  useEffect(()=>{
    console.log({
      'popup': view.popupEnabled,
      'popupCustom': view.popupEnabledCustom
    })
    
    view.popupEnabledCustom = false;
    view.popupEnabled = false;
    //close popup
    // handleClosePopup();
    // store.dispatch(closePopupSidebar());
    
    let layerViewFilters = {};
    view.layerViews.forEach(lv=>{
      if (lv.filter?.where) {
        layerViewFilters[lv.layer.id] = lv.filter.where;
        lv.filter.where = '';
      }
    })
    
    // updateLayers(view.map.layers)
    // view.map.layers.on("change", (event) => {
    //   if (event.added)
    //     updateLayers(event.added)
    // })
    //todo check when print widget is open
    return () => {
      view.layerViews.forEach(lv=>{
        if (layerViewFilters[lv.layer.id]) {
          lv.filter.where = layerViewFilters[lv.layer.id];
        }
      })
      layerViewFilters = {};
      view.popupEnabled = true;
      view.popupEnabledCustom = true;
    }
  },[])
  
  // const groupLayers = (layers) => {
  //   const layersByLabel = {};
  //
  //   layers.forEach(l=>{
  //     const label = l.layerConfig?.titleLabel;
  //     if (label && l.visible) {
  //       if (layersByLabel[label]) {
  //         layersByLabel[label].groupedIds.push(l.id)
  //       } else {
  //         layersByLabel[label] = l;
  //         layersByLabel[label].groupedIds = [l.id];
  //       }
  //     }
  //   })
  //   return layersByLabel;
  // }
  
  // const groupedLayers = useMemo(()=>{
  //   return groupLayers(editableLayers)
  // },[editableLayers])
  
  // useEffect(()=>{
  //   let timer;
  //   timer = setTimeout(()=>{
  //     updateLayers(view.map.layers);
  //   },200)
  //   return ()=>{
  //     clearTimeout(timer);
  //   }
  // },[layerCount])
  
  // const updateLayers = (newLayers) => {
  //   const newEditableLayers = newLayers.filter((l) => isEditable(l) && visibleLayersIds[l.id])
  //
  //   if (newEditableLayers.length) {
  //     setEditableLayers(newEditableLayers)
  //   }
  // }
  
  const handleEditableLayer = useCallback((layer) =>{
    dispatch(setEditableLayer(layer));
  },[]);
  
  return (
    <EditorContextProvider>
      <EditorSlides
        editableLayer={editableLayer}
        editableLayers={editableLayers}
        setEditableLayer={handleEditableLayer}
        setStep={setStep}
        step={step}
        handleGoBack={handleGoBack}
        editType={editType}
        handleMainSlideChange={handleMainSlideChange}
        setEditType={setEditType}
      />
    </EditorContextProvider>
  );
};

export default EditorPlatform;