import styled from "styled-components";
import {CalciteTab} from "@esri/calcite-components-react";

export const StyledTab = styled(CalciteTab)`
  position: relative;
  height: 100%;
  width: 100%;
  --calcite-tab-content-block-padding:0;
`

export const StyledHeaderDate = styled.span`
  font-size: 10px;
  color: #999;
  font-weight: 500;
  display: inline-block;
  text-wrap: nowrap;
`

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 6px ${({showBorder})=>showBorder ? '8px' : '0px'} 6px;
  border-bottom: ${({showBorder})=> showBorder ? '1px solid #ededed' : ''};
  position: relative;
  
  @media screen and (max-width: 769px) {
    padding-top: 0;
  }
`

export const StyledHeaderTitle = styled.div`
  display:flex;
  align-items:center;
  gap:8px;
  padding-top: 14px;
  margin: auto;
  
  p {
    margin: 0;
    color: #393738;
    line-height: 1;
    font-weight: 500;
    font-size: 20px;
    text-wrap: nowrap;
  }
  span {
    text-wrap: nowrap;
  }

  @media screen and (max-width: 768px) {
    display: none;
    padding-top: 0;
  }
`