import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {getFieldsByPattern} from "../../../../utils/helper";
import {getLayerSymbology} from "../../../../utils/symbologies";
import {EditorContext, UpdateType} from "../EditorContextProvider";
import {EditType} from "../EditorSwiper/EditorSwiper";
import AttributeFields from "./AttributeFields/AttributeFields";
import UploadMediaFiles from "../../../Editor/UploadMediaFiles/UploadMediaFiles";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import {AccordionBody, AccordionButton, AccordionWrapper} from "./Accordion/Accordion";
import CoordinatesSelection from "../CoordinatesSelection/CoordinatesSelection";
import {getFeatureNameField} from "../helpers";

const EditorDefaultFields = ({editableLayer, requiredFields, isRequired, editType, handleChange}) => {
  const [selectedId, setSelectedId] = useState(undefined);
  
  const {
    attachments,
    setAttachments,
    highlightSymbol,
    highlightFeature,
    setHighlightFeature,
    setAddedPoints,
    popupFeature,
    updateType,
    batchUpdateFeatures,
    activeColor,
    prefilledFields,
    previews,
    setPreviews
  } = useContext(EditorContext);
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  
  const getFields = useCallback((fields, requiredFields, filteredFields) => {
    return fields.filter((f) => f.editable
      && (isRequired ? requiredFields.includes(f.name) : !requiredFields.includes(f.name))
      && !filteredFields.includes(f.name)
    )
  },[isRequired, prefilledFields])
  
  const accordion = useMemo(()=>{
    const el = editableLayer;
    if (!el) return [];
    const lc = el.layerConfig
    
    const nameField = getFeatureNameField(editableLayer);
    const filteredFields = [nameField, ...prefilledFields.map(item=>item.fieldName)];
    const symbology = getLayerSymbology(editableLayer, config) || {};
    if (symbology.iconMap?.fields){
      const iconFields = editableLayer.fields.filter(field=> symbology.iconMap.fields.includes(field.name)).map(f=>f.name);
      filteredFields.push(...iconFields);
    }
    
    const baselineFields = getFields(getFieldsByPattern(el, lc.baselineFields), requiredFields, filteredFields);
    const visibilityFields = getFields(getFieldsByPattern(el, lc.visibilityFields), requiredFields, filteredFields);
    
    const acc = [];
    const fields = [];
    
    if (updateType === UpdateType.baseline || editType === EditType.create || popupFeature){
      if (baselineFields.length > 0) {
        acc.push({
          id:'baseline',
          label:t('screen.widget.Editor.baselineFields.label'),
          component: (
            <AttributeFields
              fields={baselineFields}
              highlightFeature={highlightFeature}
              config={config}
              t={t}
              symbology={symbology}
              onChangeHandler={handleChange}
              editableLayer={editableLayer}
              requiredFields={requiredFields}
            />
          )
        })
      }
      
      
      if (updateType === UpdateType.situational || !isRequired){
        const situationalFields = getFields(getFieldsByPattern(el, lc.situationalFields), [], filteredFields);
        if (situationalFields.length > 0) {
          acc.push({
            id:'situational',
            label:t('screen.widget.Editor.situationalFields.label'),
            component: (
              <AttributeFields
                highlightFeature={highlightFeature}
                fields={situationalFields}
                config={config}
                t={t}
                symbology={symbology}
                editableLayer={editableLayer}
                requiredFields={requiredFields}
              />
            )
          })
        }
      }
      
      if (visibilityFields.length > 0) {
        // acc.push({
        //   id:'visibility',
        //   label: t('screen.widget.Editor.visibilityFields.label'),
        //   component: (
        //     <AttributeFields
        //       fields={visibilityFields}
        //       highlightFeature={highlightFeature}
        //       config={config}
        //       t={t}
        //       symbology={symbology}
        //       onChangeHandler={handleChange}
        //       editableLayer={editableLayer}
        //       requiredFields={requiredFields}
        //     />
        //   ),
        // })
      }
      
      acc.push({
        id: 'coordinates',
        label: editableLayer.geometryType === 'point' ? 'Coordinates' : '' ,
        show: editableLayer.geometryType === 'point' && popupFeature,
        component: (
          editableLayer.layerConfig.titleLabel !== 'roadsSituational' ? (
            <CoordinatesSelection
              config={config}
              editableLayer={editableLayer}
              editType={editType}
              clickActive= {false}
            />
          ) : null
        )
      })
      
      if (editableLayer.capabilities.operations.supportsQueryAttachments && !isRequired){
        acc.push({
          id:'upload',
          label: t('screen.widget.Editor.mediaFiles.label'),
          component: (
            <UploadMediaFiles
              layer={editableLayer}
              feature={highlightFeature}
              t={t}
              attachments={attachments}
              setAttachments={setAttachments}
              config={config}
              previews={previews}
              setPreviews={setPreviews}
              color={activeColor}
            />
          )
        })
      }
    }
    
    return acc;
  },[editableLayer, selectedId, highlightFeature, popupFeature, editType, t, attachments, setAttachments, previews, setPreviews, config, config, updateType, prefilledFields, getFields, requiredFields, activeColor])
  
  const handleAccordionSelection = useCallback((id)=>{
    if (selectedId === id) {
      setSelectedId('');
    } else {
      setSelectedId(id);
    }
  },[selectedId])
  
  return (
    <>
      {
        accordion.map((item, index)=>{
          const isSelected = (selectedId !== undefined ? item.id === selectedId : index === 0) || accordion.length === 1;
          return (
            <AccordionWrapper key={item.id} id={item.id} selected={isSelected} show={item.show}>
              <>
                <AccordionButton
                  selected={isSelected || accordion.length === 1}
                  label={item.label}
                  config={config}
                  onSelect={accordion.length > 1 ? ()=>handleAccordionSelection(item.id) : undefined}
                  description="Section description"
                  color={activeColor}
                />
                <AccordionBody
                  selected={isSelected}
                >
                  {item.component ?? (isRequired ? null : <UploadMediaFiles
                    layer={editableLayer}
                    feature={highlightFeature}
                    t={t}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    config={config}
                  />)}
                </AccordionBody>
              </>
            </AccordionWrapper>
          )
        })
      }
      {/*<div style={{display:'none'}}>*/}
      {/*  {*/}
      {/*    editableLayer.layerConfig.titleLabel !== 'roadsSituational' ? (*/}
      {/*      <CoordinatesSelection*/}
      {/*        config={config}*/}
      {/*        editableLayer={editableLayer}*/}
      {/*        editType={editType}*/}
      {/*        clickActive= {false}*/}
      {/*      />*/}
      {/*    ) : null*/}
      {/*  }*/}
      {/*</div>*/}
    </>
  );
};

export default EditorDefaultFields;