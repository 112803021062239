import {useCallback, useEffect, useState} from "react";
import {loadModules} from "esri-loader";
import {graphicsLayer, view} from "../utils/API";
import {calculateDistanceFromPixels, queryLayer} from "../components/Dashboard/Editor/helpers";
import {geojsonToArcGIS} from "@terraformer/arcgis";
import {useSelector} from "react-redux";
import {lineSymbol} from "../components/Dashboard/Editor/EditorSwiper/EditorSwiper";
import {selectionLineSymbol} from "../components/Dashboard/Editor/CoordinatesSelection/RoadsCoordinates";
import {baseLineColor} from "../components/Dashboard/Editor/EditorContextProvider";

export const API_KEY = '5b3ce3597851110001cf624829be8a817ed84d45abfc70dfdfcef81a';

const useRouteCalculation = (
  clickedPoints,
  setAddedPoints,
  requestHandle,
) => {
  const [loading, setLoading] = useState(false);
  // const [startLookingPossibleRoutes, setStartLookingPossibleRoutes] = useState(false);
  const [errorPoints, setErrorPoints] = useState([]);
  const {editableLayer} = useSelector(state=>state.dashboard);
  
  const handleCreateRoute = async () => {
    setLoading(true);
    const [Graphic, geometryEngine, Point, Polyline, Extent, esriRequest, webMercatorUtils, geometryEngineAsync] = await loadModules([
      "esri/Graphic",
      "esri/geometry/geometryEngine",
      "esri/geometry/Point",
      "esri/geometry/Polyline",
      "esri/geometry/Extent",
      "esri/request",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/geometryEngineAsync"
    ])
  
    if (clickedPoints.length < 2) return;
    
    const allPoints = graphicsLayer.graphics.filter(g=>g.id && g.id.includes('point')).toArray();
    const pointsPolyline = new Polyline({
      paths: allPoints.map(p=>([p.geometry.longitude, p.geometry.latitude]))
    })
    if (pointsPolyline.extent){
      await view.goTo(pointsPolyline.extent.expand(1.5), {
        duration: 1500,
      })
    }
    
    const routePoints = [];
    const graphicIds = [];
    graphicsLayer.graphics.forEach(g=>{
      if (g.id && g.id.includes('route')){
        graphicIds.push(g.id);
      }
    })
    
    clickedPoints.forEach((point, index, array)=>{
      let nextPoint;
      if (index < array.length - 1){
        nextPoint = array[index + 1];
      }
      
      if (nextPoint?.searchable && point.searchable ){
        const id = point.coordinate.join('|') + '-' + nextPoint.coordinate.join('|');
        if (!graphicIds.includes(`route-${id}`)) {
          routePoints.push({
            id: point.coordinate.join('|') + '-' + nextPoint.coordinate.join('|'),
            coordinate: [point.coordinate, nextPoint.coordinate],
            points: [point, nextPoint]
          })
        }
      }
    })
    
    const routePromises = routePoints.map(route=>{
      return esriRequest('https://api.openrouteservice.org/v2/directions/driving-hgv/geojson', {
        method: 'POST',
        responseType: 'json',
        body: JSON.stringify({
          "coordinates": route.coordinate,
          "radiuses": [6000]
        }),
        headers: {
          'Authorization': API_KEY,
          'Content-type': 'application/json'
        }
      })
    })
    
    
    try {
      const routes = await Promise.all(routePromises);
      const routeGraphicPromises = [];
      routes.forEach((route, index)=>{
        const id = routePoints[index].id;
        const points = routePoints[index].points;
        const {data} = route;
        if (!data) return;
  
        const [feature] = geojsonToArcGIS(data);
        const polyline = new Polyline({
          paths: feature.geometry.paths,
          spatialReference: feature.spatialReference
        })
        routeGraphicPromises.push(handleQueryLayer(polyline, id, points));
      })
      
      // graphicsLayer.graphics.forEach(g=>{
      //   if (g.id && g.id.includes('route')){
      //     graphicsLayer.remove(g);
      //   }
      // })
      const result = await Promise.allSettled(routeGraphicPromises);
      // setLoading(false);
   
    } catch (err){
      console.log(err);
      setTimeout(()=>{
        requestHandle?.onError()
        // setLoading(false);
      },1000)
    } finally {
      setLoading(false);
    }
  }
  
  const findAllLines = (clickedPoints) => {
    const possibleRoutes = [];
    const promises = [];
    clickedPoints.forEach((start,i)=>{
      const allCoordinates = [];
      if (clickedPoints.length - 1 > i){
        clickedPoints.forEach((destination,index)=>{
          if (i !== index){
            const coordinates = [start, destination];
            allCoordinates.push(coordinates);
          }
        })
      }
      possibleRoutes.push(allCoordinates);
    })
  
    possibleRoutes.forEach(arr=>{
      arr.forEach(coordinates=>{
        promises.push(handleRequest(coordinates));
      })
    })
    
    
    return Promise.all(promises)
  }
  
  
  const handleRequest = async (points) => {
    try {
      const [esriRequest] = await loadModules(["esri/request"]);
      const route = await esriRequest('https://api.openrouteservice.org/v2/directions/driving-hgv/geojson', {
        method: 'POST',
        responseType: 'json',
        body: JSON.stringify({
          "coordinates": points.map(item => item.coordinate),
          "radiuses": [6000],
        }),
        headers: {
          'Authorization': '5b3ce3597851110001cf624829be8a817ed84d45abfc70dfdfcef81a',
          'Content-type': 'application/json'
        }
      })
      return route;
    } catch (err){
      return null;
    }
  }
  
  const handleQueryLayer = async (polyline, id, clickedPoints) =>{
    let routeGraphic, requestedPolylineGraphic;
    const layer = view.map.layers.find(l=>l.layerConfig?.titleLabel === 'roads');
    
    const [Graphic, geometryEngine, Point, Polyline, Extent, esriRequest, webMercatorUtils, geometryEngineAsync] = await loadModules([
      "esri/Graphic",
      "esri/geometry/geometryEngine",
      "esri/geometry/Point",
      "esri/geometry/Polyline",
      "esri/geometry/Extent",
      "esri/request",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/geometryEngineAsync"
    ])
    
    const bufferNumber = calculateDistanceFromPixels(view.zoom >= 9 ? 0.2 : 0.004 , view, Point, Extent);
    
    const drawBuffer = await geometryEngineAsync.geodesicBuffer(polyline, bufferNumber, 'meters')
    
    const res = await queryLayer(layer, drawBuffer, bufferNumber, [],{
      geometryEngine,
      geometryEngineAsync,
      Polyline,
      Graphic,
      webMercatorUtils,
      Point
    })
    
    if (!res){
      console.log(res);
      return;
    }
    
    requestedPolylineGraphic = res;
    const geometry = webMercatorUtils.geographicToWebMercator(requestedPolylineGraphic.geometry);
    requestedPolylineGraphic.geometry = geometry;
    requestedPolylineGraphic.id = 'route-' + id;
    const apiStartPoint = webMercatorUtils.geographicToWebMercator(polyline.getPoint(0,0));
    const apiEndPoint = webMercatorUtils.geographicToWebMercator(polyline.getPoint(0,polyline.paths[0].length - 1));
    const points = [apiStartPoint, apiEndPoint];
  
    // graphicsLayer.add({
    //   symbol: selectionLineSymbol,
    //   geometry: polyline
    // })
    
    const promises = points.map(p=>{
      return geometryEngineAsync.nearestVertex(requestedPolylineGraphic.geometry, p);
    })
  
    // const nearest1 = await geometryEngineAsync.nearestCoordinate(res.geometry, points[0].geometry);
    // const neares2 = await geometryEngineAsync.nearestCoordinate(res.geometry, points[1].geometry);
    // console.log(res.geometry, nearest1, neares2);
    
    const pointResults = await Promise.all(promises);
    
    const paths = [];
    
    pointResults.forEach((coordinateResult, index)=>{
      const point = points[index];
      console.log(point, index);
      paths.push([[point.x, point.y], [coordinateResult.coordinate.x, coordinateResult.coordinate.y]])
    })
    // console.log(points);
    // points.forEach((point, index)=>{
    //   paths.push([point.geometry.longitude, point.geometry.latitude]);
    // })
    //
    const pointsPolyline = new Polyline({
      paths: paths,
      spatialReference: view.spatialReference
    })
    
    // graphicsLayer.add({
    //   symbol: selectionLineSymbol,
    //   geometry: pointsPolyline
    // })
    
    const unioned = await geometryEngineAsync.union([pointsPolyline, requestedPolylineGraphic.geometry]);
    
    requestedPolylineGraphic.geometry = unioned;
    // console.log(unioned);
    
    // requestedPolylineGraphic.geometry.paths.forEach(path=>{
    //   const [startX, startY] = path[0];
    //   const [endX, endY] = path[path.length - 1];
    //   console.log(startY)
    //   const startPoint = new Point({
    //     latitude: startX,
    //     longitude: startY
    //   })
    //
    //   const endPoint = new Point({
    //     x: endX,
    //     y: endY
    //   })
    //
    //   const startGraphic = new Graphic({
    //     symbol: pointGraphicSymbol,
    //     geometry: startPoint
    //   })
    //
    //   const endGraphic = new Graphic({
    //     symbol: pointGraphicSymbol,
    //     geometry: endPoint
    //   })
    //
    //
    //   graphicsLayer.add(startGraphic);
    //   graphicsLayer.add(endGraphic);
    // })
    // const end = requestedPolylineGraphic.geometry.paths[requestedPolylineGraphic.geometry.paths.length - 1]
    // const start = requestedPolylineGraphic.geometry.paths[0];
    
    // console.log(end[end.length-1], start[0]);
    // const newStartPoint = new Point({
    //   longitude: start[0][0],
    //   latitude: start[0][1],
    //   spatialReference:view.spatialReference
    // })
    // console.log(points.toArray()[0], points)
    // points.toArray()[0].geometry = newStartPoint;
    
    
    // requestedPolylineGraphic.geometry = geometry;
    // const graphic = graphicsLayer.graphics.find(g=>g.id && g.id.includes('route'));
    
    // if (graphic){
    //   // graphic.geometry = geometry;
    // } else {
    //
    // }
    graphicsLayer.add(requestedPolylineGraphic);
    
    // points.forEach(p=>{
    //   if (p.geometry.x <= 180 || p.geometry.y <= 180) {
    //     const geometry = webMercatorUtils.geographicToWebMercator(p.geometry);
    //     console.log(geometry);
    //
    //     const graphic = new Graphic({
    //       id: p.id,
    //       geometry: geometry,
    //       symbol: p.symbol,
    //       spatialReference: view.spatialReference
    //     })
    //     graphicsLayer.add(graphic);
    //   } else {
    //     graphicsLayer.add(p);
    //   }
    // })
    requestHandle?.onSuccess(geometry)
  }
  
  useEffect(()=>{
    const routeGraphics = graphicsLayer.graphics.filter(g=>g.id && g.id.includes('route'));
    
    // const clickedGraphics = [];
    // clickedPoints.forEach((point=>{
    //   const graphic = graphicsLayer.graphics.find(g=>g.id && g.id.includes(`line-${point.coordinate.join('|')}`));
    //   if (graphic){
    //     clickedGraphics.push(graphic.id);
    //   }
    // }))
  
    // const manualAddedGraphicsToRemove = graphicsLayer.graphics.filter(g=>clickedGraphics.includes(g.id));
    
    if (routeGraphics.length > 0){
      // graphicsLayer.removeMany(routeGraphics);
    }
    
    // if (manualAddedGraphicsToRemove.length > 0){
    //   // graphicsLayer.removeMany(manualAddedGraphicsToRemove);
    // }
  },[clickedPoints])
  
  useEffect(()=>{
    const getGraphics = (geometryEngine) =>{
      try {
        const emptyPoints = clickedPoints.filter(p=>p.coordinate.length === 0);
        if (emptyPoints.length === 0){
          const graphicsArray = graphicsLayer.graphics.toArray();
    
          const graphicsIds = [];
          const intersectedIds = [];
          graphicsArray.forEach(g=>{
            if (!g.id || g.id.includes('point')) return;
            if (Array.isArray(g.intersectedPoints)){
              intersectedIds.push(...g.intersectedPoints);
            }
            graphicsIds.push({
              id: g.id,
              geometry: g.geometry
            });
          })
    
          const coordinates = clickedPoints.filter(point=>graphicsIds.some(item=>item.id.includes(point.coordinate.join('|'))));
          const ids = clickedPoints.filter(point=>intersectedIds.includes(point.coordinate.join('|')));

          if (coordinates.length + ids.length >= clickedPoints.length){
            const union = geometryEngine.union(graphicsIds.map(g=>g.geometry));
            setAddedPoints([union]);
          } else {
          
          }
        } else {
          setAddedPoints([]);
        }
      }catch (err){
        console.log(err);
        return null;
      }
    }
    
    
    let handler;
    loadModules(["esri/core/reactiveUtils", "esri/geometry/geometryEngine"]).then(([reactiveUtils, geometryEngine])=>{
      view.whenLayerView(graphicsLayer).then(lv=>{
        handler = reactiveUtils.watch(
          () => lv.updating,
          (updating) => {
            if (!updating){
              getGraphics(geometryEngine)
            }
          });
      })
      getGraphics(geometryEngine);
    })
    return ()=>{
      handler?.remove();
    }
  },[clickedPoints]);
  
  return {
    loading,
    handleCreateRoute,
    errorPoints
  }
};

export default useRouteCalculation;