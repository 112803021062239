import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {CalciteTabTitle} from '@esri/calcite-components-react'
import {
	StyledTabSection,
} from "./CustomPopupWrapper-styled";
import {ROLE_EDITOR} from "../../utils/helper";
import DocumentsTab from "./components/DocumentsTab";
import ImagesTab from "./components/ImagesTab";
import OverViewTab from "./components/OverViewTab";
import DetailsTab from "./components/DetailsTab";
import {useSelector} from "react-redux";
import getAllowedFields from "./helpers/getAllowedFields";
import PopupBody from "../MobileApp/MobilePopup/PopupBody";
import SnackbarProvider from '../../components/SnackBar'
import {view} from "../../utils/API";

const CustomPopupWrapper = ({ actions, row, config, t, i18n}) => {
	const layer = row.sourceLayer? row.sourceLayer : row.layer
	const fields = layer.fields
	const layerConfig = layer.layerConfig
	const layerSettings = layerConfig.customPopupOps
	const opsColor = config.opsColor
	const {clusterFeature} = useSelector(state=>state);
	let [attachments, setAttachments] = useState([])
	let [activeTab, setActiveTab] = useState(0)

	const title = layer.getLayerTitle(t)
	useEffect(() => {
		if (!layer.capabilities.operations.supportsQueryAttachments)
			return

		layer.queryAttachments({objectIds: [row.attributes[layer.objectIdField]]}).then((response) => {
			setAttachments(Object.values(response).flat(1))
		})
	}, [row, fields, layer])


	const refreshAttachments = () => {
		layer.queryAttachments({objectIds: [row.attributes[layer.objectIdField]]}).then((response) => {
			setAttachments(Object.values(response).flat(1))
		})
	}

	const goToAttachmentTab = () => {
		setActiveTab(2)
	}


	const getFields = (filterFields) => {
		if (!Array.isArray(filterFields))
			return null

		return allowedFields.filter(field => filterFields.includes(field.name))
	}

	const allowedFields = getAllowedFields(layer, row)

	const tabNavs = [], tabContents = []
	const overviewTable1Fields = getFields(layerSettings.overviewTable1Fields)
	const overviewTable2Fields = getFields(layerSettings.overviewTable2Fields)
	let images = attachments?.filter((att) => att.contentType.startsWith("image"))

	if (overviewTable1Fields.length > 0 || overviewTable2Fields.length > 0 ) {
		tabNavs.push(<CalciteTabTitle key="overview">{t('screen.popup.overview')}</CalciteTabTitle>)
		tabContents.push(
			<StyledTabSection key="overview">
				<OverViewTab layer={layer} overviewTable1Fields={overviewTable1Fields} overviewTable2Fields={overviewTable2Fields}
						images={images} feature={row} t={t} i18n={i18n} config={config} goToImageTab={goToAttachmentTab}/>
			</StyledTabSection>)
	}

	tabNavs.push(<CalciteTabTitle key="details">{t('screen.popup.details')}</CalciteTabTitle>)
	tabContents.push(
		<StyledTabSection key={"det"}>
			<DetailsTab fields={allowedFields} row={row} t={t} i18n={i18n} config={config} />
		</StyledTabSection>
	)

	const isEditable = config.role === ROLE_EDITOR && layer.capabilities.data.supportsAttachment
	if (images.length !== 0) {
		tabNavs.push(<CalciteTabTitle key="media">{t('screen.popup.media')}</CalciteTabTitle>)
		tabContents.push(<StyledTabSection key={"mediaTab"}><ImagesTab key="mediaTab" layer={layer} isEditable={isEditable} attachments={images} feature={row} refreshAttachments={refreshAttachments} /></StyledTabSection>)
	}

	let documents = attachments?.filter((att) => !att.contentType.startsWith("image"))
	if (isEditable || documents.length !== 0) {
		tabNavs.push(<CalciteTabTitle key="documents">{t('screen.popup.documents')}</CalciteTabTitle>)
		tabContents.push(<StyledTabSection key={"docTab"}><DocumentsTab key="docTab" layer={layer} isEditable={isEditable} attachments={documents} feature={row} refreshAttachments={refreshAttachments} /></StyledTabSection>)
	}
	
	// if (clusterFeature){
	// 	return <StyledCustomPopupWrapperContainer opsColor={config.opsColor}>
	// 		<StyledPopupTitleWrapper>
	// 			<StyledPopUpTitle>{title}</StyledPopUpTitle>
	// 			<PopupReportButton config={config} actions={actions} t={t} />
	// 		</StyledPopupTitleWrapper>
	// 		<ClusterDetails fields={allowedFields} feature={row} t={t} config={config} layer={layer}>
	// 			<CalciteTabs style={{width:'100%'}} onTabChange={setActiveTab} activeTabIndex={activeTab}>
	// 				<CalciteTabNav opsColor={opsColor} activeTab={activeTab + 1}  slot="title-group" >
	// 					{tabNavs}
	// 				</CalciteTabNav>
	// 				{tabContents}
	// 			</CalciteTabs>
	// 		</ClusterDetails>
	// 	</StyledCustomPopupWrapperContainer>
	// } else {
	//
	// }
	
	useEffect(()=>{
		view.popup.visibleElements.closeButton = false;
	},[])
	
	return <SnackbarProvider>
		<PopupBody
			config={config}
			t={t}
			i18n={i18n}
			selectedFeature={row}
			expand={true}
			setFullScreenActive={()=>{
			
			}}
			isPopup={true}
		/>
	</SnackbarProvider>
}

CustomPopupWrapper.propTypes = {
	row: PropTypes.object,
	config: PropTypes.object,
	t: PropTypes.func
}

export default CustomPopupWrapper;
