import styled from "styled-components";
import {CalciteTabs, CalciteTab, CalciteTabNav} from '@esri/calcite-components-react'
import {Swiper} from 'swiper/react'

export const StyledCustomPopupWrapperContainer = styled.div`
	font-family: 'Open Sans', sans-serif !important;
    display: flex;
    width: 100%;
    flex-direction: column;
  	height: 100%;
	align-items: center;
	justify-content: space-around;

  --calcite-color-brand:${({opsColor})=>opsColor};
  --calcite-color-brand-hover: transparent;
  
  .swiper-slide {
    padding-bottom: 8px;
    box-sizing: border-box;
    
    @media screen and (min-width: 768px){
      max-height: 183px;
      overflow:auto;
    }
  }
`;

export const StyledTabSection = styled(CalciteTab)`
	border: none;
	padding: 0;
	background-color: #F6F6F6 !important;
`;

export const StyledTabs = styled(CalciteTabs)`
	width: 100%;
	background-color: #F6F6F6;
`;

export const StyledTabContents = styled(CalciteTab)`
	border: none;
	padding: 0;
  	background-color: #F6F6F6;
`;

export const StyledTable = styled.div`
	border: none;
	border-radius: 4px !important;
	border-collapse: separate;
	line-height: inherit;
	padding: 8px;
	margin: 12px 0 5px 0;
	&:nth-child(n){
		background-color: #fff;
	}
`;

export const StyledPopupSwiper = styled(Swiper)`
  	width: 100%;
 	height: 100%;
	margin: 5px 0;
	border-radius: 4px;
		.swiper-slide {
  			width: 100%;
		}
		.swiper-slide img {
  			display: block;
  			width: 100%;
  			height: 150px !important;
  			object-fit: cover;
			object-position: center;
		}
		.swiper-button-next,
		.swiper-button-prev {
			position: relative;
			display: ${({ length }) => ( length > 1 ? "block" : "none")};;
		}
		& > .swiper-button-prev {
			left: 7px;
			position: absolute;
    			&::after {
      				content: url("${process.env.PUBLIC_URL + "/assets/Prev-popUp.svg"}");
    			}
  		}
  		& > .swiper-button-next {
			position: absolute;
			right: 7px;
    			&::after {
      				content: url("${process.env.PUBLIC_URL + "/assets/Next-popUp.svg"}");
    			}
  		}
`;

export const OverviewImage = styled.img`
	cursor: pointer;
`;

export const StyledTabNav = styled(CalciteTabNav)`
	background-color: #F6F6F6;
	padding: 0;
	margin: 0;
	gap: 16px;
	align-items: center;
		& > a {
		font-size: 14px;
		line-height: 20px;
		height: 30px;
		border: none;
		margin: 8px 0 0 0;
		color: #333945 !important;
		background-color: #F6F6F6;
		position: relative;
		padding: 0 4px;
			&:active,
			&:hover,
			&:focus {
				background-image: none;
				background-color: #F6F6F6;
			}
			&::before {
				content: '';
				display: none;
				width: 100%;
				height: 2px;
				background-color: ${props => props.opsColor};
				border-radius: 2px 2px 0 0;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}

		& > :nth-child(${props => props.activeTab})  .interaction-container {
            color: ${props => props.opsColor} !important;
                &::before {
                    display: block;
                }
		}
`;

export const StyledPopupTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

export const StyledPopUpTitle = styled.span`
	font-weight: 600 !important;
	font-size: 10px !important;
	line-height: 12px !important;
	padding: 0;
	margin: 0 !important;
	text-align: left;
	color: #031C2D;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const DocumentsList = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 10px;
`;

export const StyledReportButton = styled.button`
  margin-left: auto;
  white-space: nowrap;
  border: none;
  background: #b21b0c; //fallback color
  background: var(--opsColor);
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 5px;
`
