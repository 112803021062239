import styled from "styled-components";
import {mutedColor} from "../../utils/Theme";

export const TextAreaWrapper = styled.div`
  display:flex;
  flex-direction:column;
  text-align:left;
  position: relative;
  margin-bottom: ${({noMargin})=>noMargin ? '' : '20px' };
  width: inherit;
  
  & span {
    font-family: 'Open Sans', sans-serif !important;
    width: auto;
    padding: 0 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5em;
    color: ${mutedColor};
    text-align: left;
    border-radius: 4px;
    pointer-events: none;
    transition: all 0.5s;
    position: absolute;
    top: 10px;
    left: 8px;
    background: #FFFFFF;
  }

  & textarea::placeholder {
    opacity: 0;
    transition: all 0.7s;
    color: #cbcbcb;
  }

  & textarea:focus {
    border: ${({borderColor, error})=>`1px solid ${error ? '#ff0000' : borderColor ?? '#C0392B'}`};;
    ::placeholder {
      opacity: 1;
    }
  }

  textarea:not(:placeholder-shown) + span {
    top: -9px;
    font-size: 10px;
  }

  & textarea:focus + span {
    top: -9px;
    font-size: 10px;
  }
`

export const TextAreaStyled = styled.textarea`
  width: auto;
  height: ${({height})=> height + 'px' ?? '160px'};
  resize: none;
  border-radius: 10px;
  padding: 8px 14px;
  color: #25282b;
  font-size: 14px;
  outline: none;
  background: #ffffff;
  border: ${({borderColor, error})=>`1px solid ${error ? '#ff0000' : borderColor ?? '#C0392B'}4D`};
  transition: all 0.7s;

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #535353;
  }
`

export const MaxLength = styled.p`
  margin: 0;
  position: absolute;
  right: 10px;
  bottom: -8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5em;
  color: ${mutedColor};
  background: #FFFFFF;
  border-radius: 4px;
  pointer-events: none;
  padding: 2px 2px;
`

export const DescriptionWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 0;
  transform: translateY(-50%);
  background: white;
  display: flex;
  cursor: pointer;
`