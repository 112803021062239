import {StyledButton} from "../../Editor-styled";
import React, {useState} from "react";
import Dropdown, {DropdownBody} from "../../../../Dropdown";

export const AccordionWrapper = ({id, style = {}, selected, children, show = true, ...props}) =>(
  <div
    style={{
      display: show ? 'block' : 'none',
      transition: 'all 0.5s',
      overflow: selected ? 'auto' : 'visible',
      minHeight: 29,
      padding: '0px 14px',
      ...style
    }}
    {...props}
  >
    {children}
  </div>
)

export const AccordionButton = ({selected, color, label, onSelect, description}) => {
  const [show, setShow] = useState(false);
  
  return (
    <div style={{display:'flex', alignItems:'center', gap:8, position:'sticky', top:0, zIndex:1, background:'#fff'}}>
      <StyledButton
        type="button"
        hover={false}
        style={{
          fontStyle:'normal',
          fontSize:14,
          fontWeight:500,
          color: color,
          cursor:onSelect ? 'pointer' : '',
          padding:0,
        }}
        expand={selected}
        kind="ghost"
        small
        onClick={onSelect}>
        <span>{label}</span>
        {onSelect &&
          <svg className="arrow" fill={color} fillRule="evenodd" height="7" role="img" viewBox="0 0 12 7" width="10"
               aria-label=""><title></title>
            <path d="M6.002 5.55L11.27 0l.726.685L6.003 7 0 .685.726 0z"></path>
          </svg>}
      </StyledButton>
      {description && (
        <Dropdown
          setShow={setShow}
          show={show}
          leftOffset={0.3}
          hasMinWidth={false}
        >
          <div style={{
            display:'flex',
            cursor: 'pointer'
          }}>
            <svg fill={color} fillRule="evenodd" height="10" role="img" viewBox="0 0 16 16" width="10" aria-label="info" alt="info"><title>info</title><circle cx="8" cy="4" r="1"></circle><path d="M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 118 0a8 8 0 010 16z"></path><path d="M9 13H7V7h2z"></path></svg>
          </div>
          <DropdownBody type="tooltip" style={{display: "flex"}}>
            {description}
          </DropdownBody>
        </Dropdown>
      )}
    </div>
  );
};

export const AccordionBody = ({
  selected,
  children
}) =>{
  return (
    <div style={{
      display: "grid",
      gridTemplateRows: selected ? "1fr" : "0fr",
      transition: "all 0.5s",
      marginLeft:4
    }}>
      <div style={{
        overflow: 'hidden',
        display: "flex",
        flexDirection: "column",
        padding:selected ? '10px 0px' : '0px 0px',
        transition: "all 0.5s",
        gap:14
      }}>
        {children}
      </div>
    </div>
  )
}