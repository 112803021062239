import {useCallback, useEffect, useRef} from "react";
import {applyCurrentFilters, applyCurrentFiltersOnLayer} from "../components/Filter/ApplyFilters";
import {useDispatch, useSelector} from "react-redux";
import {setFilters_actionType} from "../redux/constants";
import useLayersChange from "./useLayersChange";
import {view} from "../utils/API";

const useFilters = ({isActive, config}, useDebounce = true) => {
  const {filters, activeModule} = useSelector(state=>state);
  const {countAdded} = useLayersChange(config)
  const dispatch = useDispatch();
  const appliedFilters = useRef([]);
  const module = useRef(activeModule);
  
  // useEffect(()=>{
  //   let timer;
  //   if (countAdded > 0 && isActive){
  //     timer = setTimeout(()=>{
  //       view.map.layers.forEach(layer=>{
  //         if (!appliedFilters.current.includes(layer.id)){
  //           appliedFilters.current.push(layer.id)
  //           applyCurrentFiltersOnLayer(layer, filters[activeModule], config);
  //         }
  //       })
  //     },100)
  //   }
  //
  //   return ()=>{
  //     clearTimeout(timer);
  //   }
  // },[countAdded])
  
  useEffect(() => {
    if (isActive) {
      applyCurrentFilters(filters[activeModule], config)
    }
  }, [activeModule])
  
  useEffect(() => {
    if (isActive && useDebounce) {
      applyFilters()
    }
  }, [filters, useDebounce])
  
  let timer
  const applyFilters = useCallback(() => {
    //Prevent creating multiple queries while user is still typing
    if (timer)
      return
    
    timer = setInterval(() => {
      clearInterval(timer)
      timer = null
      applyCurrentFilters(filters[activeModule], config)
    }, 1000)
  }, [filters])
  
  const setFilters = (f) => {
    dispatch({ type: setFilters_actionType, payload: f })
  }
  
  const setAndApplyFilters = useCallback((filters) =>{
    applyCurrentFilters(filters[activeModule], config);
    setFilters(filters);
  },[activeModule, config])
  
  return {
    filters,
    setFilters,
    setAndApplyFilters
  }
}


export default useFilters;
