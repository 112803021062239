import {isdeletedFieldName, opidFieldName, showpublicFieldName} from "../../../../utils/API";
import {supportedGeometryTypes} from "../../../Editor/Editor";

export const getFieldDefaultValue = (f, config) => {
  if (f.name === 'iso3' || f.name === 'iso3a'){
    return config.iso3 ? Array.isArray(config.iso3) ? config.iso3[0] : config.iso3 : ""
  } else if (f.name === opidFieldName){
    return config.id
  } else if (f.name === isdeletedFieldName || f.name === showpublicFieldName){
    return 2
  } else if (f.defaultValue)
    return f.defaultValue
}

export const isEditable = (l) => {
  return l.type === "feature" && supportedGeometryTypes.includes(l.geometryType) && l.editingEnabled && l.layerConfig?.editable
}